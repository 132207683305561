/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import AxiosRepository from './axios.repository';
import IEngineeringRepository from '@digital-office/common/interfaces/EngineeringRepository';
import {
  EditableFields,
  IServiceRequestParams,
  IListParams,
  IFFAUpdatePayload,
  IBodyParams,
  IProjectUpdatePayload,
  IShowCaseListParams,
  ICountryPageParams,
  IProjectFundingSourcePayload,
  ICostDetailItem,
  IProjectCostPayload,
  IProjectCostStagePaymentPayload,
  IProjectCostVariationItem,
} from '@digital-office/common/interfaces';
import { IProjectPayload } from '@digital-office/pages/ProjectsManagementPages/ProjectConversionPage/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authenticateRequest = function (req: AxiosRequestConfig<any>) {
  const authorization = String(req.headers?.['Authorization']);
  const isWithoutBearer = ['/user-profile/managed-facilities/'].some((path) => req.url?.includes(path));

  if (isWithoutBearer && authorization.startsWith('Bearer')) {
    req.headers!['Authentication'] = authorization.split(' ')[1];
    delete req.headers!['Authorization'];
  }

  return req;
};

class EngineeringRepository extends AxiosRepository implements IEngineeringRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
    this.axiosClient.interceptors.request.use(authenticateRequest);
  }

  async getServiceRequestList(facilityId: number, params: IServiceRequestParams) {
    const { data } = await this.get(`facilities/${facilityId}/service-requests/`, { params: params });
    return data;
  }

  async fieldAssessmentsListPage(facilityId: number, params: IServiceRequestParams) {
    const { data } = await this.get(`facilities/${facilityId}/field-assessments-reports/`, { params: params });
    return data;
  }

  async getAssessmentsList(facilityId: number, params: IServiceRequestParams) {
    const { data } = await this.get(`/facilities/${facilityId}/ffa-assessments/`, { params: params });
    return data;
  }

  async getAssessmentsChartData(facilityId: number) {
    const { data } = await this.get(`facilities/${facilityId}/activities/chart`);
    return data;
  }

  async getActivityDashboard(facilityId: number, params: IListParams) {
    const { data } = await this.get(`facilities/${facilityId}/activities/dashboard/`, { params: params });
    return data;
  }

  async getAssessmentsDetails(facilityId: number, uuid: string, activity_id: string) {
    const { data } = await this.get(`facilities/${facilityId}/ffa-assessments/${uuid}/`);
    return data;
  }

  async getGuidanceActivities() {
    const { data } = await this.get(`/activities/`, { params: { limit: 200 } });
    return data;
  }

  async getCountryPages(facilityId: number, params: ICountryPageParams | null) {
    const { data } = await this.get(`facilities/${facilityId}/country-pages/`, { params });
    return data;
  }

  async getCountryPageDetails(facilityId: number, uuid: string) {
    const { data } = await this.get(`facilities/${facilityId}/country-pages/${uuid}/`);
    return data;
  }

  async getCountryOverviewPage(facilityId: number, country_iso_num: string) {
    const { data } = await this.get(`facilities/${facilityId}/country-pages/${country_iso_num}/display/`);
    return data;
  }

  async postCountryPage(facilityId: number, payload: any) {
    const { data } = await this.post(`facilities/${facilityId}/country-pages/`, { ...payload });
    return data;
  }

  async updateCountryPage(facilityId: number, iso_code: string, payload: any) {
    const { data } = await this.patch(`facilities/${facilityId}/country-pages/${iso_code}/`, { ...payload });
    return data;
  }

  async approveCountryPage(facilityId: number, iso_code: string) {
    const { data } = await this.post(`facilities/${facilityId}/country-pages/${iso_code}/approve/`);
    return data;
  }

  async getStories(facilityId: number, params: IShowCaseListParams | null) {
    const { data } = await this.get(`facilities/${facilityId}/stories/`, { params });
    return data;
  }

  async getStoryDetails(facilityId: number, uuid: string) {
    const { data } = await this.get(`facilities/${facilityId}/stories/${uuid}/`);
    return data;
  }

  async postStory(facilityId: number, payload: any) {
    const { data } = await this.post(`facilities/${facilityId}/stories/`, { ...payload });
    return data;
  }

  async updateStory(facilityId: number, uuid: string, payload: any) {
    const { data } = await this.patch(`facilities/${facilityId}/stories/${uuid}/`, { ...payload });
    return data;
  }

  async approveStory(facilityId: number, uuid: string) {
    const { data } = await this.post(`facilities/${facilityId}/stories/${uuid}/approve/`, {});
    return data;
  }

  async sendActivityRate(activityId: string, country: string) {
    const { data } = await this.post(`/activity-statistics/`, {
      activity: activityId,
      country: country,
    });
    return data;
  }

  async updateAssessment(facilityId: number, uuid: string, payload: IFFAUpdatePayload) {
    const { data } = await this.patch(`/facilities/${facilityId}/ffa-assessments/${uuid}/`, { ...payload });
    return data;
  }

  async getGuidanceRiskCategories() {
    const { data } = await this.get(`/risk_categories/`);
    return data;
  }

  async getDemandOverview(facilityId: number, params: IListParams) {
    const { data } = await this.get(`facilities/${facilityId}/service-requests/demand-overview/`, {
      params: params,
    });
    return data;
  }

  async getServiceRequestDetails(facilityId: number, uuid: string) {
    const { data } = await this.get(`facilities/${facilityId}/service-requests/${uuid}/`);
    return data;
  }

  async getDataForProjectConversion(facilityId: number, uuid: string) {
    const { data } = await this.get(`facilities/${facilityId}/service-requests/data-for-project/${uuid}/`);
    return data;
  }

  async getServiceRequestLocations() {
    const { data } = await this.get(`location-types/`);
    return data;
  }

  async getServiceRequestServices(facilityId: number) {
    const { data } = await this.get(`facilities/${facilityId}/services/`);
    return data;
  }

  async getServiceRequestsCSV(facilityId: number) {
    const { data } = await this.get(`facilities/${facilityId}/service-requests/csv`);
    return data;
  }

  async getInfrastructureCategories() {
    const { data } = await this.get(`/infrastructure-categories/`);
    return data;
  }

  async getProjectsOverview(selectedCategories: string) {
    const { data } = await this.get(`/stories/overview/`, {
      params: {
        infrastructure_category: selectedCategories,
      },
    });
    return data;
  }

  async getProjectsDetails(facilityId: number, uuid: string) {
    const { data } = await this.get(`facilities/${facilityId}/projects/${uuid}/`);
    return data;
  }

  async getProjectRepository(facilityId: number, uuid: string) {
    const { data } = await this.get(`facilities/${facilityId}/projects/${uuid}/sharepoint-tree/`);
    return data;
  }

  async getCountryOverview(countryIsoCode: string, selectedCategories: string | null) {
    const { data } = await this.get(`/stories/country-overview/${countryIsoCode}/`, {
      params: {
        infrastructure_category: selectedCategories,
      },
    });
    return data;
  }

  async getCountryPageOptions(facilityId: number, countryIsoCode: string) {
    const { data } = await this.get(`facilities/${facilityId}/country-pages/${countryIsoCode}/options-for-create/`);
    return data;
  }

  async getGeoJSON() {
    const { data } = await axios.get(`/countries.geo.json`);

    return data;
  }

  async getCountries() {
    const { data } = await this.get(`/countries/`, {
      params: {
        limit: 500,
      },
    });
    return data;
  }

  async getProjectData(uuid: string) {
    const { data } = await this.get(`/stories/${uuid}/`);
    return data;
  }

  async getProjectRiskData(facilityId: number, uuid: string) {
    const { data } = await this.get(`/facilities/${facilityId}/projects/${uuid}/risks/`, {
      params: {
        limit: 500,
      },
    });
    return data;
  }

  async getFacilities(organization: string) {
    const { data } = await this.get(`/facilities/?limit=200&offset=0&organisation=${organization}`);
    return data;
  }

  async getRiskExport(facilityId: number, uuid: string) {
    const { data } = await this.get(`/facilities/${facilityId}/projects/${uuid}/risks-export/`, {
      responseType: 'arraybuffer',
    });
    return data;
  }

  async getPaymentExport(facilityId: number, uuid: string, cost_detail_uuid: string) {
    const { data } = await this.get(
      `/facilities/${facilityId}/projects/${uuid}/financials/cost-details/${cost_detail_uuid}/payments/export/`,
      {
        responseType: 'arraybuffer',
      }
    );
    return data;
  }

  async getStagePaymentExport(facilityId: number, uuid: string, cost_detail_uuid: string) {
    const { data } = await this.get(
      `/facilities/${facilityId}/projects/${uuid}/financials/cost-details/${cost_detail_uuid}/stage-payments/export/`,
      {
        responseType: 'arraybuffer',
      }
    );
    return data;
  }

  async getVariationsExport(facilityId: number, uuid: string, cost_detail_uuid: string) {
    const { data } = await this.get(
      `/facilities/${facilityId}/projects/${uuid}/financials/cost-details/${cost_detail_uuid}/variations/export/`,
      {
        responseType: 'arraybuffer',
      }
    );
    return data;
  }

  async getProjectRiskDetails(facilityId: number, uuid: string, riskId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/projects/${uuid}/risks/${riskId}/`);
    return data;
  }

  async getAllProjectUpdates(facilityId: number, params: IListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/projects/status-updates/`, { params });
    return data;
  }

  async getProjectUpdates(facilityId: number, uuid: string) {
    const { data } = await this.get(`/facilities/${facilityId}/projects/${uuid}/status-updates/`);
    return data;
  }

  async getProjectFundingSources(facilityId: number, uuid: string, params: IListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/projects/${uuid}/financials/funding-sources/`, {
      params,
    });
    return data;
  }

  async getProjectCostDetails(facilityId: number, uuid: string, params: IListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/projects/${uuid}/financials/cost-details/`, {
      params,
    });
    return data;
  }

  async getProjectCostPayments(facilityId: number, project_uuid: string, cost_uuid: string, params: IListParams) {
    const { data } = await this.get(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}/payments/`,
      {
        params,
      }
    );
    return data;
  }

  async getProjectCostStagePayments(facilityId: number, project_uuid: string, cost_uuid: string, params: IListParams) {
    const { data } = await this.get(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}/stage-payments/`,
      {
        params,
      }
    );
    return data;
  }

  async getProjectVariationsList(facilityId: number, project_uuid: string, cost_uuid: string, params: IListParams) {
    const { data } = await this.get(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}/variations/`,
      {
        params,
      }
    );
    return data;
  }

  async getProjectOverview(facilityId: number, params: IListParams) {
    const { data } = await this.get(`/facilities/${facilityId}/projects/overview/`, { params });
    return data;
  }

  async getProjectStory(uuid: string) {
    const { data } = await this.get(`/stories/${uuid}?long=true/`);
    return data;
  }

  async getFieldAssessmentFormState(facilityId: number, uuid: string) {
    const { data } = await this.get(`/facilities/${facilityId}/field-assessments-reports/${uuid}/`);
    return data;
  }

  async getFieldAssessmentProjects(facilityId: number) {
    const { data } = await this.get(`/facilities/${facilityId}/field-assessments-reports/projects/`, {
      params: { limit: 1000 },
    });
    return data;
  }

  async postFieldAssessmentProject(facilityId: number, projectUuid: string) {
    const { data } = await this.post(`/facilities/${facilityId}/field-assessments-reports/draft/projects/`, {
      project: projectUuid,
    });
    return data;
  }

  async reviewAssessment(facilityId: number, fieldAssessmentID: string) {
    const { data } = await this.patch(
      `/facilities/${facilityId}/field-assessments-reports/${fieldAssessmentID}/review/`,
      {}
    );
    return data;
  }

  async getFieldAssessmentFile(facilityId: number, fieldAssessmentID: string) {
    const { data } = await this.get(
      `/facilities/${facilityId}/field-assessments-reports/${fieldAssessmentID}/published/export`
    );
    return data;
  }

  async publishAssessment(facilityId: number, fieldAssessmentID: string) {
    const { data } = await this.patch(
      `/facilities/${facilityId}/field-assessments-reports/${fieldAssessmentID}/publish/`,
      {}
    );
    return data;
  }

  async postFieldAssessmentServiceRequest(facilityId: number, serviceRequestId: string) {
    const { data } = await this.post(`/facilities/${facilityId}/field-assessments-reports/draft/service-requests/`, {
      service_request: serviceRequestId,
    });
    return data;
  }

  async getFieldAssessmentServiceRequests(facilityId: number) {
    const { data } = await this.get(`/facilities/${facilityId}/field-assessments-reports/service-requests/`, {
      params: { limit: 1000 },
    });
    return data;
  }

  async updateFieldAssessmentFormStateDraft(facilityId: number, uuid: string, payload: any) {
    const { data } = await this.patch(`/facilities/${facilityId}/field-assessments-reports/${uuid}/draft/`, {
      ...payload,
    });
    return data;
  }

  async finalizeStepFieldAssessmentFormState(facilityId: number, uuid: string, payload: any) {
    const { data } = await this.put(`/facilities/${facilityId}/field-assessments-reports/${uuid}/finalize-step/`, {
      ...payload,
    });
    return data;
  }

  async removeFieldAssessmentReport(facilityId: number, uuid: string) {
    const { data } = await this.delete(`/facilities/${facilityId}/field-assessments-reports/${uuid}/`);
    return data;
  }

  async getUserDetails() {
    const { data } = await this.get(`users/me/`);
    return data;
  }

  async getServiceRequestHistory(facilityId: number, uuid: string) {
    const { data } = await this.get(`facilities/${facilityId}/service-requests/${uuid}/history/`);
    return data;
  }

  async getFocalPointsOptions(facilityId: number) {
    const { data } = await this.get(`facilities/${facilityId}/focal-points/`, {
      params: {
        limit: 900,
      },
    });
    return data;
  }

  async getProjects(facilityId: number, params: IListParams) {
    const { data } = await this.get(`facilities/${facilityId}/projects/`, { params });
    return data;
  }

  async getDeliverableTemplates(facilityId: number) {
    const { data } = await this.get(`facilities/${facilityId}/deliverable-templates/`);
    return data;
  }

  async getCustomerAreaHomePageData() {
    const { data } = await this.get(`/customer-area/`);
    return data;
  }

  async getCustomerAreaRequestsList(params: IServiceRequestParams | null) {
    const { data } = await this.get(`/customer-area/my-requests/`, { params: params });
    return data;
  }

  async getCustomerAreaProjectList() {
    const { data } = await this.get(`/customer-area/my-projects/`);
    return data;
  }

  async sendShareProjectsEmails(uuid: string, emails: string[]) {
    const { data } = await this.patch(`/customer-area/my-projects/${uuid}/share`, { collaborators: emails });
    return data;
  }

  async getCustomerAreaUpdates() {
    const { data } = await this.get(`/customer-area/my-project-updates/`);
    return data;
  }

  async getSwitches() {
    const { data } = await this.get(`/waffle/switches/`);
    return data;
  }

  async getManagedFacilities() {
    const { data } = await this.get(
      `${process.env.REACT_APP_HBH_PERMISSION_ENDPOINT}managed-facilities/?category=engineering&page_size=10000`
    );
    return data;
  }

  async updateServiceRequest(facilityId: number, uuid: string, bodyData: EditableFields) {
    const { data } = await this.patch(`facilities/${facilityId}/service-requests/${uuid}/`, { ...bodyData });
    return data;
  }

  async postProjectUpdate(facilityId: number, uuid: string, payload: IProjectUpdatePayload) {
    const { data } = await this.post(`/facilities/${facilityId}/projects/${uuid}/status-updates/`, payload);
    return data;
  }

  async postDeliverableTemplate(facilityId: number, payload: any) {
    const { data } = await this.post(`/facilities/${facilityId}/deliverable-templates/`, payload);
    return data;
  }

  async postProjectRisk(facilityId: number, uuid: string, payload: any) {
    const { data } = await this.post(`/facilities/${facilityId}/projects/${uuid}/risks/`, payload);
    return data;
  }

  async sendDataForProjectConversion(facilityId: number, uuid: string, bodyData: IProjectPayload) {
    const { data } = await this.post(`facilities/${facilityId}/projects/`, {
      ...bodyData,
    });
    return data;
  }

  async sendProjectCostPayments(
    facilityId: number,
    project_uuid: string,
    cost_uuid: string,
    payload: IProjectCostPayload
  ) {
    const { data } = await this.post(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}/payments/`,
      payload
    );
    return data;
  }

  async sendProjectCostStagePayment(
    facilityId: number,
    project_uuid: string,
    cost_uuid: string,
    payload: IProjectCostStagePaymentPayload
  ) {
    const { data } = await this.post(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}/stage-payments/`,
      payload
    );
    return data;
  }

  async sendProjectCostVariation(
    facilityId: number,
    project_uuid: string,
    cost_uuid: string,
    payload: IProjectCostVariationItem
  ) {
    const { data } = await this.post(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}/variations/`,
      payload
    );
    return data;
  }

  async updateProjectCostPayments(
    facilityId: number,
    project_uuid: string,
    cost_uuid: string,
    payment_uuid: string,
    payload: IProjectCostPayload
  ) {
    const { data } = await this.patch(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}/payments/${payment_uuid}`,
      payload
    );
    return data;
  }

  async updateProjectCostStagePayments(
    facilityId: number,
    project_uuid: string,
    cost_uuid: string,
    payment_uuid: string,
    payload: Partial<IProjectCostStagePaymentPayload>
  ) {
    const { data } = await this.patch(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}/stage-payments/${payment_uuid}`,
      payload
    );
    return data;
  }

  async updateProjectCostVariation(
    facilityId: number,
    project_uuid: string,
    cost_uuid: string,
    variation_uuid: string,
    payload: Partial<IProjectCostVariationItem>
  ) {
    const { data } = await this.patch(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}/variations/${variation_uuid}`,
      payload
    );
    return data;
  }

  async sendAssessment(facilityId: number, bodyData: IBodyParams) {
    const { data } = await this.post(`/ffa-assessments/`, {
      ...bodyData,
    });
    return data;
  }

  async sendEmail(facilityId: number, uuid: string, country: string) {
    await this.post(`/activities/${uuid}/send/`, { country: country });
    return uuid;
  }

  async updateProject(facilityId: number, uuid: string, bodyData: IBodyParams) {
    const { data } = await this.patch(`facilities/${facilityId}/projects/${uuid}/`, bodyData);
    return data;
  }

  async sendProjectFundingSource(facilityId: number, uuid: string, payload: IProjectFundingSourcePayload) {
    const { data } = await this.post(`/facilities/${facilityId}/projects/${uuid}/financials/funding-sources/`, payload);
    return data;
  }

  async updateProjectFundingSource(
    facilityId: number,
    project_uuid: string,
    source_uuid: string,
    payload: IProjectFundingSourcePayload
  ) {
    const { data } = await this.patch(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/funding-sources/${source_uuid}`,
      payload
    );
    return data;
  }

  async removeFundingSource(facilityId: number, project_uuid: string, source_uuid: string) {
    const { data } = await this.delete(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/funding-sources/${source_uuid}`
    );
    return data;
  }

  async updateRisk(facilityId: number, uuid: string, riskId: string, payload: any) {
    const { data } = await this.patch(`/facilities/${facilityId}/projects/${uuid}/risks/${riskId}`, payload);
    return data;
  }

  async updateProjectCostDetail(
    facilityId: number,
    project_uuid: string,
    cost_uuid: string,
    payload: Partial<ICostDetailItem>
  ) {
    const { data } = await this.patch(
      `/facilities/${facilityId}/projects/${project_uuid}/financials/cost-details/${cost_uuid}`,
      payload
    );
    return data;
  }

  async getBookingFormDetails(facilityId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/service-requests/options/`);
    return data;
  }

  async postNewRequest(facilityId: string, payload: FormData) {
    const { data } = await this.post(`/facilities/${facilityId}/service-requests/`, payload);
    return data;
  }
}

export default EngineeringRepository;
