import { IDropdownOption } from '@ui-modules/types';
import { endOfMonth, format, startOfMonth } from 'date-fns';

export interface TimeFilterValue {
  label: string;
  value: { date_from: string; date_to: string };
}

export const getTimeFilterObject = (timeFilter: IDropdownOption | TimeFilterValue | null) => {
  if (timeFilter?.value === 'current_year') {
    const currentYear = new Date().getFullYear();
    return {
      created_after: `${currentYear}-01-01`,
      created_before: `${currentYear}-12-31`,
    };
  } else {
    if (typeof timeFilter?.value === 'object') {
      const dateFrom = timeFilter?.value?.date_from
        ? format(startOfMonth(new Date(timeFilter.value.date_from)), 'yyyy-MM-dd')
        : null;

      const dateTo = timeFilter?.value?.date_to
        ? format(endOfMonth(new Date(timeFilter.value.date_to)), 'yyyy-MM-dd')
        : null;

      return {
        created_after: dateFrom,
        created_before: dateTo,
      };
    }
  }
};
