/* eslint-disable prefer-template */
import axios, { AxiosError } from 'axios';
import { generateCodeChallenge, generateRandomString, retrieveAuthConfigValues } from './utils';
import { TAuthConfig, TTokenData } from './types';
import { LocalStorageKeys } from './constants';

const codeVerifierStorageKey = LocalStorageKeys.PKCE_CODE_VERIFIER;
const CODE_VERIFIER_MIN_LENGTH = 43;

export async function login(authConfig: TAuthConfig) {
  // Create and store a random string in localStorage, used as the 'code_verifier'
  if (authConfig.codeVerifierLength && authConfig?.codeVerifierLength < CODE_VERIFIER_MIN_LENGTH) {
    return console.error('Code Verifier length should be at least 43 characters');
  }

  const codeVerifier = generateRandomString(authConfig.codeVerifierLength || CODE_VERIFIER_MIN_LENGTH);
  localStorage.setItem(codeVerifierStorageKey, codeVerifier);

  // Hash and Base64URL encode the code_verifier, used as the 'code_challenge'
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  const { authorizationEndpoint, clientId, scope, redirectUri } = retrieveAuthConfigValues(authConfig);

  // Set query parameters and redirect user to OAuth2 authentication endpoint
  const params = new URLSearchParams({
    response_type: 'code',
    client_id: clientId,
    scope: scope ?? '',
    redirect_uri: redirectUri ?? '',
  });

  if (['azure', 'b2c'].includes(authConfig.authType as string)) {
    params.append('code_challenge', codeChallenge);
    params.append('code_challenge_method', 'S256');
  }

  if (authConfig.authType === 'google') {
    params.append('state', codeChallenge);
    params.append('access_type', 'offline');
    params.append('prompt', 'consent');
  }

  let signInRequestEndpoint = authorizationEndpoint;
  signInRequestEndpoint += authConfig.authType === 'b2c' ? '&' : '?';
  signInRequestEndpoint += params.toString();

  window.location.replace(signInRequestEndpoint);
}

export const getTokens = async (authConfig: TAuthConfig, authCode: string): Promise<any> => {
  /*
    The browser has been redirected from the authentication endpoint with
    a 'code' url parameter.
    This code will now be exchanged for Access- and Refresh Tokens.
  */
  const codeVerifier = window.localStorage.getItem(codeVerifierStorageKey);

  if (!authCode) {
    throw new Error("Parameter 'code' not found in URL. \nHas authentication taken place?");
  }

  if (!codeVerifier) {
    throw new Error("Can't get tokens without the CodeVerifier. \nHas authentication taken place?");
  }

  const { clientId, redirectUri, tokenEndpoint } = retrieveAuthConfigValues(authConfig);

  const params = new URLSearchParams({
    grant_type: 'authorization_code',
    code: authCode,
    client_id: clientId,
    redirect_uri: redirectUri || '',
  });

  if (authConfig.authType && ['azure', 'b2c'].includes(authConfig.authType)) {
    params.append('code_verifier', codeVerifier);
  }

  if (authConfig.authType && ['google', 'b2c'].includes(authConfig.authType)) {
    const clientSecret =
      authConfig.configurations?.[authConfig.authType as keyof typeof authConfig.configurations]?.clientSecret;

    if (clientSecret) {
      params.append('client_secret', clientSecret);
    }
  }

  try {
    const response = await axios.post(tokenEndpoint, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    return { ok: true, data: response.data };
  } catch (e: any) {
    console.log(e.response.message);
    return { ok: false, error: e.response.message };
  }
};

export const getAccessTokenFromRefreshToken = async (authConfig: TAuthConfig, refreshToken: string) => {
  const { clientId, tokenEndpoint } = retrieveAuthConfigValues(authConfig);

  const params = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: clientId,
    refresh_token: refreshToken,
  });

  if (authConfig.authType && ['google', 'b2c'].includes(authConfig.authType)) {
    const clientSecret =
      authConfig.configurations?.[authConfig.authType as keyof typeof authConfig.configurations]?.clientSecret;

    if (clientSecret) {
      params.append('client_secret', clientSecret);
    }
  }

  const response = await axios.post(tokenEndpoint, params, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

  return response.data;
};

/**
 * Decodes the the base64 encoded JWT. Returns a TToken.
 */
export const decodeToken = (token: string): TTokenData => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (e: any) {
    console.log(e.message);
    throw new Error('Invalid token');
  }
};

/**
 * Check if the Access Token is about to expire by looking at the 'exp' JWT header.
 * Checks expiration in advance in order to receive refresh token.
 * Will return True if the token has expired, OR there is less than 30min until it expires.
 */
export const tokenWillExpire = (exp: number, authConfig: TAuthConfig): boolean => {
  const expirationTime = new Date(exp * 1000);
  const checkTokenMinutesBeforehand = authConfig.triggerRefreshTokenBeforehandMinutes ?? 10;

  const date = new Date(Date.now() + 1000 * 60 * checkTokenMinutesBeforehand);

  // Triggering refresh token in advance before it expires
  return date > expirationTime;
};
