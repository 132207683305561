import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { MAPBOX_API_KEY } from '@components/GlobalImpact/utils';
import { LocationData, IUserDetails } from '@digital-office/common/interfaces';
import { IYupSchema, FormTempValues, yupSchemas, STEPS } from '@pages/BookingFormPage/consts';
import { useFormState, useMultistepApi, Multistep } from 'informed';
import ReactMapGl, { Marker } from 'react-map-gl';
import { format } from 'date-fns';
import { ReactComponent as EditIcon } from '@assets/svg/edit.svg';
import { ReactComponent as ReviewIcon } from '@assets/svg/review.svg';
import { ReactComponent as MapMarkerFull } from '@assets/svg/marker-full.svg';
import { ReactComponent as ArrowIcon } from '@assets/svg/icon_arrow_round.svg';
import { DateFormatEnum } from '@common/utils/constants';
import Navigation from '../Navigation';
import Subtitle from '../Subtitle';
import { Checkbox } from 'antd';
import './styles.scss';

interface ReviewRowProps {
  label: string;
  value?: string | Array<string>;
}

interface ReviewLocationRowProps {
  location?: Array<LocationData>;
}

interface ReviewStepProps {
  userDetails: IUserDetails;
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
}

const convertValuesToReview = (values: FormTempValues) => {
  const { details, project, additional } = values;

  return {
    ...(details
      ? {
          requesting_unit: details.requesting_unit,
          phone_number: details.phone_number,
        }
      : {}),
    ...(project
      ? {
          description: project.description,
          desired_start_date: project.desired_start_date
            ? format(new Date(project.desired_start_date), DateFormatEnum.DD_MM_YYYY)
            : '-',
          estimated_budget: project.estimated_budget,
          fund_expiration_date: project.fund_expiration_date
            ? format(new Date(project.fund_expiration_date), DateFormatEnum.DD_MM_YYYY)
            : '-',
          infrastructure: project.infrastructure?.label,
          infrastructure_category: project.infrastructure_category?.label,
          location: project.location,
          services: (project.services || []).map(({ label }) => label),
          title: project.title,
        }
      : {}),
    ...(additional
      ? {
          attachments:
            additional.attachments && additional.attachments.length > 0
              ? additional.attachments.map(({ name }) => name)
              : 'No attachments',
          rental_expiration_date: additional.rental_expiration_date
            ? format(new Date(additional.rental_expiration_date), DateFormatEnum.DD_MM_YYYY)
            : '-',
          planned_usage_years: additional.planned_usage_years || '-',
          new_construction: additional.new_construction?.label,
          own_status: additional.own_status?.label,
          rehabilitation_existing_building: additional.rehabilitation_existing_building?.label,
        }
      : {}),
  };
};

const ReviewRow: FC<ReviewRowProps> = ({ label, value }) => {
  return (
    <div className='bf-review-row'>
      <div className='bf-review-label'>{label}</div>
      {Array.isArray(value) ? (
        <ul className='bf-review-list'>
          {value.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      ) : (
        <div className='bf-review-value'>{value || '-'}</div>
      )}
    </div>
  );
};

const ReviewLocationRow: FC<ReviewLocationRowProps> = ({ location }) => {
  const [open, setOpen] = useState(false);

  if (!location) return null;

  return (
    <div className='bf-review-row'>
      <div className='bf-review-label'>Project location(s)</div>
      <div className='bf-review-value'>
        <div className='bf-review-locations'>
          {location.map(({ lat, long, place_name }, idx) => (
            <div key={idx} className='bf-review-location'>
              <div className='bf-review-location-icon-wrapper'>
                <div className='bf-review-location-count'>{idx + 1}</div>
                <MapMarkerFull className='bf-review-location-icon' />
              </div>
              <div className='bf-review-location-text'>{`${place_name} ${lat} ${long}`}</div>
            </div>
          ))}
        </div>
        <button type='button' className='bf-review-location-button' onClick={() => setOpen(!open)}>
          Show location(s) on the map <ArrowIcon className={`bf-review-location-arrow ${open ? 'open' : ''}`} />
        </button>
        {open ? (
          <div className='map-field-map'>
            <ReactMapGl
              attributionControl={false}
              style={{ width: '100%', height: 300 }}
              initialViewState={{
                longitude: location[0].long,
                latitude: location[0].lat,
                zoom: 4,
              }}
              mapStyle='mapbox://styles/mapbox/streets-v11'
              mapboxAccessToken={MAPBOX_API_KEY}
            >
              {location.map(({ long, lat }, idx) => (
                <Marker key={idx} longitude={long} latitude={lat}>
                  <div className='map-field-marker-custom'>
                    <div className='map-field-marker-custom-count'>{idx + 1}</div>
                    <MapMarkerFull className='map-field-marker-custom-icon' />
                  </div>
                </Marker>
              ))}
            </ReactMapGl>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const ReviewStep: FC<ReviewStepProps> = ({ setYupSchema, userDetails }) => {
  const formState = useFormState();
  const { setCurrent } = useMultistepApi();
  const [confirmEnabled, setConfirmEnabled] = useState(false);

  const {
    attachments,
    description,
    desired_start_date,
    estimated_budget,
    fund_expiration_date,
    infrastructure,
    infrastructure_category,
    location,
    new_construction,
    own_status,
    phone_number,
    planned_usage_years,
    rehabilitation_existing_building,
    rental_expiration_date,
    requesting_unit,
    services,
    title,
  } = convertValuesToReview(formState.values as unknown as FormTempValues);

  const { email, first_name, last_name, organisation } = userDetails;

  const onClick = (step: string) => {
    setCurrent(step);
    setYupSchema(yupSchemas[step]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Multistep.Step step={STEPS.REVIEW}>
      <Subtitle text='Review and submit' icon={<ReviewIcon />} />

      <div className='bf-review-section'>
        <div className='bf-review-title'>
          Requestor details{' '}
          <button className='bf-review-title-button' onClick={() => onClick(STEPS.DETAILS)}>
            Edit <EditIcon />
          </button>
        </div>
        <ReviewRow label='Name' value={first_name} />
        <ReviewRow label='Surname' value={last_name} />
        <ReviewRow label='Organization' value={organisation || '-'} />
        <ReviewRow label='Email' value={email} />
        <ReviewRow label='Phone number' value={phone_number} />
        <ReviewRow label='Requesting unit' value={requesting_unit} />
      </div>

      <div className='bf-review-section'>
        <div className='bf-review-title'>
          Project information{' '}
          <button className='bf-review-title-button' onClick={() => onClick(STEPS.PROJECT)}>
            Edit <EditIcon />
          </button>
        </div>
        <ReviewRow label='Infrastructure category' value={infrastructure_category} />
        <ReviewRow label='Infrastructure' value={infrastructure} />
        <ReviewLocationRow location={location} />
        <ReviewRow label='Desired start date' value={desired_start_date} />
        <ReviewRow label='Project estimated budget (USD)' value={estimated_budget} />
        <ReviewRow label='Fund expiration date (optional)' value={fund_expiration_date} />
        <ReviewRow label='Select the Engineering Services you will need for the project' value={services} />
        <ReviewRow label='Project title' value={title} />
        <ReviewRow label='Project description' value={description} />
      </div>

      <div className='bf-review-section'>
        <div className='bf-review-title'>
          Additional information{' '}
          <button className='bf-review-title-button' onClick={() => onClick(STEPS.ADDITIONAL)}>
            Edit <EditIcon />
          </button>
        </div>
        <ReviewRow label='New construction' value={new_construction} />
        <ReviewRow label='Rehabilitation existing buildings' value={rehabilitation_existing_building} />
        <ReviewRow label='Is the land/building owned or rented?' value={own_status} />
        <ReviewRow label='Rental expiration date' value={rental_expiration_date} />
        <ReviewRow label='Planned usage years (optional)' value={planned_usage_years} />
        <ReviewRow label='Upload attachments' value={attachments} />
      </div>

      <div className='bf-review-terms'>
        <Checkbox onChange={(e) => setConfirmEnabled(e.target.checked)} className='checkbox' checked={confirmEnabled}>
          <div className='bf-review-terms-text'>I have read and accept the Terms and Conditions *</div>
        </Checkbox>
        <a
          className='bf-review-terms-link'
          href='https://unbooking.org/en/terms-of-use/'
          target='_blank'
          rel='noreferrer'
        >
          Click here to open in a new window
        </a>
      </div>

      <Navigation setYupSchema={setYupSchema} confirmEnabled={confirmEnabled} />
    </Multistep.Step>
  );
};

export default ReviewStep;
