import React from 'react';
import { ReactComponent as InProgressIcon } from '@assets/svg/in_progress.svg';
import { ReactComponent as PlannedIcon } from '@assets/svg/planned.svg';
import { ReactComponent as CompletedIcon } from '@assets/svg/completed.svg';
import { ReactComponent as PartnersIcon } from '@assets/svg/partners.svg';
import './styles.scss';
import { Button } from '@ui-modules';
import DiamondIcon from '@components/GlobalImpact/components/DiamondIcon';
import { IProjectData } from '@digital-office/common/interfaces';
import { useNavigate } from 'react-router-dom';

interface IProps {
  country: string;
  data: IProjectData;
  projectId: string;
}

const getBackgroundImage = (url: string) => {
  return {
    backgroundImage: `url(${url}), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))`,
    backgroundBlendMode: 'overlay',
    backgroundSize: 'cover',
  };
};

const MAX_TEXT_LENGTH = 250;

const ProjectSummary = ({ data, country, projectId }: IProps) => {
  const navigate = useNavigate();

  const statusData = {
    Planned: {
      title: 'Planned',
      icon: <PlannedIcon className='info-icon' />,
      description: 'Infrastructure planned',
    },
    Completed: {
      title: 'Completed',
      icon: <CompletedIcon className='info-icon' />,
      description: 'Infrastructure completed',
    },
    ['In Progress']: {
      title: 'In progress',
      icon: <InProgressIcon className='info-icon' />,
      description: 'Infrastructure in progress',
    },
  };

  const description =
    data.snapshot.length > MAX_TEXT_LENGTH ? `${data.snapshot.substring(0, MAX_TEXT_LENGTH)}...` : data.snapshot;

  return (
    <div className='project-summary-wrapper'>
      <div className='country-intro' style={data?.main_picture ? getBackgroundImage(data.main_picture) : {}}>
        <p className='summary-title'>{data.title}</p>
        <p className='summary-country'>{country}</p>
        <DiamondIcon
          customClassNames='project-icon'
          color={data.infrastructure_category_color}
          iconSrc={data.infrastructure_category_icon}
        />
      </div>
      <div className='project-info-wrapper'>
        <p className='information-label'>Background</p>
        <p>{description}</p>
        {data.show_story_button && (
          <Button onClick={() => navigate(projectId)} className='story-button' text='Read more about the project' />
        )}
        <div className='projects-info-list'>
          <div className='project-info-element'>
            {statusData[data.status_name].icon}
            <p className='project-label'>{statusData[data.status_name].title}</p>
            <p className='value'>{"Project's status"}</p>
          </div>
          <div className='project-info-element'>
            <img src={data.infrastructure_category_icon} className='infrastructure-icon' />
            <p className='project-label'>{data?.infrastructure_category_name}</p>
            <p className='value'>Infrastructure Category</p>
          </div>
          {data?.partners_involved?.length ? (
            <div className='project-info-element'>
              <PartnersIcon className='info-icon' />
              <p className='project-label'>{data?.partners_involved}</p>
              <p className='value'>Main partner(s)</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProjectSummary;
