import React, { useMemo, useRef, useState } from 'react';
import { Button, PageTitle, Tabs } from '@ui-modules';
import { message, Modal, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormApi, FormStateAccessor } from 'informed';
import { Tooltip } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { IDropdownOption } from '@ui-modules/types';
import LocationSection from '@digital-office/pages/ProjectsManagementPages/components/LocationSection';
import { ILocationObject } from '@digital-office/pages/ProjectsManagementPages/components/LocationModal';
import PhaseSection, { IPhase } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection';
import {
  IFormData,
  IProjectPayload,
  onSubmitHandler,
} from '@digital-office/pages/ProjectsManagementPages/ProjectConversionPage/utils';
import { mapDataToComponents, updateForm, validationSchema } from '@digital-office/pages/ProjectsManagementPages/utils';
import { useRepository } from '@context/repository.context';
import { WarningFilled } from '@ant-design/icons';
import { ReactComponent as SuccessIcon } from '@assets/svg/project_created.svg';
import { OVERVIEW_TAB, PHASES_TAB } from '@digital-office/pages/ProjectsManagementPages/constants';
import formConfig from '../formConfig';
import './styles.scss';
import { IDeliverableTemplatesResponse } from '@digital-office/common/interfaces';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

export interface IOptions {
  [key: string]: IDropdownOption[];
}

export interface IDataForProject {
  code: string;
  options: {
    [key: string]: IDropdownOption[];
  };
  prefill_data: {
    [key: string]: string | number;
  };
}

const tabs = [OVERVIEW_TAB, PHASES_TAB];

const ProjectConversionPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const facilityId = +localStorage.getItem('facility')!;
  const [locations, setLocations] = useState<ILocationObject[]>([]);
  const [formData, setFormData] = useState<Record<any, any> | null>(null);
  const [selectedPhases, setSelectedPhases] = useState<IPhase[]>([]);
  const [createdProjectId, setCreatedProjectId] = useState<string>('');
  const navigate = useNavigate();
  const [isSuccessfulModalActive, setIsSuccessfulModalActive] = useState<boolean>(false);
  const { engineeringRepository } = useRepository();
  const [projectTemplates, setProjectTemplates] = useState<IDropdownOption[] | undefined>(undefined);

  const isOverviewTabCompleted = !formData || !locations.length;

  const { id: conversionId } = useParams<{ id: string }>();

  const getConversionId = () => {
    return !conversionId || conversionId === 'new' ? 'default' : conversionId;
  };

  const { data: projectData, isLoading } = useQuery<IDataForProject>(
    `request-${conversionId}`,
    () => engineeringRepository.getDataForProjectConversion(facilityId, getConversionId()),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { refetch } = useQuery<IDeliverableTemplatesResponse>(
    `deliverable-templates`,
    () => engineeringRepository.getDeliverableTemplates(facilityId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const options = data?.results.map((template) => ({
          label: template.name,
          value: template.name,
        }));

        setProjectTemplates(options);
      },
    }
  );

  const sendDeliverableTemplateMutation = useMutation(
    (data: { name: string }) => engineeringRepository.postDeliverableTemplate(facilityId, data),
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const sendProject = useMutation(
    (data: IProjectPayload) => engineeringRepository.sendDataForProjectConversion(facilityId, getConversionId(), data),
    {
      onSuccess: (data) => {
        setCreatedProjectId(data.uuid);
        setIsSuccessfulModalActive(true);
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const options = projectData?.options;

  const formPrefillData = { ...projectData?.prefill_data };

  const onBlurHandler = useMemo(() => {
    return () => {
      updateForm(formRef, setFormData);
    };
  }, [options]);

  const formRef = useRef<FormApi>(null);

  const getInitialValues = () => {
    if (!formRef.current && formPrefillData) {
      return formPrefillData;
    }
    return (formRef.current && formRef.current.getFormState().values) || {};
  };

  return (
    <div className='conversion-wrapper'>
      <Spin spinning={isLoading}>
        <Modal
          footer={null}
          title={
            <div className={'modal-title'}>
              <p>PROJECT CREATED SUCCESSFULLY</p>
            </div>
          }
          width={'40%'}
          centered
          closable={false}
          open={isSuccessfulModalActive}
        >
          <div className='project-creation-success-modal'>
            <div className='message-wrapper'>
              <SuccessIcon className='icon' />
              <p className='te'>
                Project <strong>{formData?.code}</strong> was created successfully! You will now be able to track
                project status and deliverables and manage project documents!
              </p>
            </div>
            <Button
              onClick={() => navigate(`/digital-office/projects/${createdProjectId}`)}
              className='project-button'
              text='Go to project'
              variant='submit'
            />
          </div>
        </Modal>
        <div className='page-title-wrapper'>
          <PageTitle
            title={`Convert engineering request, ${projectData?.prefill_data?.reference_code || ''} to Project`}
          />
          {conversionId === 'new' ? (
            <VideoLink link={videoLinks.PROJECT_CREATION} />
          ) : (
            <VideoLink link={videoLinks.SERVICE_REQUEST} />
          )}
        </div>
        <div className='booking-list'>
          <Tabs
            disabledTabs={isOverviewTabCompleted ? [PHASES_TAB] : []}
            className='engineering-tabs'
            tabs={tabs}
            selectedTab={tabs.indexOf(selectedTab)}
            tabTooltip={isOverviewTabCompleted ? { [PHASES_TAB]: 'Please fill in mandatory fields first' } : {}}
            onTabSelect={setSelectedTab}
          />
        </div>
        <div className='booking-details-container hbh-container project-form-wrapper'>
          {selectedTab === OVERVIEW_TAB && (
            <div>
              <h3 className='section-header'>Project general information</h3>
              {projectData && (
                <Form
                  yupSchema={validationSchema}
                  onBlur={onBlurHandler}
                  validateOn='change-blur'
                  onSubmit={(formState) => setFormData(formState.values)}
                  formApiRef={formRef}
                  initialValues={getInitialValues()}
                >
                  <FormStateAccessor>
                    {({ values }) => {
                      return (
                        <div className='hbh-details-container conversion-section'>
                          {formConfig(projectData?.code || '', 'conversion').map((formData) => {
                            return mapDataToComponents(true, formData, projectData?.options || {}, values);
                          })}
                        </div>
                      );
                    }}
                  </FormStateAccessor>
                </Form>
              )}
              <h3 className='section-header'>Project infrastructures and deliverables information</h3>
              <div className='hbh-details-container conversion-section map-section'>
                <div className='hbh-details-item map-section-details'>
                  {!locations.length && (
                    <Tooltip placement='left' title='At least 1 location must be added'>
                      <WarningFilled className='details-tooltip no-location-warning' />
                    </Tooltip>
                  )}
                  <div className='hbh-details-item-label'>
                    Preview of Project location(s) - Click on &quot;ADD PROJECT INFRASTRUCTURES & LOCATION&quot; to
                    insert more
                  </div>
                  <div className='hbh-details-item-value'>
                    <LocationSection
                      infrastructures={projectData?.options?.infrastructures || []}
                      locations={locations}
                      setLocations={setLocations}
                    />
                  </div>
                </div>
              </div>
              <Button
                disabled={isOverviewTabCompleted}
                variant='submit'
                className='confirm-button'
                text='NEXT STEP'
                onClick={() => {
                  const node = document.querySelector('.hbh-tab-select:nth-child(2)') as HTMLDivElement;
                  node.click();
                }}
              />
            </div>
          )}
          {selectedTab === PHASES_TAB && (
            <div>
              <h3 className='section-header'>
                Create and manage Project phases and deliverables - This section is optional and you can add this later
              </h3>
              <div className='hbh-details-container conversion-section map-section'>
                <div className='hbh-details-item map-section-details'>
                  <div className='hbh-details-item-label'>
                    PROJECT PHASES - ADJUST THE EXPECTED PHASES AND DELIVERABLES (NOTE THAT WILL BE ALWAYS POSSIBLE TO
                    UPDATE THESE INFORMATION LATER ON)
                  </div>
                  <div className='hbh-details-item-value'>
                    <PhaseSection
                      persistingPhases={selectedPhases.length ? selectedPhases : undefined}
                      isEditableView={true}
                      showSharepointRemovingSection={false}
                      sendDeliverableTemplateMutation={sendDeliverableTemplateMutation}
                      deliverableTemplates={projectTemplates || []}
                      onPhaseChange={setSelectedPhases}
                    />
                  </div>
                </div>
              </div>
              <Button
                disabled={isOverviewTabCompleted}
                variant='submit'
                className='confirm-button'
                text='CONFIRM PROJECT CREATION'
                onClick={() =>
                  onSubmitHandler(
                    formData as IFormData,
                    selectedPhases,
                    locations,
                    sendProject,
                    projectData?.code || '',
                    formPrefillData
                  )
                }
              />
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default ProjectConversionPage;
