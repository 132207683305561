import { TAuthConfig } from '@common/auth';

const scope = ['email', 'openid', 'profile'].join(' ');

const errorCodes = process.env.REACT_APP_AZURE_REFRESH_TOKEN_ERROR_CODES
  ? process.env.REACT_APP_AZURE_REFRESH_TOKEN_ERROR_CODES.split(',').map((code: string) => parseInt(code))
  : undefined;

const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
  authorizationEndpoint: process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT ?? '',
  autoAuthorize: false,
  unbhPermissionsEndpoint: process.env.REACT_APP_HBH_PERMISSION_ENDPOINT,
  tokenEndpoint: process.env.REACT_APP_AUTH_TOKEN_ENDPOINT ?? '',
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
  logoutEndpoint: process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT ?? '',
  logoutUri: process.env.REACT_APP_AUTH_LOGOUT_URI ?? '',
  hbhSubscriptionToken: process.env.REACT_APP_HBH_SUBSCRIPTION_TOKEN,
  refreshTokenErrorCodes: errorCodes,
  scope,
  configurations: {
    b2c: {
      authorizationEndpoint: process.env.REACT_APP_B2C_AUTHORIZATION_ENDPOINT || '',
      tokenEndpoint: process.env.REACT_APP_B2C_TOKEN_ENDPOINT ?? '',
      clientId: process.env.REACT_APP_B2C_CLIENT_ID || '',
      logoutEndpoint: process.env.REACT_APP_B2C_LOGOUT_ENDPOINT ?? '',
      logoutUri: '/?is_b2c_user=true',
      scope: `${scope} offline_access`,
    },
  },
};

export default authConfig;
