import { IDropdownOption } from '@ui-modules/types';
import { message, Upload, UploadProps } from 'antd';
import React, { useState } from 'react';
import { FormState } from 'informed';
import { ILocationObject } from '@digital-office/pages/ProjectsManagementPages/components/LocationModal';
import { ReactComponent as MapIcon } from '@assets/svg/location_map.svg';
import { RcFile } from 'antd/es/upload';
import { IStoryDetailsResponse } from '@digital-office/common/interfaces';
import { parseLocationData } from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/utils';
import { DeleteFilled, InboxOutlined } from '@ant-design/icons';
import { Image } from 'antd';

const MAX_FILE_SIZE = 2560000; // 250KB
const EXTENSIONS_LIST = ['jpg', 'png', 'jpeg'];

const { Dragger } = Upload;

const getDrawerProps = (
  locations: ILocationObject[],
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
) => ({
  className: 'drawer drawer-projects',
  mask: true,
  title: (
    <div className='title-wrapper'>
      <MapIcon className='project-icon' />
      <span className='country-label'>{locations.length && locations[0].locationData.country}</span>
    </div>
  ),
  onClose: () => setIsDrawerOpen(false),
  headerStyle: {
    backgroundColor: '#CC6027',
    height: 60,
    borderBottom: 'none',
  },
  bodyStyle: {
    padding: 0,
    backgroundColor: '#1E252A',
  },
});

const getProjectData = (
  isEditMode: boolean,
  formState: FormState | null,
  storyDetails: IStoryDetailsResponse | undefined,
  locations: ILocationObject[],
  imageUrl: string,
  infrastructureOptions: IDropdownOption[]
) => {
  if (storyDetails && !isEditMode) {
    const parsedLocations = parseLocationData(storyDetails.project.locations, infrastructureOptions);

    return {
      city_name: parsedLocations[0]?.locationData.place_name,
      infrastructure_category_icon:
        'https://dev.engineering.unbooking.org/public/media/infrastructure_category_icons/community_DuU7ag3.png',
      infrastructure_category_name: storyDetails.infrastructure_category,
      infrastructure_category_color: '#9160AD',
      show_story_button: '',
      infrastructure_name: parsedLocations[0]?.infrastructure.label,
      partners_involved: storyDetails?.partners_involved || [],
      main_picture: storyDetails?.main_picture as string,
      snapshot: storyDetails?.snapshot as string,
      status_name: 'Completed' as const,
      title: storyDetails?.title as string,
    };
  }

  if (formState && locations.length && imageUrl) {
    const category = formState?.values?.infrastructure_category as IDropdownOption;
    return {
      city_name: locations[0].locationData.place_name,
      infrastructure_category_icon:
        'https://dev.engineering.unbooking.org/public/media/infrastructure_category_icons/community_DuU7ag3.png',
      infrastructure_category_name: category?.label,
      infrastructure_category_color: '#9160AD',
      show_story_button: '',
      infrastructure_name: locations[0]?.infrastructure?.label,
      partners_involved: formState?.values['partners_involved'] as string[],
      main_picture: imageUrl as string,
      snapshot: formState?.values['snapshot'] as string,
      status_name: 'Completed' as const,
      title: formState?.values['title'] as string,
    };
  }
  return null;
};

const isFileAllowedSize = (file: File): void | boolean => {
  if (file.size >= MAX_FILE_SIZE) {
    throw new Error(
      `File ${file.name} has too much size. Allowed size of file: ${Math.floor(MAX_FILE_SIZE / 1024 / 1024)} MB`
    );
  }
  return true;
};

const isAllowedExtension = (name: string): void | boolean => {
  if (!new RegExp('(' + EXTENSIONS_LIST.join('|').replace(/\./g, '\\.') + ')$').test(name.toLowerCase())) {
    throw new Error(`${name}: extension incorrect, accepted extensions: ${EXTENSIONS_LIST.join(', ')}`);
  }
  return true;
};

const validateFiles = (file: File): boolean => {
  try {
    isFileAllowedSize(file);
    isAllowedExtension(file.name);

    return true;
  } catch (error: any) {
    message.error(error.message);
    return false;
  }
};

const getUploadProps = (
  multiple: boolean,
  coverFile: RcFile | null,
  imageUrl: undefined | string | ArrayBuffer,
  setCoverFile?: React.Dispatch<React.SetStateAction<RcFile | null>>,
  setImageUrl?: React.Dispatch<React.SetStateAction<string | ArrayBuffer | undefined>>,
  setImagesUrl?: React.Dispatch<React.SetStateAction<RcFile[]>>,
  otherFiles?: RcFile[]
): UploadProps => ({
  multiple: multiple,
  onRemove(upload) {
    if (!multiple) {
      setCoverFile && setCoverFile(null);
    } else {
      setImagesUrl && setImagesUrl((prevState) => prevState.filter((file) => file.name !== upload.name));
    }
  },
  defaultFileList: otherFiles,
  showUploadList: multiple,
  disabled: Boolean(coverFile || imageUrl),
  beforeUpload: (file, fileList) => {
    if (!validateFiles(file)) {
      return Upload.LIST_IGNORE;
    }

    if (multiple && otherFiles?.some((listFile) => listFile.name === file.name)) {
      message.error('File name has been unique');
      return Upload.LIST_IGNORE;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const url = reader.result;

      if (!multiple) {
        url && setImageUrl && setImageUrl(url);
      } else {
        url && setImagesUrl && setImagesUrl((prevState) => [...prevState, file]);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }

    setCoverFile && setCoverFile(file);

    return false;
  },
});

const getStoryCover = (
  coverFile: RcFile | null,
  setCoverFile: React.Dispatch<React.SetStateAction<RcFile | null>>,
  storyDetails: IStoryDetailsResponse | undefined,
  imageUrl: string | ArrayBuffer | undefined,
  setImageUrl: React.Dispatch<React.SetStateAction<string | ArrayBuffer | undefined>>,
  isEditMode: boolean
) => {
  const picturePath = coverFile || storyDetails?.main_picture ? (imageUrl as string) : null;

  return (
    <div>
      {!isEditMode && <p className='picture-display-mode-label'>COVER</p>}
      <div className={`file-picker ${coverFile ? 'image-cover' : ''}`}>
        {isEditMode ? (
          <Dragger {...getUploadProps(false, coverFile, imageUrl, setCoverFile, setImageUrl)}>
            {!coverFile && !imageUrl ? (
              <>
                <p
                  className={`story-label ant-upload-text heading title ${
                    !coverFile && isEditMode && !imageUrl ? 'error' : ''
                  }`}
                >
                  Cover* {!coverFile ? '- REQUIRED' : ''}
                </p>
                <div className='description-info'>
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-text title'>Drag and Drop or browse</p>
                  <p className='ant-upload-text subtitle'>Supports PNG, JPEG, JPG</p>
                </div>
              </>
            ) : (
              <div>
                <div className='selected-file-header'>
                  <p>{coverFile?.name || ''}</p>
                  <DeleteFilled
                    onClick={() => {
                      setCoverFile(null);
                      setImageUrl('');
                    }}
                  />
                </div>
                {picturePath && <img src={picturePath} />}
              </div>
            )}
          </Dragger>
        ) : (
          <>
            <img className='preview-image' src={storyDetails?.main_picture} />
          </>
        )}
      </div>
    </div>
  );
};

const getMultipleOtherPictures = (
  otherFiles: RcFile[],
  setImagesUrl: React.Dispatch<React.SetStateAction<RcFile[]>>,
  storyDetails: IStoryDetailsResponse | undefined,
  isEditMode: boolean,
  disabled: boolean
) => {
  return (
    <div>
      <div className={`file-picker`}>
        {isEditMode ? (
          <Dragger
            maxCount={5}
            {...getUploadProps(true, null, undefined, undefined, undefined, setImagesUrl, otherFiles)}
            className={`${disabled ? 'disabled' : false}`}
          >
            <>
              <p className={`ant-upload-text heading title`}>Other pictures (max 5)</p>
              <div className='description-info'>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text title'>Drag and Drop or browse</p>
                <p className='ant-upload-text subtitle'>Supports PNG, JPEG, JPG</p>
              </div>
            </>
          </Dragger>
        ) : (
          <>
            <p className='picture-display-mode-label'>OTHER PICTURES</p>
            <Image.PreviewGroup items={storyDetails?.pictures.map((pic) => pic.picture)}>
              <Image src={storyDetails?.pictures[0]?.picture} />
            </Image.PreviewGroup>
          </>
        )}
      </div>
    </div>
  );
};

const handleGuidanceDownload = () => {
  const fileUrl = '/guidance.pdf'; // Replace with the actual file path
  const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
  downloadFile(fileUrl, fileName);
};

const downloadFile = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = fileName;
  link.click();
};

const getAmountOfWords = (fieldName: string, requiredWords: number, formState: FormState) => {
  const field: string | unknown = formState?.values[fieldName];

  if (typeof field === 'string') {
    const fieldLength = field.split(/\s+/).length;
    return (
      <p className={`words-counter - ${fieldLength > requiredWords ? 'error' : ''}`}>
        {fieldLength} / {requiredWords} words{' '}
      </p>
    );
  }
};

export {
  getDrawerProps,
  getUploadProps,
  getProjectData,
  getStoryCover,
  getMultipleOtherPictures,
  handleGuidanceDownload,
  getAmountOfWords,
};
