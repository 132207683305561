import React, { useState, useEffect } from 'react';
import { Button, Table, PageTitle, Pagination, Tabs, Dropdown as FiltersDropdown } from '@ui-modules';
import { IDropdownOption } from '@ui-modules/types';
import { useQuery } from 'react-query';
import './styles.scss';
import { message, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { useRepository } from '@context/repository.context';
import { StatusList, IUserDetails, IServiceRequestParams } from '@digital-office/common/interfaces/Api';
import { ICustomerAreaServiceRequestListItem, IStatusProperty } from '@digital-office/common/interfaces';
import { stringToCSVFile } from '@common/utils/stringToCSVFile';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

export const getBookingStateBtn = (record: string, statuses: StatusList): JSX.Element => {
  const statusProperties: IStatusProperty = {
    ['to_be_processed']: {
      className: 'btn to_be_processed',
      variant: 'submit',
    },
    ['reviewed']: {
      className: 'btn reviewed',
      variant: 'transparent',
    },
    ['activity_in_progress']: {
      className: 'btn activity_in_progress',
      variant: 'transparent',
    },
    ['under_assessment']: {
      className: 'btn under_assessment',
      variant: 'transparent',
    },
    ['redirected_to_co']: {
      className: 'btn redirected_to_co',
      variant: 'submit',
    },
    ['assigned_to_tech_advisor']: {
      className: 'btn assigned_to_tech_advisor',
      variant: 'submit',
    },
    ['project_created']: {
      className: 'btn project_created',
      variant: 'submit',
    },
    ['closed']: {
      className: 'btn closed',
      variant: 'submit',
    },
    ['cancelled']: {
      className: 'btn cancelled',
      variant: 'submit',
    },
    defaultProperty: {
      className: 'btn btn-default',
      variant: 'submit',
    },
  };

  const additionalProperties = statusProperties[record] || statusProperties.defaultProperty;

  const label = find(
    Object.values(statuses).flatMap((item) => item),
    { value: record }
  )?.label;

  return <Button {...additionalProperties} text={label} />;
};

const columns = (focalPointOptions: IDropdownOption[], statuses: StatusList) => [
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Request Date',
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'descend',
    sorter: true,
    render: (record: string) => {
      return format(new Date(record), DateFormatEnum.DD_MM_YYYY);
    },
  },
  {
    dataIndex: 'user_first_name',
    key: 'requestorFirstName',
    title: 'Name',
  },
  {
    dataIndex: 'user_last_name',
    key: 'requestorLastName',
    title: 'Last Name',
  },
  {
    dataIndex: 'user_organisation',
    key: 'requestorOrganisation',
    title: 'Organisation',
  },
  {
    dataIndex: 'locality',
    key: 'locality',
    title: 'Location',
    render: (_: string, { location, locality }: ICustomerAreaServiceRequestListItem) => {
      return location || locality;
    },
  },
  {
    dataIndex: 'focal_point',
    key: 'focal_point',
    title: 'Focal point',
    render: (record: string) => {
      const label = find(focalPointOptions, (item) => record === item?.value)?.label || '';
      return <Tooltip title={label}>{label}</Tooltip>;
    },
  },
  {
    dataIndex: 'infrastructure_name',
    key: 'infrastructure_name',
    title: 'Infrastructure',
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    width: 100,
    render: (record: string) => {
      return getBookingStateBtn(record, statuses);
    },
  },
];

const tabsList = ['Not processed', 'Processed'];

const RequestsListPage = () => {
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);

  const { data: userDetails } = useQuery(['user-details'], () => engineeringRepository.getUserDetails(), {
    refetchOnWindowFocus: false,
  });

  const { data: focalPointsOptions } = useQuery<IDropdownOption[]>(
    `focal-points-options`,
    () => engineeringRepository.getFocalPointsOptions(facilityId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: requestsCSV } = useQuery<string>(
    `service-requests-csv`,
    () => engineeringRepository.getServiceRequestsCSV(facilityId),
    {
      refetchOnWindowFocus: false,
      enabled: isExportButtonClicked,
      onSuccess: (data) => {
        stringToCSVFile(data, `ENGINEERING_REQUEST_EXPORT_${format(new Date(), DateFormatEnum.DD_MM_YYYY)}`);
        setIsExportButtonClicked(false);
      },
      onError: (err) => {
        message.error(err as string);
        setIsExportButtonClicked(false);
      },
    }
  );

  const tabs = tabsList;
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [agencyFilter, setAgencyFilter] = useState<IDropdownOption | null>(null);
  const [statusFilter, setStatusFilter] = useState<IDropdownOption | null>(null);
  const [focalPointFilter, setFocalPointFilter] = useState<IDropdownOption | null>(null);
  const [columnOrder, setColumnOrder] = useState<string>('-created');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';

  const navigate = useNavigate();
  const { engineeringRepository } = useRepository();

  const getFilterStatusesOptions = (): IDropdownOption[] => {
    const tab = selectedTab === 'Processed' ? 'processed' : 'not_processed';

    return list?.options?.[tab] || [];
  };

  const getAgencyOptions = (detailUser: IUserDetails): IDropdownOption[] => {
    return [{ label: detailUser.organisation ?? '', value: detailUser.organisation ?? '' }];
  };

  useEffect(() => {
    if (statusFilter) {
      setStatusFilter(getFilterStatusesOptions()[0]);
    }
    setSelectedPage(1);
  }, [selectedTab]);

  const clearFilters = (): void => {
    setStatusFilter(null);
    setAgencyFilter(null);
    setFocalPointFilter(null);
  };

  const buildParams = (): IServiceRequestParams => {
    return {
      show: selectedTab === 'Processed' ? 'processed' : 'not_processed',
      status: statusFilter ? statusFilter.value : null,
      organisation: agencyFilter ? agencyFilter.value : null,
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder ? columnOrder : null,
      focal_point: focalPointFilter?.value || '',
    };
  };

  const { data: list, isLoading } = useQuery(
    [
      'service-requests-list',
      selectedTab,
      statusFilter?.value,
      agencyFilter?.value,
      focalPointFilter?.value,
      selectedPage,
      pageSize,
      columnOrder,
    ],
    () => engineeringRepository.getServiceRequestList(facilityId, buildParams()),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <section className='hbh-container booking-list'>
      <div className='service-page-title-wrapper'>
        <PageTitle title={`Engineering Requests - ${country}`} bottomLine={true} />
        <VideoLink link={videoLinks.SERVICE_REQUEST} />
      </div>
      <Tabs
        className='engineering-tabs'
        tabs={tabs}
        selectedTab={tabs.indexOf(selectedTab)}
        onTabSelect={(value) => {
          setSelectedTab(value);
          value !== selectedTab && setStatusFilter(null);
        }}
      />
      <section className='filters'>
        <FiltersDropdown
          label={<label className='hbh-select-label'>Filter by agencies</label>}
          className='filter select outline'
          value={agencyFilter}
          placeholder='All Agencies'
          options={(userDetails?.organisation && getAgencyOptions(userDetails)) || []}
          onChange={setAgencyFilter}
        />
        <FiltersDropdown
          className='filter select outline'
          label={<label className='hbh-select-label'>Filter by statuses</label>}
          value={statusFilter}
          placeholder='All statuses'
          options={getFilterStatusesOptions()}
          onChange={setStatusFilter}
        />
        <FiltersDropdown
          className='filter select outline wide'
          label={<label className='hbh-select-label'>Filter by focal point</label>}
          value={focalPointFilter}
          placeholder='All focal points'
          options={focalPointsOptions || []}
          onChange={setFocalPointFilter}
        />
        <span className='reset' onClick={clearFilters}>
          Reset filters
        </span>
        <Button
          onClick={() => setIsExportButtonClicked(true)}
          disabled={isExportButtonClicked}
          className='export-button'
          variant='submit'
          text='Export Data'
          data-testid='csv-button'
        />
      </section>
      <div className='table'>
        <Table
          columns={columns(focalPointsOptions || [], list?.options)}
          data={list?.results}
          onRow={(record) => {
            return {
              onClick: () => navigate(`${record.uuid}`), // click row
            };
          }}
          variant='dark'
          scroll={{ x: true }}
          isLoading={isLoading}
          onChangeColumnOrder={setColumnOrder}
        />
      </div>
      <Pagination
        className='pagination'
        selectedPage={selectedPage}
        showJumper={true}
        showPageSize={true}
        totalPages={list?.count}
        variant='dark'
        onPageChange={setSelectedPage}
        onPageSizeChange={setPageSize}
      />
    </section>
  );
};

export default RequestsListPage;
