import React, { useState } from 'react';
import './styles.scss';
import { useRepository } from '@context/repository.context';
import { useNavigate } from 'react-router-dom';
import { Pagination, Table, Tabs } from '@ui-modules';
import {
  ICustomerAreaRequestList,
  ICustomerAreaServiceRequestListItem,
  IServiceRequestParams,
  ISwitchesResponse,
} from '@digital-office/common/interfaces';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import { Breadcrumb, message } from 'antd';

const tabs = ['In progress', 'Closed'];

const columns = [
  {
    dataIndex: 'reference_code',
    key: 'reference_code',
    title: 'Request N.',
  },
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Request Date',
    render: (record: string) => {
      return format(new Date(record), DateFormatEnum.DD_MM_YYYY);
    },
  },
  {
    dataIndex: 'infrastructure_name',
    key: 'infrastructure_name',
    title: 'Infrastructure category',
  },
  {
    dataIndex: 'locality',
    key: 'locality',
    title: 'Location',
    render: (_: string, { location, locality }: ICustomerAreaServiceRequestListItem) => {
      return location || locality;
    },
  },
  {
    dataIndex: 'country_name',
    key: 'country_name',
    title: 'Country',
  },
  {
    dataIndex: 'focal_point_label',
    key: 'focal_point_label',
    title: 'Engineering focal point',
  },
  {
    dataIndex: 'country',
    key: 'country',
    title: 'Actions',
    render: () => {
      return <p>View request</p>;
    },
  },
];

const CustomerRequestsList = () => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const facilityId = +localStorage.getItem('facility')!;
  const { engineeringRepository } = useRepository();
  const navigate = useNavigate();

  const buildParams = (): IServiceRequestParams => {
    return {
      show: selectedTab === tabs[0] ? 'not_processed' : 'processed',
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
    };
  };

  useQuery<ISwitchesResponse>('waffle-switch', () => engineeringRepository.getSwitches(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const storyPublishingSwitch = data?.results?.find((siteSwitch) => siteSwitch.name === 'customer_area');
      if (!storyPublishingSwitch?.active) {
        message.warning('Page is not available');
        navigate('/home');
      }
    },
  });

  const { data, isLoading } = useQuery<ICustomerAreaRequestList>(
    `custom-area-requests-list-${selectedPage}=${selectedTab}-${pageSize}`,
    () => engineeringRepository.getCustomerAreaRequestsList(buildParams()),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className='customer-projects-list-wrapper'>
      <Breadcrumb
        items={[
          {
            title: (
              <p className='crumb-active' onClick={() => navigate('/customer-area')}>
                Home
              </p>
            ),
          },
          { title: <p>My requests</p> },
        ]}
      />
      <p className='title'>My requests</p>
      <section className='hbh-container booking-list projects-list'>
        <Tabs
          className='engineering-tabs'
          tabs={tabs}
          selectedTab={tabs.indexOf(selectedTab)}
          onTabSelect={setSelectedTab}
        />
        <div className='table'>
          <Table
            onRow={(record) => {
              return {
                onClick: () => navigate(record.uuid, { state: { facility: record.facility_id_on_hbh } }),
              };
            }}
            columns={columns}
            data={data?.results || []}
            variant='dark'
            scroll={{ x: true }}
            isLoading={isLoading}
          />
        </div>
        <Pagination
          className='pagination'
          selectedPage={selectedPage}
          showJumper={true}
          showPageSize={true}
          totalPages={0}
          variant='dark'
          onPageChange={setSelectedPage}
          onPageSizeChange={setPageSize}
        />
      </section>
    </div>
  );
};

export default CustomerRequestsList;
