import React, { FC } from 'react';
import GuidanceModal from '@components/CommunityGuidancePage/components/GuidanceModal';
import { Button } from '@ui-modules';
import './styles.scss';
import { COMPLEXITY_LABELS } from '@components/CommunityGuidancePage/constants';
import { IGuidanceRiskCategory } from '@digital-office/common/interfaces';

interface IComplexityModalProps {
  open: boolean;
  closeHandler: () => void;
  riskCategories: IGuidanceRiskCategory[];
}

const ModalContent: FC<Omit<IComplexityModalProps, 'open'>> = ({ closeHandler, riskCategories }) => {
  return (
    <div className='complexity-modal-content'>
      <p className='title'>What is Engineering requirements for Community infrastructure activities?</p>
      <div className='complexity-image' />
      {riskCategories.map((category) => {
        return (
          <div key={category.name} className='complexity-info'>
            <p className='complexity-label'>{category.name}</p>
            <p className='complexity-description'>{category.description}</p>
          </div>
        );
      })}
      <Button onClick={closeHandler} className='close-button' text='Close' variant='transparent' />
    </div>
  );
};

const ComplexityModal: FC<IComplexityModalProps> = ({ open, closeHandler, riskCategories }) => {
  return (
    <GuidanceModal open={open} closeHandler={closeHandler}>
      <ModalContent riskCategories={riskCategories} closeHandler={closeHandler} />
    </GuidanceModal>
  );
};

export default ComplexityModal;
