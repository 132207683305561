import { LocationData } from '@digital-office/common/interfaces';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types';
import * as Yup from 'yup';
import 'yup-phone';

export interface IDropdownLocationType extends IDropdownOption {
  distance_required: boolean;
}

export interface IDropdownCountryType extends IDropdownOption {
  iso_code: string;
}

export interface DetailsStepValues {
  phone_number: string;
  requesting_unit: string;
  country: IDropdownCountryType;
}

export interface ProjectStepValues {
  description: string;
  desired_start_date: string;
  estimated_budget: string;
  fund_expiration_date?: string;
  infrastructure: IDropdownOption;
  infrastructure_category: IDropdownOption;
  location: Array<LocationData>;
  services: Array<IDropdownOption>;
  title: string;
}

export interface AdditionalStepValues {
  new_construction: IDropdownOption;
  rehabilitation_existing_building: IDropdownOption;
  own_status: IDropdownOption;
  rental_expiration_date?: string;
  planned_usage_years?: string;
  attachments?: Array<File>;
}

export interface FormValues {
  details: DetailsStepValues;
  project: ProjectStepValues;
  additional: AdditionalStepValues;
}

export interface FormTempValues {
  details?: Partial<DetailsStepValues>;
  project?: Partial<ProjectStepValues>;
  additional?: Partial<AdditionalStepValues>;
}

export type IYupSchema = Yup.SchemaOf<{
  // TO DO: Should be three different schemas for each steps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}>;

export const MAX_TITLE_LENGTH = 150;
export const MAX_DESCRIPTION_LENGTH = 255;
export const MAX_DISTANCE = 1000;
export const MAX_USAGE = 100;
export const MAX_FILE_SIZE = 10485760;
export const MAX_FILE_COUNT = 5;

export const STEPS = {
  DETAILS: 'details',
  PROJECT: 'project',
  ADDITIONAL: 'additional',
  REVIEW: 'review',
};

const YupFileType = () =>
  Yup.mixed().test(
    'fileSize',
    (value) => `The ${value.value.name} is too large `,
    ({ size }) => {
      return size <= MAX_FILE_SIZE;
    }
  );

const YupBudgetType = () =>
  Yup.string().test(
    'estimated_budget',
    ({ value }) => {
      if (!value) return 'Required';
      return 'Should be less';
    },
    (value) => {
      if (!value) return false;
      const cleanValue = parseFloat(value.replace(/,/g, ''));
      return cleanValue < 99999999999;
    }
  );

export const yupSchemas = {
  [STEPS.DETAILS]: Yup.object().shape({
    [STEPS.DETAILS]: Yup.object().shape({
      phone_number: Yup.string().phone(undefined, undefined, 'Incorrect phone format').required('Required'),
      requesting_unit: Yup.string().required('Required'),
      country: Yup.object().required('Required'),
    }),
  }),
  [STEPS.PROJECT]: Yup.object().shape({
    [STEPS.PROJECT]: Yup.object().shape({
      description: Yup.string()
        .required('Required')
        .max(MAX_DESCRIPTION_LENGTH, `Description must be at most ${MAX_DESCRIPTION_LENGTH} characters`),
      desired_start_date: Yup.date().required('Required'),
      estimated_budget: YupBudgetType(),
      fund_expiration_date: Yup.date(),
      infrastructure: Yup.object().required('Required'),
      infrastructure_category: Yup.object().required('Required'),
      location: Yup.array()
        .of(Yup.object())
        .min(1, 'At least 1 location is required')
        .required('At least 1 location is required'),
      services: Yup.array()
        .of(Yup.object())
        .min(1, 'At least 1 service is required')
        .required('At least 1 service is required'),
      title: Yup.string()
        .required('Required')
        .max(MAX_TITLE_LENGTH, `Title must be at most ${MAX_TITLE_LENGTH} characters`),
    }),
  }),
  [STEPS.ADDITIONAL]: Yup.object().shape({
    [STEPS.ADDITIONAL]: Yup.object().shape({
      new_construction: Yup.object().required('Required'),
      rehabilitation_existing_building: Yup.object().required('Required'),
      own_status: Yup.object().required('Required'),
      rental_expiration_date: Yup.date().when('own_status', {
        is: (value: IDropdownOption) => value && value.value === 'no',
        then: Yup.date().required('Required'),
        otherwise: Yup.date(),
      }),
      planned_usage_years: Yup.number()
        .positive()
        .max(MAX_USAGE, `Planned usage years must be less than or equal to ${MAX_USAGE}`)
        .nullable(),
      attachments: Yup.array()
        .of(YupFileType())
        .max(MAX_FILE_COUNT, `You can upload maximum of ${MAX_FILE_COUNT} files`),
    }),
  }),
};
