import React, { FC, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { Form, FormApi, FormStateAccessor, Radio, RadioGroup } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import { ArrowLeftOutlined, ArrowRightOutlined, CheckCircleFilled } from '@ant-design/icons';
import { UseMutationResult } from 'react-query';
import { Button } from '@ui-modules';
import { cloneDeep, forEach, isEmpty, isEqual, isObject, set } from 'lodash';
import * as Yup from 'yup';
import { AssessmentConductedList, getAssessmentConductedListYupValidation } from './components/AssessmentConductedList';
import {
  AssessmentImprovementsList,
  getAssessmentImprovementsListYupValidation,
} from './components/AssessmentImprovementsList';
import { AssessmentMasonryList, getAssessmentMasonryListYupValidation } from './components/AssessmentMasonryList';
import {
  AssessmentSteelSupplyList,
  getAssessmentSteelSupplyListYupValidation,
} from './components/AssessmentSteelSupplyList';
import {
  AssessmentWaterSupplyList,
  getAssessmentWaterSupplyListYupValidation,
} from './components/AssessmentWaterSupplyList';
import {
  AssessmentWastewaterList,
  getAssessmentWastewaterListYupValidation,
} from './components/AssessmentWastewaterList';
import { AssessmentSecurityList, getAssessmentSecurityListYupValidation } from './components/AssessmentSecurityList';
import {
  AssessmentElectricalList,
  getAssessmentElectricalListYupValidation,
} from './components/AssessmentElectricalList';
import {
  AssessmentMiscellaneousList,
  getAssessmentMiscellaneousListYupValidation,
} from './components/AssessmentMiscellaneousList';
import { IAssessmentChecklist } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface IRadioElement {
  name: string;
  values: {
    [key: string]: string;
  };
}

interface IAssessmentChecklistStep {
  initialValues: IAssessmentChecklist | null;
  updateMutation: UseMutationResult<any, unknown, any, unknown>;
  finalizeStepMutation: UseMutationResult<any, unknown, any, unknown>;
  formKey: number;
  onStepBackButton: () => void;
}

export interface IAssessmentFormData {
  data: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

const RadioElement: FC<IRadioElement> = ({ name, values }) => {
  return (
    <RadioGroup className='radio' name={name}>
      <div className={`radio-element button ${values?.[name] === 'yes' ? 'checked' : ''}`}>
        <div className='radio-label'>
          <span className='radio-input-label'>Yes</span>
          {values?.[name] === 'yes' && <CheckCircleFilled />}
          <Radio value='yes' label='' />
        </div>
      </div>
      <div className={`radio-element button ${values?.[name] === 'no' ? 'checked' : ''}`}>
        <div className='radio-label'>
          <span className='radio-input-label'>No</span>
          {values?.[name] === 'no' && <CheckCircleFilled />}
          <Radio value='no' label='' />
        </div>
      </div>
    </RadioGroup>
  );
};

const validationSchema = (values: any) => {
  return Yup.object().shape({
    assessment_new_variation_works: Yup.string().required('This field is required'),
    ground_improvements_required: Yup.string().required('This field is required'),
    masonry_concreting_paint_required: Yup.string().required('This field is required'),
    steel_ironworks_supply_fabrication_installation_required: Yup.string().required('This field is required'),
    water_supply_works_required: Yup.string().required('This field is required'),
    wastewater_sanitation_works_required: Yup.string().required('This field is required'),
    security_works_required: Yup.string().required('This field is required'),
    electrical_works_required: Yup.string().required('This field is required'),
    miscellaneous_works_required: Yup.string().required('This field is required'),
    data: Yup.object().shape({
      assessment_new_variation_works:
        values?.assessment_new_variation_works === 'yes'
          ? getAssessmentConductedListYupValidation(values)
          : Yup.mixed(),
      ground_improvements_required:
        values?.ground_improvements_required === 'yes' ? getAssessmentImprovementsListYupValidation() : Yup.mixed(),
      masonry_concreting_paint_required:
        values?.masonry_concreting_paint_required === 'yes' ? getAssessmentMasonryListYupValidation() : Yup.mixed(),
      steel_ironworks_supply_fabrication_installation_required:
        values?.steel_ironworks_supply_fabrication_installation_required === 'yes'
          ? getAssessmentSteelSupplyListYupValidation()
          : Yup.mixed(),
      water_supply_works_required:
        values?.water_supply_works_required === 'yes' ? getAssessmentWaterSupplyListYupValidation() : Yup.mixed(),
      wastewater_sanitation_works_required:
        values?.wastewater_sanitation_works_required === 'yes'
          ? getAssessmentWastewaterListYupValidation()
          : Yup.mixed(),
      security_works_required:
        values?.security_works_required === 'yes' ? getAssessmentSecurityListYupValidation() : Yup.mixed(),
      electrical_works_required:
        values?.electrical_works_required === 'yes' ? getAssessmentElectricalListYupValidation() : Yup.mixed(),
      miscellaneous_works_required:
        values?.miscellaneous_works_required === 'yes'
          ? getAssessmentMiscellaneousListYupValidation(values)
          : Yup.mixed(),
    }),
  });
};

const AssessmentChecklistStep: FC<IAssessmentChecklistStep> = ({
  finalizeStepMutation,
  updateMutation,
  onStepBackButton,
  initialValues,
}) => {
  const formRef = useRef<FormApi>(null);
  const [updateType, setUpdateType] = useState<string | undefined>();
  const [formData, setFormData] = useState<any>(null);
  const [, updateState] = useState<any>(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  const scrollToFirstErrorElement = () => {
    if (shouldScroll) {
      const firstErrorElement = document.querySelector('.error')?.parentElement;
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      setShouldScroll(false);
    }
  };

  useEffect(() => {
    scrollToFirstErrorElement();
  }, [shouldScroll]);

  useEffect(() => {
    if (updateType) {
      formRef?.current?.submitForm();
    }
  }, [updateType]);

  useEffect(() => {
    if (formData) {
      formRef?.current?.validate();
    }
  }, [formData]);

  useEffect(() => {
    if (initialValues) {
      const values: any = parsePayloadToFormData(initialValues);
      formRef?.current?.setValues(values);
      formRef?.current?.setValue('data', values?.['data']);
    }
  }, [initialValues]);

  const onSubmitButtonClick = (type: string) => {
    if (type === updateType) {
      formRef?.current?.submitForm();
      setShouldScroll(true);
    } else {
      setUpdateType(type);
    }
  };

  // todo: Add TypeScript to values

  const parseFormDataToPayload = (values: any, performSubmit = true) => {
    const parseValues = (value: string | number) => {
      if ((typeof value === 'string' && value === 'yes') || value === 'no') {
        return value === 'yes';
      } else if (typeof value === 'string' && parseInt(value)) {
        return parseInt(value);
      } else {
        return value;
      }
    };

    const result = {};

    forEach(values, (value, key) => {
      if (key !== 'data') {
        if (value === 'yes') {
          values?.data?.[key]
            ? forEach(values?.data[key], (dataValue, dataKey) => {
                set(result, `${key}.${dataKey}`, parseValues(dataValue));
              })
            : set(result, `${key}`, {});
        } else {
          set(result, key, false);
        }
      }
    });

    if (!performSubmit) {
      return result;
    } else {
      if (updateType === 'draft') {
        updateMutation.mutate({ current_step: 2, assessment_checklist: result });
      } else {
        finalizeStepMutation.mutate({ current_step: 2, assessment_checklist: result });
      }
    }
  };

  const parsePayloadToFormData = (payload: IAssessmentChecklist) => {
    const parseValues = (value: boolean | number | string) => {
      if (typeof value === 'boolean') {
        return value ? 'yes' : 'no';
      } else if (typeof value === 'number') {
        return value.toString();
      } else {
        return value;
      }
    };

    const result = {};

    forEach(payload, (value, key) => {
      if (isObject(value)) {
        !isEmpty(value)
          ? forEach(value, (nestedValue, nestedKey) => {
              set(result, `data.${key}.${nestedKey}`, parseValues(nestedValue));
              set(result, `${key}`, 'yes');
            })
          : set(result, `${key}`, 'yes');
      } else {
        set(result, key, parseValues(value));
      }
    });

    setFormData(result);

    return result;
  };

  const onStepBackClick = () => {
    const modifiedField = formRef?.current?.getFormState().values;
    if (isEqual(initialValues, parseFormDataToPayload(modifiedField, false))) {
      onStepBackButton();
    } else {
      const confirmAlert = confirm(
        'You have unsaved changes. Saves will not be saved. Are you sure you want to leave?'
      );

      if (confirmAlert) {
        onStepBackButton();
      }
    }
  };

  return (
    <div className='assessment-checklist-step-container'>
      <Form
        yupSchema={!updateType || updateType === 'draft' ? null : validationSchema(formData)}
        formApiRef={formRef}
        onValueChange={({ values }) => (!isEqual(values, initialValues) ? setFormData(cloneDeep(values)) : null)}
        onClick={() => updateState({})}
        onSubmitFailure={() => setShouldScroll(true)}
        onSubmit={() => parseFormDataToPayload(formData)}
      >
        <FormStateAccessor>
          {({ values }) => {
            return (
              <div>
                <InformedField
                  name='assessment_new_variation_works'
                  formRefApi={formRef}
                  label='Assessment conducted for new and/or variation in works?'
                  informedComponent={
                    <RadioElement name='assessment_new_variation_works' values={values as IRadioElement['values']} />
                  }
                />
                {values?.assessment_new_variation_works === 'yes' ? (
                  <AssessmentConductedList
                    formRef={formRef}
                    name='assessment_new_variation_works'
                    values={values as unknown as IAssessmentFormData}
                  />
                ) : null}
                <InformedField
                  name='ground_improvements_required'
                  formRefApi={formRef}
                  label='Ground improvements works required?'
                  informedComponent={
                    <RadioElement name='ground_improvements_required' values={values as IRadioElement['values']} />
                  }
                />
                {values?.ground_improvements_required === 'yes' ? (
                  <AssessmentImprovementsList
                    formRef={formRef}
                    name='ground_improvements_required'
                    values={values as unknown as IAssessmentFormData}
                  />
                ) : null}
                <InformedField
                  name='masonry_concreting_paint_required'
                  formRefApi={formRef}
                  label='Masonry, concreting & paint works required?'
                  informedComponent={
                    <RadioElement name='masonry_concreting_paint_required' values={values as IRadioElement['values']} />
                  }
                />
                {values?.masonry_concreting_paint_required === 'yes' ? (
                  <AssessmentMasonryList
                    formRef={formRef}
                    name='masonry_concreting_paint_required'
                    values={values as unknown as IAssessmentFormData}
                  />
                ) : null}
                <InformedField
                  name='steel_ironworks_supply_fabrication_installation_required'
                  formRefApi={formRef}
                  label='Supply, fabrication & installation steel & ironworks in required?'
                  informedComponent={
                    <RadioElement
                      name='steel_ironworks_supply_fabrication_installation_required'
                      values={values as IRadioElement['values']}
                    />
                  }
                />
                {values?.steel_ironworks_supply_fabrication_installation_required === 'yes' ? (
                  <AssessmentSteelSupplyList
                    formRef={formRef}
                    name='steel_ironworks_supply_fabrication_installation_required'
                    values={values as unknown as IAssessmentFormData}
                  />
                ) : null}
                <InformedField
                  formRefApi={formRef}
                  name='water_supply_works_required'
                  label='Water supply works required?'
                  informedComponent={
                    <RadioElement name='water_supply_works_required' values={values as IRadioElement['values']} />
                  }
                />
                {values?.water_supply_works_required === 'yes' ? (
                  <AssessmentWaterSupplyList
                    formRef={formRef}
                    name='water_supply_works_required'
                    values={values as unknown as IAssessmentFormData}
                  />
                ) : null}
                <InformedField
                  name='wastewater_sanitation_works_required'
                  formRefApi={formRef}
                  label='Wastewater & sanitation works required?'
                  informedComponent={
                    <RadioElement
                      name='wastewater_sanitation_works_required'
                      values={values as IRadioElement['values']}
                    />
                  }
                />
                {values?.wastewater_sanitation_works_required === 'yes' ? (
                  <AssessmentWastewaterList
                    formRef={formRef}
                    name='wastewater_sanitation_works_required'
                    values={values as unknown as IAssessmentFormData}
                  />
                ) : null}
                <InformedField
                  name='security_works_required'
                  formRefApi={formRef}
                  label='Security works required?'
                  informedComponent={
                    <RadioElement name='security_works_required' values={values as IRadioElement['values']} />
                  }
                />
                {values?.security_works_required === 'yes' ? (
                  <AssessmentSecurityList
                    formRef={formRef}
                    name='security_works_required'
                    values={values as unknown as IAssessmentFormData}
                  />
                ) : null}
                <InformedField
                  name='electrical_works_required'
                  label='Electrical works required?'
                  formRefApi={formRef}
                  informedComponent={
                    <RadioElement name='electrical_works_required' values={values as IRadioElement['values']} />
                  }
                />
                {values?.electrical_works_required === 'yes' ? (
                  <AssessmentElectricalList
                    formRef={formRef}
                    name='electrical_works_required'
                    values={values as unknown as IAssessmentFormData}
                  />
                ) : null}
                <InformedField
                  name='miscellaneous_works_required'
                  formRefApi={formRef}
                  label='Miscellaneous works required?'
                  informedComponent={
                    <RadioElement name='miscellaneous_works_required' values={values as IRadioElement['values']} />
                  }
                />
                {values?.miscellaneous_works_required === 'yes' ? (
                  <AssessmentMiscellaneousList
                    formRef={formRef}
                    name='miscellaneous_works_required'
                    values={values as unknown as IAssessmentFormData}
                  />
                ) : null}
              </div>
            );
          }}
        </FormStateAccessor>
        <div className='buttons-container'>
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            onClick={() => onStepBackClick()}
            variant='transparent'
            className='button back'
            text='Back'
            icon={<ArrowLeftOutlined />}
          />
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            className='button'
            variant='transparent'
            onClick={() => onSubmitButtonClick('draft')}
            text='Save as draft'
          />
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            icon={<ArrowRightOutlined />}
            className='button go-next'
            onClick={() => onSubmitButtonClick('submit')}
            text='Save and go next'
          />
        </div>
      </Form>
    </div>
  );
};

export default AssessmentChecklistStep;
