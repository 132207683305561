import React, { FC } from 'react';
import { FormApi, Radio, RadioGroup } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import * as Yup from 'yup';
import { IAssessmentFormData } from '@digital-office/pages/FieldAssessmentReports/components/AssessmentChecklistStep';

interface IAssessmentConductedList {
  name: string;
  values: IAssessmentFormData;
  formRef: React.RefObject<FormApi>;
}

const getAssessmentImprovementsListYupValidation = () => {
  return Yup.object().shape({
    clearing_grubbing_land_leveling_needed: Yup.string().required('This field is required'),
    floor_level_establishment_required: Yup.string().required('This field is required'),
    nearby_supply_available_gravel_crushed_stone_murram: Yup.string().required('This field is required'),
    demolish_dismantle_dispose_existing_structures_required: Yup.string().required('This field is required'),
    compound_area_drainage_needed: Yup.string().required('This field is required'),
    access_trunk_road_rehabilitation_needed: Yup.string().required('This field is required'),
    culverts_needed_under_roads_paths: Yup.string().required('This field is required'),
  });
};

const AssessmentImprovementsList: FC<IAssessmentConductedList> = ({ formRef }) => {
  return (
    <div className='assessment-details-list-container'>
      <div className='table-header'>
        <div className='headers'>
          <span>Yes</span>
          <span>No</span>
        </div>
      </div>
      <InformedField
        label=''
        name='data[ground_improvements_required][clearing_grubbing_land_leveling_needed]'
        formRefApi={formRef}
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Is clearing, grubbing, and/or land levelling needed?</span>
            <RadioGroup
              className='radio'
              name='data[ground_improvements_required][clearing_grubbing_land_leveling_needed]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[ground_improvements_required][floor_level_establishment_required]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>
              Does the floor level need to be established? i.e., ground raised, filling with imported soil, sand, and/or
              murram and then compacted?
            </span>
            <RadioGroup className='radio' name='data[ground_improvements_required][floor_level_establishment_required]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[ground_improvements_required][nearby_supply_available_gravel_crushed_stone_murram]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Is a nearby supply of gravel, crushed stone, or murram available?</span>
            <RadioGroup
              className='radio'
              name='data[ground_improvements_required][nearby_supply_available_gravel_crushed_stone_murram]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[ground_improvements_required][demolish_dismantle_dispose_existing_structures_required]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Is demolishing, dismantling & disposal of existing structures required?</span>
            <RadioGroup
              className='radio'
              name='data[ground_improvements_required][demolish_dismantle_dispose_existing_structures_required]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[ground_improvements_required][compound_area_drainage_needed]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Does the drainage of the compound area and/or around MSUs needed?</span>
            <RadioGroup className='radio' name='data[ground_improvements_required][compound_area_drainage_needed]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[ground_improvements_required][access_trunk_road_rehabilitation_needed]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Is rehabilitation of the access or trunk road needed?</span>
            <RadioGroup
              className='radio'
              name='data[ground_improvements_required][access_trunk_road_rehabilitation_needed]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[ground_improvements_required][culverts_needed_under_roads_paths]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>
              Are Culverts (in concrete or steel pipes) needed under roads and/or paths?
            </span>
            <RadioGroup className='radio' name='data[ground_improvements_required][culverts_needed_under_roads_paths]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
    </div>
  );
};

export { getAssessmentImprovementsListYupValidation, AssessmentImprovementsList };
