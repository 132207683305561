import React, { FC, useState } from 'react';
import { Checkbox, Progress } from 'antd';
import DeliverableCompletionModal from '@digital-office/pages/ProjectsManagementPages/components/DeliverableCompletionModal';
import { cloneDeep, findIndex } from 'lodash';
import { calculateDeliverableProgress } from '@digital-office/pages/ProjectsManagementPages/components/PhaseDetail/utils';
import './styles.scss';
import { IDeliverables } from '@digital-office/common/interfaces';
import { IPhase } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

export interface ISelectedDeliverable {
  deliverable: IDeliverables | 'all';
  phase: IPhase;
}

interface IProps {
  phase: IPhase;
  phasesList: IPhase[];
  setPhases: React.Dispatch<React.SetStateAction<IPhase[]>>;
  updateProject: (phases: IPhase[]) => void;
}

interface IPhaseDateProps {
  phase: IPhase;
}

const PhaseDate: FC<IPhaseDateProps> = ({ phase }) => {
  return (
    <>
      <div className='phase-date-wrapper'>
        <div className='phase-date-item'>
          <span className='date-label'>Start date: </span>
          <span className='date-value'>
            {phase.start_date ? format(new Date(phase.start_date), DateFormatEnum.DD_MM_YYYY) : '' || 'TBA'}
          </span>
        </div>
        <div className='phase-date-item'>
          <span className='date-label'>End date: </span>
          <span className='date-value'>
            {phase.end_date ? format(new Date(phase.end_date), DateFormatEnum.DD_MM_YYYY) : '' || 'TBA'}
          </span>
        </div>
      </div>
    </>
  );
};

const PhaseDetail: FC<IProps> = ({ phase, phasesList, updateProject }) => {
  const [selectedDeliverable, setSelectedDeliverable] = useState<ISelectedDeliverable | null>(null);

  const onDeliverableCompletion = (fileList: FileList | undefined) => {
    const phaseIndex = findIndex(phasesList, (phase) => phase.description === selectedDeliverable?.phase.description);
    const newPhasesList = cloneDeep(phasesList);

    if (selectedDeliverable?.deliverable === 'all') {
      newPhasesList[phaseIndex].deliverables.forEach((deliverable) => {
        return (deliverable.finished = true);
      });

      updateProject(newPhasesList);

      return setSelectedDeliverable(null);
    } else {
      const deliverable = newPhasesList[phaseIndex].deliverables.find(
        (deliverable) =>
          selectedDeliverable?.deliverable !== 'all' && deliverable.name === selectedDeliverable?.deliverable?.name
      );

      if (deliverable) {
        deliverable.finished = !deliverable.finished;
      }

      updateProject(newPhasesList);

      setSelectedDeliverable(null);
    }
  };

  const checkPhaseIsCompleted = (deliverables: IDeliverables[]) => {
    return deliverables.every((deliverable) => deliverable.finished);
  };

  return (
    <div className='phases-item-wrapper'>
      {selectedDeliverable && (
        <DeliverableCompletionModal
          onDeliverableCompletion={onDeliverableCompletion}
          setSelectedDeliverable={setSelectedDeliverable}
          selectedDeliverable={selectedDeliverable}
        />
      )}
      <div className='phase-item-details'>
        <div className='details-wrapper'>
          <Progress
            width={50}
            className='progress-circle'
            type='circle'
            percent={calculateDeliverableProgress(phase.deliverables)}
            strokeWidth={8}
            strokeColor='#00FA43'
            trailColor='#707070'
          />
          <span className='phase-title'>
            Phase {phase.number}: {phase.title} - {phase.description}
          </span>
        </div>
        <PhaseDate phase={phase} />
      </div>
      {!checkPhaseIsCompleted(phase.deliverables) && (
        <div onClick={() => setSelectedDeliverable({ phase: phase, deliverable: 'all' })} className='complete-phase'>
          <Checkbox checked={false} />
          <p>Mark phase as completed</p>
        </div>
      )}
      <p className='deliverables-title'>Phase deliverables</p>
      <div className='deliverables-list'>
        {phase.deliverables.map((deliverable) => {
          return (
            <Checkbox
              onChange={() => setSelectedDeliverable({ phase: phase, deliverable: deliverable })}
              key={deliverable.name}
              className='checkbox'
              checked={deliverable.finished}
            >
              <p className='deliverable-label'>{deliverable.name}</p>
            </Checkbox>
          );
        })}
      </div>
      <hr className='phase-hr' />
    </div>
  );
};

export default PhaseDetail;
