import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { message, Spin, Tooltip } from 'antd';
import { find, isEqual } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as LocationIcon } from '@assets/svg/location.svg';
import { ReactComponent as ChecklistIcon } from '@assets/svg/checklist.svg';
import { ReactComponent as OverviewIcon } from '@assets/svg/overview.svg';
import { ReactComponent as OutcomeIcon } from '@assets/svg/outcome.svg';
import { ReactComponent as CostIcon } from '@assets/svg/cost.svg';
import { ReactComponent as PublishIcon } from '@assets/svg/publish.svg';
import { useRepository } from '@context/repository.context';
import GuidanceModal from '@components/CommunityGuidancePage/components/GuidanceModal';
import { Button, Dropdown } from '@ui-modules';
import { CloseCircleFilled, HourglassOutlined, WarningFilled } from '@ant-design/icons';
import { IDropdownOption } from '@ui-modules/types';
import { ReactComponent as CompletedIcon } from '@assets/svg/completed_circled.svg';
import NavigationStepper from '@digital-office/common/components/NavigationStepper/NavigationStepper';
import { IAssessmentFormStateResponse, IDraftData } from '@digital-office/pages/FieldAssessmentReports/utils/types';
import ProjectOverviewStep from './components/ProjectOverviewStep';
import ProjectLocationStep from './components/ProjectLocationStep';
import AssessmentChecklistStep from './components/AssessmentChecklistStep';
import MissionOutcomeStep from './components/MissionOutcomeStep';
import ProjectProgressStep from './components/ProjectProgressStep';
import PublishStep from './components/PublishStep';

import './styles.scss';

const FieldAssessmentReports = () => {
  const { engineeringRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;
  const { id: uuid } = useParams<{ id: string }>();
  const [formState, setFormState] = useState<IAssessmentFormStateResponse | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const navigate = useNavigate();
  const [selectedFieldAssessmentModalValue, setSelectedFieldAssessmentModalValue] = useState<IDropdownOption | null>(
    null
  );
  const [itemToEdit, setItemToEdit] = useState<IDropdownOption | null>(null);
  const [displayEditWarningMessage, setDisplayWarningMessage] = useState<boolean>(false);
  const [options, setOptions] = useState<IAssessmentFormStateResponse['options'] | null>(null);
  const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

  const config = [
    {
      label: 'Project overview',
      icon: <OverviewIcon />,
      isCompleted: false,
      isDisabled: false,
      onClick: (key: number) => !isFormLocked && setCurrentStep(key),
    },
    {
      label: 'Project location',
      icon: <LocationIcon />,
      isCompleted: false,
      isDisabled: false,
      onClick: (key: number) => !isFormLocked && setCurrentStep(key),
    },
    {
      label: 'Assessment checklist',
      icon: <ChecklistIcon />,
      isCompleted: false,
      isDisabled: false,
      onClick: (key: number) => !isFormLocked && setCurrentStep(key),
    },
    {
      label: 'Mission outcomes and recommendations',
      icon: <OutcomeIcon />,
      isCompleted: false,
      isDisabled: false,
      onClick: (key: number) => !isFormLocked && setCurrentStep(key),
    },
    {
      label: 'Progress, estimated cost, and conclusion (optional)',
      icon: <CostIcon />,
      isCompleted: false,
      isDisabled: false,
      onClick: (key: number) => !isFormLocked && setCurrentStep(key),
    },
    {
      label: 'Review and publish',
      icon: <PublishIcon />,
      isCompleted: formState?.status === 'pending_review' || formState?.status === 'published',
      isDisabled: false,
      onClick: (key: number) => !isFormLocked && setCurrentStep(key),
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStep]);

  const {
    refetch,
    dataUpdatedAt,
    isLoading: isFormStateLoading,
  } = useQuery<IAssessmentFormStateResponse>(
    `assessment-report-state-${uuid}`,
    () => engineeringRepository.getFieldAssessmentFormState(facilityId, uuid || ''),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setFormState(data);
        setOptions(data.options);
        setCurrentStep(data?.draft_data?.current_step || 0);
        setIsFormLocked(data.status !== 'draft');
      },
      enabled: uuid !== 'new',
      refetchInterval: formState?.status === 'pending_review' ? 30000 : false,
    }
  );

  const { data: projectsData, isLoading: isProjectsListLoading } = useQuery<{ results: IDropdownOption[] }>(
    'field-assessment-projects-list',
    () => engineeringRepository.getFieldAssessmentProjects(facilityId),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { data: servicesRequests, isLoading: isServiceRequestsListLoading } = useQuery(
    'field-assessment-service-requests-list',
    () => engineeringRepository.getFieldAssessmentServiceRequests(facilityId),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const postFieldAssessmentProjectMutation = useMutation(
    (projectId: string) => engineeringRepository.postFieldAssessmentProject(facilityId, projectId || ''),
    {
      onSuccess: (data) => {
        navigate(`/digital-office/field-assessment-reports/form/${data.uuid}/`);
      },
    }
  );

  const reviewAssessmentMutation = useMutation(() => engineeringRepository.reviewAssessment(facilityId, uuid || ''), {
    onSuccess: () => {
      message.success('Field assessment has been reviewed');
      refetch();
    },
    onError: () => {
      message.error('Field assessment review failure');
    },
  });

  const getAssessmentFileMutation = useMutation(
    () => engineeringRepository.getFieldAssessmentFile(facilityId, uuid || ''),
    {
      onSuccess: (data: { file: string }) => {
        window.open(data.file, '_blank');
      },
      onError: () => {
        message.error('Field assessment export failure. Try later');
      },
    }
  );

  const assessmentPublishMutation = useMutation(() => engineeringRepository.publishAssessment(facilityId, uuid || ''), {
    onSuccess: () => {
      message.success('Field assessment has been published');
      refetch();
    },
    onError: () => {
      message.error('Field assessment publish failure');
    },
  });

  const postFieldAssessmentServiceRequestMutation = useMutation(
    (serviceRequestId: string) =>
      engineeringRepository.postFieldAssessmentServiceRequest(facilityId, serviceRequestId || ''),
    {
      onSuccess: (data) => {
        navigate(`/digital-office/field-assessment-reports/form/${data.uuid}/`);
      },
    }
  );

  const updateDraftMutation = useMutation(
    (data: Partial<IAssessmentFormStateResponse['draft_data']>) =>
      engineeringRepository.updateFieldAssessmentFormStateDraft(facilityId, uuid || '', data),
    {
      onSuccess: () => {
        if (itemToEdit) {
          setItemToEdit(null);
          setSelectedFieldAssessmentModalValue(null);
          setDisplayWarningMessage(false);
        }

        refetch();
        message.success('Draft has been updated');
      },
      onError: () => {
        message.error('Error has occurred, try later');
      },
    }
  );

  const finalizeStepMutation = useMutation(
    (data: Partial<IDraftData>) =>
      engineeringRepository.finalizeStepFieldAssessmentFormState(facilityId, uuid || '', data),
    {
      onSuccess: () => {
        refetch();
        message.success('Step has been saved');
      },
      onError: () => {
        message.error('Error has occurred, try later');
      },
    }
  );

  const removeAssessmentMutation = useMutation(
    () => engineeringRepository.removeFieldAssessmentReport(facilityId, uuid || ''),
    {
      onSuccess: () => {
        message.success('Field Assessment has been removed');
        navigate('/digital-office/field-assessment-reports');
      },
      onError: () => {
        message.error('Error has occurred, try later');
      },
    }
  );

  const onFieldAssessmentClickHandler = (isEdit = false) => {
    const belongsToProject = find(projectsData?.results, { value: selectedFieldAssessmentModalValue?.value });
    if (belongsToProject) {
      isEdit
        ? updateDraftMutation.mutate({
            project_overview: { project: (selectedFieldAssessmentModalValue?.value as any) || '' },
            current_step: 0,
          })
        : postFieldAssessmentProjectMutation.mutate(selectedFieldAssessmentModalValue?.value || '');
    } else {
      isEdit
        ? updateDraftMutation.mutate({
            project_overview: { service_request: (selectedFieldAssessmentModalValue?.value as any) || '' },
            current_step: 0,
          })
        : postFieldAssessmentServiceRequestMutation.mutate(selectedFieldAssessmentModalValue?.value || '');
    }
  };

  const onStepBackButton = () => {
    setCurrentStep(currentStep - 1);
  };

  const referenceEditButtonHandler = (project: IDropdownOption | null, serviceRequest: IDropdownOption | null) => {
    if (project) {
      setItemToEdit(project);
      setSelectedFieldAssessmentModalValue(project);
    } else if (serviceRequest) {
      setSelectedFieldAssessmentModalValue(serviceRequest);
      setItemToEdit(serviceRequest);
    }
  };

  const formConfig = [
    {
      name: 'Project overview',
      component: (
        <ProjectOverviewStep
          onReferenceEditClick={referenceEditButtonHandler}
          formKey={dataUpdatedAt}
          updateMutation={updateDraftMutation}
          finalizeStepMutation={finalizeStepMutation}
          initialValues={formState?.draft_data?.project_overview || {}}
        />
      ),
      icon: <OverviewIcon />,
    },
    {
      name: 'Project location',
      component: (
        <ProjectLocationStep
          formKey={dataUpdatedAt}
          updateMutation={updateDraftMutation}
          finalizeStepMutation={finalizeStepMutation}
          onStepBackButton={onStepBackButton}
          initialValues={formState?.draft_data?.project_location || []}
        />
      ),
      icon: <LocationIcon />,
    },
    {
      name: 'Assessment checklist',
      component: (
        <AssessmentChecklistStep
          formKey={dataUpdatedAt}
          updateMutation={updateDraftMutation}
          finalizeStepMutation={finalizeStepMutation}
          onStepBackButton={onStepBackButton}
          initialValues={formState?.draft_data?.assessment_checklist || null}
        />
      ),
      icon: <ChecklistIcon />,
    },
    {
      name: 'Mission outcomes and recommendations',
      component: (
        <MissionOutcomeStep
          formKey={dataUpdatedAt}
          updateMutation={updateDraftMutation}
          finalizeStepMutation={finalizeStepMutation}
          onStepBackButton={onStepBackButton}
          initialValues={formState?.draft_data?.mission_outcomes_and_recommendations || null}
        />
      ),
      icon: <OutcomeIcon />,
    },
    {
      name: 'Progress, estimated cost, and conclusion (optional)',
      component: (
        <ProjectProgressStep
          formKey={dataUpdatedAt}
          updateMutation={updateDraftMutation}
          finalizeStepMutation={finalizeStepMutation}
          onStepBackButton={onStepBackButton}
          initialValues={formState?.draft_data?.progress_estimated_cost_and_conclusion || null}
        />
      ),
      icon: <CostIcon />,
    },
    {
      name: 'Review and publish',
      component: (
        <PublishStep
          formKey={dataUpdatedAt}
          updateMutation={updateDraftMutation}
          finalizeStepMutation={finalizeStepMutation}
          initialValues={(formState?.draft_data as IDraftData) || null}
          focalPointOptions={options?.focal_points || []}
          onStepClick={(step: number) => setCurrentStep(step)}
          onStepBackButton={onStepBackButton}
          isFormLocked={isFormLocked}
        />
      ),
      icon: <PublishIcon />,
    },
  ];

  const onRemoveClickHandler = () => {
    const confirmAlert = confirm(
      'Caution: This action will permanently remove the element. Confirm to proceed. Changes cannot be undone.'
    );

    if (confirmAlert) {
      removeAssessmentMutation.mutate();
    }
  };

  return (
    <div className='field-assessment-container'>
      <GuidanceModal
        open={uuid === 'new' || Boolean(itemToEdit)}
        destroyOnClose={true}
        closeHandler={
          uuid === 'new'
            ? () => null
            : () => {
                setItemToEdit(null);
                setDisplayWarningMessage(false);
              }
        }
      >
        <div className='field-assessment-picker-modal'>
          <div className='picker-section'>
            <p className='modal-header'>
              {itemToEdit ? 'Edit Request / Project reference' : 'Select the Request / Project reference'}
            </p>
            {itemToEdit && displayEditWarningMessage && (
              <div className='edit-warning-notification'>
                <WarningFilled className='warning-icon' />
                <p>Proceeding will permanently delete all existing data inputs in this form.</p>
              </div>
            )}
            {itemToEdit && !displayEditWarningMessage && (
              <p className='edit-warning-message'>
                Please be aware that by saving any change in the Request/Project reference,
                <span> all existing data inputs in this form will be permanently deleted</span>. This action cannot be
                undone.
              </p>
            )}
            {!displayEditWarningMessage ? (
              <Dropdown
                onChange={(value) => setSelectedFieldAssessmentModalValue(value)}
                label='Request / Project reference'
                defaultValue={itemToEdit || undefined}
                isDisabled={isProjectsListLoading || isServiceRequestsListLoading}
                options={[...(servicesRequests?.results || []), ...(projectsData?.results || [])]}
              />
            ) : null}
          </div>
          <div className='buttons-container'>
            {itemToEdit && (
              <Button
                onClick={() => {
                  setItemToEdit(null);
                  setDisplayWarningMessage(false);
                }}
                className='button'
                variant='transparent'
                text='Cancel'
              />
            )}
            {itemToEdit ? (
              <Button
                disabled={isEqual(itemToEdit, selectedFieldAssessmentModalValue)}
                onClick={() =>
                  !displayEditWarningMessage ? setDisplayWarningMessage(true) : onFieldAssessmentClickHandler(true)
                }
                className='button edit-element'
                text={!displayEditWarningMessage ? 'Save changes and delete all data' : 'Proceed and delete data'}
              />
            ) : (
              <>
                <Button
                  onClick={() => navigate('/digital-office/field-assessment-reports/')}
                  className='button'
                  variant='transparent'
                  text='Cancel'
                />
                <Button
                  disabled={!selectedFieldAssessmentModalValue}
                  onClick={onFieldAssessmentClickHandler}
                  className='button go-next'
                  text='Start the form'
                />
              </>
            )}
          </div>
        </div>
      </GuidanceModal>
      {uuid !== 'new' ? (
        <div className='content-wrapper'>
          <div className='navigation'>
            <Spin spinning={isFormStateLoading}>
              {formState ? <NavigationStepper config={config} currentStep={currentStep} /> : null}
            </Spin>
          </div>
          <div className='form-content'>
            <div className='form-header'>
              <div className='form-title'>
                <div className='header-icon'>{formConfig[currentStep].icon}</div>
                <p className='header-title'>{formConfig[currentStep].name}</p>
              </div>
              {formState?.status === 'draft' ? (
                <div className='form-status'>
                  <Tooltip title='Click to remove Field Assessment Report'>
                    <CloseCircleFilled onClick={() => onRemoveClickHandler()} className='remove-icon' />
                  </Tooltip>
                </div>
              ) : null}
              {formState?.status === 'published' ? (
                <div className='form-status'>
                  <div className='badge completed'>Completed</div>
                </div>
              ) : null}
              {formState?.status === 'pending_review' ? (
                <div className='form-status'>
                  <div className={`badge ${formState?.reviewers_emails?.length === 0 ? 'completed' : 'pending'}`}>
                    {formState?.reviewers_emails?.length === 0 ? (
                      <CompletedIcon className='completed-icon' />
                    ) : (
                      <HourglassOutlined />
                    )}
                    {formState?.reviewers_emails?.length === 0 ? (
                      <span>
                        Reviews: all completed {formState?.approved_by_users?.length} /{' '}
                        {formState?.approved_by_users?.length}
                      </span>
                    ) : (
                      <span>
                        Reviews: {formState?.reviewers_emails?.length} pending out of{' '}
                        {(formState?.reviewers_emails?.length || 0) + (formState?.approved_by_users?.length || 0)}
                      </span>
                    )}
                  </div>
                  <div className='emails-list'>
                    {formState?.reviewers_emails?.map((email) => (
                      <div key={email.email} className='emails-element pending'>
                        <span>{email.email}:</span>
                        <div className='email-status pending'>
                          Pending <HourglassOutlined />
                        </div>
                      </div>
                    ))}
                    {formState?.approved_by_users?.map((email) => (
                      <div key={email.email} className='emails-element completed'>
                        <span>{email.email}:</span>
                        <div className='email-status completed'>
                          Reviewed <CompletedIcon className='completed-icon' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            <Spin
              spinning={
                isFormStateLoading ||
                updateDraftMutation.status === 'loading' ||
                finalizeStepMutation.status === 'loading'
              }
            >
              {formState ? formConfig[currentStep].component : null}
            </Spin>
            {formState?.status === 'published' ? (
              <div className='buttons-container'>
                <Button
                  onClick={() => onRemoveClickHandler()}
                  className='button remove'
                  disabled={removeAssessmentMutation.status === 'loading'}
                  text='Remove'
                  variant='danger'
                />
                <Button
                  onClick={() => getAssessmentFileMutation.mutate()}
                  className='button publish'
                  disabled={getAssessmentFileMutation.status === 'loading'}
                  text='Download PDF'
                  variant='submit'
                />
              </div>
            ) : null}
            {formState?.status === 'pending_review' ? (
              <div className='buttons-container'>
                <Button
                  onClick={() => onRemoveClickHandler()}
                  className='button remove'
                  disabled={removeAssessmentMutation.status === 'loading'}
                  text='Remove'
                  variant='danger'
                />
                {formState?.is_focal_point_for_facility ? (
                  <Tooltip
                    title={
                      formState?.reviewers_emails?.length !== 0
                        ? 'Reviews are pending. You can publish the assessment when all reviews are completed.'
                        : ''
                    }
                  >
                    <div>
                      <Button
                        onClick={() => assessmentPublishMutation.mutate()}
                        className='button publish'
                        text='Publish assessment'
                        variant='submit'
                        disabled={
                          formState?.reviewers_emails?.length !== 0 || assessmentPublishMutation.status === 'loading'
                        }
                      />
                    </div>
                  </Tooltip>
                ) : null}
                {formState?.can_review ? (
                  <Button
                    disabled={reviewAssessmentMutation.status === 'loading'}
                    onClick={() => reviewAssessmentMutation.mutate()}
                    className='button publish'
                    text='Accept review'
                    variant='submit'
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FieldAssessmentReports;
