import React, { FC, useEffect, useRef, useState } from 'react';
import { Form, FormApi, TextArea } from 'informed';
import { Tooltip } from 'antd';
import { DateField, Dropdown, TextField } from '@ui-modules/informed';
import { IDropdownOption } from '@ui-modules/types';
import { ReactComponent as InfoIcon } from '@assets/svg/InfoIcon.svg';
import { Button } from '@ui-modules';
import * as Yup from 'yup';
import { UseMutationResult } from 'react-query';

import './styles.scss';
import { addDays, format, isValid } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

interface IRiskFormProps {
  options: {
    [key: string]: IDropdownOption[];
  };
  sendRiskMutation: UseMutationResult<any, any, any, unknown>;
  updateRiskMutation: UseMutationResult<any, any, any, unknown>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRisk: React.Dispatch<React.SetStateAction<string | null>>;
  formKey: number;
  riskData: {
    [key: string]: any;
  };
}

const InformedField = ({ name, children, formRef, className }: any) => {
  const error = formRef?.current && formRef.current.getError(name);
  return (
    <div className={`input-wrapper ${className}`}>
      {children}
      {error && <div className='error'>{error}</div>}
    </div>
  );
};

const validationSchema = Yup.object().shape({
  risk_category: Yup.object().required('Required'),
  activity: Yup.object().required('Required'),
  impact: Yup.object().required('Required'),
  likelihood: Yup.object().required('Required'),
  risk_response_acat: Yup.object().required('Required'),
  management_results_dimension: Yup.object().required('Required'),
  cause_group: Yup.object().required('Required'),
  effect_group: Yup.object().required('Required'),
});

const RiskForm: FC<IRiskFormProps> = ({
  options,
  sendRiskMutation,
  setIsModalOpen,
  setSelectedRisk,
  riskData,
  formKey,
  updateRiskMutation,
}) => {
  const formRef = useRef<FormApi>(null);
  const [riskSelection, setRiskSelection] = useState<IDropdownOption | undefined>({ label: 'Add New', value: 'new' });
  const [, updateState] = useState<any>(null);

  useEffect(() => {
    riskSelection && setSelectedRisk(riskSelection?.value === 'new' ? null : riskSelection.value);
  }, [riskSelection]);

  const parseFormDataToPayload = (values: Record<string, IDropdownOption>) => {
    const result: any = {};

    Object.keys(values).map((key) => {
      if (typeof values[key] === 'object' && values[key].value) {
        result[key] = values[key].value;
      } else if (key === 'start_date' || key === 'end_date') {
        result[key] = format(new Date(values[key] as unknown as string), DateFormatEnum.YYYY_MM_DD);
      } else {
        result[key] = values[key];
      }
    });

    return result;
  };

  return (
    <div className='risk-section-container'>
      <div className='title'>
        <p className='title-paragraph'>Edit or Add Risk for Project</p>
        <hr className='hr' />
      </div>
      <Form
        className='risk-form-container'
        key={formKey}
        formApiRef={formRef}
        onSubmit={({ values, modified }) => {
          const dataToParsed = riskSelection?.value === 'new' ? values : modified;
          const data = parseFormDataToPayload(dataToParsed as Record<string, IDropdownOption>);
          riskSelection?.value === 'new' ? sendRiskMutation.mutate(data) : updateRiskMutation.mutate(data);
        }}
        yupSchema={validationSchema}
        initialValues={{
          ...riskData,
          start_date: riskData?.start_date ? new Date(riskData?.start_date) : undefined,
          end_date: riskData?.end_date ? new Date(riskData?.end_date) : undefined,
        }}
        validateOn='change-blur'
      >
        <InformedField name='country' formRef={formRef}>
          <Dropdown
            onChange={(option) => setRiskSelection(option)}
            name='risk_selection'
            value={riskSelection}
            options={[{ label: 'Add New', value: 'new' }, ...options.risks]}
            label='Risk selection'
            key='risk_selection'
          />
        </InformedField>
        <InformedField name='risk_category' formRef={formRef}>
          <Dropdown name='risk_category' options={options.risk_category} label='Risk category*' key='risk_category' />
        </InformedField>
        <InformedField name='activity' formRef={formRef}>
          <Dropdown name='activity' options={options.activity} label='Activity*' key='activity' />
        </InformedField>
        <InformedField name='impact' formRef={formRef}>
          <Dropdown name='impact' options={options.impact} label='Impact(1 low - 5 high)*' key='impact' />
        </InformedField>
        <InformedField name='likelihood' formRef={formRef}>
          <Dropdown
            name='likelihood'
            options={options.likelihood}
            label='Likelihood(1 low - 5 high)*'
            key='likelihood'
          />
        </InformedField>
        <InformedField name='risk_response_acat' formRef={formRef}>
          <Dropdown
            name='risk_response_acat'
            options={options.risk_response}
            label='Risk response*'
            key='risk_response_acat'
          />
        </InformedField>
        <InformedField name='risk_owner' formRef={formRef}>
          <TextField name='risk_owner' label='Risk owner' key='risk_owner' placeholder='Insert' />
        </InformedField>
        <InformedField name='management_results_dimension' formRef={formRef}>
          <Dropdown
            name='management_results_dimension'
            options={options.management_results_dimension}
            label='Management results dimension*'
            key='management_results_dimension'
          />
        </InformedField>
        <InformedField name='status' formRef={formRef}>
          <Dropdown name='status' options={options.status} label='Status' key='status' />
        </InformedField>
        <InformedField name='status' formRef={formRef}>
          <DateField
            onChange={() => {
              formRef?.current?.setValue('end_date', undefined);
              updateState({});
            }}
            name='start_date'
            placeholder='Insert...'
            label='Risk Start date'
            dateFormat={DateFormatEnum.DD_MM_YYYY}
          />
        </InformedField>
        <InformedField name='status' formRef={formRef}>
          <DateField
            dateFormat={DateFormatEnum.DD_MM_YYYY}
            name='end_date'
            placeholder='Insert...'
            label={
              formRef?.current?.getValue('start_date') === undefined ? (
                <div className='tooltip-label-wrapper'>
                  Risk End date{' '}
                  <Tooltip title='Provide start date first'>{<InfoIcon className='tooltip-helper-icon' />}</Tooltip>
                </div>
              ) : (
                'Risk End date'
              )
            }
            minDate={
              isValid(formRef?.current?.getValue('start_date'))
                ? addDays(new Date(formRef?.current?.getValue('start_date') as string), 1)
                : undefined
            }
            disabled={formRef?.current?.getValue('start_date') === undefined}
          />
        </InformedField>
        <InformedField name='cause_group' formRef={formRef}>
          <Dropdown name='cause_group' options={options.cause_group} label='Cause group*' key='cause_group' />
        </InformedField>
        <InformedField name='effect_group' formRef={formRef}>
          <Dropdown name='effect_group' options={options.effect_group} label='Effect group*' key='effect_group' />
        </InformedField>
        <InformedField className='text-area' name='description' formRef={formRef}>
          <TextArea name='description' label='Description' key='description' />
        </InformedField>
        <InformedField className='text-area' name='causes' formRef={formRef}>
          <TextArea name='causes' label='Causes' key='causes' />
        </InformedField>
        <InformedField className='text-area' name='effects' formRef={formRef}>
          <TextArea name='effects' label='Effects' key='effects' />
        </InformedField>
        <InformedField className='text-area' name='mitigations_actions_needed' formRef={formRef}>
          <TextArea
            name='mitigations_actions_needed'
            label='Mitigation actions needed'
            key='mitigations_actions_needed'
          />
        </InformedField>
      </Form>
      <div className='button-container'>
        <Button
          disabled={updateRiskMutation.status === 'loading' || sendRiskMutation.status === 'loading'}
          onClick={() => setIsModalOpen(false)}
          variant='warning'
          text='CANCEL'
        />
        <Button
          disabled={updateRiskMutation.status === 'loading' || sendRiskMutation.status === 'loading'}
          onClick={() => formRef?.current && formRef.current.submitForm()}
          text='CONFIRM'
        />
      </div>
    </div>
  );
};

export default RiskForm;
