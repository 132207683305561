import React, { FC } from 'react';
import { FormApi, Radio, RadioGroup } from 'informed';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import * as Yup from 'yup';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import { IAssessmentFormData } from '@digital-office/pages/FieldAssessmentReports/components/AssessmentChecklistStep';

interface IAssessmentConductedList {
  name: string;
  values: IAssessmentFormData;
  formRef: React.RefObject<FormApi>;
}

const getAssessmentConductedListYupValidation = (values: IAssessmentFormData) => {
  const isPrefabOfficeAccommodationUnitsExist =
    values?.data?.assessment_new_variation_works?.prefab_office_accommodation_units_exist === 'yes';
  const isNewPrefabUnitsRequiredOfficeAccommodationExist =
    values?.data?.assessment_new_variation_works?.new_prefab_units_required_office_accommodation === 'yes';
  const isNewShippingContainersRequiredFacilityStorageExist =
    values?.data?.assessment_new_variation_works?.new_shipping_containers_required_facility_storage === 'yes';
  const isMobileStorageUnitsExistFacility =
    values?.data?.assessment_new_variation_works?.mobile_storage_units_exist_facility === 'yes';

  return Yup.object().shape({
    new_prefab_units_required_office_accommodation: Yup.string().required('This field is required'),
    shipping_containers_exist: Yup.string().required('This field is required'),
    new_shipping_containers_required_facility_storage: Yup.string().required('This field is required'),
    mobile_storage_units_exist_facility: Yup.string().required('This field is required'),
    drainage_system_exists: Yup.string().required('This field is required'),
    prefab_office_accommodation_units_exist: Yup.string().required('This field is required'),
    how_many_prefab_office_accommodation_units_exist: isPrefabOfficeAccommodationUnitsExist
      ? Yup.string().required('This field is required')
      : Yup.mixed(),
    how_many_new_prefab_units_required_office_accommodation: isNewPrefabUnitsRequiredOfficeAccommodationExist
      ? Yup.string().required('This field is required')
      : Yup.mixed(),
    how_many_new_shipping_containers_required_facility_storage: isNewShippingContainersRequiredFacilityStorageExist
      ? Yup.string().required('This field is required')
      : Yup.mixed(),
    how_many_mobile_storage_units_exist_facility: isMobileStorageUnitsExistFacility
      ? Yup.string().required('This field is required')
      : Yup.mixed(),
  });
};

const AssessmentConductedList: FC<IAssessmentConductedList> = ({ values, formRef }) => {
  const setValue = formRef.current?.setValue;

  return (
    <div className='assessment-details-list-container'>
      <div className='table-header'>
        <div className='headers'>
          <span>Yes</span>
          <span>No</span>
        </div>
      </div>
      <InformedField
        label=''
        formRefApi={formRef}
        name='data[assessment_new_variation_works][prefab_office_accommodation_units_exist]'
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>
              Does prefabricated office and/or accommodation units exist at this facility?
            </span>
            <RadioGroup
              onChange={(e) =>
                e.value === 'no' &&
                setValue &&
                setValue(
                  'data[assessment_new_variation_works][how_many_prefab_office_accommodation_units_exist]',
                  undefined
                )
              }
              className='radio'
              name='data[assessment_new_variation_works][prefab_office_accommodation_units_exist]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <InformedField
        name='data[assessment_new_variation_works][how_many_prefab_office_accommodation_units_exist]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>How many?</span>
            <div className='list-input'>
              <NumericInput
                disabled={Boolean(
                  values?.data?.assessment_new_variation_works?.prefab_office_accommodation_units_exist !== 'yes'
                )}
                suffix=''
                decimalScale={0}
                name='data[assessment_new_variation_works][how_many_prefab_office_accommodation_units_exist]'
              />
            </div>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        label=''
        name='data[assessment_new_variation_works][new_prefab_units_required_office_accommodation]'
        formRefApi={formRef}
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>
              Are new prefabricated units required for office and/or accommodation units?
            </span>
            <RadioGroup
              onChange={(e) =>
                e.value === 'no' &&
                setValue &&
                setValue(
                  'data[assessment_new_variation_works][how_many_new_prefab_units_required_office_accommodation]',
                  undefined
                )
              }
              className='radio'
              name='data[assessment_new_variation_works][new_prefab_units_required_office_accommodation]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <InformedField
        name='data[assessment_new_variation_works][how_many_new_prefab_units_required_office_accommodation]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>How many?</span>
            <div className='list-input'>
              <NumericInput
                disabled={Boolean(
                  values?.data?.assessment_new_variation_works?.new_prefab_units_required_office_accommodation !== 'yes'
                )}
                suffix=''
                decimalScale={0}
                name='data[assessment_new_variation_works][how_many_new_prefab_units_required_office_accommodation]'
              />
            </div>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        label=''
        name='data[assessment_new_variation_works][shipping_containers_exist]'
        formRefApi={formRef}
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>
              Are new prefabricated units required for office and/or accommodation units?
            </span>
            <RadioGroup className='radio' name='data[assessment_new_variation_works][shipping_containers_exist]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[assessment_new_variation_works][new_shipping_containers_required_facility_storage]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>
              Are new shipping containers required at this facility for storage or similar purposes?
            </span>
            <RadioGroup
              onChange={(e) =>
                e.value === 'no' &&
                setValue &&
                setValue(
                  'data[assessment_new_variation_works][how_many_new_shipping_containers_required_facility_storage]',
                  undefined
                )
              }
              className='radio'
              name='data[assessment_new_variation_works][new_shipping_containers_required_facility_storage]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <InformedField
        label=''
        name='data[assessment_new_variation_works][how_many_new_shipping_containers_required_facility_storage]'
        formRefApi={formRef}
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>How many?</span>
            <div className='list-input'>
              <NumericInput
                disabled={Boolean(
                  values?.data?.assessment_new_variation_works?.new_shipping_containers_required_facility_storage !==
                    'yes'
                )}
                suffix=''
                decimalScale={0}
                name='data[assessment_new_variation_works][how_many_new_shipping_containers_required_facility_storage]'
              />
            </div>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        label=''
        name='data[assessment_new_variation_works][mobile_storage_units_exist_facility]'
        formRefApi={formRef}
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>
              Are there Mobile Storage Units (temporary warehouses) existing at this facility for storage or similar
              purposes?
            </span>
            <RadioGroup
              onChange={(e) =>
                e.value === 'no' &&
                setValue &&
                setValue(
                  'data[assessment_new_variation_works][how_many_mobile_storage_units_exist_facility]',
                  undefined
                )
              }
              className='radio'
              name='data[assessment_new_variation_works][mobile_storage_units_exist_facility]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <InformedField
        label=''
        name='data[assessment_new_variation_works][how_many_mobile_storage_units_exist_facility]'
        formRefApi={formRef}
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>How many?</span>
            <div className='list-input'>
              <NumericInput
                disabled={Boolean(
                  values?.data?.assessment_new_variation_works?.mobile_storage_units_exist_facility !== 'yes'
                )}
                suffix=''
                decimalScale={0}
                name='data[assessment_new_variation_works][how_many_mobile_storage_units_exist_facility]'
              />
            </div>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        label=''
        formRefApi={formRef}
        name='data[assessment_new_variation_works][drainage_system_exists]'
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Does an effective or functional drainage system exists?</span>
            <RadioGroup className='radio' name='data[assessment_new_variation_works][drainage_system_exists]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
    </div>
  );
};

export { AssessmentConductedList, getAssessmentConductedListYupValidation };
