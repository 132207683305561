import React, { useMemo, useState } from 'react';
import { Table, PageTitle, Pagination, Tabs } from '@ui-modules';
import { Tooltip as Tooltip } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRepository } from '@context/repository.context';
import { IFFARequestParams, StatusList } from '@digital-office/common/interfaces';
import { getBookingStateBtn } from '@digital-office/pages/RequestsListPage';
import { IDropdownOption } from '@ui-modules/types';
import { Dropdown as FiltersDropdown } from '@ui-modules/components';

import './styles.scss';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

const getColumns = (statuses: StatusList) => [
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Request Date',
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'descend',
    sorter: true,
    render: (record: string) => {
      return format(new Date(record), DateFormatEnum.DD_MM_YYYY);
    },
  },
  {
    dataIndex: 'reference_code',
    key: 'reference_code',
    title: 'ID',
  },
  {
    dataIndex: 'organisation',
    key: 'organisation',
    title: 'Organisation',
  },
  {
    dataIndex: 'location',
    key: 'location',
    title: 'Location',
  },
  {
    dataIndex: 'activity_type',
    key: 'activity_type',
    title: 'Category',
    render: (category: string) => (
      <Tooltip placement='topLeft' title={category}>
        {category}
      </Tooltip>
    ),
  },
  {
    dataIndex: 'activity',
    key: 'uuid',
    title: 'Activity',
    render: (activity: string) => (
      <Tooltip placement='topLeft' title={activity}>
        {activity}
      </Tooltip>
    ),
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    width: 100,
    render: (record: string) => {
      return getBookingStateBtn(record, statuses);
    },
  },
];

const tabsList = ['Not processed', 'Processed'];

const FFAListPage = () => {
  const tabs = tabsList;

  const initialStatusFilter = sessionStorage.getItem('ffaAssignmentListStatus');
  const initialCategoryFilter = sessionStorage.getItem('ffaAssignmentListCategory');

  const [statusFilter, setStatusFilter] = useState<IDropdownOption | null>(
    initialStatusFilter ? JSON.parse(initialStatusFilter) : null
  );
  const [categoryFilter, setCategoryFilter] = useState<IDropdownOption | null>(
    initialCategoryFilter ? JSON.parse(initialCategoryFilter) : null
  );

  const initialTab = sessionStorage.getItem('ffaAssignmentListTab') || tabs[0];
  const initialSelectedPage = parseFloat(sessionStorage.getItem('ffaAssignmentListPage') ?? '1');
  const initialPageSize = parseFloat(sessionStorage.getItem('ffaAssignmentListPerPage') ?? '10');

  const [columnOrder, setColumnOrder] = useState<string>('-created');
  const [selectedPage, setSelectedPage] = useState<number>(initialSelectedPage);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';

  const { engineeringRepository } = useRepository();
  const navigate = useNavigate();

  const resetFilters = useMemo(
    () => () => {
      setStatusFilter(null);
      setCategoryFilter(null);
      sessionStorage.removeItem('ffaAssignmentListStatus');
      sessionStorage.removeItem('ffaAssignmentListCategory');
      clearPagination();
    },
    []
  );

  const buildParams = (): IFFARequestParams => {
    return {
      show: selectedTab === 'Processed' ? 'processed' : 'not_processed',
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder ? columnOrder : null,
      status: statusFilter?.value,
      activity_type: categoryFilter?.value as string,
    };
  };

  const { data: list, isLoading } = useQuery(
    ['ffa-requests-list', selectedPage, pageSize, columnOrder, selectedTab, statusFilter?.value, categoryFilter?.value],
    () => engineeringRepository.getAssessmentsList(facilityId, buildParams()),
    {
      refetchOnWindowFocus: false,
    }
  );

  const clearPagination = () => {
    setSelectedPage(1);
    setPageSize(10);
    sessionStorage.setItem('assignmentListPage', '1');
    sessionStorage.setItem('assignmentListPerPage', '10');
  };

  return (
    <section className='hbh-container booking-list ffa-list-wrapper'>
      <div className='ffa-page-title-wrapper'>
        <PageTitle className='page-title' title={`FFA Engineering support requests - ${country}`} bottomLine={true} />
        <VideoLink link={videoLinks.GUIDANCE} />
      </div>
      <Tabs
        className='engineering-tabs'
        tabs={tabs}
        selectedTab={tabs.indexOf(selectedTab)}
        onTabSelect={(index) => {
          setSelectedTab(index);
          sessionStorage.setItem('ffaAssignmentListTab', index);
          resetFilters();
        }}
      />
      <section className='filters'>
        <div className='dropdown-filters'>
          <FiltersDropdown
            className='filter select outline'
            label={<label className='hbh-select-label'>Filter by Status</label>}
            value={statusFilter}
            isClearable={true}
            placeholder='All'
            options={list?.options[selectedTab === 'Processed' ? 'processed' : 'not_processed'] || []}
            onChange={(value) => {
              setStatusFilter(value);
              clearPagination();
              sessionStorage.setItem('ffaAssignmentListStatus', JSON.stringify(value));
            }}
          />
          <FiltersDropdown
            className='filter select outline'
            label={<label className='hbh-select-label'>Filter by Category</label>}
            value={categoryFilter}
            placeholder='All'
            options={list?.options?.activity_types || []}
            onChange={(value) => {
              setCategoryFilter(value);
              clearPagination();
              sessionStorage.setItem('ffaAssignmentListCategory', JSON.stringify(value));
            }}
          />
          <span className='reset' onClick={resetFilters}>
            Reset filters
          </span>
        </div>
      </section>
      <div className='table'>
        <Table
          columns={getColumns(list?.options)}
          data={list?.results}
          variant='dark'
          rowKey='uuid'
          onRow={(record) => {
            return {
              onClick: () => navigate(`${record.uuid}`),
            };
          }}
          isLoading={isLoading}
          onChangeColumnOrder={setColumnOrder}
        />
      </div>
      <Pagination
        className='pagination'
        selectedPage={selectedPage}
        showJumper={true}
        showPageSize={true}
        totalPages={list?.count}
        variant='dark'
        onPageChange={(page) => {
          setSelectedPage(page);
          sessionStorage.setItem('ffaAssignmentListPage', page.toString());
        }}
        onPageSizeChange={(perPage) => {
          setPageSize(perPage);
          sessionStorage.setItem('ffaAssignmentListPerPage', perPage.toString());
        }}
      />
    </section>
  );
};

export default FFAListPage;
