import React, { FC, useEffect } from 'react';
import { useField } from 'informed';
import { DataItemExpanded } from '@digital-office/common/interfaces';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types';
import './styles.scss';

interface IServiceField {
  options: Array<DataItemExpanded>;
  name: string;
}

const ServiceField: FC<IServiceField> = (props) => {
  const { options, name } = props;

  const { fieldState, fieldApi, render, ref } = useField(props);

  const fieldValue = (fieldState.value || []) as Array<IDropdownOption>;

  const { setValue } = fieldApi;

  useEffect(() => {
    if (!fieldValue) {
      setValue([]);
    }
  }, [fieldValue]);

  const allValues = options.map(({ value, display_name }) => ({ value, label: display_name }));
  const isFullSelect = allValues.length === fieldValue.length;

  return render(
    <div ref={ref} className='sf-wrapper'>
      <div className='sf-container'>
        {options.map(({ description, display_name, icon, value }, idx) => {
          const isChecked = Boolean(fieldValue.find((option) => option.value === value));
          return (
            <label key={idx} htmlFor={value} className={`sf-option ${isChecked ? 'checked' : ''}`}>
              <div className='sf-title'>
                {display_name}
                <input
                  type='checkbox'
                  id={value}
                  name={name}
                  className='sf-input'
                  checked={isChecked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue([...fieldValue, { value, label: display_name }]);
                    } else {
                      setValue([...fieldValue].filter((item) => item.value !== value));
                    }
                  }}
                />
              </div>
              <img src={icon} alt='' className='sf-icon' />
              <div className='sf-description'>{description}</div>
            </label>
          );
        })}
        <button
          className={`sf-button ${isFullSelect ? 'checked' : ''}`}
          type='button'
          onClick={() => {
            if (isFullSelect) {
              setValue([]);
            } else {
              setValue(allValues);
            }
          }}
        >
          {isFullSelect ? 'Deselect all' : 'Select all'}
        </button>
      </div>
    </div>
  );
};

export default ServiceField;
