export default [
  {
    title: 'Business case and feasibility',
    description: 'Initial phase required to identify the need for the project and to examine its feasibility',
    deliverables: [{ name: 'Business case report' }, { name: 'Project plan' }, { name: 'Summary and ecommendation' }],
    uuid: 'new',
  },
  {
    title: 'Project options and preliminary design',
    uuid: 'new',
    description: 'Development of a preliminary design; sufficient to allow for a cost assessment to be prepared',
    deliverables: [
      { name: 'Surveys' },
      { name: 'Design Report' },
      { name: 'Project plan' },
      { name: 'Documentation or appointment of specialists (designers etc)' },
      { name: 'Summary and recommendation' },
    ],
  },
  {
    title: 'Permits & authorisations',
    uuid: 'new',
    description:
      'Defined phase for the submission of all applications for permissions/authorisations to the local municipality or others',
    deliverables: [
      { name: 'A report of the outcome of the authorisation process' },
      { name: 'Updated project plan' },
      { name: 'The finalised authorisation submission documentation' },
      { name: 'Environmental risk assessments or formal environmental assessment' },
      { name: 'Summary and recommendation' },
    ],
  },
  {
    title: 'Detailed design & contract documents',
    uuid: 'new',
    description:
      'Phase for the development of the preferred solution to a detailed level suitable to allow for the production of a set contract specifications and tender documentation.',
    deliverables: [
      { name: 'Design drawings' },
      { name: 'Design specification' },
      { name: 'Pricing document' },
      { name: 'Bidder Capacity Questionnaire' },
      { name: 'Evaluation methodology' },
      { name: 'Updated risk assessments' },
      { name: 'Updated cost estimate' },
      { name: 'Updated project plan' },
      { name: 'Summary and recommendation' },
    ],
  },
  {
    title: 'Construction procurement',
    uuid: 'new',
    description: 'Management of the tender process and subsequent assessment and award',
    deliverables: [
      { name: 'Tender documentation' },
      { name: 'Tender Communications' },
      { name: 'Tender Evaluation and NF-PCC Recommendation' },
      { name: 'Purchase Order' },
      { name: 'Signed contracts' },
    ],
  },
  {
    title: 'Construction management',
    uuid: 'new',
    description: 'Management and supervision of the construction activities',
    deliverables: [
      { name: 'Site records' },
      { name: 'Documentation required under the contract' },
      { name: 'Draft final account' },
    ],
  },
  {
    title: 'Close-out',
    uuid: 'new',
    description: 'Completion of all closing reports and collating all project data',
    deliverables: [{ name: 'Final account' }, { name: 'Construction records' }, { name: 'Close-out review reports' }],
  },
];
