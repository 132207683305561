/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import { FC, createContext, useEffect, useState, useContext, PropsWithChildren } from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { decodeToken, getAccessTokenFromRefreshToken, getTokens, login, tokenWillExpire } from './authorization';
import { useLocalStorage } from './hooks';
import { IAuthProvider, TTokenData, IAuthContext, AuthType } from './types';
import { validateAuthConfig, retrieveAuthConfigValues } from './utils';
import { LocalStorageKeys } from './constants';

const AuthContext = createContext<IAuthContext>({
  axios: axios.create(),
});

let tokenRefreshing: boolean;

export const AuthProvider: FC<PropsWithChildren<IAuthProvider>> = ({
  authConfig,
  axiosInstance,
  forbiddenUrl = '/forbidden',
  errorUrl = '/error',
  children,
}) => {
  // TOKEN DATA
  const [refreshToken, setRefreshToken] = useLocalStorage<string | null>(LocalStorageKeys.REFRESH_TOKEN, null);
  const [accessToken, setAccessToken] = useLocalStorage<string | null>(LocalStorageKeys.ACCESS_TOKEN, null);

  const [idToken, setIdToken] = useLocalStorage<string | null>(LocalStorageKeys.ID_TOKEN, null);
  const [, setTokenExpiration] = useLocalStorage<string | null>(LocalStorageKeys.EXPIRATION, null);
  const [tokenData, setTokenData] = useState<TTokenData>();
  const [error, setError] = useState<string | null>(null);

  // LOGIN FLOW DATA
  const [loginInProgress, setLoginInProgress] = useLocalStorage<boolean>(LocalStorageKeys.LOGIN_IN_PROGRESS, false);
  const [firstLoginAttempt, setFirstLoginAttempt] = useLocalStorage<boolean>(
    LocalStorageKeys.FIRST_LOGIN_ATTEMPT,
    true
  );
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage<boolean>(LocalStorageKeys.IS_AUTHENTICATED, false);
  const [hasAccessDO, setHasAccessDO] = useLocalStorage<boolean>(LocalStorageKeys.HAS_ACCESS_DO, false);

  // AUTH TYPE
  const [authType, setAuthType] = useLocalStorage<AuthType>(LocalStorageKeys.AUTH_TYPE, authConfig.authType ?? 'azure');

  // USER DATA
  const [userEmail, setUserEmail] = useLocalStorage<string | null>(LocalStorageKeys.USER_EMAIL, null);
  const [userName, setUserName] = useLocalStorage<string | null>(LocalStorageKeys.USER_NAME, null);

  validateAuthConfig(authConfig);

  const processAuthorization = async () => {
    if (!accessToken && firstLoginAttempt && authConfig.autoAuthorize === true) {
      authorize(authType);
    } else if (loginInProgress) {
      processAuthResponse();
    } else if (refreshToken) {
      const tokenExpirationSource = authConfig.tokenExpirationSource === 'access_token' ? accessToken : idToken;

      if (tokenWillExpire(decodeToken(tokenExpirationSource).exp, authConfig)) {
        handleTokenExpiration();
      }
    }
  };

  const handleTokenExpiration = async () => {
    try {
      const response = await getAccessTokenFromRefreshToken({ authType, ...authConfig }, refreshToken);
      handleTokenResponse(response);
    } catch (e) {
      processRefreshTokenError(e as AxiosError);
    }
  };

  const clearLocalStorage = () => {
    setRefreshToken(null);
    setAccessToken(null);
    setIdToken(null);
    setTokenData(undefined);
    setLoginInProgress(false);
    setIsAuthenticated(false);
  };

  const processRefreshTokenError = async (error: AxiosError<any>) => {
    const data = error.response?.data;

    const errorContainsErrorCodes = data?.error_codes?.some((code: number) =>
      authConfig.refreshTokenErrorCodes?.includes(code)
    );

    if (
      error.response?.status === 400 &&
      data?.error === 'invalid_grant' &&
      (authConfig.pkceProvider === 'ciam' || errorContainsErrorCodes || refreshToken === null)
    ) {
      console.warn('Refresh token has been expired. Triggering login process...');
      clearLocalStorage();
      authorize(authType);
    }
  };

  const authorize = (initialAuthType?: AuthType) => {
    setLoginInProgress(true);
    setFirstLoginAttempt(false);

    const loginAuthType = initialAuthType || authType || authConfig.authType;

    if (initialAuthType) {
      setAuthType(initialAuthType);
    }

    login({ authType: loginAuthType, ...authConfig });
  };

  const processAuthResponse = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = authConfig.authType === 'google' ? urlParams.get('state') : urlParams.get('code');

    if (!authCode) {
      const error = urlParams.get('error');

      if (error) {
        console.error(error);
        setError(error);
      }

      logout();
      window.location.reload();
    } else {
      const response = await getTokens({ authType, ...authConfig }, authCode);

      if (response.ok) {
        const tokenData = decodeToken(response.data?.id_token);
        handleTokenResponse({
          ...response.data,
          email: tokenData['email'],
          preferred_username: tokenData['preferred_username'] || tokenData['name'],
        });
        setIsAuthenticated(true);

        if (authConfig.unbhPermissionsEndpoint) {
          await checkUNBHPermissions(response.data?.id_token, tokenData['email']);
        }

        setLoginInProgress(false);
        window.location.href = authConfig.successRedirectUri ?? '/';
      } else {
        console.error(response.error);
        setError(response.error);
      }
    }
  };

  const logout = () => {
    clearLocalStorage();

    const { clientId, logoutEndpoint, logoutUri } = retrieveAuthConfigValues({
      authType: authType || authConfig.authType,
      ...authConfig,
    });

    if (clientId && logoutEndpoint) {
      const params = new URLSearchParams({
        response_type: 'code',
        client_id: clientId,
      });

      if (authType === 'b2c') {
        params.append('post_logout_redirect_uri', logoutUri || '');
        params.append('id_token_hint', idToken);
      } else {
        params.append('logout_uri', logoutUri || '');
      }

      let logoutRedirectUrl = logoutEndpoint;
      logoutRedirectUrl += authType === 'b2c' ? '&' : '?';
      logoutRedirectUrl += params.toString();

      window.location.href = logoutRedirectUrl;
    } else {
      window.location.href = logoutUri || '/';
    }
  };

  const checkUNBHPermissions = async (idToken: string, userEmail: string) => {
    try {
      const { status } = await axios.get(authConfig.unbhPermissionsEndpoint!, {
        headers: {
          'X-Subscription-Token': authConfig.hbhSubscriptionToken!,
          Authentication: idToken,
          'X-User-Email': userEmail,
        },
      });

      if (status === 200) {
        setHasAccessDO(true);
      }
    } catch (e: any) {
      if (authConfig.errorOnPermissionsCheckFail) {
        if (e.response && e.response?.status === 403) {
          window.location.href = forbiddenUrl;
        } else {
          window.location.href = errorUrl;
          setError(e.message ?? 'We are sorry! Report to the dev team please');
        }
      }
    }
  };

  const handleTokenResponse = (data: any) => {
    localStorage.removeItem(LocalStorageKeys.PKCE_CODE_VERIFIER);

    if (data.email) setUserEmail(data.email);
    if (data.preferred_username) setUserName(data.preferred_username);

    setAccessToken(data.access_token);
    setIdToken(data.id_token);

    if (data.refresh_token) setRefreshToken(data.refresh_token);

    const decodedToken = decodeToken(
      authConfig.tokenExpirationSource === 'access_token' ? data.access_token : data.id_token
    );

    setTokenExpiration(decodedToken.exp);
    setTokenData(decodedToken);
  };

  useEffect(() => {
    processAuthorization();
  }, []);

  if (axiosInstance && !authConfig.skipInterceptors) {
    axiosInstance.interceptors.request.use(
      async (req: AxiosRequestConfig) => {
        const bearerToken =
          authConfig.tokenBearerSource === 'access_token'
            ? localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)
            : localStorage.getItem(LocalStorageKeys.ID_TOKEN);

        req.headers = {
          ...req.headers,
          Authorization: `Bearer ${JSON.parse(String(bearerToken))}`,
        };

        return req;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (res) => res,
      async (error) => {
        const data = error.response?.data;
        const originalRequest = error.config;

        if (JSON.stringify(data).includes('JWT has expired') && !originalRequest._retry && !tokenRefreshing) {
          originalRequest._retry = true;
          tokenRefreshing = true;

          try {
            const response = await getAccessTokenFromRefreshToken({ authType, ...authConfig }, refreshToken);
            handleTokenResponse(response);
          } catch (e) {
            processRefreshTokenError(e as AxiosError);
          }

          tokenRefreshing = false;
          return axiosInstance(originalRequest);
        }

        return Promise.reject(error);
      }
    );
  }

  const contextState = {
    axios: axiosInstance ?? axios.create(),
    accessToken,
    error,
    isAuthenticated,
    idToken,
    hasAccessDO,
    firstLoginAttempt,
    tokenData,
    userName,
    userEmail,
    authorize,
    logout,
  };

  return <AuthContext.Provider value={contextState}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
