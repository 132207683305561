import { useField } from 'informed';
import React, { FC } from 'react';

import './styles.scss';

export interface IMoneyField {
  name: string;
}

const isNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const currentVal = (e.target as HTMLInputElement).value + e.key;
  if (!/^([\d+,]+\.?\d*)$/.test(currentVal)) {
    return e.preventDefault();
  }
};

const formatToMoney = (value: string) => {
  // catch a case where it could be NaN
  if (!value) {
    return value;
  }

  // catch a case where value already formatted
  if (value.includes(',')) return value;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
    .format(parseFloat(value))
    .replace(/\$/g, '')
    .trim();
};

const MoneyField: FC<IMoneyField> = (props) => {
  const { fieldState, fieldApi, render, ref } = useField(props);

  const { value } = fieldState;

  const { setValue, setTouched } = fieldApi;

  return render(
    <div className='mf-wrapper'>
      <input
        ref={ref}
        value={(value as string) || ''}
        type='text'
        className='mf-field'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
        onBlur={(e) => {
          setTouched(true);
          setValue(formatToMoney(e.target.value));
        }}
        onKeyPress={isNumber}
      />
    </div>
  );
};

export default MoneyField;
