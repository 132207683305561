import React, { Dispatch, FC, SetStateAction } from 'react';
import { BookingFormDetails, DataItem, ICountry } from '@digital-office/common/interfaces';
import {
  IYupSchema,
  STEPS,
  ProjectStepValues,
  MAX_TITLE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
} from '@pages/BookingFormPage/consts';
import { TextField, DateField, TextAreaField, Dropdown } from '@unbooking/ui-modules/lib/informed';
import { useFieldApi, Multistep } from 'informed';
import { ReactComponent as ProjectIcon } from '@assets/svg/project.svg';
import Counter from '../Counter';
import InformedField from '../InformedField';
import InfrastructureField from '../InfrastructureField';
import MapField from '../MapField';
import MoneyField from '../MoneyField';
import ServiceField from '../ServiceField';
import Subtitle from '../Subtitle';
import Navigation from '../Navigation';

interface ProjectInformationStepProps {
  formDetails: BookingFormDetails;
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
  countryData?: ICountry;
}

const ProjectInformationStep: FC<ProjectInformationStepProps> = ({ countryData, formDetails, setYupSchema }) => {
  const { setValue, getValue } = useFieldApi(STEPS.PROJECT);

  const stepValues = (getValue() || {}) as Partial<ProjectStepValues>;

  const { title = '', description = '', infrastructure_category } = stepValues;

  const infraOptions = ((formDetails.infrastructure[infrastructure_category?.value || 0] || []) as Array<DataItem>).map(
    ({ display_name, value }) => ({ label: display_name, value })
  );

  const resetInfraValue = () => {
    setValue({ ...stepValues, infrastructure: undefined });
  };

  return (
    <Multistep.Step step={STEPS.PROJECT}>
      <Subtitle text='Project information' icon={<ProjectIcon />} />

      <InformedField label='Infrastructure category *'>
        <InfrastructureField
          onChange={resetInfraValue}
          options={formDetails.infrastructure_category}
          name='infrastructure_category'
        />
      </InformedField>

      <InformedField label='Infrastructure *'>
        <Dropdown
          name='infrastructure'
          placeholder='Select...'
          options={infraOptions}
          isDisabled={!infrastructure_category}
        />
      </InformedField>

      <InformedField
        label='Project location(s) *'
        description='Search by address, coordinates or click on the map to add the location. You can add multiple locations.'
      >
        <MapField name='location' preferredCountry={countryData?.name} />
      </InformedField>

      <div className='bf-row'>
        <InformedField label='Desired start date *'>
          <DateField showMonthYearPicker dateFormat='MM/yyyy' minDate={new Date()} name='desired_start_date' />
        </InformedField>
        <InformedField label='Project estimated budget (USD) *'>
          <MoneyField name='estimated_budget' />
        </InformedField>
        <InformedField label='Fund expiration date (optional)'>
          <DateField dateFormat='dd/MM/yyyy' minDate={new Date()} name='fund_expiration_date' />
        </InformedField>
      </div>

      <InformedField
        label='Select the Engineering Services you will need for the project *'
        description='More than one can be selected'
      >
        <ServiceField options={formDetails.services} name='services' />
      </InformedField>

      <InformedField
        label='Project title *'
        description='What is the need about? Use some keywords to refer to the infrastructure needed (e.g. Construction of Humanitarian Resource Facilities)'
      >
        <TextField name='title' max={String(MAX_TITLE_LENGTH)} />
        <Counter length={title.length} max={MAX_TITLE_LENGTH} />
      </InformedField>

      <InformedField
        label='Project description *'
        description='Provide more context about the infrastructure/rehabilitation that is needed. You will be able to add attachments on the next page'
      >
        <TextAreaField name='description' />
        <Counter length={description.length} max={MAX_DESCRIPTION_LENGTH} />
      </InformedField>

      <Navigation setYupSchema={setYupSchema} />
    </Multistep.Step>
  );
};

export default ProjectInformationStep;
