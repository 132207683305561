import React, { useState } from 'react';
import { Dropdown, PageTitle } from '@ui-modules';
import { useQuery } from 'react-query';
import { IDemandOverviewResponse } from '@digital-office/common/interfaces';
import { ReactComponent as CoFilterIcon } from '@assets/svg/co_filter.svg';
import { Spin } from 'antd';
import {
  PhasesTable,
  DoughnutChart,
  SummaryTiles,
  InfrastructureChart,
  BarChart,
  CountryMap,
} from '@digital-office/common/components';
import { IDropdownOption } from '@ui-modules/types';
import { useRepository } from '@context/repository.context';
import { getChartProps } from '@digital-office/common/components/Dashboard/DoughnutChart';
import './styles.scss';
import { components, ControlProps } from 'react-select';
import TimeRangeFilter from '@digital-office/common/components/Dashboard/TimeRangeFilter';
import { getTimeFilterObject } from '@digital-office/common/helpers/timeFilterParser';
import { Progress } from 'antd';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

const infrastructureChartColors = ['#006B81', '#008D8D', '#00C6B4'];
const labels = ['Requests reviewed', 'Under assessment', 'Processed'];

const timeFilterOptions = [
  { label: 'This Year', value: 'current_year' },
  { label: 'Custom', value: 'custom' },
];

export interface TimeFilterValue {
  label: string;
  value: { date_from: string; date_to: string };
}

const DemandOverviewPage = () => {
  const { engineeringRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';
  const [timeFilter, setTimeFilter] = useState<IDropdownOption | TimeFilterValue | null>(timeFilterOptions[0]);
  const [coFilter, setCoFilter] = useState<IDropdownOption | null>(null);

  const { data: overviewData, isLoading } = useQuery<IDemandOverviewResponse>(
    ['projects-demand-overview', timeFilter?.value, coFilter?.value],
    () =>
      engineeringRepository.getDemandOverview(Number(coFilter?.value) || facilityId, {
        ...getTimeFilterObject(timeFilter),
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const summaryTilesData = [
    {
      label: 'Incoming requests',
      value: overviewData?.incoming_requests || 0,
    },
    // {
    //   label: 'Requests conversion rate',
    //   value: `${overviewData?.conversion_rate || 0}%`,
    // },
    {
      label: 'Avg budget',
      value: (
        <span>
          {overviewData?.average_budget
            ? Number(overviewData?.average_budget.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })
            : 0}
          <span className='currency'> USD</span>
        </span>
      ),
    },
  ];

  const infrastructureDoughnutChart = overviewData?.infrastructure_categories.map((category, key) => {
    return {
      name: category.name,
      value: parseInt(category.percentage),
      color: infrastructureChartColors[key],
    };
  });

  const infrastructureTreeChartData = overviewData?.infrastructures.map((infrastructure, key) => {
    return {
      name: infrastructure.category_name,
      color: infrastructureChartColors[key],
      children: infrastructure.infrastructures.map((infrastructureChild) => ({
        name: infrastructureChild.name,
        size: parseInt(infrastructureChild.percentage),
      })),
    };
  });

  const getBarChartData = () => {
    const chartData = {
      labels,
      datasets: [
        {
          data: [] as number[],
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: '#2F80ED',
          borderRadius: 3,
          barPercentage: 0.9,
          categoryPercentage: 0.9,
        },
      ],
    };

    if (!overviewData) return chartData;

    const { processed, request_reviewed, under_assessment } = overviewData.average_steps_conversion_rate;
    chartData.datasets[0].data = [processed, request_reviewed, under_assessment];

    return chartData;
  };

  const tableData = overviewData?.services.map((service) => ({
    title: service.name,
    value: parseInt(service.percentage),
  }));

  const clearFilters = () => {
    setCoFilter(null);
    setTimeFilter(timeFilterOptions[0]);
  };

  const getControl = (label: string, Icon: any) => {
    const Control = ({ children, ...props }: ControlProps) => (
      <components.Control className='control-wrapper' {...props}>
        <Icon className='control-icon' /> <span>{label}: </span>
        {children}
      </components.Control>
    );

    return Control;
  };

  return (
    <div className='dashboard-overview-wrapper'>
      <div className='demand-dashboard-page-title-wrapper'>
        <PageTitle className='title' title={`Demand overview - ${country}`} />
        <VideoLink link={videoLinks.DASHBOARD} />
      </div>
      <Spin spinning={isLoading}>
        <div className='booking-details-container hbh-container'>
          <div className='filter-dropdown-section'>
            <TimeRangeFilter
              timeFilterValue={timeFilter}
              setTimeFilter={setTimeFilter}
              timeFilterOptions={timeFilterOptions}
            />
          </div>
          <span className='reset' onClick={clearFilters}>
            Reset filters
          </span>
          <div className='projects-stats-section'>
            <DoughnutChart
              chartData={infrastructureDoughnutChart || []}
              chartProps={getChartProps(infrastructureDoughnutChart || [])}
              label='Infrastructure breakdown'
            />
            <div className='summary-section'>
              <div className='statuses-chart'>
                <p>Requests for Engineering support</p>
                {overviewData?.statuses.map((status) => (
                  <div className='status-element' key={status.name}>
                    <Progress
                      trailColor='#828282'
                      strokeWidth={9}
                      size={40}
                      type='circle'
                      percent={status.percentage}
                    />
                    <span>{status.percentage}%</span>
                    <span className='status-label'>{status.name}</span>
                  </div>
                ))}
              </div>
              <SummaryTiles data={summaryTilesData} />
            </div>
          </div>
          <div className='infrastructure-breakdown-section'>
            <InfrastructureChart data={infrastructureTreeChartData || []} />
          </div>
          <div className='widget-section'>
            <div className='widget-wrapper'>
              <div className='projects-chart'>
                <p>Average Step completion time (days)</p>
                <BarChart data={getBarChartData()} />
              </div>
            </div>
            <PhasesTable tableData={tableData || []} headers={['BREAKDOWN BY SERVICE', '%']} />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default DemandOverviewPage;
