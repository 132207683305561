import React from 'react';
import { ReactComponent as ArrowIcon } from '@assets/svg/icon_arrow_round.svg';
import { ReactComponent as WorldIcon } from '@assets/svg/world.svg';
import { Button, Dropdown } from '@ui-modules';
import { multiDropdownCustomStyles } from '@components/GlobalImpact/utils';
import { IProjectsOverview } from '@digital-office/common/interfaces';
import { IDropdownOption } from '@ui-modules/types';

interface IProps {
  projectsOverviewData: IProjectsOverview | null;
  categories: IDropdownOption[];
  onCategorySelectChange: (value: IDropdownOption[]) => void;
  countryDropdownHandler: (value: IDropdownOption) => void;
  onCountryDropdownClick: () => void;
  countriesListDropdown: IDropdownOption[];
  isItemSelected: boolean;
}

const Menu = ({
  projectsOverviewData,
  onCategorySelectChange,
  categories,
  countryDropdownHandler,
  onCountryDropdownClick,
  countriesListDropdown,
  isItemSelected,
}: IProps) => {
  const [isMenuHidden, setIsMenuHidden] = React.useState(false);

  // @ts-ignore
  return (
    <div className={`map-menu ${isMenuHidden && 'hidden'} ${isItemSelected && 'item-selected'}`}>
      <div className='toggle-menu' onClick={() => setIsMenuHidden(!isMenuHidden)}>
        <p className='menu-label'>{isMenuHidden ? 'SHOW' : 'HIDE'}</p>
        <ArrowIcon className={`toggle-icon ${isMenuHidden ? 'right' : 'left'}`} />
      </div>
      <div className='title-container'>
        <div className='menu-title-wrapper'>
          <WorldIcon className='title-icon' />
          <p className='menu-title'>Discover the Engineering projects</p>
        </div>
        <p className='menu-description'>
          Our engineering work bridges humanitarian and development activities to enable operations, build
          communities&apos; resilience, and change beneficiaries&apos; lives.
        </p>
      </div>
      <div className='block-info'>
        Please note we are working to improve and update this map to provide a comprehensive overview of our projects.
      </div>
      <div className='projects-stats'>
        <div className='stats-element countries'>
          <p className='stats-value'>{projectsOverviewData?.no_of_countries || 0}</p>
          <p className='stats-label'>Countries</p>
        </div>
        <div className='stats-element completed-project'>
          <p className='stats-value'>{projectsOverviewData?.no_of_stories_completed || 0}</p>
          <p className='stats-label'>Projects</p>
        </div>
        {/*<div className='stats-element in-progress'>*/}
        {/*  <p className='stats-value'>{projectsOverviewData?.no_of_stories_in_progress || 0}</p>*/}
        {/*  <p className='stats-label'>In progress</p>*/}
        {/*</div>*/}
      </div>
      <div className='country-selection'>
        <div className='control'>
          <Dropdown
            placeholder='Select country'
            containerClassName='dropdown'
            onChange={countryDropdownHandler}
            isSearchable={false}
            options={countriesListDropdown}
          />
          <Button onClick={onCountryDropdownClick} className='menu-button' text='Go to country' />
        </div>
        <hr className='line' />
        <div className='infrastructure-filter'>
          <Dropdown
            styles={multiDropdownCustomStyles}
            isMulti={true}
            onChange={onCategorySelectChange as any}
            placeholder='Filter by infrastructures delivered'
            className='basic-multi-select'
            classNamePrefix='select'
            options={categories}
          />
        </div>
      </div>
    </div>
  );
};

export default Menu;
