import { Tooltip } from '@digital-office/common/components/Dashboard/CountryMap';
import React from 'react';
import { IFFADashboardResponse } from '@digital-office/common/interfaces';

const colors = {
  LOW: '#006B81',
  MEDIUM: '#008D8D',
  HIGH: '#00C6B4',
  RELEVANT: '#00E6C5',
};

const customTooltip = (data: Tooltip, dashboardData: IFFADashboardResponse) => {
  const countryData = dashboardData && dashboardData.countries.find((country) => country.country_iso_code3 === data.id);

  if (!countryData || !data) return 'Unknown';

  return (
    <div>
      <p className='country-tooltip-content'>{data.country}</p>
      <p className='country-tooltip-content'>Use: {countryData.number_of_clicks}</p>
      <p className='country-tooltip-content'>Emails: {countryData.number_of_emails_sent}</p>
      <p className='country-tooltip-content'>Requests: {countryData.requests_for_support}</p>
    </div>
  );
};

const mapCountriesData = (data: IFFADashboardResponse['countries']) => {
  return data.map((country) => {
    return {
      country_iso_code3: country.country_iso_code3,
      number_of_requests: country.number_of_clicks,
    };
  });
};

const mapComplexityToChartData = (data: IFFADashboardResponse['activities_by_engineering_complexity']) => {
  return data.map((complexity) => ({
    name: complexity.name === 'RELEVANT' ? 'MEDIUM PLUS' : complexity.name,
    value: complexity.percentage,
    color: colors[(complexity.name as 'LOW') || 'RELEVANT' || 'HIGH' || 'MEDIUM'],
  }));
};

const mapActivitiesToTable = (data: IFFADashboardResponse['activities_by_category']): any => {
  return data.map((row) => ({
    title: row.name,
    value: row.percentage,
    ...(row?.activities && { expandedTable: mapActivitiesToTable(row.activities) }),
  }));
};

export { customTooltip, mapCountriesData, mapActivitiesToTable, mapComplexityToChartData };
