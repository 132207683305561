import React, { FC } from 'react';
import './styles.scss';
import { EstimatedCost } from '../../../ProjectProgressStep';
import { ProgressEstimatedCostAndConclusion } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface IAssessmentOverviewSummaryProps {
  values: ProgressEstimatedCostAndConclusion;
}

const AssessmentProgressSummary: FC<IAssessmentOverviewSummaryProps> = ({ values }) => {
  return (
    <div className='assessment-progress-summary-container'>
      <div className='summary-field'>
        <div className='section-label'>Potential recommended Scope of Works (SoW) (optional)</div>
        <div className='section-value'>{values.potential_recommended_sow}</div>
      </div>
      <div className='summary-field estimated-cost-table'>
        <div className='section-label'>Estimated cost (optional)</div>
        <div className='assessment-progress-step-container'>
          <EstimatedCost isEditMode={false} values={values} />
        </div>
      </div>
      <div className='summary-field'>
        <div className='section-label'>Conclusion (optional)</div>
        <div className='section-value'>{values?.conclusion || '-'}</div>
      </div>
    </div>
  );
};

export default AssessmentProgressSummary;
