import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import MapPreview from '@digital-office/pages/ProjectsManagementPages/components/MapPreview';
import { Button } from '@ui-modules';
import axios from 'axios';
import { find, findIndex } from 'lodash';
import { ReactComponent as DeleteIcon } from '@assets/svg/CloseIcon.svg';
import { getMapboxGeocodingPath } from '@digital-office/pages/ProjectsManagementPages/utils';
import { ReactComponent as PinIcon } from '@assets/svg/PinIcon.svg';
import LocationModal, { ILocationObject } from '@digital-office/pages/ProjectsManagementPages/components/LocationModal';
import { IDropdownOption } from '@ui-modules/types';
import { RemoveModal } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection/utils';

import './styles.scss';
import { EditOutlined } from '@ant-design/icons';

interface IProps {
  locations: ILocationObject[];
  setLocations: (data: ILocationObject[]) => void;
  infrastructures: IDropdownOption[];
  disabled?: boolean;
  multi?: boolean;
  modalTitle?: string;
  infrastructureSectionDescription?: string;
  showSharepointRemovingSection?: boolean;
  showDescriptionOnList?: boolean;
  prefillDescription?: boolean;
  infrastructureLabel?: string;
}

const getCoordsOfCountry = async (countryName: string) => {
  const { data } = await axios.get(getMapboxGeocodingPath(countryName));

  return find(data.features, { place_type: ['country'] });
};

const LocationSection: FC<IProps> = ({
  locations,
  setLocations,
  infrastructures,
  disabled,
  multi = true,
  modalTitle,
  infrastructureSectionDescription,
  showDescriptionOnList = true,
  prefillDescription = false,
  infrastructureLabel,
}) => {
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [initCoords, setInitCoords] = useState<number[] | null>(null);
  const facilityCountry = localStorage.getItem('country');
  const [locationToRemoval, setLocationToRemoval] = useState<ILocationObject | null>(null);
  const [locationToEdit, setLocationToEdit] = useState<ILocationObject | null>(null);

  useEffect(() => {
    if (locationToEdit) {
      setIsModalActive(true);
    } else {
      setIsModalActive(false);
    }
  }, [locationToEdit]);

  useEffect(() => {
    if (!locations?.length) {
      getCoordsOfCountry(facilityCountry || '').then((data) => setInitCoords(data ? data.center : [15, 11]));
    } else {
      locationToEdit
        ? setInitCoords([Number(locationToEdit.locationData.long), Number(locationToEdit.locationData.lat)])
        : setInitCoords([Number(locations[0].locationData.long), Number(locations[0].locationData.lat)]);
    }
  }, [locations, locationToEdit]);

  const onLocationChangeHandler = (data: ILocationObject) => {
    if (locationToEdit) {
      const editedIndex = findIndex(locations, { uuid: locationToEdit.uuid });

      if (editedIndex !== -1) {
        const updatedLocations = [...locations];
        updatedLocations[editedIndex] = data;
        setLocations(updatedLocations);
      }
    } else {
      setLocations([...locations, data]);
    }

    setIsModalActive(false);
  };

  return (
    <div className='location-picker-wrapper'>
      <Modal
        footer={null}
        title={
          <div className={'modal-title'}>
            <p>{modalTitle}</p>
          </div>
        }
        width={'70%'}
        destroyOnClose={true}
        open={isModalActive}
        onCancel={() => {
          setIsModalActive(false);
          setLocationToEdit(null);
        }}
      >
        <LocationModal
          infrastructures={infrastructures}
          initCoords={initCoords || []}
          locations={locations}
          infrastructureSectionDescription={infrastructureSectionDescription}
          prefillDescription={prefillDescription}
          onLocationChange={onLocationChangeHandler}
          infrastructureLabel={infrastructureLabel}
          locationToEdit={locationToEdit || undefined}
        />
      </Modal>
      <RemoveModal
        title='Remove location'
        description={<p>{`Are you sure you want to remove location ${locationToRemoval?.description}?`}</p>}
        isVisible={!!locationToRemoval}
        closeHandler={() => setLocationToRemoval(null)}
        removeHandler={() => {
          setLocations(locations.filter((location) => location !== locationToRemoval));
          setLocationToRemoval(null);
        }}
      />
      {initCoords && <MapPreview initCoords={initCoords} locations={locations} />}
      <div style={{ width: '50%' }}>
        <Button
          onClick={() => setIsModalActive(true)}
          className={`pick-location-button ${disabled ? 'hidden' : ''}`}
          disabled={!multi && locations.length >= 1}
          text='ADD PROJECT INFRASTRUCTURES & LOCATION'
          icon={<PinIcon />}
        />
        <div className='locations-list'>
          {locations?.map((location, key) => {
            return (
              <div key={key} className='location-item'>
                {!disabled ? <EditOutlined onClick={() => setLocationToEdit(location)} /> : null}
                <DeleteIcon
                  onClick={() => {
                    setLocationToRemoval(location);
                  }}
                  className={`delete-icon ${disabled ? 'hidden' : ''}`}
                />
                <p className='marker-icon list'>
                  <span className='icon-value'>{key + 1}</span>
                </p>
                <p className='description'>
                  {`${location.locationData.place_name}, ${location.locationData.lat}, ${location.locationData.long} ${
                    showDescriptionOnList ? `- ${location.description}` : ''
                  }`}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LocationSection;
