import React, { useState, FC } from 'react';
import './styles.scss';
import LocationPicker, {
  ILocationPickerData,
} from '@digital-office/pages/ProjectsManagementPages/components/LocationPicker';
import { Input, Tooltip } from 'antd';
import { Button, Dropdown } from '@ui-modules';
import { IDropdownOption } from '@ui-modules/types';

export interface ILocationObject {
  uuid?: string | undefined;
  locationData: ILocationPickerData;
  description: string;
  infrastructure: IDropdownOption;
}

interface IProps {
  onLocationChange: (data: ILocationObject) => void;
  initCoords?: number[];
  infrastructures: IDropdownOption[];
  locations: ILocationObject[];
  infrastructureSectionDescription?: string;
  prefillDescription?: boolean;
  infrastructureLabel?: string;
  locationToEdit?: ILocationObject;
}

const LocationModal: FC<IProps> = ({
  onLocationChange,
  initCoords,
  infrastructures,
  locations,
  infrastructureSectionDescription,
  prefillDescription = true,
  infrastructureLabel,
  locationToEdit,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<ILocationPickerData | null>(
    locationToEdit?.locationData || null
  );
  const [descriptionValue, setDescriptionValue] = useState<string>(locationToEdit?.description || '');
  const [infrastructureValue, setInfrastructureValue] = useState<IDropdownOption | null>(
    locationToEdit?.infrastructure || null
  );

  const onLocationChangeHandler = (data: ILocationPickerData) => {
    setSelectedLocation(data);
    prefillDescription && setDescriptionValue(`${data?.place || data?.region || data?.place_name || data?.country}`);
  };

  return (
    <div data-testid='location-picker-modal' className='location-picker-modal'>
      <div className='location-picker-map'>
        <p>
          Location:{' '}
          <span className='location-value'>
            {(selectedLocation && `${selectedLocation?.place_name}`) ?? 'Select...'}
          </span>
        </p>
        <LocationPicker
          zoomToLocation={Boolean(locations.length)}
          initCoords={initCoords}
          onLocationChange={onLocationChangeHandler}
          persistingLocation={locationToEdit?.locationData || undefined}
        />
        <span>Search on by address, coordinates on click on the map to add location</span>
      </div>
      <div className='infrastructure-picker'>
        {infrastructureSectionDescription && <p className='title'>{infrastructureSectionDescription}</p>}
        <p className='title'>Infrastructure at this location</p>
        <div data-testid='infrastructure-dropdown'>
          <label>What type of infrastructure is related to this location?</label>
          <Dropdown
            onChange={setInfrastructureValue}
            options={infrastructures}
            value={infrastructureValue}
            placeholder='Select infrastructure...'
          />
        </div>
        <div>
          <label>{infrastructureLabel || 'Add a short description of the Infrastructure at this location'}</label>
          <Input
            data-testid='description-input'
            value={descriptionValue}
            onChange={(e) => setDescriptionValue(e.target.value)}
            placeholder='Insert...'
          />
        </div>
      </div>
      <Tooltip
        className='button-tooltip'
        title={
          !selectedLocation || !descriptionValue || !infrastructureValue
            ? 'Insert all the data to confirm location and infrastructure'
            : ''
        }
      >
        <div className='buttons-wrapper'>
          <Button
            disabled={Boolean(!selectedLocation || !descriptionValue || !infrastructureValue)}
            className='confirm-button'
            onClick={() => {
              if (!selectedLocation || !descriptionValue || !infrastructureValue) return false;
              onLocationChange({
                uuid: locationToEdit?.uuid,
                locationData: selectedLocation,
                description: descriptionValue,
                infrastructure: infrastructureValue,
              });
            }}
            text='CONFIRM'
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default LocationModal;
