import React, { useEffect, useState } from 'react';
import './/styles.scss';
import { Spin } from 'antd';
import { Button, PageTitle, Pagination, Table, Tabs } from '@ui-modules';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PinIcon } from '@assets/svg/PinIcon.svg';
import { ReactComponent as AssessmentIcon } from '@assets/svg/assessment.svg';
import { useQuery } from 'react-query';
import { useRepository } from '@context/repository.context';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

const columns = [
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Creation date',
    sorter: true,
    render: (data: string) => {
      return format(new Date(data), DateFormatEnum.DD_MM_YYYY);
    },
  },
  {
    dataIndex: 'project_title',
    key: 'project_title',
    title: 'Related Project/Request',
    render: (data: string, set: any) => {
      return data || set?.service_request_title || '';
    },
  },
  {
    dataIndex: 'created_by',
    key: 'created_by',
    title: 'Created by',
  },
  {
    dataIndex: 'assessment_date',
    key: 'assessment_date',
    title: 'Assessment date',
    render: (data: string) => {
      return data && data !== 'N/A' ? format(new Date(data), DateFormatEnum.DD_MM_YYYY) : 'N/A';
    },
  },
  {
    dataIndex: 'button',
    key: 'button',
    title: '',
    render: () => {
      return <Button className='view-button' text='View' />;
    },
  },
];

const FieldAssessmentListPage = () => {
  const country = localStorage?.getItem('country') || '';
  const facilityId = +localStorage.getItem('facility')!;
  const tabs = ['Published', 'Draft', 'Pending Review'];
  const navigate = useNavigate();
  const { engineeringRepository } = useRepository();

  const [selectedTab, setSelectedTab] = useState<string>(
    sessionStorage.getItem('FIELD_ASSESSMENT_TAB_FILTER') || tabs[0]
  );
  const [columnOrder, setColumnOrder] = useState<string>('-created');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    sessionStorage.setItem('FIELD_ASSESSMENT_TAB_FILTER', selectedTab);
    setSelectedPage(1);
  }, [selectedTab]);

  const { data: list, isLoading } = useQuery(
    ['service-requests-list', selectedTab, selectedPage, pageSize, columnOrder],
    () => engineeringRepository.fieldAssessmentsListPage(facilityId, buildParams()),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getTabParam = () => {
    switch (selectedTab) {
      case 'Published':
        return 'published';
      case 'Draft':
        return 'draft';
      case 'Pending Review':
        return 'pending_review';
    }
  };

  const buildParams = () => {
    return {
      status: getTabParam(),
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder ? columnOrder : null,
    };
  };

  return (
    <Spin spinning={isLoading}>
      <section className='hbh-container booking-list field-assessment-list-container'>
        <div className='field-assessment-video-link-wrapper'>
          <VideoLink link={videoLinks.FIELD_ASSESSMENT_REPORT} />
        </div>
        <PageTitle
          filters={
            <div className='buttons-container'>
              <Button
                icon={<AssessmentIcon />}
                variant='submit'
                onClick={() => navigate('form/new')}
                text='Generate new report'
                className='generate-button'
              />
            </div>
          }
          title={`Engineering Field Assessment and Mission reports - ${country}`}
          bottomLine={true}
        />
        <Tabs
          className='engineering-tabs'
          tabs={tabs}
          selectedTab={tabs.indexOf(selectedTab)}
          onTabSelect={setSelectedTab}
        />
        <div className='table'>
          <Table
            columns={columns}
            data={list?.results || []}
            onRow={(record) => {
              return {
                onClick: () => navigate(`form/${record.uuid}`),
              };
            }}
            variant='dark'
            onChangeColumnOrder={setColumnOrder}
          />
        </div>
        <Pagination
          className='pagination'
          selectedPage={selectedPage}
          showJumper={true}
          showPageSize={true}
          totalPages={list?.count}
          variant='dark'
          onPageChange={setSelectedPage}
          onPageSizeChange={setPageSize}
        />
      </section>
    </Spin>
  );
};

export default FieldAssessmentListPage;
