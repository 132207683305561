import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { Form, FormApi } from 'informed';
import { useRepository } from '@context/repository.context';
import { message, Spin, Tooltip } from 'antd';
import { find } from 'lodash';
import { Button, DetailsContainer, Dropdown, PageTitle } from '@ui-modules';
import {
  IDetailsItem,
  IFFADetailsConfigItem,
  IAssessmentActivityDetails,
  IFFAUpdatePayload,
} from '@digital-office/common/interfaces';
import detailsConfig from '@digital-office/pages/FFADetailsPage/detailsConfig';
import {
  focalPointHandler,
  getInitialValues,
  parseModifiedFields,
  validationSchema,
} from '@digital-office/pages/FFADetailsPage/utils';
import { IDropdownOption } from '@ui-modules/types';
import {
  focalPointDropdownCustomStyles,
  FocalPointModal,
  getDropdownStatusComponentProps,
  statusDropdownCustomStyles,
} from '@digital-office/pages/RequestsDetailsPage/utils';

import './styles.scss';

const FFADetailsPage: FC = () => {
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<Record<any, any> | null>(null);
  const [, updateState] = useState<any>(null);
  const [selectedFocalPoint, setSelectedFocalPoint] = React.useState<IDropdownOption | null>(null);
  const formRef = useRef<FormApi>(null);

  const { engineeringRepository } = useRepository();
  const { id: uuid } = useParams<{ id: string }>();

  const { data: assessmentRequestDetails, isLoading } = useQuery<IAssessmentActivityDetails>(
    `assessment-${uuid}-${uuid}`,
    () => engineeringRepository.getAssessmentsDetails(facilityId, uuid || '', uuid || ''),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { data: focalPointsOptions } = useQuery<IDropdownOption[]>(
    `focal-points-options`,
    () => engineeringRepository.getFocalPointsOptions(facilityId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const updateAssessment = useMutation(
    (data: IFFAUpdatePayload) => engineeringRepository.updateAssessment(facilityId, uuid || '', data),
    {
      onSuccess: () => {
        window.location.reload();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const mapDetailItems = (source: IAssessmentActivityDetails, items: IFFADetailsConfigItem[]): IDetailsItem[] => {
    return items.map((item) => {
      const content = item.content
        ? item.content(source[item.key], isEditMode, source, formRef.current as FormApi)
        : source[item.key];
      return {
        label: item.label,
        content: (
          <div data-testid={item.key} className={!isEditMode || !item.editable ? 'text-ellipsis' : ''}>
            {(!isEditMode || !item.editable) && !item.tooltip_disabled ? (
              <Tooltip
                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                placement='bottomLeft'
                title={content}
              >
                {content}
              </Tooltip>
            ) : (
              content
            )}
          </div>
        ),
        className: item.className ?? '',
      };
    });
  };

  const renderDetailElements = (source: IAssessmentActivityDetails): JSX.Element[] | null => {
    if (!source || !formRef) return null;

    return detailsConfig().map((section) => {
      return (
        <DetailsContainer
          key={section.section_title}
          title={section.section_title}
          items={mapDetailItems(source, section.items)}
        />
      );
    });
  };

  useEffect(() => {
    if (assessmentRequestDetails && !initialValues) {
      setInitialValues(getInitialValues(assessmentRequestDetails));
    }
  }, [assessmentRequestDetails]);

  const FocalPointDropdown = () => {
    return (
      <div className='focal-point-dropdown'>
        <Dropdown
          styles={focalPointDropdownCustomStyles}
          isSearchable={false}
          label='Engineering Focal Point'
          isDisabled={!assessmentRequestDetails}
          value={
            selectedFocalPoint ||
            find(focalPointsOptions, (item) => assessmentRequestDetails?.project_information.focal_point === item.value)
          }
          onChange={(option) => {
            if (option.value !== assessmentRequestDetails?.project_information.focal_point) {
              setSelectedFocalPoint(option);
            }
          }}
          options={focalPointsOptions || []}
        />
      </div>
    );
  };

  return (
    <>
      <PageTitle
        title={`FFA Engineering support request - #${
          assessmentRequestDetails?.project_information.reference_code || ''
        } - ${country}`}
      />
      <FocalPointDropdown />
      {selectedFocalPoint && (
        <FocalPointModal
          isActive={true}
          onCancel={() => setSelectedFocalPoint(null)}
          isLoading={updateAssessment.status === 'loading'}
          data={selectedFocalPoint}
          onSubmitHandler={() =>
            assessmentRequestDetails &&
            focalPointHandler(assessmentRequestDetails, selectedFocalPoint, updateAssessment)
          }
        />
      )}
      <Spin spinning={isLoading}>
        <div className='booking-details-container hbh-container ffa-details'>
          {assessmentRequestDetails && initialValues && (
            <>
              <div className='dropdown-title'>
                <h3>Project information</h3>
                <Dropdown
                  styles={statusDropdownCustomStyles}
                  components={getDropdownStatusComponentProps(
                    !assessmentRequestDetails.project_information?.allowed_transitions.length,
                    assessmentRequestDetails?.project_information.status.value
                  )}
                  isSearchable={false}
                  onChange={(option) => {
                    updateAssessment.mutate({ status: option.value });
                  }}
                  // @ts-ignore
                  isOptionDisabled={(option: IDropdownOption) =>
                    Boolean(!assessmentRequestDetails.project_information?.focal_point && option.value !== 'cancelled')
                  }
                  options={assessmentRequestDetails.project_information?.allowed_transitions as IDropdownOption[]}
                  value={assessmentRequestDetails?.project_information.status}
                />
              </div>
              <Form
                initialValues={initialValues}
                onClick={() => updateState({})}
                onSubmit={({ modified }) => {
                  updateAssessment.mutate(parseModifiedFields(modified));
                }}
                yupSchema={validationSchema}
                formApiRef={formRef}
              >
                {renderDetailElements(assessmentRequestDetails)}
              </Form>
              <div className='buttons-wrapper'>
                <Button
                  onClick={() => setIsEditMode(!isEditMode)}
                  className='button'
                  disabled={updateAssessment.status === 'loading'}
                  text={!isEditMode ? 'Edit' : 'Cancel'}
                  variant='primary'
                />
                {isEditMode && (
                  <Button
                    onClick={() => formRef?.current && formRef.current.submitForm()}
                    disabled={updateAssessment.status === 'loading'}
                    className='button apply'
                    text='Apply'
                    variant='submit'
                  />
                )}
              </div>
            </>
          )}
        </div>
      </Spin>
    </>
  );
};

export default FFADetailsPage;
