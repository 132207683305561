import React from 'react';
import { EditableFields, IRequestDetailsConfig } from '@digital-office/common/interfaces';
import { IDropdownOption } from '@ui-modules/types';
import {
  getDateComponent,
  getDropdownComponent,
  getInputComponent,
  retrieveDropdownInitialValue,
  ownedOptions,
} from '@digital-office/pages/RequestsDetailsPage/utils';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

const detailsConfig = (
  locationTypes: IDropdownOption[],
  servicesOptions: IDropdownOption[],
  editedFields: EditableFields
): IRequestDetailsConfig[] => [
  {
    section_title: '',
    items: [
      {
        key: 'created',
        label: 'CREATED AT',
        content: (record) => format(new Date(record), DateFormatEnum.DD_MM_YYYY),
      },
      {
        key: 'nature_of_request',
        label: 'NATURE OF REQUEST',
      },
      {
        key: 'infrastructure_category_name',
        label: 'INFRASTRUCTURE CATEGORY',
      },
      {
        key: 'infrastructure_name',
        label: 'INFRASTRUCTURE',
      },
      {
        key: 'country_name',
        label: 'PROJECT COUNTRY',
      },
      {
        key: 'location',
        label: 'PROJECT LOCATION',
        content: function (record, isEditMode) {
          if (!record) return 'Multiply';
          return getInputComponent(record, isEditMode, this.key);
        },
      },
      // {
      //   key: 'location_type',
      //   label: 'LOCATION TYPE',
      //   content: function (record, isEditMode) {
      //     return getDropdownComponent(
      //       retrieveDropdownInitialValue(record, 'value', locationTypes)?.label || '',
      //       isEditMode,
      //       this.key,
      //       locationTypes,
      //       false,
      //       undefined,
      //       editedFields
      //     );
      //   },
      // },
      // {
      //   key: 'distance',
      //   label: 'DISTANCE FROM CLOSEST TOWN',
      //   content: function (record, isEditMode) {
      //     const locationTypeLabel =
      //       (editedFields['location_type'] &&
      //         retrieveDropdownInitialValue(editedFields['location_type'], 'value', locationTypes)?.distance_required) ||
      //       '';
      //     if (locationTypeLabel) return getInputComponent(record, isEditMode, this.key);
      //     else return record || '-';
      //   },
      // },
      {
        key: 'desired_start_date',
        label: 'DESIRED START DATE',
        content: function (record, isEditMode) {
          if (!isEditMode) return format(new Date(record), 'MM/yyyy');
          return getDateComponent(record, isEditMode, this.key, 'MM/yyyy');
        },
      },
      {
        key: 'fund_expiration_date',
        label: 'FUND EXPIRATION DATE',
        content: function (record, isEditMode) {
          if (!isEditMode) return record ? format(new Date(record), 'MM/yyyy') : '-';
          return getDateComponent(record, isEditMode, this.key);
        },
      },
      {
        key: 'title',
        label: 'PROJECT TITLE',
        tooltip_full_width: true,
        content: function (record, isEditMode) {
          return getInputComponent(record, isEditMode, this.key);
        },
      },
      {
        key: 'services',
        label: 'SERVICES NEEDED',
        tooltip_full_width: true,
        className: 'grid-full-column',
        content: function (record, isEditMode) {
          const parsedRecord = record
            ?.map((option: string) => retrieveDropdownInitialValue(option, 'value', servicesOptions)?.label || '')
            .join(', ');

          return getDropdownComponent(parsedRecord, isEditMode, this.key, servicesOptions, true, record, editedFields);
        },
      },
      {
        key: 'description',
        label: 'PROJECT DESCRIPTION',
        className: 'grid-full-column',
        editable: true,
        field_type: 'input',
        tooltip_full_width: true,
        content: function (record, isEditMode) {
          return getInputComponent(record, isEditMode, this.key);
        },
      },
    ],
  },
  {
    section_title: 'Requestor Details',
    items: [
      {
        key: 'first_name',
        label: 'Name',
      },
      {
        key: 'last_name',
        label: 'SURNAME',
      },
      {
        key: 'email',
        label: 'EMAIL',
      },
      {
        key: 'phone_number',
        label: 'PHONE NUMBER',
        content: function (record) {
          return record || '-';
        },
      },
      {
        key: 'organisation',
        label: 'ORGANISATION',
      },
      {
        key: 'requesting_unit',
        label: 'REQUESTING UNIT',
      },
    ],
  },
  {
    section_title: 'Additional Information',
    items: [
      {
        key: 'new_construction',
        label: 'NEW CONSTRUCTION',
        content: (record) => (record ? 'Yes' : 'No'),
      },
      {
        key: 'rehabilitation_existing_building',
        label: 'REHABILITATION EXISTING BUILDINGS',
        content: (record) => (record ? 'Yes' : 'No'),
      },
      {
        key: 'owned',
        label: 'IS THE LAND/BUILDING OWNED OR RENTED?',
        content: function (record, isEditMode) {
          const ownedValue = record === null ? '-' : record ? 'Owned' : 'Rented';

          return getDropdownComponent(ownedValue, isEditMode, this.key, ownedOptions, false, undefined, editedFields);
        },
      },
      {
        key: 'rental_expiration_date',
        label: 'LEASE EXPIRATION',
        content: function (record, isEditMode) {
          if (!isEditMode || editedFields?.owned !== 'rented')
            return record ? format(new Date(record), DateFormatEnum.DD_MM_YYYY) : '-';
          return getDateComponent(record, isEditMode, this.key);
        },
      },
      {
        key: 'planned_usage_years',
        label: 'NO. OF PLANNED YEARS OF FACILITY USAGE',
        content: function (record, isEditMode) {
          if (!isEditMode) return record || '-';
          return getInputComponent(record, isEditMode, this.key);
        },
      },
    ],
  },
];

export default detailsConfig;
