import React, { useMemo, useState } from 'react';
import { Button, PageTitle, Pagination, Table, Tabs } from '@ui-modules';
import { Input, Spin, Tooltip } from 'antd';
import { Dropdown as FiltersDropdown } from '@ui-modules/components';
import { IDropdownOption } from '@ui-modules/types';
import ProgressBar from '@digital-office/common/components/ProgressBar';
import { ReactComponent as NewProjectIcon } from '@assets/svg/new_project.svg';
import { useQuery } from 'react-query';
import { IProjectListItem, IProjectListResponse } from '@digital-office/common/interfaces';
import { useRepository } from '@context/repository.context';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

const { Search } = Input;

const columns = [
  {
    dataIndex: 'code',
    key: 'code',
    title: 'Project ID',
  },
  {
    dataIndex: 'organisation',
    key: 'organisation',
    title: 'Organisation',
  },
  {
    dataIndex: 'country',
    key: 'country',
    title: 'Country',
  },
  {
    dataIndex: 'engineering_project_manager',
    key: 'engineering_project_manager',
    title: 'Engineering Project Manager',
  },
  {
    dataIndex: 'overall_completion',
    key: 'overall_completion',
    title: 'Overall completion',
    width: 150,
    render: (record: number) => <ProgressBar value={record} />,
  },
  {
    dataIndex: 'actual_phase_number',
    key: 'actual_phase_number',
    title: 'Actual phase',
    width: 300,
    render: (record: number, allRecords: IProjectListItem) => {
      if (record < 0) return <p className='phase-cell phase-0'>TBD</p>;

      const title = `Phase ${record}: ${allRecords?.actual_phase_description}`;
      return (
        <Tooltip title={title}>
          <p className={`phase-cell phase-${record}`}>{title}</p>
        </Tooltip>
      );
    },
  },
];

const tabs = ['In progress', 'Completed'];

const ProjectsListPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [organisationFilter, setOrganisationFilter] = useState<IDropdownOption | null>();
  const [countryFilter, setCountryFilter] = useState<IDropdownOption | null>();
  const [advisorFilter, setAdvisorFilter] = useState<IDropdownOption | null>();
  const [projectManagerFilter, setProjectManagerFilter] = useState<IDropdownOption | null>();
  const [projectFilter, setProjectFilter] = useState<string | null>();
  const [projectFilterInput, setProjectFilterInput] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';
  const { engineeringRepository } = useRepository();
  const navigate = useNavigate();

  const { data: projectsList, isLoading } = useQuery<IProjectListResponse>(
    `projects-list-${selectedTab}-${selectedPage}-${organisationFilter?.value}
    -${countryFilter?.value}-${advisorFilter?.value}-${projectManagerFilter?.value}-${projectFilter}-${pageSize}`,
    () =>
      engineeringRepository.getProjects(facilityId, {
        code: projectFilter,
        country: countryFilter?.value,
        engineering_project_manager: projectManagerFilter?.value,
        offset: (selectedPage - 1) * 10,
        organisation: organisationFilter?.value,
        show: selectedTab === 'In progress' ? 'in_progress' : 'completed',
        limit: pageSize,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const resetFilters = useMemo(
    () => () => {
      setOrganisationFilter(null);
      setCountryFilter(null);
      setAdvisorFilter(null);
      setProjectManagerFilter(null);
    },
    []
  );

  const onRowClickHandler = (record: string) => {
    return {
      onClick: () => navigate(`${record}`),
    };
  };

  return (
    <Spin spinning={isLoading}>
      <section className='hbh-container booking-list projects-list'>
        <div className='project-list-video-link-wrapper'>
          <VideoLink link={videoLinks.PROJECT_LIST} />
        </div>
        <PageTitle
          filters={
            <Button
              onClick={() => navigate('create/new')}
              className='new-project-button'
              icon={<NewProjectIcon />}
              text='Add new project'
              variant='submit'
            />
          }
          title={`Projects Management - ${country}`}
          bottomLine={true}
        />
        <Tabs
          className='engineering-tabs'
          tabs={tabs}
          selectedTab={tabs.indexOf(selectedTab)}
          onTabSelect={setSelectedTab}
        />
        <section className='filters'>
          <div className='project-input-wrapper'>
            <label className='project-input-label'>Search Project</label>
            <Search
              className='project-input'
              placeholder='Insert ID...'
              value={projectFilterInput}
              onChange={(e) => setProjectFilterInput(e.target.value)}
              allowClear
              enterButton
              onSearch={() => setProjectFilter(projectFilterInput)}
            />
            <span
              className='reset'
              onClick={() => {
                setProjectFilter(null);
                setProjectFilterInput('');
              }}
            >
              Reset filter
            </span>
          </div>
          <div className='project-dropdown-filters'>
            <FiltersDropdown
              className='filter select outline'
              isClearable={true}
              label={<label className='hbh-select-label'>Filter by Organisation</label>}
              value={organisationFilter}
              placeholder='All organisations'
              options={projectsList?.options.organisations || []}
              onChange={setOrganisationFilter}
            />
            <FiltersDropdown
              className='filter select outline'
              label={<label className='hbh-select-label'>Filter by Country</label>}
              value={countryFilter}
              isClearable={true}
              placeholder='All countries'
              options={projectsList?.options.countries || []}
              onChange={setCountryFilter}
            />
            <FiltersDropdown
              className='filter select outline'
              label={<label className='hbh-select-label'>Filter by Project Manager</label>}
              value={projectManagerFilter}
              placeholder='All PM'
              options={projectsList?.options.engineering_project_managers || []}
              onChange={setProjectManagerFilter}
            />
            <span className='reset' onClick={resetFilters}>
              Reset filters
            </span>
          </div>
        </section>
        <div className='table'>
          <Table
            columns={columns}
            data={projectsList?.results ?? []}
            variant='dark'
            scroll={{ x: true }}
            isLoading={false}
            onRow={(record) => onRowClickHandler(record.uuid)}
          />
        </div>
        <Pagination
          className='pagination'
          selectedPage={selectedPage}
          showJumper={true}
          showPageSize={true}
          totalPages={projectsList?.count || 0}
          variant='dark'
          onPageChange={setSelectedPage}
          onPageSizeChange={setPageSize}
        />
      </section>
    </Spin>
  );
};

export default ProjectsListPage;
