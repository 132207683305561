import React, { FC, useState } from 'react';
import './styles.scss';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Form } from 'informed';
import { DateField, TextField } from '@ui-modules/informed';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import { Button } from '@ui-modules';
import { ICostDetailItem } from '@digital-office/common/interfaces';
import { format, parse, parseISO } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import { useMutation } from 'react-query';
import { message } from 'antd';
import { useRepository } from '@context/repository.context';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const items = [
  {
    key: 'USD',
    label: 'USD',
  },
  {
    key: 'PLN',
    label: 'PLN',
  },
];

interface IDetailOverviewProps {
  selectedDetail: ICostDetailItem;
  setSelectedDetail: React.Dispatch<React.SetStateAction<ICostDetailItem | undefined>>;
}

const validationSchema = Yup.object().shape({
  vendor: Yup.string().required('Required'),
  contract_lump_sum: Yup.string().required('Required'),
  po_number: Yup.string().required('Required'),
  contract_completion_date: Yup.date().required('Required'),
  approved_contingency: Yup.string().required('Required'),
});

const DetailOverview: FC<IDetailOverviewProps> = ({ selectedDetail, setSelectedDetail }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>(items[0].key);
  const { engineeringRepository } = useRepository();
  const [, updateState] = useState<any>(null);
  const facilityId = +localStorage.getItem('facility')!;
  const { id: paramId } = useParams<{ id: string }>();

  const updateCostOverviewMutation = useMutation(
    (data: Partial<ICostDetailItem>) =>
      engineeringRepository.updateProjectCostDetail(facilityId, paramId || '', selectedDetail.uuid, data),
    {
      onSuccess: (data: ICostDetailItem) => {
        setIsEditMode(false);
        message.success('Overview has been updated');
        setSelectedDetail(data);
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const updateCostDetail = (values: Record<any, any>) => {
    const payload = {
      ...values,
      contract_completion_date: format(values.contract_completion_date as Date, DateFormatEnum.YYYY_MM_DD),
    };

    updateCostOverviewMutation.mutate(payload);
  };

  return (
    <p className='detail-overview-wrapper'>
      <div className='header-section'>
        <p className='title'>Overview</p>
        <div onClick={() => setIsEditMode(!isEditMode)} className='edit-section'>
          {!isEditMode ? (
            <>
              <span>Edit</span>
              <EditOutlined />
            </>
          ) : (
            <>
              <span>Cancel</span>
              <CloseOutlined />
            </>
          )}
        </div>
      </div>
      <Form
        key={JSON.stringify(selectedDetail)}
        onSubmit={({ values }) => updateCostDetail(values)}
        onClick={() => updateState({})}
        yupSchema={validationSchema}
        initialValues={{
          vendor: selectedDetail.vendor,
          contract_lump_sum: selectedDetail.contract_lump_sum,
          po_number: selectedDetail.po_number,
          approved_contingency: selectedDetail.approved_contingency,
          contract_completion_date: selectedDetail?.contract_completion_date
            ? parse(selectedDetail?.contract_completion_date || '', DateFormatEnum.YYYY_MM_DD, new Date())
            : null,
        }}
      >
        <div className='overview-section'>
          <div className={`overview-element ${isEditMode ? 'edit-mode' : ''}`}>
            <p className='overview-label'>Vendor</p>
            {isEditMode ? <TextField name='vendor' /> : <p className='value'>{selectedDetail.vendor}</p>}
          </div>
          <div className={`overview-element ${isEditMode ? 'edit-mode' : ''}`}>
            <p className='overview-label'>PO Number</p>
            {isEditMode ? <TextField name='po_number' /> : <p className='value'>{selectedDetail.po_number}</p>}
          </div>
          <div className={`overview-element ${isEditMode ? 'edit-mode' : ''}`}>
            <div className='overview-label currency'>Contract lump sum</div>
            {isEditMode ? (
              <NumericInput suffix={` ${selectedCurrency}`} name='contract_lump_sum' />
            ) : (
              <p className='value'>
                {Number(selectedDetail.contract_lump_sum).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })}{' '}
                USD
              </p>
            )}
          </div>
          <div className={`overview-element ${isEditMode ? 'edit-mode' : ''}`}>
            <div className='overview-label currency'>Approved contingency</div>
            {isEditMode ? (
              <NumericInput suffix={` ${selectedCurrency}`} name='approved_contingency' />
            ) : (
              <p className='value'>
                {Number(selectedDetail.approved_contingency).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })}{' '}
                USD
              </p>
            )}
          </div>
          <div className={`overview-element ${isEditMode ? 'edit-mode' : ''}`}>
            <p className='overview-label'>Completion date estimate</p>
            {isEditMode ? (
              <DateField name='contract_completion_date' />
            ) : (
              <p className='value'>
                {selectedDetail?.contract_completion_date
                  ? format(parseISO(selectedDetail?.contract_completion_date || ''), DateFormatEnum.DD_MM_YYYY)
                  : null}
              </p>
            )}
          </div>
        </div>
        {isEditMode && <Button type='submit' className='submit-button' variant='primary' text='Save' />}
      </Form>
    </p>
  );
};

export default DetailOverview;
