import React, { FC } from 'react';
import './styles.scss';

interface SubtitleProps {
  icon: JSX.Element;
  text: string;
}

const Subtitle: FC<SubtitleProps> = ({ text, icon }: SubtitleProps) => {
  return (
    <div className='bf-subtitle'>
      <div className='bf-subtitle-icon'>{icon}</div>
      {text}
    </div>
  );
};

export default Subtitle;
