import React, { Dispatch, FC, SetStateAction } from 'react';
import { ICountriesResponse, IUserDetails } from '@digital-office/common/interfaces';
import { IYupSchema, STEPS } from '@pages/BookingFormPage/consts';
import { Dropdown, TextField } from '@unbooking/ui-modules/lib/informed';
import { Multistep } from 'informed';
import PhoneField from '../PhoneField';
import { ReactComponent as UserIcon } from '@assets/svg/user.svg';
import InformedField from '../InformedField';
import Subtitle from '../Subtitle';
import Navigation from '../Navigation';
import { useParams } from 'react-router-dom';

interface DetailsStepProps {
  userDetails: IUserDetails;
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
  countries: ICountriesResponse;
}

const DetailsStep: FC<DetailsStepProps> = ({ userDetails, setYupSchema, countries }) => {
  const { country = '' } = useParams<{ country: string }>();

  const { email, first_name, last_name, organisation } = userDetails;

  const userInfo = `${first_name} ${last_name}${organisation ? `, ${organisation}` : ''}`;

  const countryOptions = countries.results.map(({ iso_num, full_name, iso_code }) => ({
    label: full_name,
    value: iso_num,
    iso_code,
  }));

  const defaultCountry = countryOptions.find(({ iso_code }) => country.toUpperCase() === iso_code);

  return (
    <Multistep.Step step={STEPS.DETAILS}>
      <Subtitle text='Your details' icon={<UserIcon />} />

      <div className='bf-user-info'>{userInfo}</div>
      <div className='bf-user-email'>{email}</div>

      <InformedField label='Project country *'>
        <Dropdown defaultValue={defaultCountry} name='country' placeholder='Select...' options={countryOptions} />
      </InformedField>

      <InformedField
        label='Phone number *'
        description="Your phone number is safe with us. We'll only use it for service updates and won't share it with anyone else."
      >
        <PhoneField name='phone_number' dark preferredCountries={country ? [country] : undefined} />
      </InformedField>

      <InformedField label='Requesting unit *'>
        <TextField name='requesting_unit' className='dark-input' placeholder='Supply Chain, Administration...' />
      </InformedField>

      <Navigation setYupSchema={setYupSchema} />
    </Multistep.Step>
  );
};

export default DetailsStep;
