import React, { useEffect, useRef, useState } from 'react';
import { Button, Pagination, Table } from '@ui-modules';
import GuidanceModal from '@components/CommunityGuidancePage/components/GuidanceModal';
import { Form, FormApi, FormState, FormStateAccessor, TextArea } from 'informed';
import { DateField, Dropdown as FormDropdown } from '@ui-modules/informed';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import CurrencyPicker from '@digital-office/pages/ProjectsManagementPages/components/CurrencyPicker';

import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  IProjectFundingSourceListItem,
  IProjectFundingSourcePayload,
  IProjectFundingSourcesList,
} from '@digital-office/common/interfaces';
import * as Yup from 'yup';
import { message, Tooltip } from 'antd';
import { format, parse, parseISO } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import { IDropdownOption } from '@ui-modules/types';
import { DeleteOutlined, EditOutlined, InfoCircleFilled } from '@ant-design/icons';
import { enGB } from 'date-fns/locale';

const validationSchema = Yup.object().shape({
  budget_source: Yup.object().required('Required'),
  amount_value: Yup.string().required('Required'),
  tod: Yup.date().required('Required'),
  tdd: Yup.date().required('Required'),
});

import './styles.scss';

const items = [
  {
    key: 'USD',
    label: 'USD',
  },
  {
    key: 'PLN',
    label: 'PLN',
  },
];

const getColumns = (
  onEditHandler: (uuid: string) => void,
  onRemarksShowHandler: (uuid: string) => void,
  removeHandler: (uuid: string) => void
) => [
  {
    dataIndex: 'order_number',
    key: 'order_number',
    title: '#',
  },
  {
    dataIndex: 'budget_source',
    key: 'budget_source',
    title: 'SOURCE',
    render: (record: IDropdownOption) => {
      return record?.label || '';
    },
  },
  {
    dataIndex: 'amount_value',
    key: 'amount_value',
    title: 'VALUE(USD)',
    render: (record: string) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'tod',
    key: 'tod',
    title: 'TOD',
    render: (data: string) => {
      return data ? format(new Date(data), DateFormatEnum.DD_MM_YYYY) : '';
    },
  },
  {
    dataIndex: 'tdd',
    key: 'tdD',
    title: 'TDD',
    render: (data: string) => {
      return data ? format(new Date(data), DateFormatEnum.DD_MM_YYYY) : '';
    },
  },
  {
    dataIndex: '',
    key: '',
    title: 'ACTIONS',
    render: (record: string, item: IProjectFundingSourceListItem) => {
      if (item.uuid) {
        return (
          <div className='actions-section'>
            <Tooltip align={{ offset: [0, 0] }} title='Click to remove source'>
              <DeleteOutlined onClick={() => removeHandler(item.uuid)} />
            </Tooltip>
            <Tooltip align={{ offset: [0, 0] }} title='Click to edit source'>
              <EditOutlined onClick={() => onEditHandler(item.uuid)} />
            </Tooltip>
            {item.remarks ? (
              <Tooltip align={{ offset: [0, 0] }} title='Click to show remarks'>
                <InfoCircleFilled onClick={() => onRemarksShowHandler(item.uuid)} />
              </Tooltip>
            ) : null}
          </div>
        );
      } else {
        return undefined;
      }
    },
  },
];

const FundingSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>(items[0].key);
  const [uuidToRemove, setUuidToRemove] = useState<string | undefined>();
  const [itemToEdit, setItemToEdit] = useState<string | undefined>();
  const [selectedRemark, setSelectedRemark] = useState<string | undefined>();
  const [, updateState] = useState<any>(null);
  const facilityId = +localStorage.getItem('facility')!;
  const { id: uuid } = useParams<{ id: string }>();
  const formRef = useRef<FormApi>(null);

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);

  useEffect(() => {
    if (uuidToRemove) {
      const confirmMessage = confirm('Do you want to delete this element?');
      if (confirmMessage) {
        removeFundingSource.mutate(uuidToRemove);
      } else {
        setItemToEdit(undefined);
      }
    }
  }, [uuidToRemove]);

  useEffect(() => {
    if (itemToEdit) {
      setIsModalOpen(true);
    }
  }, [itemToEdit]);

  const { engineeringRepository } = useRepository();

  const {
    data: fundingData,
    isLoading,
    refetch,
  } = useQuery<IProjectFundingSourcesList>(
    `funding-list-${pageSize}-${selectedPage}`,
    () =>
      engineeringRepository.getProjectFundingSources(facilityId, uuid || '', {
        limit: 5,
        offset: (selectedPage - 1) * 5,
      }),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const getFundingSourceData = () => {
    if (!fundingData?.results) {
      return [];
    }

    const fundingSourceData = fundingData?.results.reduce(
      (accumulator, item, index) => {
        return {
          values: [...accumulator.values, { ...item, order_number: (selectedPage - 1) * pageSize + index + 1 }],
          totalAmount: accumulator.totalAmount + item.amount_value,
        };
      },
      { values: [] as IProjectFundingSourceListItem[], totalAmount: 0 }
    );

    return [...(fundingSourceData?.values || []), { amount_value: fundingSourceData?.totalAmount }];
  };

  const sendFundingSourcesMutation = useMutation(
    (data: IProjectFundingSourcePayload) =>
      engineeringRepository.sendProjectFundingSource(facilityId, uuid || '', data),
    {
      onSuccess: () => {
        message.success('Funding source has been added');
        setIsModalOpen(false);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const removeFundingSource = useMutation(
    (itemUuid: string) => engineeringRepository.removeFundingSource(facilityId, uuid || '', itemUuid),
    {
      onSuccess: () => {
        message.success('Funding source has been removed');
        setIsModalOpen(false);
        setUuidToRemove(undefined);
        refetch();
      },
      onError: (error: any) => {
        setUuidToRemove(undefined);
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const updateFundingSourceMutation = useMutation(
    (data: IProjectFundingSourcePayload) =>
      engineeringRepository.updateProjectFundingSource(facilityId, uuid || '', itemToEdit || '', data),
    {
      onSuccess: (data: IProjectFundingSourceListItem) => {
        message.success('Funding source has been updated');
        setIsModalOpen(false);
        setItemToEdit(undefined);
        refetch();

        if (data.uuid === selectedRemark && !data.remarks) {
          setSelectedRemark(undefined);
        }
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const parseInitialFundingData = () => {
    const fundingItem = fundingData?.results?.find((data) => data.uuid === itemToEdit);

    if (fundingItem) {
      return {
        ...fundingItem,
        tdd: parse(fundingItem.tdd, DateFormatEnum.YYYY_MM_DD, new Date()),
        tod: parse(fundingItem.tod, DateFormatEnum.YYYY_MM_DD, new Date()),
      };
    } else {
      message.error('Selected item not found');
      setItemToEdit(undefined);
      setIsModalOpen(false);
    }
  };

  const sendFundingSource = (formState: FormState) => {
    const values: Record<any, any> = formState.values;

    // currency is temporarily hardcoded (USD)
    const payload: IProjectFundingSourcePayload = {
      ...values,
      amount_value: values.amount_value,
      tdd: format(values.tdd as Date, DateFormatEnum.YYYY_MM_DD),
      tod: format(values.tod as Date, DateFormatEnum.YYYY_MM_DD),
      budget_source: values?.budget_source?.value as string,
      currency: 4,
      remarks: values.remarks || null,
    };

    itemToEdit ? updateFundingSourceMutation.mutate(payload) : sendFundingSourcesMutation.mutate(payload);
  };

  const remarksItemToDisplay = fundingData?.results?.find((data) => data.uuid === selectedRemark);

  return (
    <div className='funding-section-wrapper'>
      <GuidanceModal
        className='financial-modal'
        isClosable={true}
        width='35%'
        centered={false}
        destroyOnClose={true}
        open={isModalOpen}
        closeHandler={() => {
          setIsModalOpen(false);
          setItemToEdit(undefined);
        }}
      >
        <p className='title'>{itemToEdit ? 'Edit source' : 'Add new source'}</p>
        <Form
          yupSchema={validationSchema}
          onSubmit={sendFundingSource}
          formApiRef={formRef}
          key={`${itemToEdit}`}
          initialValues={itemToEdit ? parseInitialFundingData() : {}}
          className='source-form'
          onClick={() => updateState({})}
        >
          <FormStateAccessor>
            {({ values }) => {
              return (
                <>
                  <div className='field'>
                    <label>Source</label>
                    <FormDropdown name='budget_source' options={fundingData?.options?.sources} />
                  </div>
                  <div className='field'>
                    <label>Value</label>
                    <NumericInput name='amount_value' />
                  </div>
                  <div className='field'>
                    <label>TOD</label>
                    <DateField onChange={() => formRef.current && formRef.current.setValue('tdd', null)} name='tod' />
                  </div>
                  <Tooltip align={{ offset: [0, 30] }} title={!values.tod ? 'Select TOD field first' : ''}>
                    <div className='field'>
                      <label>TDD</label>
                      <DateField minDate={values.tod as Date} disabled={Boolean(!values.tod)} name='tdd' />
                    </div>
                  </Tooltip>
                  <div className='field full-column'>
                    <div className='label-with-counter'>
                      <label>Funding remarks (optional)</label>
                      {/*// @ts-ignore*/}
                      <div className={`counter ${values?.remarks?.length >= 225 ? 'error' : ''}`}>
                        {/*// @ts-ignore*/}
                        <span>{values?.remarks?.length || 0}</span>
                        <span>/225</span>
                      </div>
                    </div>
                    <TextArea maxLength={225} name='remarks' />
                  </div>
                  <hr />
                  <div className='buttons-container'>
                    <Button
                      disabled={
                        updateFundingSourceMutation.status === 'loading' ||
                        sendFundingSourcesMutation.status === 'loading'
                      }
                      onClick={() => {
                        setIsModalOpen(false);
                        setItemToEdit(undefined);
                      }}
                      variant='transparent'
                      text='Cancel'
                    />
                    <Button
                      disabled={
                        updateFundingSourceMutation.status === 'loading' ||
                        sendFundingSourcesMutation.status === 'loading'
                      }
                      type='submit'
                      variant='primary'
                      text='Save source'
                    />
                  </div>
                </>
              );
            }}
          </FormStateAccessor>
        </Form>
      </GuidanceModal>
      <div className='funding-sources-section'>
        <p className='section-title'>Funding sources</p>
        {fundingData ? (
          <div className='hbh-details-item funding-budget-value'>
            <div className='hbh-details-item-label'>Total funding source amount</div>
            <div className='hbh-details-item-value'>
              {Number((fundingData?.total_sum_amount_value || 0).toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}{' '}
              USD
            </div>
          </div>
        ) : null}
        <div className='section-content'>
          <div style={{ visibility: 'hidden' }}>
            <CurrencyPicker
              value={selectedCurrency}
              options={items}
              onChange={(currency) => setSelectedCurrency(currency)}
            />
          </div>
          {fundingData ? (
            <span onClick={() => setIsModalOpen(true)} className='new-source-button'>
              Add new source <span>+</span>
            </span>
          ) : null}
          <Table
            className='funding-table'
            columns={getColumns(setItemToEdit, setSelectedRemark, setUuidToRemove)}
            data={getFundingSourceData()}
            isLoading={isLoading}
            variant='dark'
            scroll={{ x: true }}
          />
          <Pagination
            className='pagination'
            selectedPage={selectedPage}
            showJumper={false}
            showPageSize={false}
            totalPages={fundingData ? Math.ceil(fundingData?.count * 2) : 0}
            variant='dark'
            onPageChange={setSelectedPage}
            onPageSizeChange={setPageSize}
          />
        </div>
      </div>
      <div className='funding-remarks-section'>
        <p className='section-title'>Funding remarks</p>
        <div className={`remarks-box ${!remarksItemToDisplay ? 'empty' : ''}`}>
          {remarksItemToDisplay ? (
            <div>
              <div className='remarks-info'>
                <span className='author'>{remarksItemToDisplay.author || 'Unknown'}</span>
                <span className='date'>
                  {format(parseISO(remarksItemToDisplay.updated), 'dd MMMM yyyy', { locale: enGB })}
                </span>
              </div>
              <p className='remarks-content'>{remarksItemToDisplay.remarks}</p>
            </div>
          ) : (
            <p>No remark selected</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FundingSection;
