import React, { FC, useEffect } from 'react';

import { find } from 'lodash';
import { message, Spin } from 'antd';
import { useMutation, UseMutationResult, useQuery } from 'react-query';

import GuidanceModal from '@components/CommunityGuidancePage/components/GuidanceModal';
import GuidanceSummary from '@components/CommunityGuidancePage/components/GuidanceSummary';
import GuidanceForm from '@components/CommunityGuidancePage/components/GuidanceForm';
import GuidanceConfirmation from '@components/CommunityGuidancePage/components/GuidanceConfirmation';
import GuidanceActivitiesList from '@components/CommunityGuidancePage/components/GuidanceActivitiesList';
import {
  EMAIL_CONFIRMATION_MODE,
  FORM_MODE,
  SUBMIT_CONFIRMATION_MODE,
  SUMMARY_MODE,
} from '@components/CommunityGuidancePage/constants';

import { useRepository } from '@context/repository.context';
import {
  IGuidanceActivitiesResponse,
  IGuidanceActivity,
  IGuidanceRiskCategoriesResponse,
  IGuidanceRiskCategory,
} from '@digital-office/common/interfaces';
import { IDropdownOption } from '@ui-modules/types';
import background from '@assets/img/background3.jpg';

import './styles.scss';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

export interface IGuidanceData {
  activity: IGuidanceActivity & { activity_type_label: string };
  prefilled_data: {
    [key: string]: any;
  };
  risk: IGuidanceRiskCategory;
  options: {
    [key: string]: IDropdownOption[];
  };
}

interface IGuidanceSetupModalProps {
  data: IGuidanceData;
  submitMutation: UseMutationResult<any, unknown, any, unknown>;
  sendEmailMutation: UseMutationResult<any, unknown, any, unknown>;
  open: boolean;
  closeHandler: () => void;
  mode: string;
  setSetupMode: (mode: string) => void;
}

const GuidanceSetupModal: FC<IGuidanceSetupModalProps> = ({
  open,
  closeHandler,
  mode,
  setSetupMode,
  data,
  submitMutation,
  sendEmailMutation,
}) => {
  const Component = () => {
    switch (mode) {
      case SUMMARY_MODE:
        return <GuidanceSummary data={data} sendEmailMutation={sendEmailMutation} setSetupMode={setSetupMode} />;
      case FORM_MODE:
        return <GuidanceForm submitMutation={submitMutation} data={data} setSetupMode={setSetupMode} />;
      case EMAIL_CONFIRMATION_MODE:
        return <GuidanceConfirmation data={data} onButtonClickHandler={() => setSetupMode(FORM_MODE)} mode={mode} />;
      case SUBMIT_CONFIRMATION_MODE:
        return <GuidanceConfirmation data={data} mode={mode} />;
      default:
        return null;
    }
  };

  return (
    <GuidanceModal
      width={mode === EMAIL_CONFIRMATION_MODE ? '30%' : '40%'}
      centered={mode !== EMAIL_CONFIRMATION_MODE}
      closeHandler={sendEmailMutation.status === 'loading' ? () => null : closeHandler}
      isClosable={sendEmailMutation.status !== 'loading'}
      open={open}
    >
      <Component />
    </GuidanceModal>
  );
};

const CommunityGuidancePage = () => {
  const [selectedActivity, setSelectedActivity] = React.useState<IGuidanceActivity | null>(null);
  const [selectedCountry, setSelectedCountry] = React.useState<IDropdownOption | null>(null);
  const [isActivityRateSent, setIsActivityRateSent] = React.useState<boolean>(false);
  const [setupMode, setSetupMode] = React.useState<string>('summary');
  const { engineeringRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;

  const { data: activitiesList, isLoading } = useQuery<IGuidanceActivitiesResponse>(
    'guidance-tool-activities-list',
    () => engineeringRepository.getGuidanceActivities(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: riskCategories, isLoading: riskCategoriesLoading } = useQuery<IGuidanceRiskCategoriesResponse>(
    'guidance-tool-risk-categories',
    () => engineeringRepository.getGuidanceRiskCategories(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const sendAssessmentMutation = useMutation((data: any) => engineeringRepository.sendAssessment(facilityId, data), {
    onSuccess: () => {
      setSetupMode(SUBMIT_CONFIRMATION_MODE);
    },
  });

  const sendEmailMutation = useMutation(
    (uuid: string) => engineeringRepository.sendEmail(facilityId, uuid, selectedCountry?.value || ''),
    {
      onSuccess: (uuid) => {
        setSetupMode(EMAIL_CONFIRMATION_MODE);
        const emailDisabledActivities = JSON.parse(sessionStorage.getItem('EMAIL_DISABLED_ACTIVITIES') || '[]');
        sessionStorage.setItem('EMAIL_DISABLED_ACTIVITIES', JSON.stringify([...emailDisabledActivities, uuid]));
      },
      onError: (uuid) => {
        message.error('Sending email has failed');
      },
    }
  );

  useQuery(
    `${selectedActivity ? selectedActivity.uuid : ''} ${selectedCountry?.value} - rate update`,
    () => engineeringRepository.sendActivityRate(selectedActivity?.uuid || '', selectedCountry?.value || ''),
    {
      onError: () => {
        message.error('Request failed. Try again');
        setSelectedActivity(null);
      },
      onSuccess: () => {
        setIsActivityRateSent(true);
      },
      enabled: Boolean(selectedActivity),
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!selectedActivity) {
      setSetupMode(SUMMARY_MODE);
      setIsActivityRateSent(false);
    }
  }, [selectedActivity]);

  const onSetupUpModeChange = (mode: string) => {
    setSetupMode(mode);
  };

  const getGuidanceData = () => {
    if (activitiesList && selectedActivity && riskCategories) {
      const riskData = find(
        riskCategories.results,
        (category: IGuidanceRiskCategory) => category.level === selectedActivity.risk_category
      );

      const activityLabel = find(
        activitiesList?.options?.activity_types,
        (option) => option.value === selectedActivity.activity_type
      )?.label;

      return riskData && activityLabel
        ? {
            options: activitiesList?.options,
            prefilled_data: {
              country: selectedCountry,
            },
            activity: {
              ...selectedActivity,
              activity_type_label: activityLabel,
            },
            risk: {
              ...riskData,
            },
          }
        : undefined;
    }
  };

  const guidanceData = getGuidanceData();

  return (
    <div className='container' style={{ background: `url(${background})` }}>
      <Spin spinning={isLoading || riskCategoriesLoading}>
        <div className='video-link'>
          <VideoLink link={videoLinks.GUIDANCE} />
        </div>
        <div className='guidance-wrapper'>
          {guidanceData && isActivityRateSent && (
            <GuidanceSetupModal
              data={guidanceData}
              mode={setupMode}
              submitMutation={sendAssessmentMutation}
              sendEmailMutation={sendEmailMutation}
              open={Boolean(selectedActivity)}
              closeHandler={() => setSelectedActivity(null)}
              setSetupMode={onSetupUpModeChange}
            />
          )}
          {activitiesList && riskCategories && (
            <GuidanceActivitiesList
              activities={activitiesList?.results}
              riskCategories={riskCategories.results}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              setSelectedActivity={setSelectedActivity}
              options={activitiesList?.options}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default CommunityGuidancePage;
