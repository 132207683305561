import React, { FC, useState } from 'react';
import './styles.scss';
import { ArrowUpOutlined } from '@ant-design/icons';
import { Collapse } from 'react-collapse';
import { IAssessmentProjectLocation } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface IAssessmentOverviewSummaryProps {
  values: IAssessmentProjectLocation;
  index: number;
  isDefaultOpen: boolean;
}

const AssessmentLocationSummary: FC<IAssessmentOverviewSummaryProps> = ({ values, index, isDefaultOpen }) => {
  const locationDetails = values.location_details;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isDefaultOpen);

  return (
    <div className='assessment-location-summary-container'>
      <div onClick={() => setIsCollapsed(!isCollapsed)} className='location-title'>
        <span className='section-label'>#{index}</span>
        <span className='section-value'>
          {locationDetails.name}, {locationDetails.country}, {locationDetails.latitude}, {locationDetails.longitude}
        </span>
        <ArrowUpOutlined className={`arrow-icon ${isCollapsed ? 'up' : 'down'}`} />
      </div>
      <Collapse isOpened={isCollapsed}>
        <div className='content-section'>
          <div className='summary-field'>
            <div className='section-label'>Project location</div>
            <div className='section-value'>
              {locationDetails.name}, {locationDetails.country}, {locationDetails.latitude}, {locationDetails.longitude}
            </div>
          </div>
          <div className='summary-field'>
            <div className='section-label'>Description</div>
            <div className='section-value'>{values.description}</div>
          </div>
          <div className='summary-field'>
            <div className='section-label'>Observations</div>
            <div className='section-value'>{values.observations}</div>
          </div>
          <div className='summary-field'>
            <div className='section-label'>List of attendees at meeting (Office location)</div>
            <div className='section-value'>{values?.list_of_attendees_at_meeting.join(', ') || '-'}</div>
          </div>
          <div className='summary-field'>
            <div className='section-label'>Minutes of the meeting (optional)</div>
            <div className='section-value'>{values.minutes_of_the_engineering_proposal_discussed || '-'}</div>
          </div>
          <div className='summary-field'>
            <div className='section-label'>Pictures</div>
            <div className='section-value'>
              <ul className='pictures-list'>
                {values.pictures.map((picture) => (
                  <div key={picture.name}>
                    <li>
                      <span>
                        {picture.name} - {picture.caption}
                      </span>
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default AssessmentLocationSummary;
