import React, { Dispatch, FC, SetStateAction } from 'react';
import { IYupSchema, yupSchemas, STEPS } from '@pages/BookingFormPage/consts';
import { useFormApi, useMultistepApi } from 'informed';
import { ReactComponent as ArrowLeft } from '@assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '@assets/svg/arrow-right.svg';
import { ReactComponent as SuccessIcon } from '@assets/svg/success.svg';
import './styles.scss';

interface NavigationProps {
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
  confirmEnabled?: boolean;
}

const Navigation: FC<NavigationProps> = ({ setYupSchema, confirmEnabled = false }) => {
  const { getFormState } = useFormApi();
  const { next, previous, getCurrentStep, getNextStep, getPreviousStep } = useMultistepApi();

  const currentStep = getCurrentStep();
  const prevStep = getPreviousStep();
  const nextStep = getNextStep() as unknown as string;

  const onPrev = () => {
    previous();
    setYupSchema(yupSchemas[prevStep]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onNext = () => {
    next();
    const { valid } = getFormState();
    if (valid) setYupSchema(yupSchemas[nextStep]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='bf-buttons'>
      {currentStep !== STEPS.DETAILS ? (
        <button type='button' className='bf-button prev' onClick={onPrev}>
          Back
          <ArrowLeft />
        </button>
      ) : null}
      {currentStep !== STEPS.REVIEW ? (
        <button type='button' className='bf-button next' onClick={onNext}>
          Next
          <ArrowRight />
        </button>
      ) : (
        <button type='submit' className='bf-button confirm' disabled={!confirmEnabled}>
          Confirm
          <SuccessIcon />
        </button>
      )}
    </div>
  );
};

export default Navigation;
