import React from 'react';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import LazyPages from '@components/LazyPages';
import { CMSPageResponse, ICMSPagesTypes } from '../../interfaces/Common';
import LoadingPage from '@components/LoadingPage';

const PageProxy = () => {
  const { cmsRepository } = useRepository();
  const navigate = useNavigate();

  const pathname = window.location.pathname;

  const { data } = useQuery<CMSPageResponse>(`cms-site-${pathname}`, () => cmsRepository.getPage(pathname.substr(1)), {
    refetchOnWindowFocus: false,
    retry: 2,
    onError: () => {
      navigate('/forbidden');
    },
  });

  const PageComponent = data?.page_type ? LazyPages[data.page_type as ICMSPagesTypes['type']] : null;

  if (PageComponent) {
    return (
      <React.Suspense
        fallback={
          <div className='loading-site'>
            <LoadingPage />
          </div>
        }
      >
        {data?.content ? (
          <PageComponent backgroundUrl={data?.background?.meta?.download_url || ''} content={data?.content || []} />
        ) : null}
      </React.Suspense>
    );
  } else return <LoadingPage />;
};

export default PageProxy;
