import ReactMapGl, { Layer, MapboxGeoJSONFeature, MapRef, Source, Popup, MapLayerMouseEvent } from 'react-map-gl';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useRepository } from '@context/repository.context';
import './styles.scss';
import { MAPBOX_API_KEY } from '@components/GlobalImpact/utils';

interface IDemandOverviewCountries {
  country_iso_code3: string;
  number_of_requests: number;
}

interface IProps {
  selectedCountries: IDemandOverviewCountries[];
  label: string;
  customTooltip?: (tooltip: Tooltip) => JSX.Element | string;
}

export interface Tooltip {
  id: string;
  lng: number;
  lat: number;
  numberOfRequests: number;
  country: string;
}

const lineLayer = {
  id: 'outline',
  type: 'line' as 'sky',
  source: 'grouped-countries',
  layout: {},
  paint: {
    'line-width': ['case', ['boolean', ['feature-state', 'select'], false], 1.5, 0],
    'line-color': '#fff',
  },
};

const countryLayer = {
  id: 'grouped-geojson-layer-fill',
  type: 'fill' as 'sky',
  paint: {
    'fill-color': ['case', ['boolean', ['feature-state', 'select'], false], '#8ECDFF', '#595959'],
  },
};

export const handleHover = (
  event: MapLayerMouseEvent,
  selectedCountries: IDemandOverviewCountries[],
  setTooltip: React.Dispatch<React.SetStateAction<Tooltip | null>>
): void => {
  const { features } = event;
  const hoveredFeature = features && features.find((f) => f.layer.id === 'grouped-geojson-layer-fill');
  const isFeatureBelongsToSelectedCountries = selectedCountries.find(
    (country) => country.country_iso_code3 === hoveredFeature?.properties?.id
  );

  if (!isFeatureBelongsToSelectedCountries) {
    setTooltip(null);
  } else {
    setTooltip({
      id: hoveredFeature?.properties?.id,
      lng: event.lngLat.lng,
      lat: event.lngLat.lat,
      numberOfRequests: isFeatureBelongsToSelectedCountries.number_of_requests,
      country: hoveredFeature?.properties?.name,
    });
  }
};

const CountryMap: FC<IProps> = ({ selectedCountries, label, customTooltip }) => {
  const [settings] = useState({
    dragPan: false,
    dragRotate: false,
    touchRotate: false,
    keyboard: false,
    doubleClickZoom: false,
    latitude: 35,
    width: 'fit',
    height: '100%',
  });

  const { engineeringRepository } = useRepository();
  const [features, setFeatures] = useState<MapboxGeoJSONFeature[] | null>(null);
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const [isMapReady, setIsMapReady] = useState(false);

  const [tooltip, setTooltip] = useState<Tooltip | null>(null);

  useQuery(`geo-json`, () => engineeringRepository.getGeoJSON(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const features = data.features.map((feature: MapboxGeoJSONFeature, key: number) => ({
        ...feature,
        id: key,
      }));

      setFeatures(features);
    },
  });

  useEffect(() => {
    if (mapRef && features && isMapReady) {
      selectedCountries.map((country) => {
        mapRef.setFeatureState(
          {
            source: 'grouped-countries',
            id: features.find((feature) => feature?.properties?.id === country.country_iso_code3)?.id,
          },
          { select: true }
        );
      });
    }
  }, [selectedCountries, features, isMapReady]);

  const filteredCountries = {
    type: 'FeatureCollection',
    features: features || [],
  };

  return (
    <div data-testid='country-map-component' className='country-map'>
      <p>{label}</p>
      <ReactMapGl
        attributionControl={false}
        zoom={0}
        onLoad={() => setIsMapReady(true)}
        mapboxAccessToken={MAPBOX_API_KEY}
        id='country-test'
        renderWorldCopies={false}
        ref={(ref) => setMapRef(ref)}
        {...settings}
        interactive={false}
        interactiveLayerIds={['grouped-geojson-layer-fill']}
        style={{ cursor: 'default' }}
        onMouseMove={(event: MapLayerMouseEvent) => handleHover(event, selectedCountries, setTooltip)}
        onMouseLeave={() => setTooltip(null)}
      >
        {isMapReady && (
          <Source id='grouped-countries' type='geojson' data={filteredCountries as unknown as MapboxGeoJSONFeature}>
            <Layer {...countryLayer} />
            <Layer interactive={true} {...lineLayer} />
            {tooltip && (
              <Popup
                closeOnClick={false}
                closeOnMove={false}
                className='map-popover'
                closeButton={false}
                longitude={tooltip.lng}
                latitude={tooltip.lat}
              >
                <div className='tooltip'>
                  {customTooltip ? (
                    customTooltip(tooltip)
                  ) : (
                    <div>
                      {tooltip.country} - {tooltip.numberOfRequests}{' '}
                      {tooltip.numberOfRequests === 1 ? 'request' : 'requests'}
                    </div>
                  )}
                </div>
              </Popup>
            )}
          </Source>
        )}
      </ReactMapGl>
    </div>
  );
};

export default CountryMap;
