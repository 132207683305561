import React, { FC } from 'react';
import { useField } from 'informed';
import { DataItem } from '@digital-office/common/interfaces';
import { ReactComponent as SuccessIcon } from '@assets/svg/success.svg';

import './styles.scss';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types';

interface IRadioOptionField {
  name: string;
  options: Array<DataItem>;
}

const RadioOptionField: FC<IRadioOptionField> = (props) => {
  const { options, name } = props;

  const { fieldState, fieldApi, render, ref } = useField(props);

  const fieldValue = fieldState.value as IDropdownOption;

  const { setValue } = fieldApi;

  return render(
    <div ref={ref}>
      {options.map(({ value, display_name }, idx) => {
        const isChecked = fieldValue?.value === value;
        const id = `${name}-${value}`;

        return (
          <label htmlFor={id} key={idx} className='radio-option-field'>
            <input
              className='radio-option-field-input'
              type='radio'
              id={id}
              name={name}
              checked={isChecked}
              onChange={() => setValue({ value, label: display_name })}
            />
            <div className='radio-option-field-item'>
              {display_name}
              <SuccessIcon />
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default RadioOptionField;
