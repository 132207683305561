import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainMenu } from '@ui-modules';
import { ReactComponent as ChartIcon } from '@assets/svg/chart.svg';
import { ReactComponent as FFAIcon } from '@assets/svg/ffa results.svg';
import { ReactComponent as ProjectsDashboard } from '@assets/svg/report-projects.svg';

const DashboardMenu: FC = () => {
  const navigate = useNavigate();
  const country = localStorage.getItem('country');

  const menuButtons = [
    {
      label: 'Demand dashboard',
      className: 'btn-assessment',
      onClick: () => navigate('/digital-office/demand-overview'),
      icon: <ChartIcon data-testid='chart-icon' />,
    },
    {
      label: 'FFA guidance analysis',
      className: 'btn-ffa-dashboard',
      onClick: () => navigate('/digital-office/dashboard-ffa'),
      icon: <FFAIcon data-testid='ffa-icon' />,
    },
    {
      label: 'Projects overview dashboard',
      className: 'btn-project-dashboard',
      onClick: () => navigate('/digital-office/project-dashboard'),
      icon: <ProjectsDashboard data-testid='projects-dashboard-icon' />,
    },
  ];

  return <MainMenu title={`ENGINEERING DIGITAL OFFICE - ${country}`} menuButtons={menuButtons} />;
};

export default DashboardMenu;
