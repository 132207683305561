import React, { FC, useEffect } from 'react';
import { useField } from 'informed';
import { DataItemExpanded } from '@digital-office/common/interfaces';

import './styles.scss';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types';

interface IInfrastructureField {
  options: Array<DataItemExpanded>;
  name: string;
  onChange: () => void;
}

const InfrastructureField: FC<IInfrastructureField> = (props) => {
  const { options, name, onChange } = props;

  const { fieldState, fieldApi, render, ref } = useField(props);

  const fieldValue = fieldState.value as IDropdownOption | undefined;

  const { setValue } = fieldApi;

  useEffect(() => {
    if (!fieldValue) {
      setValue('');
    }
  }, [fieldValue]);

  return render(
    <div ref={ref} className='infra-wrapper'>
      <div className='infra-container'>
        {options.map(({ description, display_name, icon, value }, idx) => {
          const isChecked = fieldValue?.value === value;
          return (
            <label key={idx} htmlFor={value} className={`infra-option ${isChecked ? 'checked' : ''}`}>
              <img src={icon} alt='' className='infra-icon' />
              <div className='infra-title'>
                {display_name}
                <input
                  type='radio'
                  id={value}
                  name={name}
                  className='infra-input'
                  checked={isChecked}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue({ value, label: display_name });
                      onChange();
                    }
                  }}
                />
              </div>
              <div className='infra-description'>{description}</div>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default InfrastructureField;
