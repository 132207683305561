import React, { FC } from 'react';
import './styles.scss';
import HomeIconComponent from '@assets/icons/Icon';
import { ICMSNavigation } from '../../interfaces/Common';
import { useNavigate } from 'react-router-dom';

interface IProps {
  data: ICMSNavigation[];
}

const CMSNavigation: FC<IProps> = ({ data }) => {
  const navigate = useNavigate();

  const pathname = location.pathname.split('/')[1];

  return (
    <div className='cms-navigation'>
      {data?.map((nav, key) => (
        <a
          key={key}
          onClick={() => (!nav.is_external ? navigate(nav.link_url) : window.open(nav.link_url, '_blank'))}
          className={`menu-link ${pathname === nav.link_url && 'active'} ${nav.link_url ? '/' : ''}`}
        >
          {nav.link_url === '/' && (
            <div className='home-icon'>
              <div className='icon'>
                <HomeIconComponent />
              </div>
            </div>
          )}
          {nav.icon && <img alt='logo' className='icon' src={nav.icon} />}
          {nav?.link_title}
        </a>
      )) ?? null}
    </div>
  );
};

export default CMSNavigation;
