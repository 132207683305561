import CMSHeader from '@components/Header';
import CMSNavigation from '@components/Navigation';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useRepository } from '@context/repository.context';
import { useNavigate } from 'react-router-dom';
import { ICMSHeader } from '../../interfaces/Common';
import { IUser } from '@digital-office/common/interfaces';
import MobileNavigation from '@components/MobileNavigation';
import './styles.scss';

interface IProps {
  logoutHandler: () => void;
  userName?: string;
  hasAccessDO?: boolean;
  hasDoParams: boolean;
}

const CMSUserHeader: FC<IProps> = ({ logoutHandler, hasAccessDO, hasDoParams }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { cmsRepository, engineeringRepository } = useRepository();
  const navigate = useNavigate();
  const { data } = useQuery<ICMSHeader>(`cms-navigation`, () => cmsRepository.getNavigation(), {
    refetchOnWindowFocus: false,
    retry: 2,
    onError: () => {
      navigate('/forbidden');
    },
    onSuccess: (data) => {
      const pathname = window.location.pathname;
      if (pathname === '/') {
        navigate(data.home_page_slug);
      }
    },
  });

  const { data: userDetails } = useQuery<IUser>(['user-details'], () => engineeringRepository.getUserDetails(), {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return (
    <div>
      {data && (
        <div className='cms-menu'>
          <CMSHeader
            hasDoParams={hasDoParams}
            hasAccessDO={hasAccessDO}
            headerData={data}
            username={userDetails ? `${userDetails.first_name} ${userDetails.last_name}` : ''}
            organisation={userDetails ? userDetails.organisation : ''}
            logoutHandler={logoutHandler}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
          <CMSNavigation data={data.navigation} />
          <MobileNavigation
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            isMobileMenuOpen={isMobileMenuOpen}
            data={data.navigation}
            hasAccessDO={hasAccessDO}
            hasDoParams={hasDoParams}
            logoutHandler={logoutHandler}
          />
        </div>
      )}
    </div>
  );
};

export default CMSUserHeader;
