import React, { FC } from 'react';
import { FormApi, Radio, RadioGroup } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import * as Yup from 'yup';
import { TextField } from '@ui-modules/informed';
import { IAssessmentFormData } from '@digital-office/pages/FieldAssessmentReports/components/AssessmentChecklistStep';

interface IAssessmentConductedList {
  name: string;
  values: IAssessmentFormData;
  formRef: React.RefObject<FormApi>;
}

const getAssessmentMiscellaneousListYupValidation = (values: any) => {
  const isOthersSelected = values?.data?.miscellaneous_works_required?.others === 'yes';

  return Yup.object().shape({
    construction_of_ICT_VSAT_slab: Yup.string().required('This field is required'),
    mast: Yup.string().required('This field is required'),
    construction_of_ICT_manholes_for_cabling: Yup.string().required('This field is required'),
    construction_of_fleet_workshop_wash_bay: Yup.string().required('This field is required'),
    others: Yup.string().required('This field is required'),
    describe_others: isOthersSelected ? Yup.string().required('This field is required') : Yup.mixed(),
  });
};

const AssessmentMiscellaneousList: FC<IAssessmentConductedList> = ({ formRef, values }) => {
  const setValue = formRef.current?.setValue;

  return (
    <div className='assessment-details-list-container'>
      <div className='table-header'>
        <div className='headers'>
          <span>Yes</span>
          <span>No</span>
        </div>
      </div>
      <InformedField
        name='data[miscellaneous_works_required][construction_of_ICT_VSAT_slab]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Construction of ICT VSAT slab?</span>
            <RadioGroup className='radio' name='data[miscellaneous_works_required][construction_of_ICT_VSAT_slab]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[miscellaneous_works_required][mast]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>MAST?</span>
            <RadioGroup className='radio' name='data[miscellaneous_works_required][mast]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[miscellaneous_works_required][construction_of_ICT_manholes_for_cabling]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Construction of ICT Manholes for cabling?</span>
            <RadioGroup
              className='radio'
              name='data[miscellaneous_works_required][construction_of_ICT_manholes_for_cabling]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[miscellaneous_works_required][construction_of_fleet_workshop_wash_bay]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Construction of fleet workshop & wash-bay?</span>
            <RadioGroup
              className='radio'
              name='data[miscellaneous_works_required][construction_of_fleet_workshop_wash_bay]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[miscellaneous_works_required][others]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Others?</span>
            <RadioGroup
              onChange={(e) =>
                e.value === 'no' &&
                setValue &&
                setValue('data[miscellaneous_works_required][describe_others]', undefined)
              }
              className='radio'
              name='data[miscellaneous_works_required][others]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <InformedField
        name='data[miscellaneous_works_required][describe_others]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Describe others</span>
            <div className='text-input'>
              <TextField
                disabled={Boolean(values?.data?.miscellaneous_works_required?.others !== 'yes')}
                name='data[miscellaneous_works_required][describe_others]'
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export { getAssessmentMiscellaneousListYupValidation, AssessmentMiscellaneousList };
