import React, { FC, useRef } from 'react';
import { Spin, Tooltip } from 'antd';
import { Form, FormApi, Radio, RadioGroup, TextArea } from 'informed';
import { Button } from '@ui-modules';
import { ArrowLeftOutlined, InfoCircleFilled } from '@ant-design/icons';
import * as Yup from 'yup';
import { UseMutationResult } from 'react-query';

import { TextField, DateField, Dropdown } from '@ui-modules/informed';

import { SUMMARY_MODE } from '@components/CommunityGuidancePage/constants';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import { IGuidanceData } from '@components/CommunityGuidancePage';
import { IDropdownOption } from '@ui-modules/types';

import './styles.scss';
import { endOfMonth, format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

interface IGuidanceForm {
  setSetupMode: (mode: string) => void;
  submitMutation: UseMutationResult<any, unknown, any, unknown>;
  data: IGuidanceData;
}

const InformedField = ({ name, children, formRef, className }: any) => {
  const error = formRef?.current && formRef.current.getError(name);
  return (
    <div className={`input-wrapper ${className}`}>
      {children}
      {error && <div className='error'>{error}</div>}
    </div>
  );
};

const validationSchema = Yup.object().shape({
  country: Yup.object().required('Required'),
  location: Yup.string().required('Required'),
  desired_start_date: Yup.date().required('Required'),
  estimated_budget: Yup.string().required('Required'),
  requesting_unit: Yup.string().required('Required'),
});

const GuidanceForm: FC<IGuidanceForm> = ({ setSetupMode, data, submitMutation }) => {
  const formRef = useRef<FormApi>(null);
  const [formData, setFormData] = React.useState<Record<string, unknown> | null>(null);

  const desiredStartDate = formData?.desired_start_date
    ? format(endOfMonth(new Date(formData.desired_start_date as string)), DateFormatEnum.YYYY_MM_DD)
    : null;

  const parseValues = (formData: any) => {
    return {
      ...formData,
      activity: data.activity.uuid,
      country: formData?.country?.value,
      ...(formData?.partners && { partners: formData?.partners?.map((partner: IDropdownOption) => partner.value) }),
      ...(formData?.preliminary_assessment_completed && {
        preliminary_assessment_completed: formData?.preliminary_assessment_completed === 'yes',
      }),
      desired_start_date: desiredStartDate,
    };
  };

  return (
    <div className='guidance-form-wrapper'>
      <div className='title'>
        <p>Asset Creation and Livelihoods Activities</p>
        <hr className='hr' />
        <p>Contact WFP Engineering</p>
      </div>
      <div className='infrastructure'>
        <p className='infrastructure-label'>Activity</p>
        <Tooltip title={data.activity.name}>
          <span className='border-value'>{data.activity.name}</span>
        </Tooltip>
      </div>
      <div className='form'>
        <Spin className='form-spinner' spinning={submitMutation.status === 'loading'} />
        <Form
          formApiRef={formRef}
          yupSchema={validationSchema}
          onChange={({ values }) => setFormData({ ...values })}
          validateOn='change-blur'
          initialValues={data.prefilled_data}
          onSubmit={({ values }) => {
            submitMutation.mutate(parseValues(values));
          }}
          className={`form-container ${submitMutation.status === 'loading' ? 'disabled' : ''}`}
        >
          <InformedField name='country' formRef={formRef}>
            <Dropdown
              name='country'
              options={data.options.countries}
              containerClassName='dropdown'
              label='Country of implementation*'
              key='country'
            />
          </InformedField>
          <InformedField name='location' formRef={formRef}>
            <div>
              <label className='input-label'>Location detail (name or coordinates)*</label>
              <TextField name='location' className='input' />
            </div>
          </InformedField>{' '}
          <InformedField name='requesting_unit' formRef={formRef}>
            <div>
              <label className='input-label'>Requesting unit*</label>
              <TextField name='requesting_unit' className='input' />
            </div>
          </InformedField>
          <InformedField name='estimated_budget' formRef={formRef}>
            <div>
              <label className='input-label'>Estimate budget*</label>
              <NumericInput name='estimated_budget' />
            </div>
          </InformedField>
          <InformedField name='partners' formRef={formRef}>
            <Dropdown
              name='partners'
              isMulti={true}
              options={data.options.partners}
              containerClassName='dropdown'
              label='Potential partners'
              key='partners'
            />
          </InformedField>
          <InformedField name='desired_start_date' formRef={formRef}>
            <div>
              <label className='input-label'>Tentative start date*</label>
              <DateField
                className='date-picker'
                name='desired_start_date'
                showMonthYearPicker
                showMonthDropdown={true}
                minDate={new Date(new Date().getFullYear(), new Date().getMonth())}
                placeholder='MM/YYYY'
                dateFormat={'MM/yyyy'}
              />
            </div>
          </InformedField>
          <InformedField name='preliminary_assessment_completed' formRef={formRef}>
            <div className='radio'>
              <p>Preliminary assessment completed</p>
              <RadioGroup name='preliminary_assessment_completed'>
                <Tooltip
                  overlayClassName='preliminary-tooltip'
                  title='A visit to the site and review the potential asset creation activity(ies) that can be implemented'
                >
                  <InfoCircleFilled className='info-icon' />
                </Tooltip>
                <div className='radio-element'>
                  <Radio value='yes' label='Yes' />
                </div>
                <div className='radio-element'>
                  <Radio value='no' label='No' />
                </div>
              </RadioGroup>
            </div>
          </InformedField>
          <InformedField className='text-area' name='additional_information' formRef={formRef}>
            <TextArea
              label='To better assist you with your engineering needs, please provide us with as much information as possible regarding the specific support required.'
              name='additional_information'
              className='text-area-input'
            />
          </InformedField>
        </Form>
      </div>
      <div className='buttons-section'>
        <Button
          onClick={() => setSetupMode(SUMMARY_MODE)}
          icon={<ArrowLeftOutlined />}
          className='button'
          disabled={submitMutation.status === 'loading'}
          variant='transparent'
          text='Back to guidance'
        />
        <Button
          onClick={() => formRef?.current && formRef.current.submitForm()}
          disabled={submitMutation.status === 'loading'}
          className='button'
          text='Submit request to WFP Engineering'
        />
      </div>
    </div>
  );
};

export default GuidanceForm;
