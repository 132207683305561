import React, { FC, useEffect, useRef, useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { Checkbox, Form, FormApi, TextArea } from 'informed';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { message, Upload, UploadProps } from 'antd';
import * as Yup from 'yup';
import { Dropdown } from '@ui-modules/informed';
import { ArrowLeftOutlined, CheckOutlined, InboxOutlined } from '@ant-design/icons';
import { Button } from '@ui-modules';
import './styles.scss';
import { IDropdownOption } from '@ui-modules/types';
import { UseMutationResult } from 'react-query';
import { IProjectUpdatePayload } from '@digital-office/common/interfaces';

const { Dragger } = Upload;

interface IUpdateFormProps {
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  mutation: UseMutationResult<any, any, IProjectUpdatePayload, unknown>;
  options: {
    [key: string]: IDropdownOption[];
  };
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  details: Yup.string().required('Required'),
  related_infrastructure: Yup.object().required('Required'),
});

const MAX_FILE_SIZE = 2560000; // 250KB
const MAX_FILES_COUNT = 5;
const EXTENSIONS_LIST = ['jpg', 'png', 'jpeg'];

export const convertToBase64 = (selectedFile: RcFile) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(selectedFile);
  });
};

const UpdateForm: FC<IUpdateFormProps> = ({ setIsEditMode, mutation, options }) => {
  const [formData, setFormData] = useState<Record<any, any> | null>(null);
  const [filesList, setFilesList] = useState<RcFile[]>([]);
  const formRef = useRef<FormApi>(null);
  const [isApplyButtonActive, setIsApplyButtonActive] = useState<boolean>(false);

  useEffect(() => {
    if (!formData || !filesList?.length || !formRef.current) {
      return setIsApplyButtonActive(false);
    } else {
      const errors = formRef.current.getFormState().errors;
      return setIsApplyButtonActive(isEmpty(errors));
    }
  }, [formData, filesList]);

  useEffect(() => {
    formRef?.current && formRef.current.submitForm();
  }, [formRef.current]);

  const getError = (name: string) => {
    if (formRef?.current) {
      return formRef.current.getError(name);
    }
  };

  const isFileAllowedSize = (file: File): void | boolean => {
    if (file.size >= MAX_FILE_SIZE) {
      throw new Error(
        `File ${file.name} has too much size. Allowed size of file: ${Math.floor(MAX_FILE_SIZE / 1024 / 1024)} MB`
      );
    }
    return true;
  };

  const isAllowedExtension = (name: string): void | boolean => {
    if (!new RegExp('(' + EXTENSIONS_LIST.join('|').replace(/\./g, '\\.') + ')$').test(name)) {
      throw new Error(`${name}: extension incorrect, accepted extensions: ${EXTENSIONS_LIST.join(', ')}`);
    }
    return true;
  };

  const validateFiles = (file: File): boolean => {
    try {
      isFileAllowedSize(file);
      isAllowedExtension(file.name);

      return true;
    } catch (error: any) {
      message.error(error.message);
      return false;
    }
  };

  const props: UploadProps = {
    multiple: true,
    maxCount: MAX_FILES_COUNT,
    onRemove(file) {
      setFilesList((filesList as RcFile[]).filter((f) => f.uid !== file.uid));
    },
    beforeUpload: (file, list) => {
      if (!validateFiles(file)) {
        return Upload.LIST_IGNORE;
      }

      const exists = filesList.some((f) => f.name === file.name && f.size === file.size);
      if (exists) {
        message.error('File already added!');
        return Upload.LIST_IGNORE;
      }

      if (filesList.length + list.length > MAX_FILES_COUNT) {
        isEqual(file, list[list.length - 1]) && message.error(`You can upload maximum ${MAX_FILES_COUNT} files`);
        return Upload.LIST_IGNORE;
      }

      setFilesList((prevList) => [...prevList, file]);

      return false;
    },
  };

  const getLabel = (name: string, label: string) => {
    const error = getError(name);

    return (
      <p className={error ? 'field-label error' : 'field-label'}>
        {label} {error ? '- Required' : ''}
      </p>
    ) as any;
  };

  const onSendClickHandler = async () => {
    if (!formData) return false;

    const convertedPictures = await Promise.all(filesList.map((file) => convertToBase64(file)));

    const result = {
      ...(formData as { title: string; details: string }),
      infrastructure: formData.related_infrastructure?.value,
      phase: formData.related_phase?.value,
      is_shared: Boolean(formData['is_shared']),
      pictures: convertedPictures.map((base64) => ({ picture: base64 as string })),
    };

    mutation.mutate(result);
  };

  return (
    <div>
      <div className='hbh-details-item-label'>
        <p>INSERT INFORMATION</p>
      </div>
      <div className='update-container'>
        <Form
          yupSchema={validationSchema}
          validateOn='change-blur'
          formApiRef={formRef}
          onBlur={() => {
            formRef?.current && formRef.current.submitForm();
          }}
          onChange={(formState) => {
            setFormData(cloneDeep(formState.values));
          }}
          className='form-wrapper'
        >
          <Dropdown
            options={options.phases}
            name='related_phase'
            label={getLabel('related_phase', ' Related Project Phase')}
          />
          <Dropdown
            options={options.infrastructures}
            name='related_infrastructure'
            label={getLabel('related_infrastructure', ' Related Project Infrastructure ')}
          />
          <div className='text-area'>
            <p className='counter'>{formData?.title ? formData?.title?.length : '0'} / 100</p>
            <TextArea
              data-gramm='false'
              maxLength={100}
              label={getLabel('title', 'Project Update "Title"*')}
              name='title'
            />
          </div>
          <div className='file-picker'>
            <label className={`field-label ${!filesList?.length ? 'error' : ''}`}>
              Pictures {!filesList?.length ? '- Required' : ''}
            </label>
            <Dragger {...props}>
              <p className='ant-upload-text heading title'>Upload pictures (max 5)</p>
              <div className='description-info'>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text title'>Drag and Drop or browse</p>
                <p className='ant-upload-text subtitle'>Supports PNG, JPEG, JPG</p>
              </div>
            </Dragger>
          </div>
          <div className='text-area large'>
            <p className='counter'>{formData?.details ? formData?.details?.length : '0'} / 260</p>
            <TextArea data-gramm='false' maxLength={260} label={getLabel('details', 'Update details')} name='details' />
          </div>
          <div className='checkbox'>
            <Checkbox label='Share update with client' name='is_shared' />
          </div>
        </Form>
        <div className='buttons-container'>
          <Button
            onClick={() => setIsEditMode(false)}
            icon={<ArrowLeftOutlined />}
            disabled={mutation.status === 'loading'}
            className='button cancel'
            text='Cancel and go back'
          />
          <Button
            disabled={!isApplyButtonActive || mutation.status === 'loading'}
            onClick={() => onSendClickHandler()}
            icon={<CheckOutlined />}
            className='button'
            text='Publish'
            variant='submit'
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateForm;
