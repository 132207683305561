import React, { FC, useState } from 'react';
import { ArrowRightOutlined, MessageFilled } from '@ant-design/icons';
import { ReactComponent as PinIcon } from '@assets/svg/PinIcon.svg';
import { Pie } from 'react-chartjs-2';
import MapPreview from '@digital-office/pages/ProjectsManagementPages/components/MapPreview';
import { useNavigate, useParams } from 'react-router-dom';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { ICountryOverview, ICountryOverviewResponse, IProjectData } from '@digital-office/common/interfaces';

import './styles.scss';

interface IHighlightChartProps {
  overviewData: ICountryOverviewResponse;
}

interface IChartLegendProps {
  label: string;
  icon?: string;
  color: string;
  key: number;
}

interface IHighlightProps {
  data: IProjectData;
  id: string;
}

const chartColors = {
  infrastructure: {
    background: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
    ],
    border: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
    ],
  },
  area: {
    background: [
      'rgba(152,43,86, 1)',
      'rgba(54,181,197, 1)',
      'rgba(25,136,104, 1)',
      'rgba(244,120,71, 1)',
      'rgba(244,120,71, 1)',
      'rgba(236,223,187, 1)',
      'rgba(218, 112, 214, 1)',
      'rgba(255, 99, 71, 1)',
      'rgba(0, 128, 128, 1)',
      'rgba(255, 192, 203, 1)',
      'rgba(0, 191, 255, 1)',
      'rgba(255, 215, 0, 1)',
      'rgba(75, 0, 130, 1)',
    ],
    border: [
      'rgba(152,43,86, 1)',
      'rgba(54,181,197, 1)',
      'rgba(25,136,104, 1)',
      'rgba(244,120,71, 1)',
      'rgba(244,120,71, 1)',
      'rgba(236,223,187, 1)',
      'rgba(218, 112, 214, 1)',
      'rgba(255, 99, 71, 1)',
      'rgba(0, 128, 128, 1)',
      'rgba(255, 192, 203, 1)',
      'rgba(0, 191, 255, 1)',
      'rgba(255, 215, 0, 1)',
      'rgba(75, 0, 130, 1)',
    ],
  },
};

const getChartData = (
  data: ICountryOverviewResponse['project_by_topic'] | ICountryOverviewResponse['infrastructure_type'],
  type: 'area' | 'infrastructure'
) => ({
  labels: data.map((element) => element.name),
  datasets: [
    {
      label: '',
      data: data.map((element) => element.percentage),
      backgroundColor: chartColors[type].background,
      borderColor: chartColors[type].border,
      borderWidth: 1,
    },
  ],
});

const ChartLegend: FC<IChartLegendProps> = ({ label, color, icon }) => {
  return (
    <div key={label} className='chart-legend-element'>
      <div className='indicator' style={{ backgroundColor: color }} />
      {icon && <img className='icon' src={icon} />}
      <span className='legend-label'>{label}</span>
    </div>
  );
};

const HighlightChart: FC<IHighlightChartProps> = ({ overviewData }) => {
  const [chartMode, setChartMode] = useState('infrastructure');

  return (
    <div className='highlight-element highlight-chart'>
      <div className='chart-control'>
        <div
          onClick={() => setChartMode('infrastructure')}
          className={`control-button ${chartMode === 'infrastructure' ? 'active' : ''}`}
        >
          Infrastructure type
        </div>
        <div className={`control-button ${chartMode === 'area' ? 'active' : ''}`} onClick={() => setChartMode('area')}>
          Area
        </div>
      </div>
      <div className='summary'>
        <p>Projects by infrastructure label</p>
        <div className='chart'>
          {overviewData && (
            <Pie
              options={{
                plugins: { tooltip: { callbacks: { label: (tooltipItem) => `${tooltipItem.formattedValue}%` } } },
              }}
              data={getChartData(
                chartMode === 'infrastructure' ? overviewData?.infrastructure_type : overviewData?.project_by_topic,
                chartMode === 'infrastructure' ? 'infrastructure' : 'area'
              )}
            />
          )}
        </div>
        <div className='chart-legend-wrapper'>
          {chartMode === 'infrastructure'
            ? overviewData?.infrastructure_type.map((infrastructure, key) => (
                <ChartLegend
                  key={key}
                  icon={infrastructure.icon}
                  label={infrastructure.name}
                  color={chartColors.infrastructure.background[key]}
                />
              ))
            : overviewData?.project_by_topic.map((topic, key) => (
                <ChartLegend key={key} label={topic.name} color={chartColors.area.background[key]} />
              ))}
        </div>
      </div>
    </div>
  );
};

const CountryOverviewPage = () => {
  const [previewHighlight, setPreviewHighlight] = useState<string | null>();
  const { id: uuid } = useParams<{ id: string }>();
  const { engineeringRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;
  const navigate = useNavigate();

  const { data: overviewData } = useQuery<ICountryOverviewResponse>(
    `country-page-overview-${uuid}`,
    () => engineeringRepository.getCountryOverviewPage(facilityId, uuid || ''),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { data: stories } = useQuery<ICountryOverview>(
    `country-overview-${overviewData?.country_iso_code3 || ''}`,
    () => engineeringRepository.getCountryOverview(overviewData?.country_iso_code3 || '', null),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: Boolean(overviewData),
    }
  );

  const { data: firstHighLightData } = useQuery<IProjectData>(
    `first-highlight-story-${overviewData?.story_highlight1 || ''}`,
    () => engineeringRepository.getProjectData(overviewData?.story_highlight1 || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(overviewData),
    }
  );

  const { data: secondHighlightData } = useQuery<IProjectData>(
    `second-highlight-story-${overviewData?.story_highlight2 || ''}`,
    () => engineeringRepository.getProjectData(overviewData?.story_highlight2 || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(overviewData && overviewData?.story_highlight2),
    }
  );

  const { data: previewHighlightData } = useQuery<IProjectData>(
    `second-highlight-story-${previewHighlight || ''}`,
    () => engineeringRepository.getProjectData(previewHighlight || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(previewHighlight),
    }
  );

  const Highlight: FC<IHighlightProps> = ({ data, id }) => {
    return (
      <div className='highlight-element preview'>
        <div
          className='cover'
          style={{
            backgroundImage: `url(${data.main_picture})`,
          }}
        />
        <div className='summary'>
          <p className='summary-title'>{data.title}</p>
          <div className='summary-description'>
            <PinIcon className='pin-icon' />
            <span>{data.city_name}</span>
          </div>
          <div className='summary-description'>
            <img alt='category-icon' className='category-icon' src={data.infrastructure_category_icon} />
            <span>{data.infrastructure_category_name}</span>
          </div>
          <p className='summary-text'>{data.snapshot}</p>
          {data.show_story_button && (
            <a
              onClick={() =>
                navigate(`/global-impact/${id}`, { state: { source: 'country-overview', country_overview_id: uuid } })
              }
              className='summary-story-link'
            >
              Read the full story <ArrowRightOutlined />
            </a>
          )}
        </div>
      </div>
    );
  };

  const mapStoriesToLocationPreview = () => {
    return stories?.stories.map((story) => ({
      description: story.title,
      id: story.uuid,
      icon: story.infrastructure_category_icon,
      locationData: {
        lat: story.city_lat,
        long: story.city_lng,
      },
    }));
  };

  const CustomMarker = (id: string) => {
    const story = stories?.stories.find((story) => story.uuid === id);

    return (
      <div className='work-marker'>
        <div className='circle'>
          <img alt='map category icon' className='map-category-icon' src={story?.infrastructure_category_icon} />
        </div>
        <div className='triangle' />
      </div>
    );
  };

  const onLocationClick = (id: string) => {
    setPreviewHighlight(id);
  };

  return (
    <div className='wrapper'>
      <div className='content'>
        <div className='overview-section'>
          <div className='text-section'>
            <div className='country-overview'>
              <p className='country-name'>{overviewData?.country}</p>
              <p className='agency-label'>WFP Engineering</p>
              <a href='https://unbooking.org/en/explore/?service=Engineering' className='summary-link'>
                Contact {overviewData?.country} Engineering team <MessageFilled />
              </a>
            </div>
            <div className='country-description'>
              <p className='description-label'>Overview</p>
              <p>{overviewData?.overview}</p>
            </div>
          </div>
          <img src={overviewData?.cover} className='project-picture' />
        </div>
        <div className='work-section'>
          <p className='work-label'>Our work in {overviewData?.country}</p>
          <div className='work-location'>
            {overviewData && <HighlightChart overviewData={overviewData} />}
            {previewHighlight && previewHighlightData ? (
              <Highlight data={previewHighlightData} id={previewHighlight} />
            ) : (
              <div className='map-preview-placeholder'>
                <p>Click on a project to find more information</p>
              </div>
            )}
            <div className='map-preview-container'>
              <MapPreview
                onLocationClick={onLocationClick}
                customMarker={CustomMarker}
                clusterPopoverLabel='Works in this location'
                locations={mapStoriesToLocationPreview() || []}
                initCoords={[]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryOverviewPage;
