import axios, { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import ICMSRepository from '../../interfaces/CMSRepository';

class CMSRepository extends AxiosRepository implements ICMSRepository {
  constructor(axiosClient: AxiosInstance) {
    const cmxAxios = axios.create({
      baseURL: process.env.REACT_APP_CMS_BASE_URL,
      headers: { ...axiosClient.defaults.headers.common },
    });

    cmxAxios.interceptors.request = axiosClient.interceptors.request;
    cmxAxios.interceptors.response = axiosClient.interceptors.response;

    super(cmxAxios);
  }

  async getNavigation() {
    const { data } = await this.get(`/pages/find/?html_path=header-menu`);
    return data;
  }

  async getPage(url: string) {
    const { data } = await this.get(`/pages/find/?html_path=${url}`);
    return data;
  }
}

export default CMSRepository;
