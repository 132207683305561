import React, { useState } from 'react';
import { PageTitle, Tabs } from '@ui-modules';

import MainInformationSection from '@digital-office/pages/ShowcaseEntryPage/components/MainInformationSection';

import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import {
  IProjectDetailsResponse,
  IStoryDetailsResponse,
  IStoryListResponse,
  ISwitchesResponse,
} from '@digital-office/common/interfaces';
import { IDropdownOption } from '@ui-modules/types';
import { message, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';
import './styles.scss';

const tabs = ['Main information'];

const ShowcaseEntryPage = () => {
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage.getItem('country');

  const { id: paramId } = useParams<{ id: string }>();

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [selectedProject, setSelectedProject] = useState<IDropdownOption | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(paramId === 'new');
  const { engineeringRepository } = useRepository();
  const navigate = useNavigate();

  const { isLoading } = useQuery<ISwitchesResponse>('waffle-switch', () => engineeringRepository.getSwitches(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const storyPublishingSwitch = data?.results?.find((siteSwitch) => siteSwitch.name === 'story_publishing');
      if (storyPublishingSwitch && !storyPublishingSwitch.active) {
        message.warning('Page is not available');
        navigate('/digital-office');
      }
    },
  });

  const { data: storyData, dataUpdatedAt: storyDataUpdatedAt } = useQuery<IStoryListResponse>(
    'story-loading',
    () => engineeringRepository.getStories(facilityId, null),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: storyDetails, refetch: refetchDetails } = useQuery<IStoryDetailsResponse>(
    `story-details-${paramId}`,
    () => engineeringRepository.getStoryDetails(facilityId, paramId || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(storyData && paramId && paramId !== 'new'),
      onSuccess: (data) => {
        const project = storyData?.options_for_create.projects.find((project) => project.label === data.project.code);
        project && setSelectedProject(project);
      },
    }
  );

  const { data: projectData, dataUpdatedAt: projectDataUpdatedAt } = useQuery<IProjectDetailsResponse>(
    `project-data-${selectedProject?.value}`,
    () => engineeringRepository.getProjectsDetails(facilityId, selectedProject?.value || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(selectedProject?.value),
    }
  );

  const sendStoryMutation = useMutation((data: any) => engineeringRepository.postStory(facilityId, data), {
    onSuccess: (data) => {
      setIsEditMode(false);
      navigate(`/digital-office/showcase/${data.uuid}`);
      message.success('Showcase has been created');
      !storyData?.options_for_create.has_publishing_permissions &&
        message.info(
          'Project overview will be sent for review to MSDE communication team, you will be notified once approved. It should be reviewed within 1 working week.',
          10
        );
    },
    onError: (error: any) => {
      const errorKeys = Object.keys(error.response.data);
      const result = errorKeys
        .map((errorKey) => {
          return `${errorKey}: ${error.response.data[errorKey]}`;
        })
        .join(', ');
      message.error(result);
    },
  });

  const updateStoryMutation = useMutation(
    (data: any) => engineeringRepository.updateStory(facilityId, paramId || '', data),
    {
      onSuccess: () => {
        message.success('Project overview updated');
        !storyData?.options_for_create.has_publishing_permissions &&
          message.info(
            'Project overview will be sent for review to MSDE communication team, you will be notified once approved. It should be reviewed within 1 working week.',
            10
          );
        refetchDetails();
        setIsEditMode(false);
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const approveStoryMutation = useMutation(() => engineeringRepository.approveStory(facilityId, paramId || ''), {
    onSuccess: () => {
      message.success('Story has been approved');
      refetchDetails();
      setIsEditMode(false);
    },
    onError: (error: any) => {
      const errorKeys = Object.keys(error.response.data);
      const result = errorKeys
        .map((errorKey) => {
          return `${errorKey}: ${error.response.data[errorKey]}`;
        })
        .join(', ');
      message.error(result);
    },
  });

  return (
    <div className='showcase-entry-wrapper'>
      <div className='showcase-video-link-wrapper'>
        <PageTitle title={`Showcase project on the Engineering Platform - ${country}`} />
        <VideoLink link={videoLinks.SHOWCASE} />
      </div>
      <div className='booking-list'>
        <Tabs
          className='engineering-tabs'
          tabs={tabs}
          selectedTab={tabs.indexOf(selectedTab)}
          onTabSelect={setSelectedTab}
        />
      </div>
      <Spin
        spinning={
          isLoading ||
          updateStoryMutation.status === 'loading' ||
          sendStoryMutation.status === 'loading' ||
          approveStoryMutation.status === 'loading'
        }
      >
        <div className='booking-details-container hbh-container'>
          {storyData && (
            <MainInformationSection
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              approveStoryMutation={approveStoryMutation}
              storyDetails={storyDetails}
              formKey={`${projectDataUpdatedAt} - ${storyDataUpdatedAt}`}
              projectResponseData={projectData}
              selectedProject={selectedProject}
              sendStoryMutation={paramId === 'new' ? sendStoryMutation : updateStoryMutation}
              setSelectedProject={setSelectedProject}
              storyOptions={storyData.options_for_create}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default ShowcaseEntryPage;
