import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ReactComponent as RequestListIcon } from '@assets/svg/requests_list.svg';
import { ReactComponent as ProjectListIcon } from '@assets/svg/projects_list.svg';
import { ReactComponent as RequestsIcon } from '@assets/svg/drafting-compass.svg';

import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { ICustomerAreaHomePage, ISwitchesResponse } from '@digital-office/common/interfaces';
import { message, Spin } from 'antd';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

const CustomerAreaPage = () => {
  const navigate = useNavigate();

  const { engineeringRepository } = useRepository();

  useQuery<ISwitchesResponse>('waffle-switch', () => engineeringRepository.getSwitches(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const storyPublishingSwitch = data?.results?.find((siteSwitch) => siteSwitch.name === 'customer_area');
      if (!storyPublishingSwitch?.active) {
        message.warning('Page is not available');
        navigate('/home');
      }
    },
  });

  const { data, isLoading } = useQuery<ICustomerAreaHomePage>(
    'custom-area-homepage',
    () => engineeringRepository.getCustomerAreaHomePageData(),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className='customer-area-wrapper'>
      <div className='area-video-link-wrapper'>
        <VideoLink link={videoLinks.CUSTOMER_AREA} />
      </div>
      <div className='content'>
        <div className='area-header'>
          <p className='customer-header'>Welcome to your Engineering self-service area</p>
          <p className='customer-subheader'>
            Your one-stop-shop to request Engineering services, track your requests and see the status of your ongoing
            projects.
          </p>
        </div>
        <div className='navigation-wrapper'>
          <div onClick={() => navigate('/contact-us')} className='navigation-element'>
            <RequestsIcon className='element-icon' />
            <p className='title'>Request a service</p>
            <p className='subtitle'>Submit a request for engineering services</p>
            <ArrowRightOutlined className='navigation-icon' />
          </div>
          <div onClick={() => navigate('/customer-requests')} className='navigation-element'>
            <RequestListIcon className='element-icon' />
            <p className='title'>My requests</p>
            <p className='subtitle'>Manage and track your service requests</p>
            <Spin spinning={isLoading}>
              <div className='progress-stats'>
                <p className='badge blue'>{data?.service_requests_in_progress} in progress</p>
              </div>
            </Spin>
            <ArrowRightOutlined className='navigation-icon' />
          </div>
          <div onClick={() => navigate('/customer-projects')} className='navigation-element'>
            <ProjectListIcon className='element-icon' />
            <p className='title'>Projects overview and updates</p>
            <p className='subtitle'>Stay informed about ongoing projects</p>
            <Spin spinning={isLoading}>
              <div className='progress-stats'>
                <p className='badge blue'>{data?.projects_in_progress} in progress</p>
              </div>
            </Spin>
            <ArrowRightOutlined className='navigation-icon' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerAreaPage;
