import React, { FC, useEffect } from 'react';
import ErrorPage from '@components/ErrorPage';

interface IProps {
  hideNavHandler: React.Dispatch<React.SetStateAction<boolean>>;
}

const buttons = [
  {
    label: 'GO TO HOMEPAGE',
    onClickHandler: () => (window.location.href = String('/')),
  },
  {
    label: 'CONTACT US',
    onClickHandler: () => (window.location.href = String('mailto:noreply.humanitarianbooking@wfp.org')),
  },
];

const ForbiddenPage: FC<IProps> = ({ hideNavHandler }) => {
  useEffect(() => {
    hideNavHandler(true);
  }, []);

  return (
    <ErrorPage
      title="Sorry, you don't have permission to access this section at this time."
      subtitle='You may want to head back to homepage. If you think something is broken, contact us.'
      buttons={buttons}
    />
  );
};

export default ForbiddenPage;
