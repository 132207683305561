import React, { useRef, useState } from 'react';
import { Drawer } from 'antd';
import ReactMapGl, {
  Source,
  Layer,
  MapboxGeoJSONFeature,
  MapRef,
  NavigationControl,
  MapLayerMouseEvent,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './styles.scss';
import turf from '@turf/centroid';
import {
  MAPBOX_API_KEY,
  disableMapboxHandlers,
  enableMapboxHandlers,
  highlightedCountriesBorderLayer,
  highlightedCountriesLayer,
  mapboxStyle,
  onClickHandler,
  onMouseHandler,
} from '@components/GlobalImpact/utils';
import { useNavigate } from 'react-router-dom';
import { Feature, ICountryProperties, IFacilitiesListResponse, IUser } from '@digital-office/common/interfaces';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types/common';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { find } from 'lodash';
import { MapMouseEvent } from 'mapbox-gl';
import { exceptionalBoundaries } from '@components/GlobalImpact/constants';
import { ArrowRightOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ReactComponent as ArrowIcon } from '@assets/svg/icon_arrow_round.svg';
import { ReactComponent as WorldIcon } from '@assets/svg/world.svg';
import { Button, Dropdown } from '@ui-modules';

const RequestServicesPage = () => {
  const [selectedCountry, setSelectedCountry] = useState<ICountryProperties | null>(null);
  const [isMenuHidden, setIsMenuHidden] = useState(false);
  const [hoveredCountry, setHoveredCountry] = useState<number | null>(null);
  const [countryDropdownValue, setCountryDropdownValue] = useState<IDropdownOption | null>(null);
  const [features, setFeatures] = useState<Feature[]>([]);
  const navigate = useNavigate();

  const mapRef = useRef(null);

  const countryDropdownHandler = (value: IDropdownOption) => {
    setCountryDropdownValue(value);
  };

  const { engineeringRepository } = useRepository();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: geoJson } = useQuery(`geo-json`, () => engineeringRepository.getGeoJSON(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const features = data.features.map((feature: MapboxGeoJSONFeature, key: number) => ({
        ...feature,
        id: key,
      }));

      setFeatures(features);
    },
  });

  const { data: userDetails } = useQuery<IUser>(['user-details'], () => engineeringRepository.getUserDetails(), {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const { data: facilities } = useQuery<IFacilitiesListResponse>(
    `facilities-list`,
    () => engineeringRepository.getFacilities(userDetails?.organisation || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(userDetails),
    }
  );

  const countriesListDropdown = facilities
    ? facilities.results.map((country) => {
        const feature = find(geoJson?.features, (feature) => feature.id === country.iso_code3);
        return { label: feature?.properties.name, value: country.iso_code3 };
      })
    : [];

  const onMouseLeaveHandler = (event: MapMouseEvent) => {
    hoveredCountry && event.target.removeFeatureState({ source: 'grouped-countries', id: hoveredCountry }, 'select');
    setHoveredCountry(null);
  };

  const onCountryDropdownClick = () => {
    if (!countryDropdownValue) return false;

    // @ts-ignore
    const map = mapRef?.current.getMap();
    const feature = find(
      filteredCountries.features,
      (mbFeature) => mbFeature.properties.id === countryDropdownValue?.value
    );

    if (!feature?.geometry) return false;
    const centroid = turf(feature.geometry).geometry.coordinates;

    map.setFeatureState({ source: 'grouped-countries', id: feature?.id }, { select: true });

    map.flyTo({
      zoom: 4,
      speed: 0.45,
      center: exceptionalBoundaries[feature.properties.id] || centroid,
      pitch: 25,
    });

    disableMapboxHandlers(mapRef.current as unknown as MapRef);

    setSelectedCountry(feature?.properties);
  };

  const filteredCountries = {
    type: 'FeatureCollection',
    features:
      (features &&
        features.filter((feature: Feature) =>
          facilities?.results.find(({ iso_code3 }) => iso_code3 === feature?.properties?.id)
        )) ||
      [],
  };

  const [viewport, setViewport] = useState({
    zoom: 2.6,
    longitude: 20.68674593817775,
    latitude: 7.7439240888921,
  });

  const onMapClickHandler = (e: MapLayerMouseEvent) => {
    if (!selectedCountry) {
      onClickHandler(e, filteredCountries as Feature, mapRef.current as unknown as MapRef, setSelectedCountry);
    } else {
      setSelectedCountry(null);
      enableMapboxHandlers(mapRef.current as unknown as MapRef);
    }
  };

  const facilityData =
    selectedCountry && facilities
      ? facilities?.results.find(({ iso_code3 }) => iso_code3 === selectedCountry.id)
      : null;

  return (
    <div className='map-container'>
      <div className={`map-menu custom-map-menu ${isMenuHidden && 'hidden'}`}>
        <div className='toggle-menu' onClick={() => setIsMenuHidden(!isMenuHidden)}>
          <p className='menu-label'>{isMenuHidden ? 'SHOW' : 'HIDE'}</p>
          <ArrowIcon className={`toggle-icon ${isMenuHidden ? 'right' : 'left'}`} />
        </div>
        <div className='title-container'>
          <div className='menu-title-wrapper'>
            <WorldIcon className='title-icon' />
            <p className='menu-title'>Request engineering services</p>
          </div>
          <p className='menu-description'>
            <div className='menu-description-par'>Submit a request to your country&apos;s Engineering team.</div>
            Our engineering work bridges humanitarian and development activities to enable operations, build
            communities&apos; resilience, and change beneficiaries&apos; lives.
          </p>
        </div>
        <div className='country-selection'>
          <div className='control'>
            <Dropdown
              placeholder='Select country'
              containerClassName='dropdown'
              onChange={countryDropdownHandler}
              isSearchable={false}
              options={countriesListDropdown}
            />
            <Button onClick={onCountryDropdownClick} className='menu-button' text='Go to country' />
          </div>
          <hr className='line' />
          <button className='contact-button' onClick={() => navigate(`/request-services/it/7190`)}>
            Country not active? Contact the global Engineering team
          </button>
        </div>
      </div>
      <ReactMapGl
        attributionControl={false}
        initialViewState={viewport}
        style={{ height: '91vh', width: '100%' }}
        mapStyle={mapboxStyle}
        minZoom={2}
        maxZoom={6}
        mapboxAccessToken={MAPBOX_API_KEY}
        doubleClickZoom={false}
        onMouseMove={(e) => onMouseHandler(e, selectedCountry, hoveredCountry, setHoveredCountry)}
        onMouseLeave={(event) => !selectedCountry && onMouseLeaveHandler(event)}
        onClick={onMapClickHandler}
        interactiveLayerIds={['geojson-layer-borders', 'grouped-geojson-layer-fill']}
        onMove={(evt) => {
          setViewport(evt.viewState);
        }}
        ref={mapRef}
      >
        <NavigationControl />
        <Drawer
          getContainer={false}
          className='drawer'
          visible={Boolean(selectedCountry)}
          title={<p className='drawer-title'>{selectedCountry && selectedCountry?.name}</p>}
          closeIcon={<CloseCircleOutlined className='close-icon' />}
          style={{ height: '100%', overflow: 'auto' }}
          placement='right'
          onClose={() => {
            setSelectedCountry(null);
            enableMapboxHandlers(mapRef.current as unknown as MapRef);
          }}
          headerStyle={{
            backgroundColor: '#2c3235',
            height: 60,
            borderBottom: 'none',
          }}
          bodyStyle={{ backgroundColor: '#232D34', padding: 0 }}
          mask={false}
        >
          {facilityData ? (
            <div className='country-summary-configured'>
              <div className='drawer-facility-label'>{facilityData.name}</div>
              <div className='drawer-facility-note'>
                <b>Contact the {facilityData.country_name} Engineering team.</b>
                <br />
                To ensure prompt action on your request, you will be asked to provide information on type of
                infrastructure, location(s), and a short description of the envisaged project.
                <br />
                You can also attach documents and pictures.
                <br />
                Click on Request service to start!
                <br />
              </div>
              <div
                onClick={() =>
                  navigate(
                    `/request-services/${facilityData.iso_code.toLocaleLowerCase()}/${facilityData.facility_id_on_hbh}`
                  )
                }
                className='navigation-bar'
              >
                <p>Request service</p>
                <ArrowRightOutlined />
              </div>
            </div>
          ) : null}
        </Drawer>
        <Source id='grouped-countries' type='geojson' data={filteredCountries as unknown as MapboxGeoJSONFeature}>
          <Layer {...highlightedCountriesLayer} />
          <Layer {...highlightedCountriesBorderLayer} />
        </Source>
      </ReactMapGl>
    </div>
  );
};

export default RequestServicesPage;
