import { Radar } from 'react-chartjs-2';
import React, { FC } from 'react';
import { Chart as ChartJS, Filler, LineElement, PointElement, RadialLinearScale, Tooltip } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip);

import './styles.scss';

interface IProps {
  dataSets: IRegisterRiskChartDataset[];
  labels: string[];
}

interface IRegisterRiskChartDataset {
  label: string;
  data: Array<number | null>;
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

const options = {
  plugins: {
    legend: {
      display: true,
    },
  },
  maintainAspectRatio: false,
  scales: {
    r: {
      suggestedMin: 0,
      grid: {
        color: 'gray', // Change the grid line color
        circular: true,
      },
      pointLabels: {
        color: '#fff',
        padding: 10,
        font: {
          size: 14,
          weight: 'bold',
        },
      },
      ticks: {
        display: false,
        stepSize: 1,
      },
    },
  },
};

const RiskRadarChart: FC<IProps> = ({ labels, dataSets }) => {
  return (
    <div className='radar-chart-wrapper'>
      <div>
        <Radar options={options} height={400} width={500} data={{ labels, datasets: dataSets }} />
      </div>
      <div className='radar-chart-legend'>
        {dataSets.map((dataSet: IRegisterRiskChartDataset) => (
          <div key={dataSet.label} className='legend-element'>
            <span style={{ backgroundColor: dataSet?.backgroundColor }} className='indicator' />
            <span>{dataSet?.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RiskRadarChart;
