import React, { FC } from 'react';
import { useField } from 'informed';
import { NumericFormat } from 'react-number-format';
import './styles.scss';

interface IProps {
  name: string;
  disabled?: boolean;
  suffix?: string;
  percentage?: boolean;
  customValue?: string | number;
  decimalScale?: number;
  thousandSeparator?: boolean;
}

const InformedNumericInput: FC<IProps> = ({
  disabled = false,
  suffix = ' USD',
  name,
  percentage,
  customValue,
  decimalScale,
  thousandSeparator = true,
  ...props
}) => {
  const { render, fieldState, fieldApi } = useField({ name, ...props });
  const { error, value } = fieldState;
  const { setValue, validate } = fieldApi;

  const defaultValue = customValue ? parseInt(customValue as string) : parseInt(value as string);

  return render(
    <>
      <NumericFormat
        className='numeric-input'
        suffix={suffix}
        value={defaultValue}
        thousandSeparator={thousandSeparator}
        decimalSeparator={'.'}
        disabled={disabled}
        allowNegative={false}
        decimalScale={decimalScale === undefined ? 2 : decimalScale}
        maxLength={15}
        isAllowed={(values) => {
          if (percentage) {
            const { floatValue, formattedValue } = values;
            return formattedValue === '' || (floatValue !== undefined ? floatValue >= 0 && floatValue <= 100 : false);
          }
          return true;
        }}
        onChange={(e) => null}
        onValueChange={({ value }) => {
          setValue(value);
          validate();
        }}
      />
      {error && <p className='hbh-select-error'>{String(error)}</p>}
    </>
  );
};

export default InformedNumericInput;
