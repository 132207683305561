import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { Button } from '@ui-modules';
import {
  GanttTooltip,
  getGanttHandlers,
  parseTasksToGantt,
} from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectPlan/utils';
import { IProjectPlan } from '@digital-office/common/interfaces';
import './styles.scss';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  plan: IProjectPlan;
}

const ProjectPlan: FC<IProps> = ({ isModalOpen, setIsModalOpen, plan }) => {
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [view, setView] = React.useState<ViewMode>(ViewMode.Week);
  const [isTasksNameShowed, setIsTasksNameShowed] = useState<boolean>(true);

  useEffect(() => {
    plan && setTasks(parseTasksToGantt(plan));
  }, [plan]);

  const { getColumnWidth, handleExpanderClick, handleProgressChange, handleTaskChange, handleTaskDelete } =
    getGanttHandlers(tasks, setTasks);

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      title={
        <div className={'modal-title'}>
          <p>PROJECT PLAN</p>
        </div>
      }
      onCancel={() => setIsModalOpen(false)}
      width={'95%'}
    >
      <div className='project-plan-wrapper'>
        <div className='gantt-control-buttons'>
          <Button
            onClick={() => setIsTasksNameShowed(!isTasksNameShowed)}
            className={`control-button name-toggle ${isTasksNameShowed && 'active'}`}
            text="Show task's name"
          />
          <Button
            onClick={() => setView(ViewMode.Week)}
            className={`control-button ${view === ViewMode.Week && 'active'}`}
            text='Week'
          />
          <Button
            onClick={() => setView(ViewMode.Month)}
            className={`control-button ${view === ViewMode.Month && 'active'}`}
            text='Month'
          />
          <Button
            onClick={() => setView(ViewMode.Year)}
            className={`control-button ${view === ViewMode.Year && 'active'}`}
            text='Year'
          />
        </div>
        <Gantt
          onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          columnWidth={getColumnWidth(view)}
          viewMode={view}
          fontFamily={'Open-Sans'}
          listCellWidth={isTasksNameShowed ? '155px' : ''}
          TooltipContent={({ task }) => <GanttTooltip task={task} />}
          onExpanderClick={handleExpanderClick}
          tasks={tasks}
        />
      </div>
    </Modal>
  );
};

export default ProjectPlan;
