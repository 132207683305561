import React from 'react';
import { PhoneFilled } from '@ant-design/icons';
import { ReactComponent as EmailIcon } from '@assets/svg/email.svg';
import { IDropdownOption } from '@ui-modules/types';
import ProgressBar from '@digital-office/common/components/ProgressBar';

export default (code: string, type: string, isEditMode?: boolean | undefined) => [
  {
    key: 'code',
    label: 'PROJECT CODE',
    type: 'input',
    prefix: code && type !== 'edit' ? code : '',
    disabled: type === 'edit',
    required: true,
    tooltip:
      'Project code must always be on the following structure COUNTRY CODE-City/Location-number of projects on that location, for example -> AFGKBL001',
  },
  ...(!isEditMode && type === 'edit'
    ? [
        {
          key: 'overall_completion',
          label: 'Project completion %',
          displayFormatter: (value: number) => <ProgressBar key='overall_completion' value={value} />,
          type: 'none',
          tooltip: 'This value is automatically calculated, based on the completion of deliverables',
          showTooltipOnDetails: true,
          hideValueTooltip: true,
          required: false,
        },
      ]
    : []),
  {
    key: 'client',
    label: 'PROJECT CLIENT UNIT / ORGANIZATION',
    type: 'input',
    required: true,
  },
  {
    key: 'contact_person',
    label: 'CLIENT FOCAL POINT',
    type: 'input',
    required: true,
  },
  {
    key: 'contact_phone_number',
    label: 'CLIENT FOCAL POINT PHONE',
    type: 'input',
    required: true,
    icon: <PhoneFilled />,
  },
  {
    key: 'contact_email',
    label: 'CLIENT FOCAL POINT EMAIL',
    type: 'input',
    required: true,
    icon: <EmailIcon />,
  },
  {
    key: 'topics',
    label: 'PROJECT TOPICS',
    type: 'dropdown',
    multiple: true,
    required: true,
    displayFormatter: (values: IDropdownOption[]) => (
      <p>{values ? values.map((value, index) => `${value.label}${index !== values.length - 1 ? ',' : ''} `) : '-'}</p>
    ),
  },
  {
    key: 'partners_involved',
    label: 'PARTNERS INVOLVED',
    type: 'input',
    className: 'grid-half-column',
  },
  {
    key: 'test',
    label: 'PROJECT DESCRIPTION',
    type: 'section',
    className: 'grid-full-column',
    children: [
      {
        key: 'title',
        label: `TITLE/SHORT DESCRIPTION`,
        type: 'textarea',
        required: true,
        hideValueTooltip: true,
        tooltip: 'Provide key information about the project',
        max: 120,
      },
      {
        key: 'description',
        label: `PROJECT INFORMATION`,
        type: 'textarea',
        componentClassName: 'text-area-large',
        required: true,
        hideValueTooltip: true,
        tooltip: 'Provide information about the project goals and context',
        max: 500,
      },
    ],
  },
  ...(!isEditMode && type !== 'conversion'
    ? [
        {
          key: 'estimated_budget',
          label: 'PROJECT OVERALL BUDGET (USD)',
          displayFormatter: (value: number) =>
            Number(value.toFixed(2)).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            }),
          type: 'numberInput',
          disabled: true,
        },
      ]
    : []),
  {
    key: 'engineering_project_manager',
    label: 'ENGINEERING PROJECT MANAGER',
    type: 'dropdown',
    displayFormatter: (value: IDropdownOption) => <p>{value.label}</p>,
    required: true,
  },
];
