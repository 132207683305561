import { Button, Dropdown } from '@ui-modules';
import React, { FC, useEffect, useRef, useState } from 'react';
import { IDropdownOption } from '@ui-modules/types';
import { find, isNumber } from 'lodash';
import { Checkbox, Input, message, Modal } from 'antd';
import { DeliverableItem } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection';
import './styles.scss';
import Select from 'react-select/base';

interface IProps {
  onNewDeliverable: (data: DeliverableItem) => void;
  newDeliverableIndex?: number;
  setNewDeliverableIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  deliverableTemplates: IDropdownOption[];
}

const AddDeliverable: FC<IProps> = ({
  onNewDeliverable,
  setNewDeliverableIndex,
  newDeliverableIndex,
  deliverableTemplates = [],
}) => {
  const [dropdownValue, setDropdownValue] = useState<IDropdownOption | null>(null);
  const [newTemplateValue, setNewTemplateValue] = useState<string>('');
  const [addNewTemplate, setAddNewTemplate] = useState<boolean>(false);
  const selectRef = useRef<Select>(null);

  useEffect(() => {
    if (addNewTemplate && dropdownValue?.value !== 'new_template') {
      setAddNewTemplate(false);
    }
  }, [dropdownValue]);

  const newTemplateCallback = () => {
    selectRef.current && selectRef.current.selectOption({ label: 'Add new deliverable', value: 'new_deliverable' });
  };

  return (
    <Modal
      footer={null}
      title={
        <div className={'modal-title'}>
          <p>ADD DELIVERABLE</p>
        </div>
      }
      width={'40%'}
      data-testid='add-new-deliverable-modal'
      destroyOnClose={true}
      open={Boolean(isNumber(newDeliverableIndex))}
      onCancel={() => {
        setNewDeliverableIndex(undefined);
        setDropdownValue(null);
        setNewTemplateValue('');
        setAddNewTemplate(false);
      }}
    >
      <div className='deliverable-modal'>
        <p>Select from the list of saved deliverables or add a new deliverable</p>
        <Dropdown
          placeholder='Select...'
          className='dropdown'
          ref={selectRef}
          noOptionsMessage={() => <p onClick={newTemplateCallback}>Click to add new deliverable</p>}
          value={dropdownValue}
          onChange={setDropdownValue}
          options={[...deliverableTemplates, { label: 'Add a new deliverable', value: 'new_deliverable' }]}
        />
        {dropdownValue?.value === 'new_deliverable' && (
          <div>
            <Input
              data-testid='add-new-deliverable-input'
              onChange={(e) => setNewTemplateValue(e.target.value)}
              placeholder='Insert...'
            />
            <div className='add-new-deliverable-checkbox'>
              <Checkbox
                data-testid='add-new-deliverable-checkbox'
                onChange={(e) => setAddNewTemplate(e.target.checked)}
                checked={addNewTemplate}
              >
                <p className='checkbox-label'>Save this deliverable for future use</p>
              </Checkbox>
            </div>
          </div>
        )}
        <Button
          disabled={Boolean(!dropdownValue || (dropdownValue?.value === 'new_template' && !newTemplateValue))}
          className='button'
          onClick={() => {
            if (addNewTemplate && find(deliverableTemplates, { value: newTemplateValue })) {
              return message.warning('Template already exists');
            }

            onNewDeliverable({
              name: newTemplateValue || (dropdownValue?.value as string),
              is_template: addNewTemplate,
              finished: false,
            });

            setDropdownValue(null);
          }}
          text='CONFIRM'
        />
      </div>
    </Modal>
  );
};

export default AddDeliverable;
