/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, createContext } from 'react';

const initialState = {};

const GlobalContext = createContext(initialState);

const GlobalProvider: FC<any> = ({ children }) => {
  return <GlobalContext.Provider value={initialState}>{children}</GlobalContext.Provider>;
};

export { GlobalProvider };
