import React, { FC, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { Form, FormApi } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import { Dropdown, TextField } from '@ui-modules/informed';
import { UseMutationResult } from 'react-query';
import AssessmentOverviewSummary from './components/AssessmentOverviewSummary';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import AssessmentLocationSummary from './components/AssessmentLocationSummary';
import AssessmentChecklistSummary from './components/AssessmentChecklistSummary';
import AssessmentMissionOutcomeSummary from './components/AssessmentMissionOutcomeSummary';
import AssessmentProgressSummary from './components/AssessmentProgressSummary';
import { ReactComponent as LocationIcon } from '@assets/svg/location.svg';
import { ReactComponent as ChecklistIcon } from '@assets/svg/checklist.svg';
import { ReactComponent as OverviewIcon } from '@assets/svg/overview.svg';
import { ReactComponent as OutcomeIcon } from '@assets/svg/outcome.svg';
import { ReactComponent as CostIcon } from '@assets/svg/cost.svg';
import { Button } from '@ui-modules';
import { find, isEmpty } from 'lodash';
import * as Yup from 'yup';
import { IDropdownOption } from '@ui-modules/types';
import { IAssessmentProjectOverview, IDraftData } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface IAssessmentPublishStep {
  initialValues: IDraftData | null;
  updateMutation: UseMutationResult<any, unknown, any, unknown>;
  finalizeStepMutation: UseMutationResult<any, unknown, any, unknown>;
  formKey: number;
  onStepBackButton: () => void;
  onStepClick: (step: number) => void;
  focalPointOptions: IDropdownOption[];
  isFormLocked: boolean;
}

const validationSchemaSubmit = Yup.object().shape({
  reviewers_emails: Yup.array().min(1, 'At least 1 email should be added').required('This field is required'),
  client_focal_point_email: Yup.string().required('This field is required').email('Incorrect email'),
});

const validationSchemaDraft = Yup.object().shape({
  client_focal_point_email: Yup.string().email('Incorrect email'),
});

interface IAssessmentReviewAndPublishFormData {
  reviewers_emails: IDropdownOption[];
  client_focal_point_email: string;
}

const PublishStep: FC<IAssessmentPublishStep> = ({
  initialValues,
  onStepBackButton,
  finalizeStepMutation,
  updateMutation,
  formKey,
  onStepClick,
  focalPointOptions,
  isFormLocked,
}) => {
  const formRef = useRef<FormApi>(null);
  const [, updateState] = useState<any>(null);
  const [updateType, setUpdateType] = useState<string | undefined>();

  useEffect(() => {
    if (updateType) {
      formRef?.current?.submitForm();
    }
  }, [updateType]);

  const onSubmitButtonClick = (type: string) => {
    if (type === updateType) {
      formRef?.current?.submitForm();
    } else {
      setUpdateType(type);
    }
  };

  const parseFormDataToPayload = (values: IAssessmentReviewAndPublishFormData) => {
    const payload = {
      reviewers_emails: values?.reviewers_emails?.map((email: IDropdownOption) => email.value),
      client_focal_point_email: values?.client_focal_point_email || '',
    };

    if (updateType === 'draft') {
      updateMutation.mutate({ current_step: 5, review_and_publish: payload });
    } else {
      const submitPayload = {
        ...initialValues,
        project_overview: {
          ...initialValues?.project_overview,
          project: initialValues?.project_overview?.project?.value,
          service_request: initialValues?.project_overview?.service_request?.value,
        },
        review_and_publish: payload,
      };
      finalizeStepMutation.mutate({ ...submitPayload, current_step: 5 });
    }
  };

  const parseInitialValuesToFormData = () => {
    return {
      reviewers_emails: initialValues?.review_and_publish?.reviewers_emails?.map((email: string) =>
        find(focalPointOptions, { value: email })
      ),
      client_focal_point_email: initialValues?.review_and_publish?.client_focal_point_email,
    };
  };

  const onStepBackClick = () => {
    const modifiedField = formRef?.current?.getFormState().modified;
    if (isEmpty(modifiedField)) {
      onStepBackButton();
    } else {
      const confirmAlert = confirm(
        'You have unsaved changes. Saves will not be saved. Are you sure you want to leave?'
      );

      if (confirmAlert) {
        onStepBackButton();
      }
    }
  };

  return (
    <div className='assessment-publish-step-container'>
      <div className='assessment-step-section'>
        <div className='section-header'>
          <div className='section-title'>
            <OverviewIcon />
            <p>Project overview</p>
          </div>
          {!isFormLocked ? (
            <div onClick={() => onStepClick(0)} className='section-edit-button'>
              <p>Edit</p>
              <EditOutlined />
            </div>
          ) : null}
        </div>
        {initialValues?.project_overview ? (
          <AssessmentOverviewSummary values={initialValues.project_overview as IAssessmentProjectOverview} />
        ) : null}
      </div>
      <div className='assessment-step-section'>
        <div className='section-header'>
          <div className='section-title'>
            <LocationIcon />
            <p>Project location</p>
          </div>
          {!isFormLocked ? (
            <div onClick={() => onStepClick(1)} className='section-edit-button'>
              <p>Edit</p>
              <EditOutlined />
            </div>
          ) : null}
        </div>
        {initialValues?.project_location?.map((location, key: number) => (
          <AssessmentLocationSummary isDefaultOpen={key === 0} index={key + 1} key={key} values={location} />
        ))}
      </div>
      <div className='assessment-step-section'>
        <div className='section-header'>
          <div className='section-title'>
            <ChecklistIcon />
            <p>Assessment checklist</p>
          </div>
          {!isFormLocked ? (
            <div onClick={() => onStepClick(2)} className='section-edit-button'>
              <p>Edit</p>
              <EditOutlined />
            </div>
          ) : null}
        </div>
        {initialValues?.assessment_checklist ? (
          <AssessmentChecklistSummary values={initialValues.assessment_checklist} />
        ) : null}
      </div>
      <div className='assessment-step-section'>
        <div className='section-header'>
          <div className='section-title'>
            <OutcomeIcon />
            <p>Mission outcomes and recommendations</p>
          </div>
          {!isFormLocked ? (
            <div onClick={() => onStepClick(3)} className='section-edit-button'>
              <p>Edit</p>
              <EditOutlined />
            </div>
          ) : null}
        </div>
        {initialValues?.mission_outcomes_and_recommendations ? (
          <AssessmentMissionOutcomeSummary values={initialValues.mission_outcomes_and_recommendations} />
        ) : null}
      </div>
      <div className='assessment-step-section'>
        <div className='section-header'>
          <div className='section-title'>
            <CostIcon />
            <p>Progress, estimated cost, and conclusion</p>
          </div>
          {!isFormLocked ? (
            <div onClick={() => onStepClick(4)} className='section-edit-button'>
              <p>Edit</p>
              <EditOutlined />
            </div>
          ) : null}
        </div>
        {initialValues?.progress_estimated_cost_and_conclusion ? (
          <AssessmentProgressSummary values={initialValues.progress_estimated_cost_and_conclusion} />
        ) : null}
      </div>
      {!isFormLocked ? (
        <Form
          formApiRef={formRef}
          key={`$${formKey}`}
          onSubmit={({ values }) => parseFormDataToPayload(values as any)}
          validateOn='change'
          initialValues={parseInitialValuesToFormData()}
          yupSchema={updateType === 'draft' ? validationSchemaDraft : validationSchemaSubmit}
          onSubmitFailure={() => updateState({})}
        >
          <div className='submission-header'>
            <p className='submission-title'>Submission information</p>
          </div>
          <InformedField
            label='Reviewers email address'
            name='reviewers_emails'
            formRefApi={formRef}
            informedComponent={<Dropdown options={focalPointOptions || []} isMulti={true} name='reviewers_emails' />}
          />
          <InformedField
            label='Client focal point email'
            description='This is the email address where we will send the assessment PDF once approved'
            name='client_focal_point_email'
            formRefApi={formRef}
            informedComponent={<TextField type='email' name='client_focal_point_email' />}
          />
          <div className='buttons-container'>
            <Button
              onClick={() => onStepBackClick()}
              variant='transparent'
              className='button back'
              text='Back'
              icon={<ArrowLeftOutlined />}
            />
            <Button
              className='button'
              variant='transparent'
              onClick={() => onSubmitButtonClick('draft')}
              text='Save as draft'
            />
            <Button className='button submit' onClick={() => onSubmitButtonClick('submit')} text='Submit for review' />
          </div>
        </Form>
      ) : null}
    </div>
  );
};

export default PublishStep;
