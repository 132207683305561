import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from '@common/auth';
import { authConfig, axios } from '@common/utils';
import { BrowserRouter as Router } from 'react-router-dom';
import '../node_modules/@unbooking/ui-kit/style.css';
import './assets/styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider authConfig={authConfig} axiosInstance={axios}>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
