import React, { FC } from 'react';
import { useFormApi, useMultistepApi } from 'informed';
import './styles.scss';

interface InformedFieldProps {
  children: JSX.Element | Array<JSX.Element>;
  description?: string;
  label: string;
}

const InformedField: FC<InformedFieldProps> = ({ children, description, label }) => {
  const { getError } = useFormApi();
  const { getCurrentStep } = useMultistepApi();

  const step = getCurrentStep();
  const name = Array.isArray(children) ? children[0].props?.name : children?.props?.name;
  const error = getError(step)?.[name as unknown as never];

  return (
    <div className='bf-field'>
      <div className='bf-field-label'>{label}</div>
      {description ? <div className='bf-field-description'>{description}</div> : null}
      {children}
      {error ? <div className='bf-field-error'>{error}</div> : null}
    </div>
  );
};

export default InformedField;
