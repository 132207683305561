import React, { useState } from 'react';

import './styles.scss';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import { IRiskRegistryResponse } from '@digital-office/common/interfaces';
import RiskRadarChart from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectRisk/components/RiskRadarChart';
import { DoughnutChart } from '@digital-office/common/components';
import { getChartProps } from '@digital-office/common/components/Dashboard/DoughnutChart';
import { Button, Table } from '@ui-modules';
import { useParams } from 'react-router-dom';
import { message, Modal, Spin } from 'antd';
import { capitalize } from 'lodash';
import { EditOutlined } from '@ant-design/icons';
import RiskForm from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectRisk/components/RiskForm';
import FileSaver from 'file-saver';
import { format } from 'date-fns';

const riskCategoriesChartColors = ['#006B81', '#008D8D', '#00C6B4'];
const riskEffectChartColors = [
  {
    backgroundColor: 'rgba(31, 166, 76, 0.8)',
    borderColor: 'rgb(31, 166, 76)',
  },
  {
    backgroundColor: 'rgba(47, 213, 193, 0.8)',
    borderColor: 'rgb(47, 213, 193)',
  },
  {
    backgroundColor: 'rgba(128,0,0, 0.8)',
    borderColor: 'rgb(128,0,0)',
  },
  {
    backgroundColor: 'rgba(255,165,0, 0.8)',
    borderColor: 'rgb(255,165,0)',
  },
  {
    backgroundColor: 'rgba(0,0,128, 0.8)',
    borderColor: 'rgb(0,0,128)',
  },
];

const columns = [
  {
    dataIndex: 'order_number',
    key: 'order_number',
    title: '#',
  },
  {
    dataIndex: 'risk_category',
    key: 'risk_category',
    title: 'Risk category',
  },
  {
    dataIndex: 'management_results_dimension',
    key: 'management_results_dimension',
    title: 'Area',
  },
  {
    dataIndex: 'activity',
    key: 'activity',
    title: 'Activity',
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: 'Description',
  },
];

const ProjectRisk = () => {
  const { engineeringRepository } = useRepository();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRisk, setSelectedRisk] = useState<string | null>(null);
  const { id: uuid } = useParams<{ id: string }>();
  const facilityId = +localStorage.getItem('facility')!;

  const {
    data: riskData,
    isLoading,
    refetch,
  } = useQuery<IRiskRegistryResponse>(
    `risk-registry-${uuid}`,
    () => engineeringRepository.getProjectRiskData(facilityId, uuid || ''),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: details, dataUpdatedAt } = useQuery<any>(
    `risk-details-${selectedRisk}`,
    () => engineeringRepository.getProjectRiskDetails(facilityId, uuid || '', selectedRisk || ''),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        message.error('Risk details cannot be fetched');
        setSelectedRisk(null);
      },
      retry: 0,
      enabled: Boolean(selectedRisk),
    }
  );

  const sendRiskMutation = useMutation(
    (data: any) => engineeringRepository.postProjectRisk(facilityId, uuid || '', data),
    {
      onSuccess: () => {
        message.success('Risk has been saved');
        setIsModalOpen(false);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const getRiskExportMutation = useMutation(() => engineeringRepository.getRiskExport(facilityId, uuid || ''), {
    onSuccess: (data: string) => {
      try {
        FileSaver.saveAs(
          new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
          }),
          `risk_export_${format(new Date(), 'dd-MM-yyyy')}.xlsx`
        );
      } catch (error) {
        message.error('Parsing file error');
      }
    },
    onError: (error: any) => {
      const errorKeys = Object.keys(error.response.data);
      const result = errorKeys
        .map((errorKey) => {
          return `${errorKey}: ${error.response.data[errorKey]}`;
        })
        .join(', ');
      message.error(result);
    },
  });

  const updateRiskMutation = useMutation(
    (data: any) => engineeringRepository.updateRisk(facilityId, uuid || '', selectedRisk || '', data),
    {
      onSuccess: () => {
        message.success('Risk has been updated');
        setIsModalOpen(false);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const infrastructureDoughnutChart = riskData?.risk_categories_overview.map((category, key) => {
    return {
      name: category.label,
      value: parseInt(category.value),
      color: riskCategoriesChartColors[key],
    };
  });

  const parseDataToRadarChart = () => {
    const dataKeys = Object.keys(riskData?.risk_effect_overview[0] || {}).filter((key) => key !== 'label');
    const labels = riskData?.risk_effect_overview.map((data) => data.label);

    const dataSets = dataKeys.map((key, index) => ({
      label: capitalize(key),
      data: riskData?.risk_effect_overview.map((data) => (data[key] !== 0 ? (data[key] as number) : null)) || [],
      backgroundColor: riskEffectChartColors[index].backgroundColor,
      borderColor: riskEffectChartColors[index].borderColor,
      borderWidth: 1,
    }));

    return { labels, dataSets };
  };

  const { labels, dataSets } = parseDataToRadarChart();

  return (
    <Spin spinning={isLoading}>
      <Modal
        destroyOnClose={true}
        width={'60%'}
        closable={false}
        closeIcon={null}
        footer={null}
        title={null}
        open={isModalOpen}
      >
        {riskData?.options && (
          <RiskForm
            formKey={dataUpdatedAt}
            riskData={details}
            setSelectedRisk={setSelectedRisk}
            setIsModalOpen={setIsModalOpen}
            sendRiskMutation={sendRiskMutation}
            updateRiskMutation={updateRiskMutation}
            options={riskData?.options}
          />
        )}
      </Modal>
      <div className='risk-container'>
        <p className='section-header'>Risk overview</p>
        <div className='risk-widgets-section'>
          <div className='hbh-details-item radar-chart'>
            <div className='hbh-details-item-label'>Risk effect overview</div>
            <div className='hbh-details-item-value risk-effect-chart'>
              <RiskRadarChart labels={labels || []} dataSets={dataSets || []} />
            </div>
          </div>
          <div className='widgets-wrapper'>
            <div className='hbh-details-item doughnut-chart-wrapper'>
              <div className='hbh-details-item-label'>RISK CATEGORIES</div>
              <div className='hbh-details-item-value'>
                <div className='doughnut-chart'>
                  <DoughnutChart
                    chartData={infrastructureDoughnutChart || []}
                    chartProps={getChartProps(infrastructureDoughnutChart || [])}
                    label=''
                  />
                </div>
              </div>
            </div>
            <div className='hbh-details-item risk-traced'>
              <div className='hbh-details-item-label'>RISK TRACKED</div>
              <div className='hbh-details-item-value'>
                <p className='risk-value'>{riskData?.count}</p>
              </div>
            </div>
          </div>
        </div>
        <p className='section-header'>
          Risk registry details{' '}
          <span className='excel-trigger' onClick={() => getRiskExportMutation.mutate()}>
            Export excel
          </span>
        </p>
        <Table
          className='registry-table'
          columns={columns}
          data={riskData?.results.sort((a, b) => a.order_number - b.order_number) || []}
          variant='dark'
          scroll={{ x: true }}
        />
        <Button onClick={() => setIsModalOpen(true)} className='edit-button' icon={<EditOutlined />} text='Add/Edit' />
      </div>
    </Spin>
  );
};

export default ProjectRisk;
