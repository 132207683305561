import React, { useEffect, useState } from 'react';
import './/styles.scss';
import { message, Spin } from 'antd';
import { Button, PageTitle, Pagination, Table, Tabs } from '@ui-modules';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PinIcon } from '@assets/svg/PinIcon.svg';
import { ReactComponent as WorldIcon } from '@assets/svg/world.svg';
import { ReactComponent as Showcase } from '@assets/svg/showcase.svg';
import { useQuery } from 'react-query';
import {
  ICountryPageListResponse,
  ICountryPageParams,
  IShowCaseListParams,
  IStoryListResponse,
  ISwitchesResponse,
} from '@digital-office/common/interfaces';
import { useRepository } from '@context/repository.context';
import { Dropdown as FiltersDropdown } from '@ui-modules/components';
import { IDropdownOption } from '@ui-modules/types';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

const columns = [
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Creation date',
    sorter: true,
    render: (data: string) => {
      return format(new Date(data), DateFormatEnum.DD_MM_YYYY);
    },
  },
  {
    dataIndex: 'title',
    key: 'title',
    title: 'Title',
    render: (data: string) => {
      return data ?? '-';
    },
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'Location',
  },
  {
    dataIndex: 'visible_for_organisations',
    key: 'visible_for_organisations',
    title: 'Audience',
  },
  {
    dataIndex: 'infrastructure_category',
    key: 'infrastructure_category',
    title: 'Infrastructure category',
  },
  {
    dataIndex: 'button',
    key: 'button',
    title: '',
    render: () => {
      return <Button className='view-button' text='View' />;
    },
  },
];

const countryPageColumns = [
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Creation date',
    sorter: true,
    render: (data: string) => {
      return format(new Date(data), DateFormatEnum.DD_MM_YYYY);
    },
  },
  {
    dataIndex: 'country',
    key: 'country',
    title: 'Country',
    width: 800,
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    render: (record: string) => {
      return record === 'Draft' ? (
        <Button className='status-button draft' text='Draft' />
      ) : (
        <Button className='status-button' variant='submit' text='Approved' />
      );
    },
  },
  {
    dataIndex: 'button',
    key: 'button',
    title: '',
    render: () => {
      return <Button className='view-button' text='View' />;
    },
  },
];

const ShowcaseListPage = () => {
  const country = localStorage?.getItem('country') || '';
  const facilityId = +localStorage.getItem('facility')!;
  const getTabs = (showCountryPage?: boolean) => [
    'Published',
    'Draft',
    'Pending Clearance',
    ...(showCountryPage ? ['Country page'] : []),
  ];
  const navigate = useNavigate();
  const { engineeringRepository } = useRepository();

  const [selectedTab, setSelectedTab] = useState<string>(sessionStorage.getItem('SHOWCASE_TAB_FILTER') || getTabs()[0]);
  const [audienceFilter, setAudienceFilter] = useState<IDropdownOption | null>();
  const [columnOrder, setColumnOrder] = useState<string>('-created');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    sessionStorage.setItem('SHOWCASE_TAB_FILTER', selectedTab);
    setSelectedPage(1);
  }, [selectedTab]);

  useEffect(() => {
    setSelectedPage(1);
  }, [audienceFilter]);

  const { data: switchData, isLoading: isSwitchLoading } = useQuery<ISwitchesResponse>(
    'waffle-switch',
    () => engineeringRepository.getSwitches(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const storyPublishingSwitch = data?.results?.find((siteSwitch) => siteSwitch.name === 'story_publishing');
        if (storyPublishingSwitch && !storyPublishingSwitch.active) {
          message.warning('Page is not available');
          navigate('/digital-office');
        }
      },
    }
  );

  const { data: storyData, isLoading } = useQuery<IStoryListResponse>(
    `story-list-${selectedTab}-${audienceFilter?.value}-${columnOrder}-${pageSize}-${selectedPage}`,
    () => engineeringRepository.getStories(facilityId, buildParams()),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: countriesData } = useQuery<ICountryPageListResponse>(
    `country-page-list-${selectedTab}-${audienceFilter?.value}-${columnOrder}-${pageSize}-${selectedPage}`,
    () => engineeringRepository.getCountryPages(facilityId, buildCountryPageParams()),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(switchData?.results.find((waffleSwitch) => waffleSwitch.name === 'country_page')?.active),
    }
  );

  const getTabParam = () => {
    switch (selectedTab) {
      case 'Published':
        return 'published';
      case 'Draft':
        return 'draft';
      case 'Pending Clearance':
        return 'pending_clearance';
    }
  };

  const buildParams = (): IShowCaseListParams => {
    return {
      show: getTabParam(),
      visible_for_organisations: audienceFilter?.value || '',
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder ? columnOrder : null,
    };
  };

  const buildCountryPageParams = (): ICountryPageParams => {
    return {
      audience: audienceFilter?.value || '',
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder ? columnOrder : null,
    };
  };

  return (
    <Spin spinning={isLoading && isSwitchLoading}>
      <div className='showcase-video-link'>
        <VideoLink link={videoLinks.SHOWCASE} />
      </div>
      <section className='hbh-container booking-list showcase-list-container'>
        <PageTitle
          filters={
            <div className='buttons-container'>
              {switchData?.results?.find((siteSwitch) => siteSwitch.name === 'country_page')?.active ? (
                <Button
                  icon={<PinIcon />}
                  variant='primary'
                  onClick={() => navigate('/digital-office/showcase/country-configuration/new')}
                  className='country-page-icon'
                  text='Configure country page'
                />
              ) : null}
              <Button
                icon={<WorldIcon />}
                variant='primary'
                onClick={() => navigate('/global-impact')}
                text='View global map'
              />
              <Button
                icon={<Showcase />}
                onClick={() => navigate('new')}
                className='new-project-button'
                text='Showcase new project'
              />
            </div>
          }
          title={`Engineering Project showcasing - ${country}`}
          bottomLine={true}
        />
        <Tabs
          className='engineering-tabs'
          tabs={getTabs(
            storyData?.show_country_page_data &&
              Boolean(switchData?.results.find((waffleSwitch) => waffleSwitch.name === 'country_page')?.active)
          )}
          selectedTab={getTabs(
            storyData?.show_country_page_data &&
              Boolean(switchData?.results.find((waffleSwitch) => waffleSwitch.name === 'country_page')?.active)
          ).indexOf(selectedTab)}
          onTabSelect={setSelectedTab}
        />
        <section className='filters'>
          <div className='showcase-dropdown-filters'>
            <FiltersDropdown
              className='filter select outline'
              isClearable={true}
              label={<label className='hbh-select-label'>Filter by Audience</label>}
              value={audienceFilter}
              placeholder='All organisations'
              options={storyData?.options.audience || []}
              onChange={setAudienceFilter}
            />
            <button className='reset' onClick={() => setAudienceFilter(null)}>
              Reset filters
            </button>
          </div>
        </section>
        <div className='table'>
          <Table
            columns={selectedTab === 'Country page' ? countryPageColumns : columns}
            data={selectedTab === 'Country page' ? countriesData?.results || [] : storyData?.results || []}
            onRow={(record) => {
              return {
                onClick: () =>
                  selectedTab === 'Country page'
                    ? navigate(`country-configuration/${record.country_iso_num}`)
                    : navigate(`${record.uuid}`), // click row
              };
            }}
            isLoading={isLoading}
            variant='dark'
            onChangeColumnOrder={setColumnOrder}
          />
        </div>
        <Pagination
          className='pagination'
          selectedPage={selectedPage}
          showJumper={true}
          showPageSize={true}
          totalPages={selectedTab === 'Country page' ? countriesData?.count || 0 : storyData?.count || 0}
          variant='dark'
          onPageChange={setSelectedPage}
          onPageSizeChange={setPageSize}
        />
      </section>
    </Spin>
  );
};

export default ShowcaseListPage;
