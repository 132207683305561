import React, { FC, useEffect } from 'react';
import './styles.scss';
import { ReactComponent as HubLogo } from '@assets/svg/hub-logo.svg';

interface IButtonProps {
  label: string;
  onClickHandler: () => void;
}

interface IErrorPageProps {
  title: string;
  subtitle: string;
  buttons: IButtonProps[];
}

const Button: FC<IButtonProps> = ({ label, onClickHandler }) => {
  return (
    <button className='button' onClick={onClickHandler}>
      {label}
    </button>
  );
};

const ErrorPage: FC<IErrorPageProps> = ({ title, subtitle, buttons = [] }) => {
  const navRef = document.getElementsByClassName('cms-menu')[0];
  if (navRef) {
    //@ts-ignore
    navRef.style.display = 'none';
  }

  return (
    <div className='error-page-container'>
      <div className='info-wrapper'>
        <HubLogo className='logo' />
        <p className='title'>{title}</p>
        <hr className='hr' />
        <p className='subtitle'>{subtitle}</p>
        <div className='buttons-container'>
          {buttons.map((button) => (
            <Button key={button.label} label={button.label} onClickHandler={button.onClickHandler} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
