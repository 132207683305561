import React, { FC, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { ArrayField, Form, FormApi, FormStateAccessor, TextArea, utils } from 'informed';
import { Collapse } from 'react-collapse';
import { DateField, TextField } from '@ui-modules/informed';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseCircleFilled,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { ReactComponent as PinIcon } from '@assets/svg/PinIcon.svg';
import LocationPicker, {
  ILocationPickerData,
} from '@digital-office/pages/ProjectsManagementPages/components/LocationPicker';
import GuidanceModal from '@components/CommunityGuidancePage/components/GuidanceModal';
import { Button } from '@ui-modules';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import Dragger from 'antd/es/upload/Dragger';
import { message, Upload, UploadProps } from 'antd';
import { find, findIndex, isEmpty, isEqual } from 'lodash';
import { convertToBase64 } from '@digital-office/pages/ProjectsManagementPages/components/UpdateForm';
import uuidv4 = utils.uuidv4;
import { UseMutationResult } from 'react-query';
import { format, parse } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import * as Yup from 'yup';
import { IAssessmentProjectLocation, Picture } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface ILocationElement {
  setValue: (name: string, value: unknown) => void;
  name: string;
  formRef: React.RefObject<FormApi>;
  removeHandler: () => void;
}

const isFileAllowedSize = (file: File): void | boolean => {
  if (file.size >= 10485760) {
    throw new Error(
      `File ${file.name} has too much size. Allowed size of file: ${Math.floor(10485760 / 1024 / 1024)} MB`
    );
  }
  return true;
};

const isAllowedExtension = (name: string): void | boolean => {
  if (!new RegExp('(' + ['jpg', 'png', 'jpeg'].join('|').replace(/\./g, '\\.') + ')$').test(name)) {
    throw new Error(`${name}: extension incorrect, accepted extensions: ${['jpg', 'png', 'jpeg'].join(', ')}`);
  }
  return true;
};

const validateFiles = (file: File): boolean => {
  try {
    isFileAllowedSize(file);
    isAllowedExtension(file.name);

    return true;
  } catch (error: any) {
    message.error(error.message);
    return false;
  }
};

const getProps = (setValue: (name: string, value: unknown) => void, filesList: Picture[]): UploadProps => {
  return {
    multiple: true,
    maxCount: 5,
    showUploadList: false,
    beforeUpload: async (file, list) => {
      if (!validateFiles(file)) {
        return Upload.LIST_IGNORE;
      }

      const exists = filesList.some((f) => f.name === file.name);
      if (exists) {
        message.error('File already added!');
        return Upload.LIST_IGNORE;
      }

      if (filesList.length + list.length > 5) {
        isEqual(file, list[list.length - 1]) && message.error(`You can upload maximum ${5} files`);
        return Upload.LIST_IGNORE;
      }

      let result: Picture[] = [];

      for (const listFile of list) {
        const base64File = await convertToBase64(listFile);
        result = [...result, { name: listFile.name, picture: base64File as string, uuid: uuidv4(), caption: '' }];
      }

      setValue('pictures', [...filesList, ...result]);

      return false;
    },
  };
};

const LocationPicturesList: FC<ILocationPicturesList> = ({ setValue, values, name, formRef }) => {
  const filesInitialValue = formRef?.current?.getInitialValue(`${name}[pictures]`) as Picture[];
  const [fileList, setFileList] = useState<Picture[]>(filesInitialValue);
  const [isInitialValuesSet, setInitialValues] = useState(false);
  const list = formRef?.current?.getValue(`${name}[pictures]`);

  useEffect(() => {
    if (!isInitialValuesSet) {
      setFileList(filesInitialValue);
      setInitialValues(true);
    } else {
      setFileList(list as Picture[]);
    }
  }, [filesInitialValue, list]);

  const removeElement = (deletedPicture: Picture[], key: number) => {
    const picturesCopy = values?.pictures || [];

    if (picturesCopy && picturesCopy.length > key) {
      picturesCopy.splice(key, 1, undefined);

      setValue(
        'pictures',
        // @ts-ignore
        picturesCopy?.filter((picture: Picture | undefined) => picture?.uuid)
      );
    }
  };

  return (
    <div className='picture-dragger'>
      <Dragger {...getProps(setValue, fileList || [])} className='dragging-area'>
        <UploadOutlined />
        <p>Drag and drop files here, or click to select</p>
      </Dragger>
      <div className='pictures-list'>
        {fileList
          ? fileList
              ?.filter((file) => file?.name)
              .map((pictureData, key: number) =>
                pictureData?.name ? (
                  <div className='picture-element' key={key}>
                    <span className='picture-label'>{key + 1}.</span>
                    <InformedField
                      label='Picture uploaded'
                      name={`[pictures][${key}][name]`}
                      formRefApi={formRef}
                      informedComponent={
                        <TextField disabled={true} name={`[pictures][${findIndex(fileList, pictureData)}][name]`} />
                      }
                    />
                    <InformedField
                      formRefApi={formRef}
                      name={`${name}[pictures][${key}][caption]`}
                      label='Caption'
                      informedComponent={
                        <TextField
                          initialValue={fileList?.[key]?.caption || ''}
                          name={`[pictures][${findIndex(fileList, pictureData)}][caption]`}
                        />
                      }
                    />
                    <div style={{ visibility: 'hidden', width: 0, height: 0 }}>
                      <InformedField
                        label='Picture'
                        informedComponent={
                          <TextField name={`[pictures][${findIndex(fileList, pictureData)}][picture]`} />
                        }
                      />
                      <InformedField
                        label='Picture'
                        informedComponent={<TextField name={`[pictures][${findIndex(fileList, pictureData)}][uuid]`} />}
                      />
                    </div>
                    <CloseCircleFilled
                      onClick={() => removeElement(fileList, findIndex(fileList, pictureData))}
                      className='close-button'
                    />
                  </div>
                ) : null
              )
          : null}
      </div>
    </div>
  );
};

interface ILocationForm {
  setValue: (name: string, value: unknown) => void;
  name: string;
  values: ILocationFormData;
  formRef: React.RefObject<FormApi>;
}

interface ILocationPicturesList {
  setValue: (name: string, value: unknown) => void;
  name: string;
  values: ILocationFormData;
  formRef: React.RefObject<FormApi>;
}

const LocationForm: FC<ILocationForm> = ({ setValue, values, name, formRef }) => {
  return (
    <>
      <InformedField
        formRefApi={formRef}
        name={`${name}[description]`}
        label='Description'
        informedComponent={<TextField name='description' />}
      />
      <InformedField
        formRefApi={formRef}
        maxLength={1000}
        name={`${name}[observations]`}
        description={
          <div>
            <p>
              For each of the visited sites insert a list of observations related to the inspection. The dot points
              below indicate some elements of the subject of the assessment/ inspection. Be descriptive, without being
              unnecessarily critical.
            </p>
            <ul>
              <li>Project description No., and time of visit, and the observed weather conditions</li>
              <li>
                Observation of progress and current phase of the project, and generally what was observed happening
              </li>
              <li>
                Assessment of the field resources/ funds expended on project, projected costs, including observable
                savings
              </li>
              <li>Summary of observed or potential risk items</li>
              <li>Recommendation and proposals for the Project Manager’s attention</li>
            </ul>
          </div>
        }
        label='Observations'
        informedComponent={<TextArea maxLength={1000} className='text-area' name='observations' />}
      />
      <p className='section-label'>Observations at site meetings</p>
      <InformedField
        formRefApi={formRef}
        name={`${name}[meeting_date]`}
        label='Meeting date'
        informedComponent={
          <div className='quarter-width'>
            <DateField dateFormat={DateFormatEnum.DD_MM_YYYY} name='meeting_date' />
          </div>
        }
      />
      <InformedField
        formRefApi={formRef}
        name={`${name}[list_of_attendees_at_meeting]`}
        description='Separate each name with a comma'
        label='List of attendees at meeting (Office location)'
        informedComponent={<TextField name='list_of_attendees_at_meeting' />}
      />
      <InformedField
        formRefApi={formRef}
        name={`${name}[minutes_of_the_engineering_proposal_discussed]`}
        maxLength={1000}
        description={
          <div>
            <p>
              This note below is a summary minute of site meetings held during an Engineering team field inspection/
              assessment visit indicating any required follow-up. You can provide:
            </p>
            <ul>
              <li>Background and discussion</li>
              <li>Requirement/scope</li>
              <li>Constraints</li>
              <li>Risks</li>
              <li>Options and follow-up</li>
              <li>Recommendations proposed</li>
            </ul>
          </div>
        }
        label='Minutes of the meeting (optional)'
        informedComponent={
          <TextArea maxLength={1000} className='text-area' name='minutes_of_the_engineering_proposal_discussed' />
        }
      />
      <p className='section-label'>Pictures</p>
      <p className='pictures-upload-description'>
        Insert a few pictures from the visited location and associate a descriptive caption to provide context.
      </p>
      <InformedField
        label='Upload picture(s)'
        formRefApi={formRef}
        name={`${name}[pictures]`}
        description='Max 5 files. File size limit 10MB'
        informedComponent={<LocationPicturesList formRef={formRef} values={values} setValue={setValue} name={name} />}
      />
    </>
  );
};

const LocationElement: FC<ILocationElement> = ({ setValue, name, formRef, removeHandler }) => {
  const locationsValue: any = formRef.current?.getInitialValue('project_locations');
  const [isCollapsed, setIsCollapsed] = useState<boolean | undefined>();
  const [isLocationPickerOpen, setIsLocationPickerOpen] = useState<boolean>(false);
  const values: any = formRef.current?.getValue(name);
  const initialValues: any = formRef.current?.getInitialValue(name);
  const errorList = formRef?.current?.getError(name);
  const locationData: ILocationPickerData = values?.location_details || initialValues?.location_details;
  const [selectedLocation, setSelectedLocation] = useState<ILocationPickerData | undefined>(locationData);

  useEffect(() => {
    if (!isEmpty(errorList) || errorList) {
      setIsCollapsed(true);

      const firstErrorElement = document.querySelector('.error')?.parentElement;

      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorList]);

  useEffect(() => {
    setValue(`location_details`, locationData || null);
  }, [locationData]);

  useEffect(() => {
    locationsValue?.length <= 1 ? setIsCollapsed(true) : setIsCollapsed(false);
  }, []);

  useEffect(() => {
    if (!isLocationPickerOpen) {
      setSelectedLocation(undefined);
    } else {
      if (locationData) {
        setSelectedLocation(locationData);
      }
    }
  }, [isLocationPickerOpen]);

  const onLocationSelectHandler = () => {
    setValue(`location_details`, selectedLocation);
    setIsLocationPickerOpen(false);
  };

  return (
    <div className='location-section'>
      <GuidanceModal
        isClosable={true}
        width='50%'
        centered={false}
        destroyOnClose={true}
        open={isLocationPickerOpen}
        closeHandler={() => setIsLocationPickerOpen(false)}
      >
        <div className='field-assessment-location-picker'>
          <p className='picker-modal-title'>Select location</p>
          <p className='location-label'>Project location</p>
          <p className='location-description'>
            Search by address, coordinates or click on the map to add the location. You can add multiple locations.
          </p>
          <LocationPicker
            height={450}
            initCoords={locationData ? [locationData.long, locationData.lat] : []}
            zoomToLocation={true}
            persistingLocation={selectedLocation ? selectedLocation : null}
            onLocationChange={(selectedLocation) => setSelectedLocation(selectedLocation)}
          />
          {selectedLocation ? (
            <div className='selected-location-label'>
              <p>
                {selectedLocation.place_name}, {selectedLocation.lat.toFixed(6)}, {selectedLocation.long.toFixed(6)}
              </p>
              <CloseOutlined onClick={() => setSelectedLocation(undefined)} />
            </div>
          ) : null}
          <div className='buttons-container'>
            <Button
              onClick={() => setIsLocationPickerOpen(false)}
              className='button cancel'
              variant='transparent'
              text='Cancel'
            />
            <Button
              onClick={onLocationSelectHandler}
              disabled={!selectedLocation}
              className='button save'
              text='Save location'
            />
          </div>
        </div>
      </GuidanceModal>
      <div className='location-title' onClick={() => setIsCollapsed(!isCollapsed)}>
        <UpOutlined className={`arrow-icon ${!isCollapsed ? 'rotated-down' : ''}`} />
        <span>
          {locationData
            ? `${locationData.place_name}, ${locationData.lat.toFixed(6)}, ${locationData.long.toFixed(6)}`
            : 'New location'}
        </span>
        <span className='remove-button'>
          <DeleteOutlined onClick={() => removeHandler()} />
        </span>
      </div>
      {isCollapsed !== undefined ? (
        <Collapse
          className={`collapse ${typeof isCollapsed === undefined ? 'hidden' : ''}`}
          translate='yes'
          isOpened={isCollapsed || false}
        >
          <div className='location-content'>
            <div className='location-label'>Project location</div>
            {!locationData ? (
              <div>
                <div onClick={() => setIsLocationPickerOpen(true)} className='select-location-button'>
                  <p>Select a location</p>
                  <PinIcon />
                </div>
                <div style={{ visibility: 'hidden', width: 0, height: 0 }}>
                  <InformedField label='Location details' informedComponent={<TextField name={`test`} />} />
                </div>
              </div>
            ) : (
              <div className='project-location-form'>
                <div className='selected-location-label'>
                  {locationData.place_name}, {locationData.lat.toFixed(6)}, {locationData.long.toFixed(6)}
                  <div className='button-edit' onClick={() => setIsLocationPickerOpen(true)}>
                    <span>Edit</span>
                    <EditOutlined />
                  </div>
                </div>
                <LocationForm formRef={formRef} name={name} setValue={setValue} values={values} />
              </div>
            )}
          </div>
        </Collapse>
      ) : null}
    </div>
  );
};

interface IProjectOverviewStep {
  initialValues: IAssessmentProjectLocation[];
  updateMutation: UseMutationResult<any, unknown, any, unknown>;
  finalizeStepMutation: UseMutationResult<any, unknown, any, unknown>;
  formKey: number;
  onStepBackButton: () => void;
}

const validationSchemaSubmit = Yup.object().shape({
  project_locations: Yup.array()
    .required('This field is required')
    .min(1, 'This field is required')
    .of(
      Yup.object().shape({
        description: Yup.string().required('Description is required'),
        observations: Yup.string().required('Observations are required'),
        meeting_date: Yup.date().required('Meeting date is required').nullable(),
        list_of_attendees_at_meeting: Yup.string()
          .required('Required')
          .matches(/^(\s*\w+(\s*,\s*\w+)*\s*)*$/, 'Please separate each name with a comma'),
        minutes_of_the_engineering_proposal_discussed: Yup.string(),
        pictures: Yup.array()
          .required('Required')
          .min(1, 'Min 1 ')
          .of(
            Yup.object().shape({
              name: Yup.string().required('Picture name is required').max(255, 'Max length 255'),
              caption: Yup.string().required('Caption is required').max(255, 'Max length 255'),
            })
          ),
      })
    ),
});

const validationSchemaDraft = Yup.object().shape({});

interface ILocationFormData {
  uuid: string;
  location_details: ILocationPickerData;
  description: string;
  observations: string;
  list_of_attendees_at_meeting: string;
  minutes_of_the_engineering_proposal_discussed: string;
  pictures: IAssessmentProjectLocation['pictures'] | undefined[];
  meeting_date: Date | undefined;
}

const ProjectLocationStep: FC<IProjectOverviewStep> = ({
  initialValues,
  formKey,
  finalizeStepMutation,
  updateMutation,
  onStepBackButton,
}) => {
  const formRef = useRef<FormApi>(null);
  const [updateType, setUpdateType] = useState<string | undefined>();
  const [, updateState] = useState<any>(null);

  useEffect(() => {
    if (updateType) {
      formRef?.current?.submitForm();
    }
  }, [updateType]);

  useEffect(() => {
    // on formInit there is no fieldArray values, it re-renders form on init to get them
    updateState({});
  }, [formRef]);

  const onSubmitButtonClick = (type: string) => {
    if (type === updateType) {
      formRef?.current?.submitForm();
    } else {
      setUpdateType(type);
    }
  };

  const onStepBackClick = () => {
    const modifiedField: any = formRef?.current?.getFormState().values;
    const isEmptyInitialValue =
      !initialValues.length &&
      modifiedField?.project_locations.every(
        (projectLocation: IAssessmentProjectLocation) => projectLocation.location_details === null
      );
    if (
      isEmptyInitialValue ||
      isEqual(initialValues, parseFormDataToPayload(modifiedField?.project_locations, false))
    ) {
      onStepBackButton();
    } else {
      const confirmAlert = confirm(
        'You have unsaved changes. Saves will not be saved.  Are you sure you want to leave?'
      );

      if (confirmAlert) {
        onStepBackButton();
      }
    }
  };

  const parseInitialValues = (initialValues: IAssessmentProjectLocation[]) => {
    const result = initialValues
      ?.filter((locationData) => !isEmpty(locationData?.location_details))
      ?.map((locationData) => {
        return {
          ...locationData,
          uuid: locationData?.uuid || 'empty',
          list_of_attendees_at_meeting: locationData?.list_of_attendees_at_meeting?.join(','),
          meeting_date: locationData?.meeting_date
            ? parse(locationData?.meeting_date, DateFormatEnum.YYYY_MM_DD, new Date())
            : undefined,
          location_details: {
            country: locationData?.location_details.country,
            lat: parseFloat(locationData?.location_details.latitude),
            long: parseFloat(locationData?.location_details.longitude),
            place_name: locationData?.location_details.name,
          },
        };
      });

    return { project_locations: result.length ? result : [{}] };
  };

  const parseFormDataToPayload = (values: ILocationFormData[], performSubmit: boolean) => {
    const checkIfExist = (picture: { name: string; uuid?: string; caption: string }, locationUuid: string) => {
      const location = find(initialValues, { uuid: locationUuid });
      const file = find(location?.pictures, { uuid: picture.uuid });
      return Boolean(file?.picture);
    };

    const result = values?.map((locationData) => {
      return {
        uuid: locationData?.uuid ? locationData?.uuid : uuidv4(),
        location_details: {
          name: locationData?.location_details?.place_name,
          latitude: locationData?.location_details?.lat.toFixed(2),
          longitude: locationData?.location_details?.long.toFixed(2),
          country: locationData?.location_details?.country,
        },
        description: locationData?.description,
        observations: locationData?.observations,
        list_of_attendees_at_meeting: locationData?.list_of_attendees_at_meeting
          ?.split(',')
          ?.map((name: string) => name.trimStart().trimEnd()),
        minutes_of_the_engineering_proposal_discussed: locationData.minutes_of_the_engineering_proposal_discussed,
        pictures: locationData?.pictures?.map((picture) => ({
          ...picture,
          // temporary cause backend always sends base64
          picture: picture ? (checkIfExist(picture, locationData.uuid) ? picture.picture : picture.picture) : undefined,
        })),
        meeting_date: locationData?.meeting_date
          ? format(locationData?.meeting_date, DateFormatEnum.YYYY_MM_DD)
          : undefined,
      };
    });

    if (!performSubmit) {
      return result;
    } else {
      if (updateType === 'draft') {
        updateMutation.mutate({ current_step: 1, project_location: result?.length ? result : [] });
      } else {
        finalizeStepMutation.mutate({ current_step: 1, project_location: result });
      }
    }
  };

  const isEmptyLocationExist = (values: { project_locations: ILocationFormData[] }) => {
    return values.project_locations?.some((locationData) => !locationData.location_details);
  };

  return (
    <div className='project-location-step-wrapper'>
      <Form
        formApiRef={formRef}
        yupSchema={updateType === 'draft' ? validationSchemaDraft : validationSchemaSubmit}
        key={formKey}
        initialValues={parseInitialValues(initialValues)}
        onClick={() => updateState({})}
        onSubmit={({ values }) => parseFormDataToPayload(values?.project_locations as ILocationFormData[], true)}
      >
        <FormStateAccessor>
          {({ values }) => {
            return (
              <InformedField
                label=''
                name='project_locations'
                formRefApi={formRef}
                informedComponent={
                  <div className='project-location-form'>
                    <ArrayField name='project_locations'>
                      {({ addWithInitialValue }) => {
                        return (
                          <>
                            <ArrayField.Items>
                              {({ remove, setValue, name }) => (
                                <>
                                  <TextField className='hidden-input' name={`uuid`} />
                                  <LocationElement
                                    removeHandler={remove}
                                    formRef={formRef}
                                    name={name}
                                    setValue={setValue}
                                  />
                                </>
                              )}
                            </ArrayField.Items>
                            <p
                              onClick={() =>
                                isEmptyLocationExist(values as { project_locations: ILocationFormData[] })
                                  ? message.error('Fill empty location before adding another')
                                  : addWithInitialValue({ location_details: false, uuid: false })
                              }
                              className='add-button'
                            >
                              Add new location <span>+</span>
                            </p>
                          </>
                        );
                      }}
                    </ArrayField>
                  </div>
                }
              />
            );
          }}
        </FormStateAccessor>
        <div className='buttons-container'>
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            onClick={() => onStepBackClick()}
            variant='transparent'
            className='button back'
            text='Back'
            icon={<ArrowLeftOutlined />}
          />
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            className='button'
            variant='transparent'
            onClick={() => onSubmitButtonClick('draft')}
            text='Save as draft'
          />
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            icon={<ArrowRightOutlined />}
            className='button go-next'
            onClick={() => onSubmitButtonClick('submit')}
            text='Save and go next'
          />
        </div>
      </Form>
    </div>
  );
};

export default ProjectLocationStep;
