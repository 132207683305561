import React, { FC } from 'react';
import { ReactComponent as SuccessIcon } from '@assets/svg/solid-success.svg';

import './styles.scss';
import { Button } from '@ui-modules';
import { EMAIL_CONFIRMATION_MODE, SUBMIT_CONFIRMATION_MODE } from '@components/CommunityGuidancePage/constants';
import { IGuidanceData } from '@components/CommunityGuidancePage';

interface IGuidanceConfirmationProps {
  onButtonClickHandler?: () => void;
  mode?: string;
  data: IGuidanceData;
}

const EmailConfirmation: FC<IGuidanceConfirmationProps> = ({ onButtonClickHandler, data }) => {
  return (
    <div>
      <div className='title-wrapper'>
        <p className='title'>Guidance sent to your email successfully</p>
        <p className='subtitle'>An email containing all the needed information has been sent to your email</p>
      </div>
      <p className='description'>
        Contact WFP Engineering to get support and guidance for <strong>{data.activity.name}</strong>
      </p>
      <Button onClick={onButtonClickHandler} className='button' text='Contact WFP Engineering' variant='transparent' />
    </div>
  );
};

const SubmitConfirmation = () => {
  return (
    <div className='title-wrapper'>
      <p className='title'>Request submitted to WFP Engineering</p>
      <p className='subtitle'>An email containing all necessary information has been sent to your inbox</p>
    </div>
  );
};

const GuidanceConfirmation: FC<IGuidanceConfirmationProps> = ({ onButtonClickHandler, mode, data }) => {
  return (
    <div className='confirmation-wrapper'>
      <SuccessIcon className='success-icon' />
      {mode === EMAIL_CONFIRMATION_MODE ? (
        <EmailConfirmation data={data} onButtonClickHandler={onButtonClickHandler} />
      ) : (
        mode === SUBMIT_CONFIRMATION_MODE && <SubmitConfirmation />
      )}
    </div>
  );
};

export default GuidanceConfirmation;
