import { UseMutationResult } from 'react-query';
import { IDropdownOption } from '@ui-modules/types';
import { IPhase } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection';
import { ILocationObject } from '@digital-office/pages/ProjectsManagementPages/components/LocationModal';
import { parseLocationsToPayload, parsePhasesToPayload } from '@digital-office/pages/ProjectsManagementPages/utils';

interface IPhasesPayload {
  start_date: string;
  end_date: string;
  description: string;
  number: number;
  deliverables: {
    name: string;
    is_template: boolean;
  }[];
}

export interface IFormData {
  client: string;
  code: string;
  uuid: string;
  description: string;
  engineering_project_manager: IDropdownOption;
  hq_technical_advisor: IDropdownOption;
  risk: IDropdownOption;
  partners_involved: string;
  topics: IDropdownOption[];
  title: string;
}

// @todo: topics type to discuss (now it's single select but array expected)

export interface IProjectPayload
  extends Omit<IFormData, 'engineering_project_manager' | 'hq_technical_advisor' | 'risk'> {
  engineering_project_manager: IDropdownOption['value'];
  hq_technical_advisor: IDropdownOption['value'];
  topics: any;
  locations: {
    name: string;
    latitude: number;
    longitude: number;
    country: string;
    project_infrastructures: {
      infrastructure: string;
      description: string;
    }[];
  }[];
  phases: IPhasesPayload[];
}

const onSubmitHandler = (
  formData: IFormData,
  selectedPhases: IPhase[],
  locations: ILocationObject[],
  sendProject: UseMutationResult<any, any, IProjectPayload, unknown>,
  code: string,
  prefillData: { [p: string]: string | number }
) => {
  const result = {
    ...formData,
    ...(prefillData.uuid && { service_request: prefillData.uuid }),
    ['engineering_project_manager']: formData.engineering_project_manager?.value,
    ['hq_technical_advisor']: formData.engineering_project_manager?.value,
    ['risk']: null,
    ['topics']: formData?.topics.map((topic) => topic.value),
    code: `${code}${formData?.code}`,
    locations: parseLocationsToPayload(locations),
    phases: parsePhasesToPayload(selectedPhases),
  };

  sendProject.mutate(result);
};

export { onSubmitHandler };
