import React, { FC, useState } from 'react';
import { findIndex, pullAt, range } from 'lodash';
import { Button } from '@ui-modules';
import { FileFilled, FolderFilled } from '@ant-design/icons';
import { ISharepointRequestResponse, ISharepointTreeData } from '@digital-office/common/interfaces';
import { ReactComponent as SharepointIcon } from '@assets/svg/sharepoint-icon.svg';

import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

import './styles.scss';
import { format, parseISO } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

interface IProps {
  data: ISharepointTreeData[];
}

const ProjectRepository: FC<IProps> = () => {
  const [path, setPath] = useState<ISharepointTreeData[]>([]);
  const facilityId = +localStorage.getItem('facility')!;
  const { id: uuid } = useParams<{ id: string }>();

  const { refetch, isLoading } = useQuery<ISharepointRequestResponse>(
    `repository-tree-${uuid}`,
    () => engineeringRepository.getProjectRepository(facilityId, uuid || ''),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setPath(data.sharepoint_tree);
      },
    }
  );

  const { engineeringRepository } = useRepository();

  const onDirectoryClickHandler = (name: string) => {
    const elementIndex = findIndex(path, (element) => element.name === name);
    const filteredPaths = pullAt(path, range(0, elementIndex + 1));
    setPath(filteredPaths);
  };

  const renderTree = () => {
    const currentDict = path[path.length - 1];

    return (
      <div>
        <div className='path-url'>
          <FolderFilled className='folder-icon' />
          {path.map((pathElement, index) => {
            const isLastIndex = index === path.length - 1;
            return (
              <div key={pathElement.name}>
                <span
                  onClick={() => !isLastIndex && onDirectoryClickHandler(pathElement.name)}
                  className={!isLastIndex ? 'link' : ''}
                >
                  {pathElement.name}
                </span>
                {!isLastIndex && <span className='separator'>{'>'}</span>}
              </div>
            );
          })}
        </div>
        <div className='tree-content'>
          {currentDict?.children?.length ? (
            currentDict?.children.map((child) => {
              const icon =
                child.type === 'dictionary' ? (
                  <FolderFilled className='folder-icon' />
                ) : (
                  <FileFilled className='folder-icon' />
                );

              return (
                <div key={child.name} className='content-element'>
                  {icon}
                  <div
                    onClick={() =>
                      child.type === 'dictionary' && path[0].url
                        ? setPath([...path, child])
                        : window.open(child.url || (path[0].url as string))
                    }
                  >
                    {child.type === 'file' ? (
                      <p>
                        <span className='link'>{child.name}</span>
                        <span className='link-description'>
                          {' '}
                          - {child.date ? format(parseISO(child.date), DateFormatEnum.DD_MM_YYYY) : ''} by{' '}
                          {child.author}
                        </span>
                      </p>
                    ) : (
                      <span className='link'>{child.name}</span>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <span className='link-description empty'>Empty...</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <h3>Project repository</h3>
      <Spin spinning={isLoading}>
        <div className='hbh-details-item repository'>
          <div className='hbh-details-item-label'>
            <div>PROJECT REPOSITORY</div>
            <Button
              onClick={() => path[0]?.url && window.open(path[0]?.url)}
              disabled={Boolean(!path || !path[0]?.url)}
              className='sharepoint-button'
              icon={<SharepointIcon />}
              text='View it on SharePoint'
            />
          </div>
          <div className='hbh-details-item-value file-tree'>
            {path && path[0] ? renderTree() : <span className='link-description empty'>No data...</span>}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ProjectRepository;
