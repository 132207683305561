import React, { FC, useState } from 'react';
import './styles.scss';
import { ReactComponent as DigitalOfficeIcon } from '@assets/svg/do-icon.svg';
import { ReactComponent as UNHeader } from '@assets/svg/unbooking_logo.svg';
import { ReactComponent as MyProjectsIcon } from '@assets/svg/my_projects_icon.svg';
import { ICMSHeader } from '../../interfaces/Common';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ISwitchesResponse } from '@digital-office/common/interfaces';
import { useRepository } from '@context/repository.context';

interface ICMSHeaderProps {
  logoutHandler: () => void;
  username: string;
  headerData: ICMSHeader;
  hasAccessDO?: boolean;
  hasDoParams: boolean;
  organisation: string;
  setIsMobileMenuOpen: any;
}

const CMSHeader: FC<ICMSHeaderProps> = ({
  logoutHandler,
  username,
  headerData,
  hasDoParams,
  organisation,
  setIsMobileMenuOpen,
}) => {
  const navigate = useNavigate();

  const { engineeringRepository } = useRepository();
  const [isCustomerAreaButtonVisible, setIsCustomerAreaButtonVisible] = useState<boolean>(false);

  useQuery<ISwitchesResponse>('waffle-switch', () => engineeringRepository.getSwitches(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const storyPublishingSwitch = data?.results?.find((siteSwitch) => siteSwitch.name === 'customer_area');
      if (storyPublishingSwitch && storyPublishingSwitch.active) {
        setIsCustomerAreaButtonVisible(true);
      }
    },
  });

  return (
    <div>
      <div className='header'>
        <UNHeader onClick={() => (window.location.href = 'https://unbooking.org')} />
      </div>
      <div className='auth-header'>
        <div
          onClick={() => setIsMobileMenuOpen((prevState: boolean) => !prevState)}
          className='mobile-navigation-button'
        >
          <div className='hamburger-icon' />
          <div className='title'>Menu</div>
        </div>
        <img
          onClick={() => navigate(headerData?.home_page_slug)}
          className='logo'
          src={headerData?.logo?.meta?.download_url}
        />
        <div className='buttons-container'>
          {isCustomerAreaButtonVisible ? (
            <div onClick={() => (window.location.href = '/customer-area')} className='navigation-button my-projects'>
              <MyProjectsIcon className='icon' />
              <div className='button-label'>
                <p>My</p>
                <p>Requests</p>
              </div>
            </div>
          ) : null}
          <div
            onClick={() =>
              (window.location.href = hasDoParams ? '/digital-office' : String(process.env.REACT_APP_HBH_DO_URL))
            }
            className='navigation-button digital-office'
          >
            <DigitalOfficeIcon className='icon' />
            <div className='button-label'>
              <p>Digital</p>
              <p>Office</p>
            </div>
          </div>
        </div>
        <div className='user-section right-alignment'>
          <p className='user-title'>
            Hello{' '}
            <span data-testid='username' className='name'>
              {username}
            </span>
            , {organisation}
          </p>
          <p onClick={logoutHandler} className='logout'>
            Logout
          </p>
        </div>
      </div>
    </div>
  );
};

export default CMSHeader;
