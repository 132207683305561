import React, { FC, useState } from 'react';
import ProjectPlan from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectPlan';
import { Button } from '@ui-modules';
import PhaseSection, { IPhase } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection';
import { IDeliverableTemplatesResponse, IProjectDetailsResponse } from '@digital-office/common/interfaces';
import { ReactComponent as GanttChartIcon } from '@assets/svg/gantt-chart.svg';
import { message, Tooltip } from 'antd';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import { IDropdownOption } from '@ui-modules/types';

export interface IProjectPhaseProps {
  projectDetails: IProjectDetailsResponse;
  isEditMode: boolean;
  phases: IPhase[];
  setPhases: React.Dispatch<React.SetStateAction<IPhase[]>>;
  updateDeliverableHandler: (phases: IPhase[]) => void;
}

const ProjectPhases: FC<IProjectPhaseProps> = ({
  projectDetails,
  isEditMode,
  phases,
  setPhases,
  updateDeliverableHandler,
}) => {
  const [isProjectPlanVisible, setIsProjectPlanVisible] = useState<boolean>(false);
  const [projectTemplates, setProjectTemplates] = useState<IDropdownOption[] | undefined>(undefined);
  const facilityId = +localStorage.getItem('facility')!;

  const { engineeringRepository } = useRepository();

  const { refetch } = useQuery<IDeliverableTemplatesResponse>(
    `deliverable-templates`,
    () => engineeringRepository.getDeliverableTemplates(facilityId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const options = data?.results.map((template) => ({
          label: template.name,
          value: template.name,
        }));

        setProjectTemplates(options);
      },
    }
  );

  const sendDeliverableTemplateMutation = useMutation(
    (data: { name: string }) => engineeringRepository.postDeliverableTemplate(facilityId, data),
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  return (
    <div className='hbh-details-item'>
      <div className='hbh-details-item-label'>
        <div className='phases-label'>
          <span>PROJECT PHASES - TRACK AND MANAGE PROJECT DELIVERABLES</span>
          {projectDetails?.project_plan && (
            <ProjectPlan
              plan={projectDetails?.project_plan}
              isModalOpen={isProjectPlanVisible}
              setIsModalOpen={setIsProjectPlanVisible}
            />
          )}
          <Tooltip
            title={
              Boolean(
                !projectDetails?.project_plan ||
                  !projectDetails?.project_plan.start_date ||
                  !projectDetails?.project_plan.end_date
              ) && 'Add project phases to generate a plan'
            }
          >
            <div>
              <Button
                onClick={() => setIsProjectPlanVisible(true)}
                icon={<GanttChartIcon />}
                disabled={
                  !projectDetails?.project_plan ||
                  !projectDetails?.project_plan.start_date ||
                  !projectDetails?.project_plan.end_date
                }
                className='preview-plan-button'
                text='PREVIEW PLAN'
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className='hbh-details-item-value'>
        <PhaseSection
          isEditableView={isEditMode}
          persistingPhases={phases}
          deliverableTemplates={projectTemplates || []}
          sendDeliverableTemplateMutation={sendDeliverableTemplateMutation}
          updateDeliverable={updateDeliverableHandler}
          onPhaseChange={(phases) => setPhases(phases)}
        />
        {!phases.filter((phase) => !phase.removed).length && !isEditMode && (
          <p>No phases related to te project, click on &quot;Edit mode&quot; to add the needed ones</p>
        )}
      </div>
    </div>
  );
};

export default ProjectPhases;
