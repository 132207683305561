import React, { FC, useEffect, useState } from 'react';
import './styles.scss';
import { useRepository } from '@context/repository.context';
import { useNavigate } from 'react-router-dom';
import { Button, Tabs } from '@ui-modules';
import {
  CustomerAreaProjectsListItem,
  ICustomerAreaProjectListResponse,
  ICustomerAreaUpdatesItem,
  ICustomerAreaUpdatesListResponse,
  ISwitchesResponse,
  IUser,
} from '@digital-office/common/interfaces';
import { Breadcrumb, Image, Input, message, Spin } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import { ReactComponent as ShareIcon } from '@assets/svg/share.svg';
import GuidanceModal from '@components/CommunityGuidancePage/components/GuidanceModal';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { format, parseISO } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

const tabs = ['Projects Overview', 'Updates'];

interface IProjectListProps {
  onShareClickButtonHandler: React.Dispatch<React.SetStateAction<CustomerAreaProjectsListItem | undefined>>;
  data: CustomerAreaProjectsListItem;
}

interface IProjectUpdatesProps {
  data: ICustomerAreaUpdatesItem;
}

interface IShareProjectModal {
  itemToShare: CustomerAreaProjectsListItem | undefined;
  setItemToShare: React.Dispatch<React.SetStateAction<CustomerAreaProjectsListItem | undefined>>;
  userDomain: string;
  shareProjectMutation: UseMutationResult<any, unknown, string[], unknown>;
}

const ProjectUpdate: FC<IProjectUpdatesProps> = ({ data }) => {
  return (
    <div className='project-update-element'>
      <Image.PreviewGroup items={data?.pictures.map((pic) => pic.picture)}>
        <Image wrapperClassName='update-picture' src={data?.pictures[0]?.picture} />
      </Image.PreviewGroup>
      <div className='update-text-info-container'>
        <div className='project-update-description'>
          <p>
            {data.created_by} - {format(parseISO(data.created), DateFormatEnum.DD_MM_YYYY)}
          </p>
          <p className='update-info'>
            <span className='project-name'>{data.project_code}</span> - {data.project_title}
          </p>
          <p className='update-info'>
            <span className='update-label'>Related to infrastructure(s):</span> {data.infrastructure}
          </p>
          <p className='update-info'>
            <span className='update-label'>Phase: </span>
            {data.phase}
          </p>
        </div>
        <div className='update-description-section'>
          <p className='update-title'>{data.title}</p>
          <p className='update-description'>{data.details}</p>
        </div>
      </div>
    </div>
  );
};

const ProjectList: FC<IProjectListProps> = ({ onShareClickButtonHandler, data }) => {
  return (
    <div className='project-list-wrapper'>
      <p className='project-name'>{data.title}</p>
      <p className='project-description'>{data.description}</p>
      <div className='project-details-info'>
        <div className='project-details-element'>
          <p className='project-details-label'>Current phase</p>
          <p className='project-details-value'>
            {data.actual_phase_description?.length ? data.actual_phase_description : '-'}
          </p>
        </div>
        <div className='project-details-element'>
          <p className='project-details-label'>Total estimation cost</p>
          <p className='project-details-value'>
            {Number(data.estimated_budget).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}{' '}
            USD
          </p>
        </div>
        <div className='project-details-element'>
          <p className='project-details-label'>Engineering Focal point</p>
          <p className='project-details-value'>{data.engineering_project_manager}</p>
        </div>
      </div>
      <div className='controls-section'>
        <div className='section'>
          <p>Documents</p>
          <ArrowDownOutlined />
        </div>
        <div onClick={() => onShareClickButtonHandler(data)} className='section'>
          <p>Share access</p>
          <ShareIcon />
        </div>
      </div>
    </div>
  );
};

const ShareProjectModal: FC<IShareProjectModal> = ({
  itemToShare,
  setItemToShare,
  userDomain,
  shareProjectMutation,
}) => {
  const [projectShareEmails, setProjectShareEmails] = useState<string>('');

  useEffect(() => {
    setProjectShareEmails('');
  }, [itemToShare]);

  const testEmailAddresses = (emails: string) => {
    const emailArray = emails.split(',').map((email) => email.trim());
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const desiredDomain = userDomain.split('@')[1];
    const uniqueEmails = new Set();

    for (const email of emailArray) {
      if (!emailPattern.test(email)) {
        message.error('Incorrect email or emails formats (email should be separated by comma)');
        return false;
      }

      if (email.split('@')[1] !== desiredDomain) {
        message.error(`Email ${email} has incorrect domain. Required domain: ${desiredDomain}`);
        return false;
      }

      if (uniqueEmails.has(email)) {
        message.error(`Duplicate email detected: ${email}`);
        return false;
      }

      uniqueEmails.add(email);
    }

    return true;
  };

  const onClickHandler = () => {
    if (!testEmailAddresses(projectShareEmails)) {
      return false;
    } else {
      const emailArray = projectShareEmails.split(',').map((email) => email.trim());
      shareProjectMutation.mutate(emailArray);
    }
  };

  return (
    <GuidanceModal
      destroyOnClose={true}
      centered={false}
      isClosable={true}
      open={Boolean(itemToShare)}
      closeHandler={() => setItemToShare(undefined)}
    >
      <div className='projects-share-access-modal'>
        <p className='modal-header'>Share access to this project information with your colleagues</p>
        <div className='title-section'>
          <p className='title-label'>Project title</p>
          <p className='title-value'>{itemToShare?.title}</p>
        </div>
        <Input
          value={projectShareEmails}
          onChange={(e) => setProjectShareEmails(e.target.value)}
          placeholder='Email, comma seperated'
        />
        <div className='buttons-container'>
          <Button
            onClick={() => setItemToShare(undefined)}
            text='Cancel'
            disabled={shareProjectMutation.status === 'loading'}
            variant='transparent'
            className='borderless'
          />
          <Button
            disabled={shareProjectMutation.status === 'loading'}
            onClick={() => onClickHandler()}
            text='Share'
            variant='primary'
          />
        </div>
      </div>
    </GuidanceModal>
  );
};

const CustomerProjectsList = () => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const navigate = useNavigate();
  const [itemToShare, setItemToShare] = useState<CustomerAreaProjectsListItem | undefined>();

  const { engineeringRepository } = useRepository();

  useQuery<ISwitchesResponse>('waffle-switch', () => engineeringRepository.getSwitches(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const storyPublishingSwitch = data?.results?.find((siteSwitch) => siteSwitch.name === 'customer_area');
      if (!storyPublishingSwitch?.active) {
        message.warning('Page is not available');
        navigate('/home');
      }
    },
  });

  const { data: projectList, isLoading: isProjectLoading } = useQuery<ICustomerAreaProjectListResponse>(
    `custom-area-requests-project-list`,
    () => engineeringRepository.getCustomerAreaProjectList(),
    {
      refetchOnWindowFocus: false,
      enabled: selectedTab === tabs[0],
    }
  );

  const { data: projectUpdates, isLoading: isUpdateLoading } = useQuery<ICustomerAreaUpdatesListResponse>(
    `custom-area-requests-project-updates`,
    () => engineeringRepository.getCustomerAreaUpdates(),
    {
      refetchOnWindowFocus: false,
      enabled: selectedTab === tabs[1],
    }
  );

  const shareProjectMutation = useMutation(
    (emails: string[]) => engineeringRepository.sendShareProjectsEmails(itemToShare?.uuid || '', emails),
    {
      onSuccess: () => {
        message.success('Project has been shared with collaborators');
        setItemToShare(undefined);
      },
      onError: () => {
        message.error('Request has failed');
      },
    }
  );

  const { data: userDetails } = useQuery<IUser>(['user-details'], () => engineeringRepository.getUserDetails(), {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return (
    <div className='customer-projects-list-wrapper'>
      <Breadcrumb
        items={[
          {
            title: (
              <p className='crumb-active' onClick={() => navigate('/customer-area')}>
                Home
              </p>
            ),
          },
          { title: <p>Projects overview and updates</p> },
        ]}
      />
      <section className='booking-list projects-list'>
        {userDetails && (
          <ShareProjectModal
            shareProjectMutation={shareProjectMutation}
            userDomain={userDetails.email}
            itemToShare={itemToShare}
            setItemToShare={setItemToShare}
          />
        )}
        <Tabs
          className='engineering-tabs'
          tabs={tabs}
          selectedTab={tabs.indexOf(selectedTab)}
          onTabSelect={setSelectedTab}
        />
        <Spin spinning={isProjectLoading || isUpdateLoading}>
          <div className='project-section-wrapper'>
            {selectedTab === tabs[0] ? (
              <p className='projects-number'>{projectList?.results?.length || 0} projects</p>
            ) : (
              <p className='projects-number'>{projectUpdates?.results?.length || 0} updates</p>
            )}
            {selectedTab === tabs[0] ? (
              <div className='projects-list-container'>
                {projectList?.results?.length ? (
                  projectList?.results?.map((project) => (
                    <ProjectList data={project} key={project.uuid} onShareClickButtonHandler={setItemToShare} />
                  ))
                ) : (
                  <p>No projects</p>
                )}
              </div>
            ) : null}
            {selectedTab === tabs[1] ? (
              <div className='project-update-list'>
                {projectUpdates?.results?.length ? (
                  projectUpdates?.results?.map((update) => <ProjectUpdate data={update} key={update.project_uuid} />)
                ) : (
                  <p>No updates</p>
                )}
              </div>
            ) : null}
          </div>
        </Spin>
      </section>
    </div>
  );
};

export default CustomerProjectsList;
