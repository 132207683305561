import React, { FC, useEffect, useState } from 'react';
import ReactMapGl, { Marker, MapRef } from 'react-map-gl';
import { Popover } from 'antd';
import useSupercluster from 'use-supercluster';
import { MAPBOX_API_KEY } from '@components/GlobalImpact/utils';
import './styles.scss';

interface IProps {
  locations: {
    id?: string;
    description: string;
    locationData: {
      lat: number;
      long: number;
    };
  }[];
  initCoords: number[];
  clusterPopoverLabel?: string;
  customMarker?: (id: string) => JSX.Element;
  onLocationClick?: (id: string) => void;
}

const MapPreview: FC<IProps> = ({ locations, initCoords, customMarker, onLocationClick, clusterPopoverLabel }) => {
  const [coords, setCoords] = React.useState<number[]>();
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const country = localStorage?.getItem('country') || '';

  const [viewport, setViewport] = useState({ latitude: initCoords[1], longitude: initCoords[0], zoom: 2 });

  useEffect(() => {
    if (mapRef && locations.length) {
      const mapApp = mapRef.getMap();
      mapApp.flyTo({
        zoom: 4,
        speed: 0.45,
        center: [locations[0]?.locationData.long, locations[0]?.locationData.lat],
        pitch: 25,
      });
    }
  }, [locations]);

  const points =
    locations.map((location, key) => ({
      type: 'Feature' as any,
      properties: {
        cluster: false,
        locationName: location.description,
        id: location.id || key + 1,
      },
      geometry: {
        type: 'Point' as any,
        coordinates: [location.locationData.long, location.locationData.lat],
      },
    })) || [];

  const bounds = mapRef?.getMap().getBounds();

  const { clusters, supercluster } = useSupercluster({
    points,
    zoom: viewport.zoom,
    options: { radius: 30, maxZoom: 20 },
    bounds: [bounds?.getWest() ?? 0, bounds?.getSouth() ?? 0, bounds?.getEast() ?? 0, bounds?.getNorth() ?? 0],
  });

  React.useEffect(() => {
    initCoords && setCoords(initCoords);
  }, [initCoords]);

  return (
    <div className='map-preview'>
      {coords && (
        <ReactMapGl
          ref={(ref) => setMapRef(ref)}
          attributionControl={false}
          initialViewState={
            initCoords && { longitude: initCoords[0], latitude: initCoords[1], zoom: country === 'Global' ? 2 : 4 }
          }
          style={{ height: '100%', width: '100%' }}
          mapStyle='mapbox://styles/mapbox/streets-v11'
          minZoom={2}
          onMove={(evt) => {
            setViewport(evt.viewState);
          }}
          mapboxAccessToken={MAPBOX_API_KEY}
          {...{
            dragRotate: false,
            touchZoom: false,
            touchRotate: false,
            keyboard: false,
            doubleClickZoom: false,
          }}
        >
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            // @ts-ignore
            const { cluster: isCluster, point_count, id } = cluster.properties;

            if (isCluster) {
              return (
                <Marker key={cluster.id} longitude={longitude} latitude={latitude}>
                  <Popover
                    overlayClassName='popover'
                    title={() => <p>{clusterPopoverLabel || 'Projects in this location'}</p>}
                    content={() => (
                      <div className='cluster-elements-list'>
                        {supercluster?.getLeaves(cluster.id as number).map((cluster) => {
                          return (
                            <div
                              onClick={() => (onLocationClick ? onLocationClick(String(cluster.properties.id)) : null)}
                              key={cluster.id}
                              className='cluster-element'
                            >
                              {cluster.properties.locationName}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  >
                    <div className='cluster-icon'>{point_count}</div>
                  </Popover>
                </Marker>
              );
            } else {
              return (
                <Marker
                  onClick={() => (onLocationClick ? onLocationClick(String(id)) : null)}
                  key={id}
                  longitude={longitude}
                  latitude={latitude}
                  anchor='bottom'
                >
                  {customMarker ? (
                    customMarker(String(id))
                  ) : (
                    <div className='marker-icon'>
                      <p className='icon'>
                        <span className='icon-value'>{id}</span>
                      </p>
                    </div>
                  )}
                </Marker>
              );
            }
          })}
        </ReactMapGl>
      )}
    </div>
  );
};

export default MapPreview;
