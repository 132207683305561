import React from 'react';
import { MapLayerMouseEvent, MapRef, Point } from 'react-map-gl';
import { StylesConfig } from 'react-select';
import turf from '@turf/centroid';
import { AllGeoJSON } from '@turf/helpers';
import { ICountryProperties } from '@digital-office/common/interfaces';
import { ReactComponent as MapIcon } from '@assets/svg/location_map.svg';
import { exceptionalBoundaries } from './constants';

//todo: move to .env

const MAPBOX_API_KEY =
  'pk.eyJ1IjoiZGF2aWRlcGljaXN0cmVsbGkiLCJhIjoiY2t6bXUxeDI1MDJheDJubGM1dHllb3dxaiJ9.p5k7y5qmq1yNMATsayJf5Q';

const mapboxStyle = 'mapbox://styles/ie3yit/cjok5bbmd0ach2spkmxoeaziz';

interface Feature {
  id: string | number | undefined;
  type: string;
  features: Feature[];
}

const enableMapboxHandlers = (mapRef: MapRef) => {
  const map = mapRef.getMap();
  map.doubleClickZoom.enable();
  map.keyboard.enable();

  map.flyTo({ zoom: 2.5 });
  map.removeFeatureState({ source: 'grouped-countries' });
};

const disableMapboxHandlers = (mapRef: MapRef) => {
  const map = mapRef.getMap();
  map.doubleClickZoom.disable();
  map.keyboard.disable();
};

const unpackCoordinates = (coordinates: any): number[][] => {
  if (!coordinates) {
    return [];
  }
  const coords = [];
  for (const coord of coordinates) {
    if (!Array.isArray(coord[0])) {
      coords.push(coord);
    } else {
      coords.push(...unpackCoordinates(coord));
    }
  }
  return coords;
};

const calculateBounds = (country: any) => {
  if (!country) return {};
  const coords = unpackCoordinates(country.geometry.coordinates);

  const longitude = coords.map((coord: number[]) => coord[0]);
  const latitude = coords.map((coord: number[]) => coord[1]);

  const west = Math.min(...longitude);
  const east = Math.max(...longitude);
  const south = Math.min(...latitude);
  const north = Math.max(...latitude);

  return { west, east, south, north };
};

const multiDropdownCustomStyles: StylesConfig = {
  control: (base) => ({
    ...base,
    background: '#1E2A31',
    border: 'none !important',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'white',
    fontSize: 14,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#fff',
    fontSize: 12,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: '#3A667C',
    },
  }),
  clearIndicator: (defaultStyles) => {
    return {
      ...defaultStyles,
      fill: '#3A667C',
    };
  },
};

const countryDrawerProps = (
  setSelectedCountry: React.Dispatch<React.SetStateAction<ICountryProperties | null>>,
  mapRef: MapRef,
  isConfigured: boolean
) => ({
  headerStyle: { backgroundColor: isConfigured ? '#2c3235' : '#0A6EB1', height: 60, borderBottom: 'none' },
  bodyStyle: { backgroundColor: '#232D34', padding: isConfigured ? 0 : 24 },
  mask: false,
  onClose: () => {
    setSelectedCountry(null);
    enableMapboxHandlers(mapRef);
  },
});

const projectDrawerProps = (
  name: string,
  iconSrc: string,
  setSelectedProjectId: React.Dispatch<React.SetStateAction<string>>
) => ({
  className: 'drawer drawer-projects',
  mask: true,
  title: (
    <div className='title-wrapper'>
      <MapIcon className='project-icon' />
      <span className='country-label'>{name}</span>
    </div>
  ),
  onClose: () => {
    setSelectedProjectId('');
  },
  headerStyle: {
    backgroundColor: '#CC6027',
    height: 60,
    borderBottom: 'none',
  },
  bodyStyle: {
    padding: 0,
    backgroundColor: '#1E252A',
  },
});

const onClickHandler = (
  event: MapLayerMouseEvent,
  filteredCountries: Feature,
  mapRef: MapRef,
  setSelectedCountry: React.Dispatch<React.SetStateAction<ICountryProperties | null>>
) => {
  if (event.features) {
    const feature = event.features[0];

    // calculateBounds

    if (!feature?.geometry) return false;
    const centroid = turf(feature?.geometry as AllGeoJSON).geometry.coordinates;

    mapRef.getMap().flyTo({
      zoom: 4,
      speed: 0.45,
      center: exceptionalBoundaries[feature?.properties?.id] || centroid,
      pitch: 25,
      padding: { right: 150, left: 0, top: 0, bottom: 0 },
    });

    disableMapboxHandlers(mapRef);
    setSelectedCountry(feature?.properties as ICountryProperties);
  }
};

const onMouseHandler = (
  event: MapLayerMouseEvent,
  selectedCountry: ICountryProperties | null,
  hoveredCountry: number | null,
  setHoveredCountry: React.Dispatch<React.SetStateAction<number | null>>
) => {
  if (event.features) {
    const featureId = event.features[0]?.id;

    if (!featureId || selectedCountry) {
      return (event.target.getCanvas().style.cursor = 'default');
    }

    if (hoveredCountry && featureId !== hoveredCountry) {
      event.target.removeFeatureState({ source: 'grouped-countries', id: hoveredCountry }, 'select');
    }

    setHoveredCountry(featureId as number);
    event.target.getCanvas().style.cursor = 'pointer';
    event.target.setFeatureState({ source: 'grouped-countries', id: featureId }, { select: true });
  }
};

const mapInfrastructureCategories = (source: string[]) => {
  return source.map((categoryName) => ({
    label: categoryName,
    value: categoryName,
  }));
};

const highlightedCountriesBorderLayer = {
  id: 'geojson-layer-borders',
  type: 'line' as 'sky',
  beforeId: 'country-label',
  source: 'grouped-countries',
};

const highlightedCountriesLayer = {
  id: 'grouped-geojson-layer-fill',
  type: 'fill' as 'sky',
  beforeId: 'country-label',
  paint: {
    'fill-color': ['case', ['boolean', ['feature-state', 'select'], false], '#5cc3e6', '#135D7E'],
  },
};

export {
  multiDropdownCustomStyles,
  countryDrawerProps,
  projectDrawerProps,
  enableMapboxHandlers,
  disableMapboxHandlers,
  calculateBounds,
  onClickHandler,
  onMouseHandler,
  mapInfrastructureCategories,
  MAPBOX_API_KEY,
  mapboxStyle,
  highlightedCountriesBorderLayer,
  highlightedCountriesLayer,
};
