import React, { FC } from 'react';
import { FormApi, Radio, RadioGroup } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import * as Yup from 'yup';
import { IAssessmentFormData } from '@digital-office/pages/FieldAssessmentReports/components/AssessmentChecklistStep';

interface IAssessmentConductedList {
  name: string;
  values: IAssessmentFormData;
  formRef: React.RefObject<FormApi>;
}

const getAssessmentSecurityListYupValidation = () => {
  return Yup.object().shape({
    chain_linked_fence_razor_wires_etc: Yup.string().required('This field is required'),
    main_pedestrian_gates: Yup.string().required('This field is required'),
    guardhouse_guard_posts: Yup.string().required('This field is required'),
    boom_barrier: Yup.string().required('This field is required'),
    hesco_security_barrier_walls: Yup.string().required('This field is required'),
    security_bunker: Yup.string().required('This field is required'),
  });
};

const AssessmentSecurityList: FC<IAssessmentConductedList> = ({ formRef }) => {
  return (
    <div className='assessment-details-list-container'>
      <div className='table-header'>
        <div className='headers'>
          <span>Yes</span>
          <span>No</span>
        </div>
      </div>
      <InformedField
        name='data[security_works_required][chain_linked_fence_razor_wires_etc]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Chain-linked fence, razor wires, etc.?</span>
            <RadioGroup className='radio' name='data[security_works_required][chain_linked_fence_razor_wires_etc]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <InformedField
        name='data[security_works_required][main_pedestrian_gates]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Main & pedestrian gates?</span>
            <RadioGroup className='radio' name='data[security_works_required][main_pedestrian_gates]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[security_works_required][guardhouse_guard_posts]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Guardhouse & guard posts?</span>
            <RadioGroup className='radio' name='data[security_works_required][guardhouse_guard_posts]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[security_works_required][boom_barrier]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Boom barrier?</span>
            <RadioGroup className='radio' name='data[security_works_required][boom_barrier]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[security_works_required][hesco_security_barrier_walls]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>HESCO security barrier walls?</span>
            <RadioGroup className='radio' name='data[security_works_required][hesco_security_barrier_walls]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[security_works_required][security_bunker]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Security Bunker?</span>
            <RadioGroup className='radio' name='data[security_works_required][security_bunker]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
    </div>
  );
};

export { getAssessmentSecurityListYupValidation, AssessmentSecurityList };
