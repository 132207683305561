import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainMenu } from '@ui-modules';
import { useBanner, MaintenanceBanner } from '@unbooking/ui-kit';
import { ReactComponent as RequestsIcon } from '@assets/svg/drafting-compass.svg';
import { ReactComponent as ProjectsIcon } from '@assets/svg/projects.svg';
import { ReactComponent as AssessmentIcon } from '@assets/svg/assessment.svg';
import { ReactComponent as ChartIcon } from '@assets/svg/chart.svg';
import { ReactComponent as FFAIcon } from '@assets/svg/ffa-icon.svg';
import { ReactComponent as FeedIcon } from '@assets/svg/feed-icon.svg';
import { ReactComponent as ShowCase } from '@assets/svg/showcase.svg';
import { ReactComponent as MenuIcon } from '@assets/svg/menu.svg';
import useParamsParse from '@digital-office/common/hooks/useParamsParse';

import './styles.scss';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { ISwitchesResponse } from '@digital-office/common/interfaces';
import { Spin } from 'antd';
import { useOutsideClick } from '@common/utils';

const EntryPage = () => {
  const navigate = useNavigate();
  useParamsParse();
  const country = localStorage?.getItem('country') || '';

  const { bannerMessage, closeBanner } = useBanner({
    switchName: 'maintenance_notice_engineering',
    switchesUrl: `${process.env.REACT_APP_HBH_API}/v2/core/feature-flags/`,
    monolithApiKey: String(process.env.REACT_APP_UNBH_API_KEY),
  });

  const { engineeringRepository } = useRepository();

  const { data, isLoading } = useQuery<ISwitchesResponse>('waffle-switch', () => engineeringRepository.getSwitches(), {
    refetchOnWindowFocus: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data: facilities, isLoading: isFacilitiesLoading } = useQuery<any>(
    'managed-facilities',
    () => engineeringRepository.getManagedFacilities(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const isMultiplyFacility = facilities?.results.length > 1;

  const menuButtons = [
    {
      label: 'Incoming requests',
      icon: <RequestsIcon />,
      onClick: () => navigate('requests'),
      className: 'btn-requests',
    },
    {
      label: 'FFA support requests',
      icon: <FFAIcon />,
      onClick: () => navigate('ffa'),
      className: 'btn-ffa-assignments',
    },
    {
      label: 'Reporting and dashboards',
      icon: <ChartIcon />,
      onClick: () => navigate('dashboards'),
      className: 'btn-reporting',
    },
    {
      label: 'Project management',
      icon: <ProjectsIcon />,
      onClick: () => navigate('projects'),
      className: 'btn-project',
    },
    {
      label: 'Projects status feed',
      icon: <FeedIcon />,
      onClick: () => navigate('feed'),
      className: 'btn-feed',
    },
    {
      label: 'Project showcasing',
      icon: <ShowCase />,
      onClick: () => navigate('showcase'),
      className: `btn-showcasing ${
        data && Boolean(!data?.results?.find((siteSwitch) => siteSwitch.name === 'story_publishing')?.active)
          ? 'menu-item-hidden'
          : ''
      }`,
    },
    {
      label: 'Field assessment report',
      icon: <AssessmentIcon />,
      onClick: () => navigate('field-assessment-reports'),
      className: `btn-assessment-report ${
        data && Boolean(!data?.results?.find((siteSwitch) => siteSwitch.name === 'field_assessment_report')?.active)
          ? 'menu-item-hidden'
          : ''
      }`,
    },
  ];

  const subMenuButtons = [
    {
      label: 'Platform tutorial',
      onClick: () =>
        window.open(
          'https://wfp-my.sharepoint.com/personal/carmen_lumbrerasdesa_wfp_org/_layouts/15/stream.aspx?id=%2fpersonal%2fcarmen_lumbrerasdesa_wfp_org%2fDocuments%2fRecordings%2fINVITATION%20_%20Exploring%20the%20WFP%20Engineering%20Digital%20Hub-20230926_103216-Meeting%20Recording%2Emp4&referrer=Teams%2ETEAMS-ELECTRON&referrerScenario=RecapOpenInStreamButton%2Eview%2Eview&ga=1'
        ),
      className: 'btn-webinar',
    },
    {
      label: 'Construction manual',
      onClick: () => window.open('https://newgo.wfp.org/documents/engineering-construction-manual'),
      className: 'btn-manual',
    },
    {
      label: 'Engineering LTAs',
      onClick: () => window.open('https://newgo.wfp.org/collection/engineering-long-term-agreements-ltas'),
      className: 'btn-services',
    },
    {
      label: 'Template centre',
      onClick: () =>
        window.open('https://newgo.wfp.org/collection/wfp-engineering-construction-manual-download-centre'),
      className: 'btn-template',
    },
  ];

  const [showCustomMenu, setShowCustomMenu] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setShowCustomMenu(false));

  return (
    <>
      {bannerMessage ? <MaintenanceBanner message={bannerMessage} onClose={closeBanner} /> : null}
      <Spin spinning={isLoading || isFacilitiesLoading}>
        <div className='entry-page-container'>
          <MainMenu
            title={
              (
                <div className='do-title-text'>
                  {`ENGINEERING DIGITAL OFFICE - ${country}`}
                  {isMultiplyFacility ? (
                    <div className='do-title-menu' ref={wrapperRef}>
                      <MenuIcon className='do-title-menu-trigger' onClick={() => setShowCustomMenu(!showCustomMenu)} />
                      {showCustomMenu ? (
                        <div className='do-title-menu-list'>
                          <a href={String(process.env.REACT_APP_HBH_DO_URL)}>Select another location</a>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div> // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ) as any
            }
            menuButtons={menuButtons}
            subMenuButtons={subMenuButtons}
          />
          <div className='contact-block'>
            Need support with the Engineering Digital Office? Please note that you can see dedicated video demos by
            clicking on the link available for each section. Should you need further support, please
            <a href='mailto:engineering@unbooking.org'> Contact us</a>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default EntryPage;
