import React, { FC, useEffect, useState } from 'react';
import { useField } from 'informed';
import ReactMapGl, { Marker } from 'react-map-gl';
import MapBoxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import { MAPBOX_API_KEY } from '@components/GlobalImpact/utils';
import { useControl } from 'react-map-gl';
import { find } from 'lodash';
import axios from 'axios';
import { LocationData } from '@digital-office/common/interfaces';
import { ReactComponent as MapMarker } from '@assets/svg/marker.svg';
import { ReactComponent as MapMarkerFull } from '@assets/svg/marker-full.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/CloseIcon.svg';
import './styles.scss';

interface IMapField {
  name: string;
  preferredCountry?: string;
}

interface IGeocoderProps {
  onDecoderSelectHandler: (result: Result) => void;
}

interface MapboxGeocodingData {
  features: Array<{
    place_type: string;
    place_name: string;
  }>;
}

const getMapboxGeocodingPath = (params: string) => {
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${params}.json?access_token=${MAPBOX_API_KEY}`;
};

const getCoordsOfCountry = async (countryName: string) => {
  const { data } = await axios.get(getMapboxGeocodingPath(countryName));
  return find(data.features, { place_type: ['country'] });
};

const getCoord = (coord = 0) => {
  return Math.trunc(coord * 1000000) / 1000000;
};

const coordinatesGeocoder = (query: string): Result[] => {
  const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);
  if (!matches) {
    return [];
  }

  const coordinateFeature = (lng: number, lat: number) => {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: 'Lat: ' + lat + ' Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature',
    };
  };

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes as Result[];
};

const Geocoder: FC<IGeocoderProps> = ({ onDecoderSelectHandler }) => {
  const ctrl = new MapBoxGeocoder({
    accessToken: MAPBOX_API_KEY,
    placeholder: 'Search location',
    localGeocoder: coordinatesGeocoder,
    language: 'en-us',
    types: 'address, place',
    marker: false,
  });
  useControl(() => ctrl);
  ctrl.on('result', (e) => {
    onDecoderSelectHandler(e.result as Result);
  });
  return null;
};

const MapField: FC<IMapField> = (props) => {
  const { preferredCountry } = props;

  const [locationCoordinates, setLocationCoordinates] = useState<LocationData | null>(null);
  const [initCoords, setInitCoords] = useState<number[] | null>(null);

  const { fieldState, fieldApi, render, ref } = useField(props);
  const fieldValue = (fieldState.value || []) as Array<LocationData>;
  const { setValue } = fieldApi;

  useEffect(() => {
    if (!fieldValue) {
      setValue([]);
    }
  }, [fieldValue]);

  useEffect(() => {
    if (!initCoords) {
      getCoordsOfCountry(preferredCountry || '').then((data) => setInitCoords(data ? data.center : [15, 11]));
    }
  }, [initCoords]);

  const onDecoderSelectHandler = (result: Result) => {
    const country = result.context.find((data) => data.id.includes('country')).text;

    setLocationCoordinates({
      place_name: result.place_name,
      long: getCoord(result.geometry.coordinates[0]),
      lat: getCoord(result.geometry.coordinates[1]),
      country: country,
    });
  };

  if (!initCoords) return null;

  return render(
    <div ref={ref}>
      <div className='map-field-map'>
        <ReactMapGl
          attributionControl={false}
          style={{ width: '100%', height: 300 }}
          initialViewState={
            initCoords && {
              longitude: initCoords[0],
              latitude: initCoords[1],
              zoom: 4,
            }
          }
          mapStyle='mapbox://styles/mapbox/streets-v11'
          mapboxAccessToken={MAPBOX_API_KEY}
          onClick={async (event) => {
            const reverseGeocoding = await axios.get<MapboxGeocodingData>(
              getMapboxGeocodingPath(`${event.lngLat.lng}, ${event.lngLat.lat}`)
            );

            const country = reverseGeocoding.data.features.find((feature) =>
              feature.place_type.includes('country')
            )?.place_name;

            const region = reverseGeocoding.data.features.find((feature) =>
              feature.place_type.includes('region')
            )?.place_name;

            const place = reverseGeocoding.data.features.find((feature) =>
              feature.place_type.includes('place')
            )?.place_name;

            setLocationCoordinates({
              long: getCoord(event.lngLat.lng),
              lat: getCoord(event.lngLat.lat),
              place_name: reverseGeocoding.data.features[0].place_name,
              place: place,
              region: region,
              country: country || '',
            });
          }}
        >
          <Geocoder onDecoderSelectHandler={onDecoderSelectHandler} />
          {locationCoordinates && <Marker longitude={locationCoordinates.long} latitude={locationCoordinates.lat} />}
          {locationCoordinates && (
            <div className='map-field-popup'>
              <MapMarker className='map-field-popup-icon' />
              <div className='map-field-popup-content'>
                <div className='map-field-popup-place'>
                  <b>{locationCoordinates.place_name}</b> {locationCoordinates.country}
                </div>
                <div>
                  {locationCoordinates.lat}, {locationCoordinates.long}
                </div>
              </div>
              <button
                className='map-field-popup-action'
                onClick={() => {
                  setValue([...fieldValue, locationCoordinates]);
                  setLocationCoordinates(null);
                }}
              >
                Add location
              </button>
              <CloseIcon className='map-field-popup-close' onClick={() => setLocationCoordinates(null)} />
            </div>
          )}
          {fieldValue.map((locationCoordinates, idx) => (
            <Marker key={idx} longitude={locationCoordinates.long} latitude={locationCoordinates.lat}>
              <div className='map-field-marker-custom'>
                <div className='map-field-marker-custom-count'>{idx + 1}</div>
                <MapMarkerFull className='map-field-marker-custom-icon' />
              </div>
            </Marker>
          ))}
        </ReactMapGl>
      </div>
      <div className='map-field-options'>
        {fieldValue.map(({ lat, long, place_name }, idx) => (
          <div key={idx} className='map-field-option'>
            <div className='map-field-option-icon-wrapper'>
              <div className='map-field-option-count'>{idx + 1}</div>
              <MapMarkerFull className='map-field-option-icon' />
            </div>
            <div className='map-field-option-text'>{`${place_name} ${lat} ${long}`}</div>
            <CloseIcon
              title='Delete location'
              className='map-field-option-delete'
              onClick={() => {
                const newValue = [...fieldValue];
                newValue.splice(idx, 1);
                setValue(newValue);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapField;
