import React, { FC } from 'react';
import { FormApi, Radio, RadioGroup } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import * as Yup from 'yup';
import { IAssessmentFormData } from '@digital-office/pages/FieldAssessmentReports/components/AssessmentChecklistStep';

interface IAssessmentConductedList {
  name: string;
  values: IAssessmentFormData;
  formRef: React.RefObject<FormApi>;
}

const getAssessmentSteelSupplyListYupValidation = () => {
  return Yup.object().shape({
    galvanized_iron_roofing: Yup.string().required('This field is required'),
    embossed_metal_plates_staircases: Yup.string().required('This field is required'),
    handrails_safety_grills_manhole_covers: Yup.string().required('This field is required'),
    miscellaneous_metal_works: Yup.string().required('This field is required'),
  });
};

const AssessmentSteelSupplyList: FC<IAssessmentConductedList> = ({ formRef }) => {
  return (
    <div className='assessment-details-list-container'>
      <div className='table-header'>
        <div className='headers'>
          <span>Yes</span>
          <span>No</span>
        </div>
      </div>
      <InformedField
        name='data[steel_ironworks_supply_fabrication_installation_required][galvanized_iron_roofing]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Roofing with galvanized iron sheets?</span>
            <RadioGroup
              name='data[steel_ironworks_supply_fabrication_installation_required][galvanized_iron_roofing]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <InformedField
        name='data[steel_ironworks_supply_fabrication_installation_required][embossed_metal_plates_staircases]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Staircases with embossed metal plates?</span>
            <RadioGroup
              name='data[steel_ironworks_supply_fabrication_installation_required][embossed_metal_plates_staircases]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[steel_ironworks_supply_fabrication_installation_required][handrails_safety_grills_manhole_covers]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Handrails, safety grills & manhole covers, etc.?</span>
            <RadioGroup
              name='data[steel_ironworks_supply_fabrication_installation_required][handrails_safety_grills_manhole_covers]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[steel_ironworks_supply_fabrication_installation_required][miscellaneous_metal_works]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Miscellaneous metal works?</span>
            <RadioGroup
              name='data[steel_ironworks_supply_fabrication_installation_required][miscellaneous_metal_works]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
    </div>
  );
};

export { getAssessmentSteelSupplyListYupValidation, AssessmentSteelSupplyList };
