import React from 'react';

const HomeIconComponent = (): JSX.Element => {
  return (
    <svg style={{ width: '100%', height: '100%' }} fill='#fff' viewBox='0 0 156 156'>
      <path d='M155.5 81.8119C155.5 86.0256 152.25 89.4356 148.234 89.4356C146.319 89.4356 144.59 88.6433 143.293 87.3746L143.276 87.3919L78.186 19.1L78 18.9106L12.8713 87.2426H12.8659C11.5527 88.5974 9.75251 89.4413 7.76631 89.4413C3.75014 89.4413 0.5 86.0313 0.5 81.8176C0.5 79.6591 1.35904 77.713 2.73242 76.3237L72.7637 2.84222C74.0878 1.39556 75.9427 0.5 78 0.5C78.1313 0.5 78.2572 0.505741 78.383 0.511481C78.4049 0.511481 78.4213 0.517222 78.4432 0.517222C78.5526 0.522963 78.6621 0.534445 78.7715 0.545926C78.777 0.545926 78.7879 0.545926 78.7934 0.545926C80.5717 0.746852 82.153 1.62519 83.3129 2.92833L83.3184 2.92259L111.908 32.918V25.9085C111.908 21.6948 115.158 18.2848 119.174 18.2848C123.19 18.2848 126.44 21.6948 126.44 25.9085V48.1596L153.558 76.605L153.541 76.6222C154.745 77.9885 155.5 79.8026 155.5 81.8119ZM140.967 98.8619V117.387V127.548V147.876C140.967 152.09 137.717 155.5 133.701 155.5H96.2041V106.755H58.0614V155.5H22.2989C18.2828 155.5 15.0326 152.09 15.0326 147.876V127.548V117.387V98.8619L78 32.7974L140.967 98.8619Z' />
    </svg>
  );
};

export default HomeIconComponent;
