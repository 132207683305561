import { Task, ViewMode } from 'gantt-task-react';
import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { IProjectPlan } from '@digital-office/common/interfaces';
import { format } from 'date-fns';

type ExtendedTask = Task & { description?: string };

interface ITooltipProps {
  task: ExtendedTask;
}

const GanttTooltip: FC<ITooltipProps> = ({ task }) => {
  return (
    <div className='gantt-tooltip'>
      <div>
        Name: <strong>{task.name}</strong>
      </div>
      <div>
        Description: <strong>{task.description}</strong>
      </div>
      <div>
        Start date: <strong>{format(new Date(task.start), 'dd-MM-yyyy')}</strong>
      </div>
      <div>
        End date: <strong>{format(new Date(task.end), 'dd-MM-yyyy')}</strong>
      </div>
      <div>
        Duration:{' '}
        <strong>{`Duration: ${~~((task.end.getTime() - task.start.getTime()) / (1000 * 60 * 60 * 24))} day(s)`}</strong>
      </div>
    </div>
  );
};

const parseTasksToGantt = (plan: IProjectPlan) => {
  const { start_date, end_date, tasks } = plan;

  if (!start_date || !end_date || !tasks) return [];

  const result: ExtendedTask[] = [
    {
      start: new Date(start_date.year, start_date.month, start_date.day),
      end: new Date(end_date.year, end_date.month, end_date.day),
      name: plan.code,
      id: plan.code,
      progress: plan.overall_completion,
      type: 'project',
      hideChildren: false,
      displayOrder: 1,
      isDisabled: true,
    },
  ];

  tasks.forEach((task) => {
    result.push({
      start: new Date(task.start_date.year, task.start_date.month, task.start_date.day),
      end: new Date(task.end_date.year, task.end_date.month, task.end_date.day),
      name: (
        <div title=''>
          <Tooltip
            placement='right'
            overlayClassName='gant-task-title'
            className='gant-task-title-open'
            title={` Phase ${task.number} - ${task.description}`}
          >
            Phase {task.number} - {task.description}
          </Tooltip>
        </div>
      ) as any,
      id: String(task.number),
      progress: task.overall_completion,
      description: task.description,
      type: 'task',
      project: plan.code,
      displayOrder: task.number + 1,
      isDisabled: true,
    });
  });

  return result;
};

const getStartEndDateForProject = (tasks: Task[], projectId: string) => {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
};

const getGanttHandlers = (tasks: Task[], setTasks: React.Dispatch<React.SetStateAction<Task[]>>) => {
  const getColumnWidth = (view: ViewMode) => {
    let columnWidth = 65;

    if (view === ViewMode.Year) {
      columnWidth = 350;
    } else if (view === ViewMode.Month) {
      columnWidth = 300;
    } else if (view === ViewMode.Week) {
      columnWidth = 250;
    }

    return columnWidth;
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleTaskChange = (task: Task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) => (t.id === task.project ? changedProject : t));
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    setTasks(tasks.filter((t) => t.id !== task.id));
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  return { getColumnWidth, handleExpanderClick, handleTaskChange, handleTaskDelete, handleProgressChange };
};

export { getGanttHandlers, GanttTooltip, parseTasksToGantt };
