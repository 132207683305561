import { Drawer, Popover } from 'antd';
import React, { FC, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMapGl, {
  Source,
  Layer,
  Marker,
  MapboxGeoJSONFeature,
  Point,
  MapRef,
  NavigationControl,
  MapLayerMouseEvent,
} from 'react-map-gl';
import useSupercluster from 'use-supercluster';
import { useQuery } from 'react-query';
import { find } from 'lodash';
import turf from '@turf/centroid';
import { IDropdownOption } from '@ui-modules/types';
import { MapMouseEvent } from 'mapbox-gl';
import { useRepository } from '@context/repository.context';
import {
  Feature,
  ICountryOverview,
  ICountryProperties,
  IProjectData,
  IProjectsOverview,
} from '@digital-office/common/interfaces';
import { CloseCircleOutlined } from '@ant-design/icons';
import Menu from '@components/GlobalImpact/components/Menu';
import CountrySummary from './components/CountrySummary';
import ProjectSummary from './components/ProjectSummary';
import DiamondIcon from './components/DiamondIcon';
import { exceptionalBoundaries } from './constants';
import {
  calculateBounds,
  countryDrawerProps,
  disableMapboxHandlers,
  MAPBOX_API_KEY,
  mapInfrastructureCategories,
  onClickHandler,
  onMouseHandler,
  projectDrawerProps,
  mapboxStyle,
  highlightedCountriesBorderLayer,
  highlightedCountriesLayer,
  enableMapboxHandlers,
} from '@components/GlobalImpact/utils';
import 'mapbox-gl/dist/mapbox-gl.css';
import './styles.scss';

//@todo: refactor;

const GlobalImpact: FC = () => {
  const [selectedCategories, setSelectedCategories] = React.useState<IDropdownOption[]>([]);
  const [selectedCountry, setSelectedCountry] = React.useState<ICountryProperties | null>(null);
  const [selectedProjectId, setSelectedProjectId] = React.useState('');
  const [hoveredCountry, setHoveredCountry] = React.useState<number | null>(null);
  const [countryDropdownValue, setCountryDropdownValue] = React.useState<IDropdownOption | null>(null);
  const [features, setFeatures] = React.useState<Feature[]>([]);
  const { state } = useLocation();

  const mapRef = useRef(null);

  React.useEffect(() => {
    if (state?.persistedProjectId) {
      setSelectedProjectId(state.persistedProjectId);
      window.history.replaceState({}, document.title);
    }
  }, []);

  const countryDropdownHandler = (value: IDropdownOption) => {
    setCountryDropdownValue(value);
  };

  const { engineeringRepository } = useRepository();

  const { data: countryOverview } = useQuery<ICountryOverview>(
    `country-overview-${selectedCountry?.id}-${selectedCategories}`,
    () =>
      engineeringRepository.getCountryOverview(
        selectedCountry?.id || '',
        selectedCategories.map((category: IDropdownOption) => category.label).join(',')
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        console.log('lol', data);
        if (!data.stories.length) {
          setSelectedCountry(null);
        }
      },
      enabled: Boolean(selectedCountry),
    }
  );

  const { data: geoJson } = useQuery(`geo-json`, () => engineeringRepository.getGeoJSON(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const features = data.features.map((feature: MapboxGeoJSONFeature, key: number) => ({
        ...feature,
        id: key,
      }));

      setFeatures(features);
    },
  });

  const { data: projectData } = useQuery<IProjectData>(
    `project-overview-${selectedProjectId}`,
    () => engineeringRepository.getProjectData(selectedProjectId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(selectedProjectId),
    }
  );

  const { data: projectsOverview } = useQuery<IProjectsOverview>(
    `projects-overview-${selectedCategories}`,
    () =>
      engineeringRepository.getProjectsOverview(
        selectedCategories.map((category: IDropdownOption) => category.label).join(',')
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const countriesListDropdown = projectsOverview
    ? projectsOverview.countries.map((country) => {
        const feature = find(geoJson?.features, (feature) => feature.id === country);
        return { label: feature?.properties.name, value: country };
      })
    : [];

  const onProjectClickHandler = (uuid: string) => {
    setSelectedProjectId(uuid);
  };

  const onMouseLeaveHandler = (event: MapMouseEvent) => {
    hoveredCountry && event.target.removeFeatureState({ source: 'grouped-countries', id: hoveredCountry }, 'select');
    setHoveredCountry(null);
  };

  const onCountryDropdownClick = () => {
    if (!countryDropdownValue) return false;

    // @ts-ignore
    const map = mapRef?.current.getMap();
    const feature = find(
      filteredCountries.features,
      (mbFeature) => mbFeature.properties.id === countryDropdownValue?.value
    );

    if (!feature?.geometry) return false;
    const centroid = turf(feature.geometry).geometry.coordinates;

    map.setFeatureState({ source: 'grouped-countries', id: feature?.id }, { select: true });

    map.flyTo({
      zoom: 4,
      speed: 0.45,
      center: exceptionalBoundaries[feature.properties.id] || centroid,
      pitch: 25,
    });

    disableMapboxHandlers(mapRef.current as unknown as MapRef);

    setSelectedCountry(feature?.properties);
  };

  const filteredCountries = {
    type: 'FeatureCollection',
    features:
      (features &&
        features.filter((feature: Feature) => projectsOverview?.countries.includes(feature?.properties?.id))) ||
      [],
  };

  const points =
    countryOverview?.stories?.map((story) => ({
      type: 'Feature' as any,
      properties: {
        cluster: false,
        cityId: story.uuid,
        cityName: story.city_name,
        icon_src: story.infrastructure_category_icon,
        title: story.title,
        infrastructure_name: story.infrastructure_name,
        infrastructure_category_color: story.infrastructure_category_color,
      },
      geometry: {
        type: 'Point' as any,
        coordinates: [story.city_lng, story.city_lat],
      },
    })) || [];

  const { west, east, south, north } = calculateBounds(
    find(filteredCountries?.features, (country) => country?.properties?.id === selectedCountry?.id)
  );

  const [viewport, setViewport] = useState({
    zoom: 2.6,
    longitude: 20.68674593817775,
    latitude: 7.7439240888921,
  });

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: [west ?? 0, south ?? 0, east ?? 0, north ?? 0],
    zoom: viewport.zoom,
    options: { radius: 30, maxZoom: 30 },
  });

  const onMapClickHandler = (e: MapLayerMouseEvent) => {
    if (!selectedCountry) {
      onClickHandler(e, filteredCountries as Feature, mapRef.current as unknown as MapRef, setSelectedCountry);
    } else {
      setSelectedCountry(null);
      enableMapboxHandlers(mapRef.current as unknown as MapRef);
    }
  };

  return (
    <div className='map-container'>
      <Menu
        projectsOverviewData={projectsOverview || null}
        onCategorySelectChange={(value: IDropdownOption[]) => {
          setSelectedCategories(value);
        }}
        categories={projectsOverview ? mapInfrastructureCategories(projectsOverview.infrastructure_categories) : []}
        countryDropdownHandler={countryDropdownHandler}
        onCountryDropdownClick={onCountryDropdownClick}
        countriesListDropdown={countriesListDropdown}
        isItemSelected={Boolean(selectedCountry || selectedProjectId)}
      />
      <ReactMapGl
        attributionControl={false}
        initialViewState={viewport}
        style={{ height: '91vh', width: '100%' }}
        mapStyle={mapboxStyle}
        minZoom={2}
        maxZoom={6}
        mapboxAccessToken={MAPBOX_API_KEY}
        doubleClickZoom={false}
        onMouseMove={(e) => onMouseHandler(e, selectedCountry, hoveredCountry, setHoveredCountry)}
        onMouseLeave={(event) => !selectedCountry && onMouseLeaveHandler(event)}
        onClick={onMapClickHandler}
        interactiveLayerIds={['geojson-layer-borders', 'grouped-geojson-layer-fill']}
        onMove={(evt) => {
          setViewport(evt.viewState);
        }}
        ref={mapRef}
      >
        <NavigationControl />
        <Drawer
          getContainer={false}
          className='drawer'
          visible={Boolean(selectedCountry || selectedProjectId)}
          title={<p className='drawer-title'>{selectedCountry && selectedCountry?.name}</p>}
          closeIcon={<CloseCircleOutlined className='close-icon' />}
          style={{ height: '100%', overflow: 'auto' }}
          placement='right'
          {...(projectData
            ? projectDrawerProps(projectData.city_name, projectData.infrastructure_category_icon, setSelectedProjectId)
            : countryDrawerProps(
                setSelectedCountry,
                mapRef.current as unknown as MapRef,
                Boolean(countryOverview?.country_page_data)
              ))}
        >
          {countryOverview && !selectedProjectId && (
            <CountrySummary onProjectClickHandler={onProjectClickHandler} data={countryOverview} />
          )}
          {projectData && (
            <ProjectSummary projectId={selectedProjectId} country={selectedCountry?.name || ''} data={projectData} />
          )}
        </Drawer>
        <Source id='grouped-countries' type='geojson' data={filteredCountries as unknown as MapboxGeoJSONFeature}>
          <Layer {...highlightedCountriesLayer} />
          <Layer {...highlightedCountriesBorderLayer} />
        </Source>
        {selectedCountry && (
          <Source type='geojson' data={filteredCountries as unknown as MapboxGeoJSONFeature}>
            {clusters.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              // @ts-ignore
              const { cluster: isCluster, point_count } = cluster.properties;

              if (isCluster) {
                return (
                  <Marker key={cluster.id} longitude={longitude} latitude={latitude}>
                    <Popover
                      overlayClassName='popover'
                      title={() => <p>Projects in this location</p>}
                      content={() => (
                        <div className='cluster-elements-list'>
                          {supercluster?.getLeaves(cluster.id as number).map((cluster) => {
                            return (
                              <div
                                key={cluster.id}
                                className='cluster-element'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onProjectClickHandler(cluster.properties.cityId);
                                }}
                              >
                                <DiamondIcon
                                  customClassNames='cluster-element-icon'
                                  label={`${cluster.properties.title} - ${cluster.properties.cityName}`}
                                  iconSrc={cluster.properties.icon_src}
                                  color={cluster.properties.infrastructure_category_color}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    >
                      <div className='cluster-icon'>{point_count}</div>
                    </Popover>
                  </Marker>
                );
              } else {
                return (
                  <Marker
                    onClick={(e) => {
                      e.originalEvent.stopPropagation();
                      onProjectClickHandler(cluster.properties.cityId);
                    }}
                    key={cluster.properties.cityId}
                    longitude={longitude}
                    latitude={latitude}
                    anchor='bottom'
                  >
                    <DiamondIcon
                      color={cluster.properties.infrastructure_category_color}
                      iconSrc={cluster.properties.icon_src}
                    />
                  </Marker>
                );
              }
            })}
          </Source>
        )}
      </ReactMapGl>
    </div>
  );
};

export default GlobalImpact;
