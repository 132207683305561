import React from 'react';
import { UseMutationResult } from 'react-query';
import { find, isEmpty, isEqual } from 'lodash';
import { IPhase } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection';
import { IDropdownOption } from '@ui-modules/types';
import { IProjectDetailsResponse } from '@digital-office/common/interfaces';
import { ILocationObject } from '@digital-office/pages/ProjectsManagementPages/components/LocationModal';
import { FormState, utils } from 'informed';
import phase_data from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection/data';
import { parseLocationsToPayload, parsePhasesToPayload } from '@digital-office/pages/ProjectsManagementPages/utils';
import uuidv4 = utils.uuidv4;

const parsePhases = (phases: IPhase[]) => {
  return phase_data.map((phase, index) => {
    const phaseDetails = find(phases, { number: index }) as IPhase;
    return phaseDetails
      ? {
          ...phase,
          ...phaseDetails,
          removed: false,
          deliverables:
            phaseDetails?.deliverables.map((deliverable) => ({
              ...deliverable,
              name: deliverable.name,
            })) || [],
          number: phaseDetails?.number || index,
          title: phase.title,
          description: phase.description,
        }
      : {
          ...phase,
          removed: true,
        };
  });
};

const parseLocationData = (
  locations: IProjectDetailsResponse['locations'],
  infrastructureOptions: IDropdownOption[]
) => {
  const parsedLocations = locations?.map((location) => {
    return {
      uuid: uuidv4(),
      description: location.project_infrastructures[0]?.description,
      infrastructure: infrastructureOptions?.find(
        (infrastructure) => infrastructure.value === location.project_infrastructures[0]?.infrastructure
      ) as IDropdownOption,
      locationData: {
        lat: Number(location.latitude),
        long: Number(location.longitude),
        place_name: location.name,
        country: location.country,
      },
    };
  });
  return parsedLocations;
};

const checkLocationsAreModified = (
  projectDetails: IProjectDetailsResponse,
  locations: ILocationObject[],
  infrastructureOptions: IDropdownOption[]
) => {
  if (projectDetails) {
    return !isEqual(locations, parseLocationData(projectDetails?.locations, infrastructureOptions));
  } else return false;
};

const checkPhasesAreModified = (projectDetails: IProjectDetailsResponse, phases: IPhase[]) => {
  if (projectDetails) {
    return !isEqual(phases, parsePhases(projectDetails?.phases));
  } else return false;
};

const updateValues = (
  projectDetails: IProjectDetailsResponse,
  formData: FormState | null,
  locations: ILocationObject[],
  phases: IPhase[],
  updateDeliverable: UseMutationResult<any, any, any, unknown>,
  infrastructureOptions: IDropdownOption[]
) => {
  const formValues: any = formData?.modified || {};
  Object.keys(formValues).forEach((key) => {
    if (typeof formValues[key] === 'object') {
      if (key === 'topics') {
        formValues[key] = formValues[key].map((option: IDropdownOption) => option.value);
      } else {
        formValues[key] = formValues[key].value as IDropdownOption;
      }
    }
  });

  const locationValues = checkLocationsAreModified(projectDetails, locations, infrastructureOptions) ? locations : [];
  const phasesValues = checkPhasesAreModified(projectDetails, phases) ? parsePhasesToPayload(phases) : [];

  updateDeliverable.mutate({
    ...formValues,
    ...(locationValues.length && { locations: parseLocationsToPayload(locationValues) }),
    ...(phasesValues.length && {
      phases: phasesValues,
    }),
  });
};

const checkFormFieldsAreModified = (formData: FormState) => {
  const values = formData?.initialValues;
  const modified = formData?.modified;
  const errors = formData?.errors;

  if (modified && values && isEmpty(errors)) {
    const modifiedValuesLol = Object.keys(modified).filter((key) => !isEqual(modified[key], values[key]));
    return modifiedValuesLol.length !== 0;
  } else return false;
};

const checkApplyButtonShouldBeDisabled = (
  formData: FormState | null,
  locations: ILocationObject[],
  phases: IPhase[],
  setIsApplyButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  projectDetails: IProjectDetailsResponse,
  infrastructureOptions: IDropdownOption[]
) => {
  if (
    !locations.length ||
    phases
      .filter((phase) => !phase.removed)
      .some((phase) => !phase.deliverables.length || !phase.start_date || !phase.end_date)
  ) {
    return setIsApplyButtonDisabled(true);
  }

  const form = formData && checkFormFieldsAreModified(formData);
  const isLocationsModified = checkLocationsAreModified(projectDetails, locations, infrastructureOptions);
  const phasesSection = checkPhasesAreModified(projectDetails, phases);

  if (form || isLocationsModified || phasesSection) {
    return setIsApplyButtonDisabled(false);
  } else {
    return setIsApplyButtonDisabled(true);
  }
};

export { parsePhases, parseLocationData, updateValues, checkApplyButtonShouldBeDisabled };
