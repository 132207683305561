import React, { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useRepository } from '@context/repository.context';
import { Spin, message, Tooltip } from 'antd';
import { DetailsContainer, PageTitle, Dropdown, Button } from '@ui-modules';
import './styles.scss';
import { cloneDeep, find, merge } from 'lodash';
import {
  EditableFields,
  IDetailsItem,
  IRequestDetailsConfigItem,
  IServiceRequestDetails,
  RequestLocation,
} from '@digital-office/common/interfaces';
import detailsConfig from '@digital-office/pages/RequestsDetailsPage/detailsConfig';
import { Form } from 'informed';
import {
  convertFromValuesToRequestBody,
  focalPointDropdownCustomStyles,
  FocalPointModal,
  getDropdownStatusComponentProps,
  getParsedLocations,
  getParsedServices,
  getValidationSchema,
  History,
  statusDropdownCustomStyles,
} from '@digital-office/pages/RequestsDetailsPage/utils';
import { IDropdownOption } from '@ui-modules/types';
import { PaperClipOutlined } from '@ant-design/icons';
import { WarningFilled } from '@ant-design/icons';
import LocationSection from '../ProjectsManagementPages/components/LocationSection';
import { ILocationObject } from '../ProjectsManagementPages/components/LocationModal';

const RequestsDetailsPage: FC = () => {
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';
  const { engineeringRepository } = useRepository();
  const navigate = useNavigate();

  const { id: serviceRequestId } = useParams<{ id: string }>();

  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [editedFields, setEditedFields] = React.useState({});
  const [isHistoryModeActive, setIsHistoryModeActive] = React.useState(false);
  const [selectedFocalPoint, setSelectedFocalPoint] = React.useState<IDropdownOption | null>(null);
  const [isUpdateProcessing, setIsUpdateProcessing] = React.useState<boolean>(false);

  const { data: serviceRequestDetails, isLoading } = useQuery(
    `request-${serviceRequestId}`,
    () => engineeringRepository.getServiceRequestDetails(facilityId, serviceRequestId || ''),
    {
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status === 404) {
          message.error('Request with such id was not found');

          setTimeout(() => {
            navigate('/digital-office/requests');
          }, 1000);
        }
      },
    }
  );

  const { data: locationTypes } = useQuery(`location-types`, () => engineeringRepository.getServiceRequestLocations(), {
    refetchOnWindowFocus: false,
  });
  const { data: services } = useQuery(`services`, () => engineeringRepository.getServiceRequestServices(facilityId), {
    refetchOnWindowFocus: false,
  });

  const { data: history, isLoading: isHistoryLoading } = useQuery(
    `history`,
    () => engineeringRepository.getServiceRequestHistory(facilityId, serviceRequestId as string),
    {
      refetchOnWindowFocus: false,
      enabled: isHistoryModeActive,
    }
  );

  const { data: focalPointsOptions } = useQuery<IDropdownOption[]>(
    `focal-points-options`,
    () => engineeringRepository.getFocalPointsOptions(facilityId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const mapDetailItems = (source: IServiceRequestDetails, items: IRequestDetailsConfigItem[]): IDetailsItem[] => {
    return items.map((item) => {
      const content = item.content ? item.content(source[item.key], isEditMode) : source[item.key];
      return {
        label: item.label,
        content: (
          <div data-testid={item.key} className={!isEditMode || item.editable ? 'text-ellipsis' : ''}>
            {!isEditMode ? (
              <Tooltip
                getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                overlayStyle={
                  item.tooltip_full_width ? { width: 'auto', maxWidth: 'none', whiteSpace: 'break-spaces' } : undefined
                }
                placement='bottomLeft'
                title={content}
              >
                {content}
              </Tooltip>
            ) : (
              content
            )}
          </div>
        ),
        className: item.className ?? '',
      };
    });
  };

  const renderDetailElements = (source: IServiceRequestDetails): JSX.Element[] | null => {
    if (!source) return null;

    return detailsConfig(
      getParsedLocations(locationTypes?.results),
      getParsedServices(services?.results),
      editedFields
    ).map((section) => {
      return (
        <DetailsContainer
          key={section.section_title}
          title={section.section_title}
          items={mapDetailItems(merge(source, source.user), section.items)}
        />
      );
    });
  };

  const formChangeHandler = (values: any) => {
    setEditedFields(cloneDeep(values.values));
  };

  React.useEffect(() => {
    const initialFields = { ...serviceRequestDetails };
    if ('owned' in initialFields) {
      initialFields['owned'] = initialFields['owned'] !== null ? (initialFields['owned'] ? 'owned' : 'rented') : null;
    }

    setEditedFields(initialFields);
  }, [serviceRequestDetails]);

  const updateRequestMutation = useMutation(
    (data: EditableFields) => engineeringRepository.updateServiceRequest(facilityId, serviceRequestId as string, data),
    {
      onSuccess: () => {
        window.location.reload();
      },
      onError: (error: any) => {
        setIsUpdateProcessing(false);
        if (error.response.status === 404) {
          message.error('ID of that service request does not exist');
        } else if (error.response.status === 400) {
          const errorKeys = Object.keys(error.response.data);
          const result = errorKeys
            .map((errorKey) => {
              return error.response.data[errorKey];
            })
            .join(', ');
          message.error(result);
        }
      },
    }
  );

  const submitForm = (values: any, modified: any) => {
    const formValue = convertFromValuesToRequestBody(values, cloneDeep(modified), serviceRequestDetails);
    updateRequestMutation.mutate(formValue);
  };

  const statusChangeHandler = (value: string) => {
    updateRequestMutation.mutate({ status: value });
  };

  const focalPointHandler = (option: IDropdownOption) => {
    if (option.value !== serviceRequestDetails.focal_point && !isUpdateProcessing) {
      setIsUpdateProcessing(true);
      message.info('Request is processing...');
      updateRequestMutation.mutate({ focal_point: option.value });
    }
  };

  const mapAttachmentsToComponent = () => {
    return serviceRequestDetails?.attachments.map((item: string) => (
      <p className='attachments-list-element' key={item} onClick={() => window.open(item, '_blank')!.focus()}>
        {item.split('/').pop()}
      </p>
    ));
  };

  const FocalPointDropdown = () => {
    return (
      <div className='focal-point-dropdown'>
        <Dropdown
          styles={focalPointDropdownCustomStyles}
          isSearchable={false}
          label='Engineering Focal Point'
          isDisabled={!serviceRequestDetails}
          value={
            selectedFocalPoint || find(focalPointsOptions, (item) => serviceRequestDetails?.focal_point === item?.value)
          }
          onChange={(option) => {
            if (option.value !== serviceRequestDetails?.focal_point) {
              setSelectedFocalPoint(option);
            }
          }}
          options={focalPointsOptions || []}
        />
      </div>
    );
  };

  const getProjectLinks = () => {
    if (!serviceRequestDetails.project_ids?.length) return '';

    const projectLinks = serviceRequestDetails.project_ids.map((id: { code: string; uuid: string }, index: number) => {
      return (
        <span key={id.code}>
          <span onClick={() => navigate(`/digital-office/projects/${id.uuid}`)} className='project-link'>
            {id.code}
          </span>
          {index !== serviceRequestDetails.project_ids.length - 1 && ', '}{' '}
        </span>
      );
    });

    return <span> - Converted to Project {projectLinks}</span>;
  };

  const locations = serviceRequestDetails?.locations
    ? serviceRequestDetails.locations
        .map(({ name, longitude, latitude, country }: RequestLocation) => {
          return {
            uuid: '',
            locationData: {
              long: longitude,
              lat: latitude,
              place_name: name,
              country,
            },
            description: '',
            infrastructure: null,
          };
        })
        .filter(({ locationData }: ILocationObject) => locationData.long)
    : [];

  return (
    <>
      <PageTitle title={`Engineering Request, #${serviceRequestDetails?.reference_code ?? ''} - ${country}`} />
      <FocalPointDropdown />
      <Spin spinning={isLoading}>
        <div className='booking-details-container hbh-container'>
          {selectedFocalPoint && (
            <FocalPointModal
              isActive={selectedFocalPoint !== null}
              onCancel={() => setSelectedFocalPoint(null)}
              referenceCode={serviceRequestDetails?.reference_code ? `#${serviceRequestDetails?.reference_code}` : ''}
              isLoading={isUpdateProcessing}
              data={selectedFocalPoint}
              onSubmitHandler={() => focalPointHandler(selectedFocalPoint)}
            />
          )}
          {isHistoryModeActive && (
            <History
              isActive={isHistoryModeActive}
              onCancel={() => setIsHistoryModeActive(false)}
              dataSource={history?.results}
              isLoading={isHistoryLoading}
              referenceCode={serviceRequestDetails?.reference_code as string}
            />
          )}
          {serviceRequestDetails && (
            <div className='dropdown-title'>
              <h3>Project information {getProjectLinks()}</h3>
              <div className='buttons-container'>
                <Button onClick={() => setIsHistoryModeActive(true)} className='history-button' text='See history' />
                <form>
                  <Dropdown
                    styles={statusDropdownCustomStyles}
                    components={getDropdownStatusComponentProps(
                      !serviceRequestDetails?.allowed_transitions.length,
                      serviceRequestDetails?.status
                    )}
                    isSearchable={false}
                    onChange={(option) => {
                      statusChangeHandler(option.value);
                    }}
                    // @ts-ignore
                    isOptionDisabled={(option: IDropdownOption) =>
                      Boolean(!serviceRequestDetails?.focal_point && option.value !== 'cancelled')
                    }
                    options={serviceRequestDetails?.allowed_transitions as IDropdownOption[]}
                    value={{ label: serviceRequestDetails?.status_name, value: serviceRequestDetails?.status }}
                  />
                </form>
              </div>
            </div>
          )}
          <Form
            id='form'
            onSubmit={({ values, modified }) => submitForm(values, modified)}
            yupSchema={getValidationSchema(locationTypes?.results)}
            onChange={formChangeHandler}
          >
            {renderDetailElements(serviceRequestDetails)}
            {locations.length > 0 ? (
              <>
                <h3 className='hbh-details-title'>Locations</h3>
                <LocationSection locations={locations} setLocations={() => null} infrastructures={[]} disabled />
              </>
            ) : null}
            <div>
              <div className='attachments-label'>
                <PaperClipOutlined className='attachments-icon' />
                <span>Attachments by requestor</span>
              </div>
              <div className='attachments-list-wrapper'>{mapAttachmentsToComponent()}</div>
            </div>
            <div className='buttons-group'>
              <button
                type='submit'
                onClick={(e) => {
                  if (!isEditMode) e.preventDefault();
                  setIsEditMode(true);
                }}
                className={`btn ${!isEditMode ? 'edit-button' : 'apply-button'}`}
              >
                {isEditMode ? 'Apply' : 'Edit'}
              </button>
              <Button
                onClick={() => window.location.replace(`/digital-office/projects/create/${serviceRequestId}`)}
                text='Convert to a project'
                variant='submit'
              />
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default RequestsDetailsPage;
