export const SUMMARY_MODE = 'summary',
  FORM_MODE = 'form',
  EMAIL_CONFIRMATION_MODE = 'email-confirmation',
  SUBMIT_CONFIRMATION_MODE = 'submit-confirmation',
  LOW_COMPLEXITY_DESCRIPTION =
    'Support from technical experts and/or extension workers is required at planing and design stages, and during implementation',
  MEDIUM_COMPLEXITY_DESCRIPTION =
    'Activities where engineering requirements can be site/context specific based on geology, soil type and others and need initial assessments. Guidance to be sought ar RB or HQ level',
  HIGH_COMPLEXITY_DESCRIPTION =
    'Activities what will require engagement of a national or international engineer assigned to the project, full time during all stages, in consultation with Chief Engineer';

export const COMPLEXITY_LABELS = {
  low: LOW_COMPLEXITY_DESCRIPTION,
  medium: MEDIUM_COMPLEXITY_DESCRIPTION,
  high: HIGH_COMPLEXITY_DESCRIPTION,
};
