import React, { FC } from 'react';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import './styles.scss';

interface IProps {
  options: ItemType[];
  value?: string;
  onChange: (value: string) => void;
  hideLabel?: boolean;
}

const CurrencyPicker: FC<IProps> = ({ options, value, onChange, hideLabel }) => {
  return (
    <div className='currency-picker'>
      {!hideLabel ? <span className='picker-label'>Currency:</span> : null}
      <Dropdown menu={{ items: options, onClick: (e) => onChange(e.key) }}>
        <span className='value-container'>
          <span>{value || ''}</span>
          <DownOutlined />
        </span>
      </Dropdown>
    </div>
  );
};

export default CurrencyPicker;
