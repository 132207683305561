import React, { Dispatch, FC, SetStateAction } from 'react';
import { BookingFormDetails } from '@digital-office/common/interfaces';
import {
  IYupSchema,
  STEPS,
  AdditionalStepValues,
  MAX_USAGE,
  MAX_FILE_COUNT,
  MAX_FILE_SIZE,
} from '@pages/BookingFormPage/consts';
import { ReactComponent as HotelIcon } from '@assets/svg/hotel.svg';
import { DateField, TextField } from '@unbooking/ui-modules/lib/informed';
import { useFieldApi, Multistep } from 'informed';
import InformedField from '../InformedField';
import RadioOptionField from '../RadioOptionField';
import Subtitle from '../Subtitle';
import UploadField from '../UploadField';
import Navigation from '../Navigation';

interface AdditionalInformationStepProps {
  formDetails: BookingFormDetails;
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
}

const defaultOptions = [
  {
    display_name: 'Yes',
    value: 'yes',
  },
  {
    display_name: 'No',
    value: 'no',
  },
];

const AdditionalInformationStep: FC<AdditionalInformationStepProps> = ({ formDetails, setYupSchema }) => {
  const { getValue } = useFieldApi(STEPS.ADDITIONAL);

  const stepValues = (getValue() || {}) as Partial<AdditionalStepValues>;

  const { own_status } = stepValues;

  return (
    <Multistep.Step step={STEPS.ADDITIONAL}>
      <Subtitle text='Additional information' icon={<HotelIcon />} />

      <InformedField label='New construction *'>
        <RadioOptionField name='new_construction' options={defaultOptions} />
      </InformedField>

      <InformedField label='Rehabilitation existing buildings *'>
        <RadioOptionField
          name='rehabilitation_existing_building'
          options={formDetails.rehabilitation_existing_building}
        />
      </InformedField>

      <InformedField label='Is the land/building owned or rented? *'>
        <RadioOptionField name='own_status' options={formDetails.owned} />
      </InformedField>

      <div className='bf-row'>
        <InformedField label='Rental expiration date'>
          <DateField
            dateFormat='dd/MM/yyyy'
            minDate={new Date()}
            name='rental_expiration_date'
            disabled={!(own_status?.value === 'no')}
          />
        </InformedField>
        <InformedField label='Planned usage years (optional)'>
          <TextField name='planned_usage_years' type='number' min='1' max={String(MAX_USAGE)} />
        </InformedField>
      </div>

      <InformedField label='Upload attachments' description='Max 5 files. File size limit 10MB'>
        <UploadField name='attachments' maxCount={MAX_FILE_COUNT} maxSize={MAX_FILE_SIZE} />
      </InformedField>

      <Navigation setYupSchema={setYupSchema} />
    </Multistep.Step>
  );
};

export default AdditionalInformationStep;
