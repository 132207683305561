import React from 'react';
import { ReactComponent as SuccessIcon } from '@assets/svg/success.svg';
import { useNavigate } from 'react-router';
import './styles.scss';

const RequestSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <div className='request-success'>
      <div className='request-success-box'>
        <SuccessIcon className='request-success-icon' />
        <div className='request-success-title'>Your request has been submitted!</div>
        <div className='request-success-text'>We will review your request, and we will follow up shortly</div>
        <button className='request-success-button' onClick={() => navigate('/home')}>
          Back to homepage
        </button>
      </div>
    </div>
  );
};

export default RequestSuccessPage;
