import React, { FC } from 'react';
import { Tooltip, Spin } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button } from '@ui-modules';

import {
  DocIcon,
  FlaIcon,
  InfotechIcon,
  InvolvementIcon,
  ResponsibilitiesIcon,
  AdditionalNotesIcon,
} from '@components/CommunityGuidancePage/assets/svg';

import { IGuidanceData } from '@components/CommunityGuidancePage';
import { FORM_MODE } from '@components/CommunityGuidancePage/constants';

import './styles.scss';
import { UseMutationResult } from 'react-query';

interface IListContentProps {
  icon: JSX.Element;
  title: string;
  description: string | JSX.Element;
}

interface IGuidanceSummaryProps {
  setSetupMode: (mode: string) => void;
  data: IGuidanceData;
  sendEmailMutation: UseMutationResult<any, unknown, any, unknown>;
}

const ListContent = ({ icon, title, description }: IListContentProps): JSX.Element => {
  return (
    <div className='list-wrapper'>
      <span className='content-icon'>{icon}</span>
      <div>
        <h4 className='content-title'>{title}</h4>
        <p className='content-description'>{description}</p>
      </div>
    </div>
  );
};

const GuidanceSummary: FC<IGuidanceSummaryProps> = ({ setSetupMode, data, sendEmailMutation }) => {
  const emailDisabledActivities: string[] = JSON.parse(sessionStorage.getItem('EMAIL_DISABLED_ACTIVITIES') || '[]');

  const isActivityEmailDisabled = emailDisabledActivities.some((activity) => activity === data.activity.uuid);

  return (
    <div className='guidance-intro'>
      <div className='guidance-information'>
        <div className='description-wrapper'>
          <span className='description-label'>ACTIVITY:</span>
          <Tooltip title='Broad bed and Furrows Maker'>
            <span className='border-value'>{data.activity.name}</span>
          </Tooltip>
        </div>
        <div className='description-wrapper'>
          <span className='description-label'>CATEGORY:</span>
          <Tooltip title='Soil & Water Conservation and Gully Control Measures'>
            <span className='border-value'>{data.activity.activity_type_label}</span>
          </Tooltip>
        </div>
        <div className='complexity-wrapper'>
          <span className='complexity-label'>ENGINEERING REQUIREMENTS:</span>
          <Tooltip overlayClassName='complexity-tooltip' title={data.risk.description}>
            <span className='risk-border low complexity-value'>
              <QuestionCircleOutlined /> {data.risk.level === 'RELEVANT' ? 'MEDIUM PLUS' : data.risk.level}
            </span>
          </Tooltip>
        </div>
      </div>
      {sendEmailMutation.status === 'loading' ? (
        <Spin className='loading-spinner' spinning={true} />
      ) : (
        <div className='content-list'>
          <ListContent icon={<InvolvementIcon />} title='Technical requirement:' description={data.risk.description} />
          {data.activity.link && (
            <ListContent
              icon={<InfotechIcon />}
              title='Infotech documentation:'
              description={
                <a target='_blank' href={data.activity.link} className='link' rel='noreferrer'>
                  Click here to download the infotech
                </a>
              }
            />
          )}
          {data.risk.technical_documents && (
            <ListContent icon={<DocIcon />} title='Technical documents:' description={data.risk.technical_documents} />
          )}
          {(data.risk.conditions || data.risk.additional_considerations || data.risk.constraints) && (
            <ListContent
              icon={<FlaIcon />}
              title='Contract and agreement recommendations:'
              description={`${data.risk.conditions} ${data.risk.additional_considerations} ${data.risk.constraints}`}
            />
          )}
          {data.risk.responsibilities && (
            <ListContent
              icon={<ResponsibilitiesIcon />}
              title='Responsibilities'
              description={data.risk.responsibilities}
            />
          )}
          {data.activity.notes && (
            <ListContent icon={<AdditionalNotesIcon />} title='Additional notes' description={data.activity.notes} />
          )}
        </div>
      )}
      <hr className='hr' />
      <div className='buttons-section'>
        <Button
          onClick={() => sendEmailMutation.mutate(data.activity.uuid)}
          className='button'
          disabled={sendEmailMutation.status === 'loading' || isActivityEmailDisabled}
          variant='transparent'
          text={!isActivityEmailDisabled ? 'Get guidance via email' : 'Guidance email already sent'}
        />
        <Button
          disabled={sendEmailMutation.status === 'loading'}
          onClick={() => setSetupMode(FORM_MODE)}
          className='button'
          text='Contact WFP Engineering'
        />
      </div>
    </div>
  );
};

export default GuidanceSummary;
