import React, { FC } from 'react';
import { ReactComponent as InfoIcon } from '@assets/svg/InfoIcon.svg';
import './styles.scss';

interface IProps {
  link: string;
}

const VideoLink: FC<IProps> = ({ link }) => {
  return (
    <a target='_blank' href={link} className='video-link' rel='noreferrer'>
      <InfoIcon />
      <span>Click here to see a video guide for this section</span>
    </a>
  );
};

export default VideoLink;
