import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormState, Multistep } from 'informed';
import { AdditionalInformationStep, DetailsStep, ProjectInformationStep, ReviewStep, SideMenu } from './components';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import {
  BookingFormDetails,
  ICountriesResponse,
  IUserDetails,
  LocationData,
  PostRequestData,
} from '@digital-office/common/interfaces';
import LoadingPage from '@components/LoadingPage';
import { Spin, message } from 'antd';
import { yupSchemas, STEPS, IYupSchema, FormValues } from './consts';
import { DateFormatEnum } from '@common/utils/constants';
import { format } from 'date-fns';
import './styles.scss';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types';

const convertRadioOptionToString = (option: IDropdownOption) => {
  return option.value === 'yes' ? 'true' : option.value === 'no' ? 'false' : 'null';
};

const convertValuesToSubmit = (values: FormValues, userDetails: IUserDetails) => {
  const {
    details: { phone_number, requesting_unit, country },
    project: {
      description,
      desired_start_date,
      estimated_budget,
      fund_expiration_date,
      infrastructure,
      infrastructure_category,
      location,
      services,
      title,
    },
    additional: {
      attachments,
      new_construction,
      own_status,
      planned_usage_years,
      rehabilitation_existing_building,
      rental_expiration_date,
    },
  } = values;

  const { organisation } = userDetails;

  const data = {
    attachments,
    country: country.value,
    description,
    desired_start_date: format(new Date(desired_start_date), DateFormatEnum.YYYY_MM_DD),
    estimated_budget: estimated_budget.replace(/,/g, ''),
    fund_expiration_date: fund_expiration_date
      ? format(new Date(fund_expiration_date), DateFormatEnum.YYYY_MM_DD)
      : undefined,
    infrastructure: infrastructure.value,
    infrastructure_category: infrastructure_category.value,
    locations: location,
    new_construction: convertRadioOptionToString(new_construction),
    organisation,
    owned: convertRadioOptionToString(own_status),
    phone_number: phone_number.replace(/[ -]/g, ''),
    planned_usage_years,
    rehabilitation_existing_building: convertRadioOptionToString(rehabilitation_existing_building),
    rental_expiration_date: rental_expiration_date
      ? format(new Date(rental_expiration_date), DateFormatEnum.YYYY_MM_DD)
      : undefined,
    requesting_unit,
    services: services.map(({ value }) => value),
    title,
  };

  const formData = new FormData();

  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      const value = data[key as keyof PostRequestData];
      if (value) {
        if (key === 'attachments') {
          (value as Array<File>).forEach((item) => {
            formData.append(key, item);
          });
        } else if (key === 'locations') {
          (value as Array<LocationData>).forEach(({ lat, long, country, place_name }, idx) => {
            formData.append(`locations[${idx}]name`, place_name);
            formData.append(`locations[${idx}]latitude`, String(lat));
            formData.append(`locations[${idx}]longitude`, String(long));
            formData.append(`locations[${idx}]country`, country);
          });
        } else if (key === 'services') {
          (value as Array<string>).forEach((item) => {
            formData.append(key, item);
          });
        } else {
          formData.append(key, value as string);
        }
      }
    }
  }

  return formData;
};

const BookingFormPage = () => {
  const { id: facilityId = '', country = '' } = useParams<{ id: string; country: string }>();
  const { engineeringRepository } = useRepository();
  const navigate = useNavigate();
  const [, setKey] = useState(1);

  const [yupSchema, setYupSchema] = useState<IYupSchema>(yupSchemas[STEPS.DETAILS]);

  const { data: formDetails, isLoading: isFormDetailsLoading } = useQuery<BookingFormDetails>(
    `form-details`,
    () => engineeringRepository.getBookingFormDetails(facilityId),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: Boolean(facilityId),
      onError: () => {
        navigate('/forbidden');
      },
    }
  );

  const { data: userDetails, isLoading: isUserDetailsLoading } = useQuery<IUserDetails>(
    `user-details`,
    () => engineeringRepository.getUserDetails(),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      onError: () => {
        navigate('/forbidden');
      },
    }
  );

  const { data: countries, isLoading: isCountriesLoading } = useQuery<ICountriesResponse>(
    'countries',
    () => engineeringRepository.getCountries(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const postRequestMutation = useMutation((data: FormData) => engineeringRepository.postNewRequest(facilityId, data), {
    onSuccess: () => {
      navigate(`/request-success/`);
    },
    onError: () => {
      message.error('Error has occurred, try later');
    },
  });

  if (isFormDetailsLoading || !formDetails || isUserDetailsLoading || !userDetails || isCountriesLoading || !countries)
    return (
      <div className='story-container'>
        <LoadingPage />
      </div>
    );

  const onFormChange = () => setKey((prevKey) => prevKey + 1);

  const onFormSubmit = ({ values }: FormState) => {
    const finalValues = convertValuesToSubmit(values as unknown as FormValues, userDetails);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    postRequestMutation.mutate(finalValues);
  };

  const countryData = countries.results.find(({ iso_code }) => iso_code === country.toUpperCase());

  return (
    <div className='booking-form-page'>
      <Spin spinning={postRequestMutation.isLoading}>
        <Form onChange={onFormChange} onSubmit={onFormSubmit} yupSchema={yupSchema}>
          <Multistep>
            <div className='bf-container'>
              <div className='bf-navigation'>
                <SideMenu setYupSchema={setYupSchema} />
              </div>
              <div className='bf-content'>
                <div className='bf-main-title'>Submit a request to {countryData?.name} Engineering team</div>
                <DetailsStep countries={countries} userDetails={userDetails} setYupSchema={setYupSchema} />
                <ProjectInformationStep
                  countryData={countryData}
                  formDetails={formDetails}
                  setYupSchema={setYupSchema}
                />
                <AdditionalInformationStep formDetails={formDetails} setYupSchema={setYupSchema} />
                <ReviewStep setYupSchema={setYupSchema} userDetails={userDetails} />
              </div>
            </div>
          </Multistep>
        </Form>
      </Spin>
    </div>
  );
};

export default BookingFormPage;
