import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import './styles.scss';
import { Button } from '@ui-modules';
import { CheckOutlined, CloseCircleOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { ISelectedDeliverable } from '@digital-office/pages/ProjectsManagementPages/components/PhaseDetail';

interface Event<T = EventTarget> {
  target: T;
}

interface IProps {
  selectedDeliverable: ISelectedDeliverable;
  setSelectedDeliverable: React.Dispatch<React.SetStateAction<ISelectedDeliverable | null>>;
  onDeliverableCompletion: (fileList: FileList | undefined) => void;
}

const DeliverableCompletionModal: FC<IProps> = ({
  selectedDeliverable,
  setSelectedDeliverable,
  onDeliverableCompletion,
}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const { finished } =
    selectedDeliverable.deliverable === 'all' ? { finished: false } : selectedDeliverable.deliverable;

  const handleChange = (event: Event<HTMLInputElement> | undefined) => {
    if (event) {
      onDeliverableCompletion(event.target.files || undefined);
    } else {
      onDeliverableCompletion(undefined);
    }
  };

  const uploadButtonClickHandler = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <Modal
      footer={null}
      title={
        <div className={'modal-title'}>
          <p>Deliverable {finished ? 'incompletion' : 'completion'}</p>
        </div>
      }
      width={'40%'}
      open={true}
      onCancel={() => setSelectedDeliverable(null)}
    >
      <div className='deliverable-completion-modal'>
        <div className='deliverable-info'>
          <div className='deliverable-wrapper'>
            <p className='deliverable-label'>
              <span className='deliverable-title'>Deliverable(s):</span>
              <span className='deliverable-value'>
                {selectedDeliverable.deliverable === 'all' ? 'All' : selectedDeliverable.deliverable.name}
              </span>
            </p>
            <div className='deliverable-label'>
              <p className='deliverable-label'>
                <span className='deliverable-title'>Phase:</span>
                <span className='deliverable-value'>
                  Phase {selectedDeliverable.phase.number}: {selectedDeliverable.phase.description}
                </span>
              </p>
            </div>
          </div>
          <hr className='hr-line' />
          {!finished ? (
            <p>Make sure to upload any document related to this deliverable in the project repository</p>
          ) : (
            <p>Do you want to mark this deliverable as incomplete?</p>
          )}
        </div>
        <div className='button-section'>
          <Button
            icon={finished ? <CloseCircleOutlined /> : <CheckOutlined />}
            variant='submit'
            className={`button confirm-button`}
            text={finished ? 'MARK AS INCOMPLETE' : 'YES - MARK AS COMPLETE'}
            onClick={() => handleChange(undefined)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeliverableCompletionModal;
