import React, { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useNavigate } from 'react-router';
import useParamsParse from '@digital-office/common/hooks/useParamsParse';
import { Button, Footer } from '@ui-modules';
import { useAuth } from '@common/auth';
import './styles.scss';
import { ReactComponent as EngineeringLogo } from '@assets/svg/login-logo.svg';
import { ReactComponent as UNHeader } from '@assets/svg/unbooking_logo.svg';

interface IProps {
  isDigitalOffice?: boolean;
}

const SignInPage: FC<IProps> = ({ isDigitalOffice }) => {
  const navigate = useNavigate();
  const { firstLoginAttempt, authorize } = useAuth();
  const [loaderSpinning, setLoaderSpinning] = useState(false);
  const { isParsed, userType } = useParamsParse();
  const { pathname } = window.location;

  useEffect(() => {
    !pathname.includes('callback') && sessionStorage.setItem('SIGN_IN_ORIGIN_PAGE', pathname);
  }, []);

  useEffect(() => {
    if (firstLoginAttempt) {
      setLoaderSpinning(true);

      if (!isDigitalOffice || isParsed) {
        authorize?.(userType);
      } else {
        navigate('redirect', {
          state: {
            isRedirected: true,
          },
        });
      }
    }
  }, [firstLoginAttempt]);

  return (
    <section className='login-container'>
      <div className='header'>
        <UNHeader onClick={() => (window.location.href = 'https://unbooking.org')} />
      </div>
      <div className='content'>
        <div className='content-wrapper'>
          <div className='logo'>
            <EngineeringLogo />
            <hr />
          </div>
          <p className='description'>
            Welcome to WFP&apos;s Engineering Platform, a dedicated digital touchpoint to access engineering experience,
            skills, knowledge, and services.
          </p>
          {!loaderSpinning ? (
            <Button
              text='Sign in'
              className='login-button'
              data-testid='login-button'
              onClick={() => {
                setLoaderSpinning(true);
                authorize?.(userType);
              }}
            />
          ) : (
            <Spin data-testid='loading-spinner' className='login-loading' size='large' spinning={loaderSpinning} />
          )}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default SignInPage;
