import { ICrumb } from '@ui-modules/types';
import backgroundImage from '@assets/img/background.jpg';
import EntryPage from '@digital-office/pages/EntryPage';
import RequestsListPage from '@digital-office/pages/RequestsListPage';
import RequestsDetailsPage from '@digital-office/pages/RequestsDetailsPage';
import ProjectConversionPage from '@digital-office/pages/ProjectsManagementPages/ProjectConversionPage';
import DemandOverviewPage from '@digital-office/pages/DemandOverviewPage';
import ProjectsListPage from '@digital-office/pages/ProjectsListPage';
import ProjectDetailsPage from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage';
import FFAListPage from '@digital-office/pages/FFAListPage';
import FFADetailsPage from '@digital-office/pages/FFADetailsPage';
import FFADashboardPage from '@digital-office/pages/FFADashboardPage';
import DashboardMenu from '@digital-office/pages/DashobardMenu';
import ShowcaseEntryPage from '@digital-office/pages/ShowcaseEntryPage';
import ProjectStatusFeedPage from '@digital-office/pages/ProjectStatusFeedPage';
import ShowcaseListPage from '@digital-office/pages/ShowcaseListPage';
import ProjectDashboardPage from '@digital-office/pages/ProjectDashboardPage';
import CountryConfigurationPage from '@digital-office/pages/CountryConfigurationPage';
import FieldAssessmentListPage from '@digital-office/pages/FieldAssessmentListPage';
import FieldAssessmentReports from '@digital-office/pages/FieldAssessmentReports';

export default [
  {
    path: '/digital-office/',
    Component: EntryPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/requests',
    name: 'Engineering Requests',
    Component: RequestsListPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/requests/:id',
    name: 'Engineering Requests details',
    Component: RequestsDetailsPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/projects',
    name: 'Projects management',
    Component: ProjectsListPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/projects/create/:id',
    name: 'Convert to project',
    Component: ProjectConversionPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/projects/:id',
    name: 'Project details',
    Component: ProjectDetailsPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/dashboards',
    name: 'Reporting',
    Component: DashboardMenu,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
    customCrumbsCallback: (givenRoute) => {
      return [
        {
          path: '/',
          Component: EntryPage,
          isBackgroundImage: true,
          backgroundImage,
        },
        givenRoute,
      ];
    },
  },
  {
    path: '/digital-office/dashboard-ffa',
    name: 'FFA Dashboard',
    Component: FFADashboardPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
    customCrumbsCallback: (givenRoute) => {
      return [
        {
          path: '/',
          Component: EntryPage,
          isBackgroundImage: true,
          backgroundImage,
        },
        {
          path: '/digital-office/dashboards',
          name: 'Reporting',
          Component: DashboardMenu,
          isBackgroundImage: true,
          isNavigation: true,
          backgroundImage,
        },
        givenRoute,
      ];
    },
  },
  {
    path: '/digital-office/demand-overview',
    name: 'Demand Overview',
    Component: DemandOverviewPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
    customCrumbsCallback: (givenRoute) => {
      return [
        {
          path: '/',
          Component: EntryPage,
          isBackgroundImage: true,
          backgroundImage,
        },
        {
          path: '/digital-office/dashboards',
          name: 'Reporting',
          Component: DashboardMenu,
          isBackgroundImage: true,
          isNavigation: true,
          backgroundImage,
        },
        givenRoute,
      ];
    },
  },
  {
    path: '/digital-office/ffa',
    name: 'FFA requests',
    Component: FFAListPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/ffa/:id',
    name: 'FFA request details',
    Component: FFADetailsPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/feed',
    name: 'Project status feed',
    Component: ProjectStatusFeedPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/showcase/',
    name: 'Project showcase list',
    Component: ShowcaseListPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/showcase/:id',
    name: 'Project showcase details',
    Component: ShowcaseEntryPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/project-dashboard',
    name: 'Project Overview',
    Component: ProjectDashboardPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/showcase/country-configuration/:id',
    name: 'Country page configuration',
    Component: CountryConfigurationPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/field-assessment-reports/',
    name: 'Engineering Field Assessment and mission reports',
    Component: FieldAssessmentListPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/digital-office/field-assessment-reports/form/:id',
    name: 'Engineering Field Assessment Form',
    Component: FieldAssessmentReports,
    isNavigation: true,
  },
] as ICrumb[];
