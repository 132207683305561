import { RightOutlined, DownOutlined } from '@ant-design/icons';
import React, { FC, useState } from 'react';
import './styles.scss';

interface IProps {
  headers: string[];
  tableData: {
    title: string;
    value: string | number;
    expandedTable?: {
      title: string;
      value: string | number;
    }[];
  }[];
}

const PhasesTable: FC<IProps> = ({ headers, tableData }) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const toggleRowExpansion = (title: string) => {
    setExpandedRows({
      [title]: !expandedRows[title],
    });
  };

  return (
    <table className='phase-table'>
      <thead>
        <tr className='headers'>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody className='table-body'>
        {tableData.map((data) => (
          <React.Fragment key={data.title}>
            <tr
              onClick={() => data.expandedTable && toggleRowExpansion(data.title)}
              className={`expandable-row ${expandedRows[data.title] ? 'expanded' : ''}`}
            >
              <td className='first-cell'>
                {expandedRows[data.title] ? (
                  <DownOutlined className='phase-icon' />
                ) : (
                  <RightOutlined className='phase-icon' />
                )}
                {data.title}
              </td>
              <td>{data.value}</td>
            </tr>
            <>
              {data.expandedTable?.map((expandedData) => (
                <tr
                  key={expandedData.title}
                  data-testid={`expanded-content-row ${data.title}`}
                  className={`expanded-content-row ${expandedRows[data.title] ? 'expanded' : 'not-expanded'}`}
                >
                  <td className='expanded-row first-cell'>
                    <ul>
                      <li>{expandedData.title}</li>
                    </ul>
                  </td>
                  <td className='expanded-row'>{expandedData.value}</td>
                </tr>
              ))}
            </>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default PhasesTable;
