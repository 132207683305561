import React, { FC } from 'react';
import './styles.scss';

interface ISummaryData {
  label: string;
  value: string | number | JSX.Element;
}

interface IProps {
  data: ISummaryData[];
}

const SummaryTiles: FC<IProps> = ({ data }) => {
  return (
    <div className='projects-summary'>
      {data.map((tileData) => (
        <div key={tileData.label} className='summary-wrapper'>
          <p className='label'>{tileData.label}</p>
          <p className='value'>{tileData.value}</p>
        </div>
      ))}
    </div>
  );
};

export default SummaryTiles;
