import React, { FC } from 'react';
import { Input, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown } from '@ui-modules';
const { Search } = Input;
import { filter, includes } from 'lodash';

import ComplexityModal from '@components/CommunityGuidancePage/components/ComplexityModal';
import { IGuidanceActivity, IGuidanceRiskCategory } from '@digital-office/common/interfaces';
import { IDropdownOption } from '@ui-modules/types';

interface CustomTagProp {
  closable: boolean;
  disabled: boolean;
  value: string;
}

interface IGuidanceActivitiesListProps {
  activities: IGuidanceActivity[];
  riskCategories: IGuidanceRiskCategory[];
  options: {
    [key: string]: IDropdownOption[];
  };
  setSelectedActivity: React.Dispatch<React.SetStateAction<IGuidanceActivity | null>>;
  setSelectedCountry: React.Dispatch<React.SetStateAction<IDropdownOption | null>>;
  selectedCountry: IDropdownOption | null;
}

const GuidanceActivitiesList: FC<IGuidanceActivitiesListProps> = ({
  activities,
  selectedCountry,
  setSelectedActivity,
  setSelectedCountry,
  options,
  riskCategories,
}) => {
  const [complexityFilter, setComplexityFilter] = React.useState<string[]>([]);
  const [categoryFilter, setCategoryFilter] = React.useState<string[]>([]);
  const [isComplexityModalOpen, setIsComplexityModalOpen] = React.useState<boolean>(false);
  const [activityFilter, setActivityFilter] = React.useState<string>('');

  const mapActivities = () => {
    return filter(activities, (value) =>
      Boolean(
        value.name.toLowerCase().includes(activityFilter.toLowerCase()) &&
          (!categoryFilter.length || includes(categoryFilter, value?.activity_type)) &&
          (!complexityFilter.length || includes(complexityFilter, value.risk_category))
      )
    ).map((activity) => (
      <Tooltip key={activity.name} title={selectedCountry ? '' : 'Select a country to access the guidance'}>
        <div
          key={activity.name}
          onClick={() => selectedCountry && setSelectedActivity(activity)}
          className={`guidance-element ${!selectedCountry ? 'disabled' : ''}`}
        >
          {activity.name}
        </div>
      </Tooltip>
    ));
  };

  function renderTag(value: CustomTagProp, selectedOptions: string[]) {
    if (value.value === selectedOptions[selectedOptions.length - 1]) {
      return <p className='custom-tag-paragraph'>{selectedOptions.length} items selected</p>;
    }
    return <p />;
  }

  const getComplexityLabel = () => {
    if (complexityFilter.length === 0 || complexityFilter.length === options.risk_categories.length) {
      return 'All';
    } else if (complexityFilter.length === 1) {
      return complexityFilter[0] === 'RELEVANT' ? 'MEDIUM PLUS' : (complexityFilter[0] as any);
    } else if (complexityFilter.length > 1) {
      return `Multiple`;
    }
  };

  const resetFilters = () => {
    setComplexityFilter([]);
    setCategoryFilter([]);
    setActivityFilter('');
  };

  const checkIsFilterApplied = () => {
    return Boolean(complexityFilter.length || categoryFilter.length || activityFilter.length);
  };

  const activitiesList = mapActivities();

  return (
    <div>
      <ComplexityModal
        riskCategories={riskCategories}
        open={isComplexityModalOpen}
        closeHandler={() => setIsComplexityModalOpen(false)}
      />
      <p className='paragraph bold'>
        Engineering Requirement Assessment Matrix (ERAM) for Asset Creation and Livelihoods Activities
      </p>
      <p className='paragraph'>
        This all-in-one tool provides you with easy access to technical and engineering guidance and resources related
        to the Asset Creation and Livelihoods activities, while also offering seamless communication channel with the
        Engineering and Asset Creation & Livelihoods teams for further guidance and technical support
      </p>
      <div className='filters-description'>
        <p className='paragraph bold'>Activities available</p>
        <p className='paragraph'>
          Filter activities by the below criteria or search for a specific community infrastructure
        </p>
      </div>
      <div className='filters'>
        <Select
          className='filter-dropdown'
          mode='multiple'
          tagRender={(value) => renderTag(value, categoryFilter)}
          placeholder='Category: All'
          value={categoryFilter}
          showArrow={true}
          options={options.activity_types}
          onChange={setCategoryFilter}
        />
        <Select
          className='filter-dropdown'
          mode='tags'
          value={complexityFilter}
          placeholder='Filter by engineering requirements'
          suffixIcon={
            <div>
              <span className='complexity-suffix'>{getComplexityLabel()}</span>
              <QuestionCircleOutlined onClick={() => setIsComplexityModalOpen(true)} className='help-icon' />
            </div>
          }
          showArrow={true}
          showSearch={false}
          popupClassName={'risk-dropdown'}
          tagRender={(value) => renderTag(value, complexityFilter)}
          onChange={setComplexityFilter}
        >
          {options.risk_categories.map((option) => (
            <Select.Option className={`risk-option ${option.value}`} key={option.value} value={option.value}>
              <QuestionCircleOutlined
                onClick={(e) => {
                  setIsComplexityModalOpen(true);
                  e.stopPropagation();
                  e.preventDefault();
                }}
                className='help-icon'
              />
              <span>{option.label}</span>
            </Select.Option>
          ))}
        </Select>
        <Dropdown
          onChange={(value) => setSelectedCountry(value as IDropdownOption)}
          className='filter-dropdown'
          containerClassName='hbh-select-container'
          value={selectedCountry}
          placeholder='Country of implementation: Select...'
          options={options.countries}
        />
        <Search
          className='search-input'
          prefix={<SearchOutlined />}
          onChange={(value) => setActivityFilter(value.target.value)}
          addonAfter={false}
          addonBefore={false}
          value={activityFilter}
          allowClear
          enterButton={false}
          placeholder='Search by name...'
          size='middle'
        />
        <Button
          onClick={() => resetFilters()}
          className='clear-filters-button'
          disabled={!checkIsFilterApplied()}
          text='Clear all filters'
          variant='transparent'
        />
      </div>
      <div className='guidance-description'>
        <p>
          <span className='bold'>{activitiesList?.length || 0}</span> Activities matching your criteria
        </p>
        <p className='description'>click on an activity name to access the Engineering guidance</p>
      </div>
      <div className='list-section'>
        <div className='guidance-list'>{activitiesList}</div>
        <div className='link-box'>
          Can&apos;t find activity? <a href='mailto:wfp.engineering@wfp.org'>Contact WFP Engineering</a> or{' '}
          <a href='mailto:wfp.assetcreationandlivelihoods@wfp.org'>
            PRO-L (Livelihoods, Asset Creation and Resilience unit)
          </a>
        </div>
      </div>
    </div>
  );
};

export default GuidanceActivitiesList;
