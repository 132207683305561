import { Bar } from 'react-chartjs-2';
import React, { FC } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Filler, ChartDataset, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Filler);

interface IProps {
  data: ChartData<'bar'>;
}

const getMaxValue = (data: Array<number | [number, number] | null>): number => {
  let result = 0;

  if (data) {
    data.forEach((element) => {
      if (typeof element === 'number') result += element;
    });
    return result;
  } else {
    result = 50;
  }

  return result;
};

const getOptions = (data: ChartData<'bar'>) => ({
  indexAxis: 'y' as const,
  type: 'bar',
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  scales: {
    x: {
      max: getMaxValue(data.datasets[0].data),
      fill: true,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      stackWeight: 0.7,
      stacked: false,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    datalabels: {
      display: true,
      color: 'white',
    },
  },
});

const progressBar = {
  id: 'progressBar',
  beforeDatasetsDraw(chart: ChartJS) {
    const {
      ctx,
      data,
      chartArea: { left, width, height },
      scales: { y },
    } = chart;

    const chartData = data.datasets[0] as ChartDataset<'bar'>;

    const barHeight =
      (height / y.ticks.length) * (chartData?.barPercentage || 0.9) * (chartData?.categoryPercentage || 0.8) - 10;

    data.datasets[0].data.forEach((datapoint, index: number) => {
      ctx.beginPath();
      ctx.fillStyle = 'rgba(47, 128, 237, 0.2)';
      ctx.fillRect(left, y.getPixelForValue(index) - barHeight / 2, width, barHeight);
    });
  },
};

const BarChart: FC<IProps> = ({ data }) => {
  return <Bar plugins={[progressBar, ChartDataLabels]} options={getOptions(data)} data={data} />;
};

export default BarChart;
