export const LocalStorageKeys = {
  AUTH_TYPE: 'AUTH_TYPE',
  PKCE_CODE_VERIFIER: 'PKCE_CODE_VERIFIER',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  HBH_PERMISSION_GRANTED: 'true',
  ID_TOKEN: 'ID_TOKEN',
  EXPIRATION: 'EXPIRATION',
  LOGIN_IN_PROGRESS: 'LOGIN_IN_PROGRESS',
  FIRST_LOGIN_ATTEMPT: 'FIRST_LOGIN_ATTEMT',
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
  HAS_ACCESS_DO: 'HAS_ACCESS_DO',
  USER_EMAIL: 'USER_EMAIL',
  USER_NAME: 'USER_NAME',
};

export enum ERRORS {
  NO_CLIENT_ID = "ClientId must be set in the 'AuthConfig' object passed to 'react-oauth2-code-pkce' AuthProvider",
  NO_AUTHORIZATION_ENDPOINT = "authorizationEndpoint must be set in the 'AuthConfig' object passed to 'react-oauth2-code-pkce' AuthProvider",
  NO_TOKEN_ENDPOINT = "tokenEndpoint must be set in the 'AuthConfig' object passed to 'react-oauth2-code-pkce' AuthProvider",
  NO_REDIRECT_URI = "redirectUri must be set in the 'AuthConfig' object passed to 'react-oauth2-code-pkce' AuthProvider",
}
