import React, { FC, useEffect, useRef, useState } from 'react';
import { DateField, TextField } from '@ui-modules/informed';
import { ArrayField, Form, FormApi, FormStateAccessor, Radio, RadioGroup, TextArea } from 'informed';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import { ArrowRightOutlined, CloseCircleFilled } from '@ant-design/icons';
import { format, parse } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import { UseMutationResult } from 'react-query';
import { Button } from '@ui-modules';
import * as Yup from 'yup';
import { IDropdownOption } from '@ui-modules/types';
import { IAssessmentProjectOverview } from '@digital-office/pages/FieldAssessmentReports/utils/types';

import './styles.scss';

interface IInformedFieldProps {
  label: string;
  description?: string | JSX.Element;
  informedComponent: JSX.Element;
  name?: string;
  formRefApi?: React.RefObject<FormApi>;
  maxLength?: number;
}

const validationSchemaSubmit = Yup.object().shape({
  project_title: Yup.string().required('Required'),
  assessment_date: Yup.date().required('Required'),
  mission_purpose: Yup.string().required('Required').max(1000, 'Max 1000 characters'),
  project_information: Yup.string().required('Required').max(1000, 'Max 1000 characters'),
  request_unit: Yup.string().required('Required').max(255, 'Max 255 characters'),
  area_office: Yup.string().required('Required').max(255, 'Max 255 characters'),
  facility_size: Yup.string().max(16, 'Max 16 characters'),
  any_work_done_selected: Yup.string().required('Required'),
  work_done_items: Yup.array().when('any_work_done_selected', {
    is: 'yes',
    then: Yup.array()
      .min(1, 'At least 1 element must be added')
      .required('This field is required')
      .of(
        Yup.object().shape({
          contract_number: Yup.string().required('Required'),
          po_number: Yup.string().required('Required'),
          project_durations: Yup.string().required('Required'),
          as_built_contract_amount: Yup.string().required('Required'),
        })
      ),
    otherwise: Yup.array(),
  }),
});

const validationSchemaDraft = Yup.object().shape({
  mission_purpose: Yup.string().max(1000, 'Max 1000 characters'),
  project_information: Yup.string().max(1000, 'Max 1000 characters'),
  request_unit: Yup.string().max(255, 'Max 255 characters'),
  area_office: Yup.string().max(255, 'Max 255 characters'),
  facility_size: Yup.string().max(16, 'Max 16 characters'),
});

export const InformedField: FC<IInformedFieldProps> = ({
  label,
  description,
  informedComponent,
  name,
  formRefApi,
  maxLength,
}) => {
  const error = formRefApi?.current && name ? (formRefApi.current.getError(name) as string | string[]) : undefined;
  const values: any = maxLength && formRefApi?.current?.getValue(name || '');

  return (
    <div className='field-container'>
      <p className='field-label'>{label}</p>
      {description ? <div className='field-description'>{description}</div> : null}
      <div className='field'>{informedComponent}</div>
      {maxLength ? (
        <p className={`counter`}>
          {values?.length || 0}/{maxLength}
        </p>
      ) : null}
      {error && typeof error === 'string' ? <div className='error'>{error}</div> : null}
    </div>
  );
};

interface IProjectOverviewStep {
  initialValues: Partial<IAssessmentProjectOverview>;
  updateMutation: UseMutationResult<any, unknown, any, unknown>;
  finalizeStepMutation: UseMutationResult<any, unknown, any, unknown>;
  onReferenceEditClick: (project: IDropdownOption | null, serviceRequest: IDropdownOption | null) => void;
  formKey: number;
}

const ProjectOverviewStep: FC<IProjectOverviewStep> = ({
  initialValues,
  updateMutation,
  formKey,
  finalizeStepMutation,
  onReferenceEditClick,
}) => {
  const formRef = useRef<FormApi>(null);
  const [updateType, setUpdateType] = useState<string | undefined>();
  const [, updateState] = useState<any>(null);

  useEffect(() => {
    const workDone = {
      work_done_items:
        initialValues?.any_work_done && initialValues?.work_done_items
          ? initialValues?.work_done_items
          : [
              {
                contract_number: undefined,
                po_number: undefined,
                project_durations: undefined,
                as_built_contract_amount: undefined,
              },
            ],
    };

    formRef?.current?.setValue('work_done_items', workDone.work_done_items);
  }, [initialValues, formKey]);

  useEffect(() => {
    if (updateType) {
      formRef?.current?.submitForm();
    }
  }, [updateType]);

  useEffect(() => {
    // on formInit there is no fieldArray values, it re-renders form on init to get them
    updateState({});
  }, [formRef]);

  const onSubmitButtonClick = (type: string) => {
    if (type === updateType) {
      formRef?.current?.submitForm();
    } else {
      setUpdateType(type);
    }
  };

  const parseInitialValues = () => {
    return {
      ...initialValues,
      any_work_done_selected: initialValues?.any_work_done ? 'yes' : 'no',
      work_done_items:
        initialValues?.any_work_done && initialValues?.work_done_items
          ? initialValues?.work_done_items
          : [
              {
                contract_number: undefined,
                po_number: undefined,
                project_durations: undefined,
                as_built_contract_amount: undefined,
              },
            ],
      assessment_date: initialValues?.assessment_date
        ? parse(initialValues?.assessment_date, DateFormatEnum.YYYY_MM_DD, new Date())
        : undefined,
    };
  };

  const parseFormDataToPayload = (values: Partial<IProjectOverviewStep['initialValues']>) => {
    const workDone = values?.work_done_items?.map((item) => {
      return { ...item, currency: '1fa82e9c-7f08-11ee-9f40-0e809c0bc812' };
    });

    const result = {
      ...values,
      any_work_done: values?.any_work_done_selected === 'yes',
      work_done_items: values?.any_work_done_selected === 'yes' ? [...(workDone || [])] : undefined,
      assessment_date: values?.assessment_date
        ? format(values?.assessment_date as unknown as Date, DateFormatEnum.YYYY_MM_DD)
        : undefined,
      project: initialValues?.project?.value,
      service_request: initialValues?.service_request?.value,
      facility_size: values?.facility_size ? values?.facility_size : null,
    };

    if (updateType === 'draft') {
      updateMutation.mutate({ project_overview: result, current_step: 0 });
    } else {
      finalizeStepMutation.mutate({ current_step: 0, project_overview: result });
    }
  };

  return (
    <div className='project-overview-assessment-form'>
      <div className='project-picker-section'>
        <div className='project-picker'>
          <div className='picker-header'>
            <p className='picker-label'>Request / Project reference</p>
            <p className='picker-value'>
              {initialValues?.request_project_reference ? `#${initialValues?.request_project_reference} - ` : ''}
              {initialValues?.project?.label || initialValues?.service_request?.label}
            </p>
          </div>
          <div
            onClick={() =>
              onReferenceEditClick(
                (initialValues?.project as IDropdownOption) || null,
                initialValues?.service_request || null
              )
            }
            className='picker-edit-button'
          >
            Edit
          </div>
        </div>
        <div className='project-picker project-type'>
          <p className='picker-label'>Project type(s)</p>
          <div className='picker-header'>
            <p className='picker-value'>
              {initialValues?.project_type?.map((value: string) => (
                <p key={value} className='project-type-value'>
                  {value}
                </p>
              )) || 'N/A'}
            </p>
          </div>
        </div>
      </div>
      <Form
        formApiRef={formRef}
        yupSchema={updateType === 'draft' ? validationSchemaDraft : validationSchemaSubmit}
        key={formKey}
        initialValues={parseInitialValues()}
        onSubmit={({ values }) => parseFormDataToPayload(values)}
      >
        <FormStateAccessor>
          {({ values }) => {
            return (
              <div className=''>
                <InformedField label='Project title' informedComponent={<TextField name='project_title' />} />
                <InformedField
                  label='Assessment date'
                  formRefApi={formRef}
                  name='assessment_date'
                  informedComponent={
                    <div className='quarter-width'>
                      <DateField dateFormat={DateFormatEnum['DD_MM_YYYY']} name='assessment_date' />
                    </div>
                  }
                />
                <InformedField
                  label='Mission purpose'
                  description='Enter the mission purpose and intended activities to be carried out during the field mission.E.g. The mission is intended to assess [...], establish [...], develop [...].'
                  informedComponent={<TextArea maxLength={1000} className='text-area' name='mission_purpose' />}
                  formRefApi={formRef}
                  maxLength={1000}
                  name='mission_purpose'
                />
                <InformedField
                  label='Project information'
                  formRefApi={formRef}
                  name='project_information'
                  maxLength={1000}
                  description='Enter a description of project request & justification'
                  informedComponent={<TextArea maxLength={1000} className='text-area' name='project_information' />}
                />
                <InformedField
                  formRefApi={formRef}
                  name='request_unit'
                  label='Requesting Unit'
                  informedComponent={<TextField max='255' className='half-width' name='request_unit' />}
                />
                <InformedField
                  formRefApi={formRef}
                  name='area_office'
                  label='Area office'
                  informedComponent={<TextField max='255' className='half-width' name='area_office' />}
                />
                <InformedField
                  formRefApi={formRef}
                  name='facility_size'
                  label='Facility size (optional)'
                  description='M2 or LxW'
                  informedComponent={
                    <div className='quarter-width'>
                      <TextField max='16' name='facility_size' />
                    </div>
                  }
                />
                <InformedField
                  formRefApi={formRef}
                  name='any_work_done_selected'
                  label='Any work done here in the past three years?'
                  informedComponent={
                    <RadioGroup className='radio' name='any_work_done_selected'>
                      <div className='radio-element'>
                        <Radio value='yes' label='Yes' />
                      </div>
                      <div className='radio-element'>
                        <Radio value='no' label='No' />
                      </div>
                    </RadioGroup>
                  }
                />
                {values?.any_work_done_selected === 'yes' ? (
                  <InformedField
                    label=''
                    name='work_done_items'
                    formRefApi={formRef}
                    informedComponent={
                      <div className='work-section'>
                        <ArrayField name='work_done_items'>
                          {({ addWithInitialValue }) => {
                            return (
                              <>
                                <ArrayField.Items>
                                  {({ remove, name }) => (
                                    <>
                                      <div className='work-list-container'>
                                        <div className='work-element'>
                                          <InformedField
                                            formRefApi={formRef}
                                            name={`${name}[contract_number]`}
                                            label='Contract number'
                                            informedComponent={<TextField name='contract_number' />}
                                          />
                                        </div>
                                        <div className='work-element'>
                                          <InformedField
                                            formRefApi={formRef}
                                            name={`${name}[po_number]`}
                                            label='PO Number'
                                            informedComponent={
                                              <NumericInput
                                                thousandSeparator={false}
                                                decimalScale={0}
                                                suffix=''
                                                name='po_number'
                                              />
                                            }
                                          />
                                        </div>
                                        <div className='work-element'>
                                          <InformedField
                                            formRefApi={formRef}
                                            name={`${name}[project_durations]`}
                                            label='Project duration (days)'
                                            informedComponent={
                                              <NumericInput
                                                thousandSeparator={false}
                                                decimalScale={0}
                                                suffix=''
                                                name='project_durations'
                                              />
                                            }
                                          />
                                        </div>
                                        <div className='work-element'>
                                          <InformedField
                                            formRefApi={formRef}
                                            name={`${name}[as_built_contract_amount]`}
                                            label='As-built contract amount (USD)'
                                            informedComponent={
                                              <NumericInput suffix='' name='as_built_contract_amount' />
                                            }
                                          />
                                        </div>
                                        <CloseCircleFilled className='close-icon' onClick={() => remove()} />
                                      </div>
                                    </>
                                  )}
                                </ArrayField.Items>
                                <div
                                  onClick={() =>
                                    addWithInitialValue({
                                      contract_number: '',
                                      po_number: '',
                                      project_duration: '',
                                      contract_amount: '',
                                    })
                                  }
                                  className='add-button'
                                >
                                  Add new work <span>+</span>
                                </div>
                              </>
                            );
                          }}
                        </ArrayField>
                      </div>
                    }
                  />
                ) : null}
              </div>
            );
          }}
        </FormStateAccessor>
        <div className='buttons-container'>
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            className='button'
            variant='transparent'
            onClick={() => onSubmitButtonClick('draft')}
            text='Save as draft'
          />
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            icon={<ArrowRightOutlined />}
            className='button go-next'
            onClick={() => onSubmitButtonClick('submit')}
            text='Save and go next'
          />
        </div>
      </Form>
    </div>
  );
};

export default ProjectOverviewStep;
