import React, { FC, useEffect, useState } from 'react';

import './styles.scss';
import CurrencyPicker from '@digital-office/pages/ProjectsManagementPages/components/CurrencyPicker';
import { EditOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, Pagination, Table } from '@ui-modules';
import GuidanceModal from '@components/CommunityGuidancePage/components/GuidanceModal';
import { Form, FormStateAccessor } from 'informed';
import { TextField } from '@ui-modules/informed';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import {
  ICostDetailItem,
  IProjectCostDetailsStagePaymentList,
  IProjectCostListItem,
  IProjectCostStagePaymentPayload,
} from '@digital-office/common/interfaces';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { message, Tooltip } from 'antd';
import FileSaver from 'file-saver';
import { format } from 'date-fns';

const items = [
  {
    key: 'USD',
    label: 'USD',
  },
  {
    key: 'PLN',
    label: 'PLN',
  },
];

const getColumns = (editModeHandler: (item: IProjectCostListItem) => void) => [
  {
    dataIndex: 'stage',
    key: 'stage',
    title: 'STAGE',
  },
  {
    dataIndex: 'title',
    key: 'title',
    title: 'TITLE',
    render: (record: string) => (
      <div className='table-cell-text-overflow'>
        <Tooltip placement='topLeft' title={record}>
          {record}
        </Tooltip>
      </div>
    ),
  },
  {
    dataIndex: 'item',
    key: 'item',
    title: 'ITEM',
    render: (record: string) => (
      <div className='table-cell-text-overflow'>
        <Tooltip placement='topLeft' title={record}>
          {record}
        </Tooltip>
      </div>
    ),
  },
  {
    dataIndex: 'services',
    key: 'services',
    title: 'SERVICES',
    render: (record: string) => (
      <div className='table-cell-text-overflow'>
        <Tooltip placement='topLeft' title={record}>
          {record}
        </Tooltip>
      </div>
    ),
  },
  {
    dataIndex: 'stage_fee',
    key: 'stage_fee',
    title: 'STAGE FEE',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'percentage_complete',
    key: 'percentage_complete',
    title: 'PERCENTAGE COMPLETE (%)',
  },
  {
    dataIndex: 'value_complete',
    key: 'value_complete',
    title: 'VALUE COMPLETE',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'amount_certified',
    key: 'amount_certified',
    title: 'AMOUNT CERTIFIED',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'total_invoiced_to_date',
    key: 'total_invoiced_to_date',
    title: 'TOTAL INVOICED TO DATE',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: '',
    key: '',
    title: 'ACTION',
    render: (record: undefined, set: IProjectCostListItem) => {
      if (set.uuid) {
        return (
          <div className='actions-section'>
            <Tooltip align={{ offset: [0, 0] }} title='Click to edit source'>
              <EditOutlined onClick={() => editModeHandler(set)} />
            </Tooltip>
          </div>
        );
      } else {
        return undefined;
      }
    },
  },
];

interface IPaymentsSectionProps {
  selectedItem: ICostDetailItem;
}

const schema = Yup.object().shape({
  stage: Yup.number().required('Required').positive('Number has to be positive'),
  title: Yup.string().required('Required'),
  item: Yup.string().required('Required'),
  services: Yup.string().required('Required'),
  stage_fee: Yup.number().required('Required'),
  percentage_complete: Yup.number().required('Required'),
  total_invoiced_to_date: Yup.number().required('Required'),
});

const StagePaymentsSection: FC<IPaymentsSectionProps> = ({ selectedItem }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, updateState] = useState<any>(null);
  const facilityId = +localStorage.getItem('facility')!;
  const [selectedCurrency, setSelectedCurrency] = useState<string>(items[0].key);
  const { id: uuid } = useParams<{ id: string }>();
  const [itemToEdit, setItemToEdit] = useState<IProjectCostListItem | undefined>();

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);

  const { engineeringRepository } = useRepository();

  useEffect(() => {
    if (itemToEdit) {
      setIsModalOpen(true);
    }
  }, [itemToEdit]);

  const {
    data: stagePaymentsList,
    isLoading,
    refetch,
  } = useQuery<IProjectCostDetailsStagePaymentList>(
    `cost-details-stage-payments-list-${pageSize}-${selectedPage}`,
    () =>
      engineeringRepository.getProjectCostStagePayments(facilityId, uuid || '', selectedItem.uuid, {
        limit: 10,
        offset: (selectedPage - 1) * 10,
      }),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const sendCostStagePaymentMutation = useMutation(
    (data: IProjectCostStagePaymentPayload) =>
      engineeringRepository.sendProjectCostStagePayment(facilityId, uuid || '', selectedItem.uuid, data),
    {
      onSuccess: () => {
        message.success('Stage Payment has been added');
        setIsModalOpen(false);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const updateCostStagePaymentMutation = useMutation(
    (data: Partial<IProjectCostStagePaymentPayload>) =>
      engineeringRepository.updateProjectCostStagePayments(
        facilityId,
        uuid || '',
        selectedItem.uuid,
        itemToEdit?.uuid || '',
        data
      ),
    {
      onSuccess: () => {
        message.success('Payment has been updated');
        setIsModalOpen(false);
        setItemToEdit(undefined);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const getStagePaymentExportMutation = useMutation(
    () => engineeringRepository.getStagePaymentExport(facilityId, uuid || '', selectedItem.uuid || ''),
    {
      onSuccess: (data: string) => {
        try {
          FileSaver.saveAs(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
            }),
            `stage-payment_export_${format(new Date(), 'dd-MM-yyyy')}.xlsx`
          );
        } catch (error) {
          message.error('Parsing file error');
        }
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const getCostDetailsList = () => {
    if (!stagePaymentsList?.results) {
      return [];
    }

    const costDetailList = stagePaymentsList?.results.reduce(
      (accumulator, item) => {
        return {
          values: [...accumulator.values, { ...item }],
          totalAmount: {
            amount_certified: accumulator.totalAmount.amount_certified + (item.amount_certified ?? 0),
            stage_fee: accumulator.totalAmount.stage_fee + item.stage_fee,
            total_invoiced_to_date: accumulator.totalAmount.total_invoiced_to_date + item.total_invoiced_to_date,
            value_complete: accumulator.totalAmount.value_complete + item.value_complete,
          },
        };
      },
      {
        values: [] as IProjectCostStagePaymentPayload[],
        totalAmount: {
          amount_certified: 0,
          stage_fee: 0,
          total_invoiced_to_date: 0,
          value_complete: 0,
        },
      }
    );

    return [...(costDetailList?.values || []), { ...costDetailList?.totalAmount }];
  };

  const sendStagePayment = (values: Record<any, any>) => {
    const payload: any = {
      ...values,
      amount_certified: Number(getAmountCertifiedValue(values)),
      value_complete: Math.round((getValueCompleteValue(values) + Number.EPSILON) * 100) / 100,
    };

    itemToEdit ? updateCostStagePaymentMutation.mutate(payload) : sendCostStagePaymentMutation.mutate(payload);
  };

  const getValueCompleteValue = (values: Record<any, any>) => {
    return Number(values.stage_fee) * Number((values.percentage_complete as number) / 100);
  };

  const getAmountCertifiedValue = (values: Record<any, any>) => {
    if (values.percentage_complete === '100') {
      return values.stage_fee;
    } else return '0';
  };

  return (
    <div className='stage-payment-section-wrapper'>
      <GuidanceModal
        className='financial-modal'
        isClosable={true}
        width='40%'
        destroyOnClose={true}
        centered={false}
        open={isModalOpen}
        closeHandler={() => {
          setIsModalOpen(false);
          setItemToEdit(undefined);
        }}
      >
        <p className='title'>{itemToEdit ? 'Edit' : 'Add new'} item</p>
        <Form
          onSubmit={({ values }) => sendStagePayment(values)}
          yupSchema={schema}
          key={`${itemToEdit}`}
          initialValues={
            itemToEdit
              ? {
                  ...itemToEdit,
                }
              : {}
          }
          className='source-form'
          onClick={() => updateState({})}
        >
          <FormStateAccessor>
            {({ values }) => {
              return (
                <>
                  <div className='field'>
                    <label>Stage</label>
                    <TextField type='number' name='stage' />
                  </div>
                  <div className='field'>
                    <label>Title</label>
                    <TextField name='title' />
                  </div>
                  <div className='field'>
                    <label>Item</label>
                    <TextField name='item' />
                  </div>
                  <div className='field'>
                    <label>Services</label>
                    <TextField name='services' />
                  </div>
                  <div className='field'>
                    <label>Stage fee</label>
                    <NumericInput name='stage_fee' />
                  </div>
                  <div className='field'>
                    <label>Percentage complete (%)</label>
                    <NumericInput decimalScale={0} percentage={true} suffix=' %' name='percentage_complete' />
                  </div>
                  <div className='field'>
                    <label>Value complete</label>
                    <NumericInput customValue={getValueCompleteValue(values)} name='value_complete' disabled={true} />
                  </div>
                  <div className='field'>
                    <label>Amount certified</label>
                    <NumericInput
                      disabled={true}
                      customValue={getAmountCertifiedValue(values)}
                      name='amount_certified'
                    />
                  </div>
                  <div className='field'>
                    <label>Total invoice to date</label>
                    <NumericInput name='total_invoiced_to_date' />
                  </div>
                  <hr />
                  <div className='buttons-container'>
                    <Button onClick={() => setIsModalOpen(false)} variant='transparent' text='Cancel' />
                    <Button type='submit' variant='primary' text='Save item' />
                  </div>
                </>
              );
            }}
          </FormStateAccessor>
        </Form>
      </GuidanceModal>
      <p className='title'>Stage payments</p>
      <div className='section-header'>
        <div style={{ visibility: 'hidden' }}>
          <CurrencyPicker
            value={selectedCurrency}
            options={items}
            onChange={(currency) => setSelectedCurrency(currency)}
          />
        </div>
        <div className='control-buttons'>
          <div onClick={() => getStagePaymentExportMutation.mutate()} className='button'>
            Export Excel
            <FileExcelOutlined />
          </div>
          <div onClick={() => setIsModalOpen(true)} className='button blue'>
            Add new item <span>+</span>
          </div>
        </div>
      </div>
      <Table
        className='funding-table'
        columns={getColumns(setItemToEdit)}
        data={getCostDetailsList() || []}
        isLoading={isLoading}
        variant='dark'
        scroll={{ x: true }}
      />
      <Pagination
        className='pagination'
        selectedPage={selectedPage}
        totalPages={stagePaymentsList?.count || 0}
        showJumper={false}
        showPageSize={false}
        variant='dark'
        onPageChange={setSelectedPage}
        onPageSizeChange={setPageSize}
      />
    </div>
  );
};

export default StagePaymentsSection;
