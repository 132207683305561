import React from 'react';
import { ReactComponent as ArrowIcon } from '@assets/svg/icon_arrow_round.svg';
import './styles.scss';

interface IProps {
  iconSrc: string;
  customClassNames?: string;
  label?: string;
  color?: string;
  showArrow?: boolean;
}

const DiamondIcon = ({ iconSrc, customClassNames, color, label = '', showArrow = false, ...rest }: IProps) => {
  return (
    <div className='diamond-icon-wrapper'>
      <div style={{ backgroundColor: color }} className={`diamond-shape ${customClassNames}`} {...rest}>
        <img className='inner-icon' src={iconSrc} />
      </div>
      {label && (
        <p className='label'>
          {label}
          {showArrow && <ArrowIcon className='arrow-icon' />}
        </p>
      )}
    </div>
  );
};

export default DiamondIcon;
