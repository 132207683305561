import React, { FC, useState } from 'react';
import { useField } from 'informed';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import './styles.scss';

interface IPhoneField {
  name: string;
  dark?: boolean;
  preferredCountries?: Array<string>;
}

const PhoneField: FC<IPhoneField> = (props) => {
  const [valid, setValid] = useState(true);
  const [code, setCode] = useState('');

  const { dark, preferredCountries } = props;
  const { fieldApi, fieldState, render, ref } = useField(props);

  const fieldValue = ((fieldState.value as string) || '').replace(code, '').replace(/[ -]/g, '');
  const { setValue, setTouched } = fieldApi;

  return render(
    <IntlTelInput
      separateDialCode={true}
      ref={ref}
      value={fieldValue}
      preferredCountries={preferredCountries}
      onPhoneNumberChange={(isValid, _, { dialCode }, fullNumber) => {
        setValid(isValid);
        setValue(fullNumber);
        setCode(`+${dialCode}`);
      }}
      onSelectFlag={() => {
        setValid(true);
        setValue('');
        setCode('');
      }}
      onPhoneNumberBlur={() => setTouched(true)}
      containerClassName={`intl-tel-input ${dark ? 'dark' : ''} ${valid ? '' : 'invalid'}`}
      inputClassName='intl-tel-element'
    />
  );
};

export default PhoneField;
