import React, { FC } from 'react';
import { FormApi, Radio, RadioGroup } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import * as Yup from 'yup';
import { IAssessmentFormData } from '@digital-office/pages/FieldAssessmentReports/components/AssessmentChecklistStep';

interface IAssessmentConductedList {
  name: string;
  values: IAssessmentFormData;
  formRef: React.RefObject<FormApi>;
}

const getAssessmentMasonryListYupValidation = () => {
  return Yup.object().shape({
    masonry_foundations_footings_wall_construction: Yup.string().required('Required'),
    plastering_walls_smooth_surfaces: Yup.string().required('Required'),
    plain_cement_concreting_floors_footings_walls_walkways: Yup.string().required('Required'),
    reinforced_cement_concreting_beams_columns_roof: Yup.string().required('Required'),
    construction_rehabilitation_kitchen_offices: Yup.string().required('Required'),
    floor_finishes_screed_tiles_skirting_carpets: Yup.string().required('Required'),
    doors_windows_vents_steel_grills: Yup.string().required('Required'),
    accommodation_huts_construction_rehabilitation_timber_grass_thatch: Yup.string().required('Required'),
    wood_timber_works: Yup.string().required('Required'),
    false_ceiling: Yup.string().required('Required'),
    painting_works_cement_distemper_oil_acrylic: Yup.string().required('Required'),
  });
};

const AssessmentMasonryList: FC<IAssessmentConductedList> = ({ formRef }) => {
  return (
    <div className='assessment-details-list-container'>
      <div className='table-header'>
        <div className='headers'>
          <span>Yes</span>
          <span>No</span>
        </div>
      </div>
      <InformedField
        name='data[masonry_concreting_paint_required][masonry_foundations_footings_wall_construction]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Brick/Block/Stonemasonry in foundations, footings & wall construction?</span>
            <RadioGroup
              name='data[masonry_concreting_paint_required][masonry_foundations_footings_wall_construction]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][plastering_walls_smooth_surfaces]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Plastering of walls and other smooth surfaces?</span>
            <RadioGroup
              name='data[masonry_concreting_paint_required][plastering_walls_smooth_surfaces]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][plain_cement_concreting_floors_footings_walls_walkways]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Plain Cement Concreting in floors, footings, walls, walkways, etc.?</span>
            <RadioGroup
              name='data[masonry_concreting_paint_required][plain_cement_concreting_floors_footings_walls_walkways]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][reinforced_cement_concreting_beams_columns_roof]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Reinforced Cement Concreting in beams, columns, roof, etc.?</span>
            <RadioGroup
              name='data[masonry_concreting_paint_required][reinforced_cement_concreting_beams_columns_roof]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][construction_rehabilitation_kitchen_offices]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Construction and/or rehabilitation works in the kitchen, offices, etc.?</span>
            <RadioGroup name='data[masonry_concreting_paint_required][construction_rehabilitation_kitchen_offices]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][floor_finishes_screed_tiles_skirting_carpets]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Floor finishes (screed, tiles, skirting, carpets, etc.)?</span>
            <RadioGroup
              name='data[masonry_concreting_paint_required][floor_finishes_screed_tiles_skirting_carpets]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][doors_windows_vents_steel_grills]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Doors, windows, vents, or steel grills?</span>
            <RadioGroup
              name='data[masonry_concreting_paint_required][doors_windows_vents_steel_grills]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][accommodation_huts_construction_rehabilitation_timber_grass_thatch]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>
              Construction or rehabilitation of accommodation huts (tukuls) in timber & grass thatch
            </span>
            <RadioGroup
              name='data[masonry_concreting_paint_required][accommodation_huts_construction_rehabilitation_timber_grass_thatch]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][wood_timber_works]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Wood and timber works</span>
            <RadioGroup name='data[masonry_concreting_paint_required][wood_timber_works]' className='radio'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][false_ceiling]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>False ceiling?</span>
            <RadioGroup name='data[masonry_concreting_paint_required][false_ceiling]' className='radio'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[masonry_concreting_paint_required][painting_works_cement_distemper_oil_acrylic]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>
              Painting works [in cement based (distemper), oil based weather shield or acrylic paint?]
            </span>
            <RadioGroup
              name='data[masonry_concreting_paint_required][painting_works_cement_distemper_oil_acrylic]'
              className='radio'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
    </div>
  );
};

export { getAssessmentMasonryListYupValidation, AssessmentMasonryList };
