import React, { FC, useEffect, useState } from 'react';

import './styles.scss';
import CurrencyPicker from '@digital-office/pages/ProjectsManagementPages/components/CurrencyPicker';
import { EditOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, Pagination, Table } from '@ui-modules';
import GuidanceModal from '@components/CommunityGuidancePage/components/GuidanceModal';
import { Form, FormStateAccessor } from 'informed';
import { DateField, Dropdown, TextField } from '@ui-modules/informed';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import {
  ICostDetailItem,
  IProjectCostDetailsVariationList,
  IProjectCostVariationItem,
} from '@digital-office/common/interfaces';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { message, Tooltip } from 'antd';
import { format, parse } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import FileSaver from 'file-saver';

const items = [
  {
    key: 'USD',
    label: 'USD',
  },
  {
    key: 'PLN',
    label: 'PLN',
  },
];

const getColumns = (editModeHandler: (item: IProjectCostVariationItem) => void) => [
  {
    dataIndex: 'ref_no',
    key: 'ref_no',
    title: 'REF NO.',
  },
  {
    dataIndex: 'variation_detail',
    key: 'variation_detail',
    title: 'VARIATIONS DETAILS',
  },
  {
    dataIndex: 'date_of_application',
    key: 'date_of_application',
    title: 'DATE OF APPLICATION',
    render: (data: string) => {
      return data ? format(new Date(data), DateFormatEnum.DD_MM_YYYY) : '';
    },
  },
  {
    dataIndex: 'date_of_approval_or_rejection',
    key: 'date_of_approval_or_rejection',
    title: 'DATE OF APPROVAL OR REJECTION',
    render: (data: string) => {
      return data ? format(new Date(data), DateFormatEnum.DD_MM_YYYY) : '';
    },
  },
  {
    dataIndex: 'cost_estimate',
    key: 'cost_estimate',
    title: 'COST ESTIMATE',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'total_approved',
    key: 'total_approved',
    title: 'TOTAL APPROVED',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'is_approved',
    key: 'is_approved',
    title: 'IS APPROVED',
    render: (record: boolean | undefined) => {
      if (record !== undefined) {
        return record ? 'Yes' : 'No';
      } else return '';
    },
  },
  {
    dataIndex: '',
    key: '',
    title: 'ACTION',
    render: (record: undefined, set: IProjectCostVariationItem) => {
      if (set.uuid) {
        return (
          <div className='actions-section'>
            <Tooltip align={{ offset: [0, 0] }} title='Click to edit source'>
              <EditOutlined onClick={() => editModeHandler(set)} />
            </Tooltip>
          </div>
        );
      } else {
        return undefined;
      }
    },
  },
];

interface IPaymentsSectionProps {
  selectedItem: ICostDetailItem;
}

const approvedOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const schema = Yup.object().shape({
  ref_no: Yup.string().required('Required'),
  variation_detail: Yup.string().required('Required'),
  date_of_application: Yup.date().required('Required'),
  cost_estimate: Yup.number().required('Required'),
  is_approved: Yup.object().required('Required'),
  date_of_approval_or_rejection: Yup.date().required('Required'),
});

const PaymentsVariationsSection: FC<IPaymentsSectionProps> = ({ selectedItem }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>(items[0].key);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, updateState] = useState<any>(null);
  const facilityId = +localStorage.getItem('facility')!;
  const { id: uuid } = useParams<{ id: string }>();
  const [itemToEdit, setItemToEdit] = useState<IProjectCostVariationItem | undefined>();

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);

  const { engineeringRepository } = useRepository();

  useEffect(() => {
    if (itemToEdit) {
      setIsModalOpen(true);
    }
  }, [itemToEdit]);

  const {
    data: variationsList,
    isLoading,
    refetch,
  } = useQuery<IProjectCostDetailsVariationList>(
    `cost-details-variation-list-${pageSize}-${selectedPage}-${uuid}-${selectedItem.uuid}`,
    () =>
      engineeringRepository.getProjectVariationsList(facilityId, uuid || '', selectedItem.uuid, {
        limit: 10,
        offset: (selectedPage - 1) * 10,
      }),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const sendCostVariationMutation = useMutation(
    (data: IProjectCostVariationItem) =>
      engineeringRepository.sendProjectCostVariation(facilityId, uuid || '', selectedItem.uuid, data),
    {
      onSuccess: () => {
        message.success('Variation has been added');
        setIsModalOpen(false);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const updateCostVariationMutation = useMutation(
    (data: Partial<IProjectCostVariationItem>) =>
      engineeringRepository.updateProjectCostVariation(
        facilityId,
        uuid || '',
        selectedItem.uuid,
        itemToEdit?.uuid || '',
        data
      ),
    {
      onSuccess: () => {
        message.success('Variation has been updated');
        setIsModalOpen(false);
        setItemToEdit(undefined);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const getVariationsExportMutation = useMutation(
    () => engineeringRepository.getVariationsExport(facilityId, uuid || '', selectedItem.uuid || ''),
    {
      onSuccess: (data: string) => {
        try {
          FileSaver.saveAs(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
            }),
            `variations_export_${format(new Date(), 'dd-MM-yyyy')}.xlsx`
          );
        } catch (error) {
          message.error('Parsing file error');
        }
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const getVariationsList = () => {
    if (!variationsList?.results) {
      return [];
    }

    const costDetailList = variationsList?.results.reduce(
      (accumulator, item) => {
        return {
          values: [...accumulator.values, { ...item }],
          totalAmount: {
            cost_estimate: accumulator.totalAmount.cost_estimate + (item.cost_estimate ?? 0),
            total_approved: accumulator.totalAmount.total_approved + (item.total_approved ?? 0),
          },
        };
      },
      {
        values: [] as IProjectCostVariationItem[],
        totalAmount: {
          cost_estimate: 0,
          total_approved: 0,
        },
      }
    );

    return [...(costDetailList?.values || []), { ...costDetailList?.totalAmount }];
  };

  const sendStagePayment = (values: Record<any, any>) => {
    const payload: any = {
      ...values,
      date_of_application: format(new Date(values.date_of_application), DateFormatEnum.YYYY_MM_DD),
      date_of_approval_or_rejection: format(new Date(values.date_of_approval_or_rejection), DateFormatEnum.YYYY_MM_DD),
      is_approved: values.is_approved.value === 'yes',
      total_approved: Number(getTotalApprovedValue(values)),
    };

    itemToEdit ? updateCostVariationMutation.mutate(payload) : sendCostVariationMutation.mutate(payload);
  };

  const getTotalApprovedValue = (values: Record<any, any>) => {
    if (values?.is_approved?.value === 'yes') {
      return values.cost_estimate;
    } else return '0';
  };

  return (
    <div className='variations-section-wrapper'>
      <GuidanceModal
        className='financial-modal'
        isClosable={true}
        width='40%'
        destroyOnClose={true}
        centered={false}
        open={isModalOpen}
        closeHandler={() => {
          setIsModalOpen(false);
          setItemToEdit(undefined);
        }}
      >
        <p className='title'>{itemToEdit ? 'Edit' : 'Add new'} item</p>
        <Form
          onSubmit={({ values }) => sendStagePayment(values)}
          yupSchema={schema}
          key={`${itemToEdit}`}
          initialValues={
            itemToEdit
              ? {
                  ...itemToEdit,
                  is_approved: itemToEdit.is_approved ? approvedOptions[0] : approvedOptions[1],
                  date_of_application: parse(itemToEdit.date_of_application, DateFormatEnum.YYYY_MM_DD, new Date()),
                  date_of_approval_or_rejection: parse(
                    itemToEdit.date_of_approval_or_rejection,
                    DateFormatEnum.YYYY_MM_DD,
                    new Date()
                  ),
                }
              : {}
          }
          className='source-form'
          onClick={() => updateState({})}
        >
          <FormStateAccessor>
            {({ values }) => {
              return (
                <>
                  <div className='field'>
                    <label>Ref No.</label>
                    <TextField name='ref_no' />
                  </div>
                  <div className='field'>
                    <label>Variation detail</label>
                    <TextField name='variation_detail' />
                  </div>
                  <div className='field'>
                    <label>Date of application</label>
                    <DateField name='date_of_application' />
                  </div>
                  <div className='field'>
                    <label>Cost of estimate</label>
                    <NumericInput name='cost_estimate' />
                  </div>
                  <div className='field'>
                    <label>Is approved</label>
                    <Dropdown options={approvedOptions} name='is_approved' />
                  </div>
                  <div className='field'>
                    <label>Date of approval or rejection</label>
                    <DateField name='date_of_approval_or_rejection' />
                  </div>
                  <div className='field'>
                    <label>Total approved</label>
                    <NumericInput customValue={getTotalApprovedValue(values)} name='total_approved' disabled={true} />
                  </div>
                  <hr />
                  <div className='buttons-container'>
                    <Button onClick={() => setIsModalOpen(false)} variant='transparent' text='Cancel' />
                    <Button type='submit' variant='primary' text='Save item' />
                  </div>
                </>
              );
            }}
          </FormStateAccessor>
        </Form>
      </GuidanceModal>
      <p className='title'>Variations</p>
      <div className='section-header'>
        <div style={{ visibility: 'hidden' }}>
          <CurrencyPicker
            value={selectedCurrency}
            options={items}
            onChange={(currency) => setSelectedCurrency(currency)}
          />
        </div>
        <div className='control-buttons'>
          <div onClick={() => getVariationsExportMutation.mutate()} className='button'>
            Export Excel
            <FileExcelOutlined />
          </div>
          <div onClick={() => setIsModalOpen(true)} className='button blue'>
            Add new item <span>+</span>
          </div>
        </div>
      </div>
      <Table
        className='funding-table'
        columns={getColumns(setItemToEdit)}
        data={getVariationsList() || []}
        isLoading={isLoading}
        variant='dark'
        scroll={{ x: true }}
      />
      <Pagination
        className='pagination'
        selectedPage={selectedPage}
        totalPages={variationsList?.count || 0}
        showJumper={false}
        showPageSize={false}
        variant='dark'
        onPageChange={setSelectedPage}
        onPageSizeChange={setPageSize}
      />
    </div>
  );
};

export default PaymentsVariationsSection;
