import React, { FC } from 'react';
import './styles.scss';
import { isObject } from 'lodash';
import { IAssessmentChecklist } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface IAssessmentOverviewSummaryProps {
  values: IAssessmentChecklist;
}

const getConfig = (values: IAssessmentChecklist) => [
  {
    label: 'Assessment conducted for new and/or variation in works?',
    key: 'assessment_new_variation_works',
    children: [
      {
        label: 'Does prefabricated office and/or accommodation units exist at this facility?',
        key: 'prefab_office_accommodation_units_exist',
        customValue: `${
          values?.assessment_new_variation_works !== false &&
          values?.assessment_new_variation_works?.prefab_office_accommodation_units_exist
            ? 'Yes - '
            : 'No'
        } ${
          values?.assessment_new_variation_works !== false &&
          values?.assessment_new_variation_works?.how_many_prefab_office_accommodation_units_exist
            ? values?.assessment_new_variation_works?.how_many_prefab_office_accommodation_units_exist
            : ''
        }`,
      },
      {
        label: 'Are new prefabricated units required for office and/or accommodation units?',
        key: 'new_prefab_units_required_office_accommodation',
        customValue: `${
          values?.assessment_new_variation_works !== false &&
          values?.assessment_new_variation_works?.new_prefab_units_required_office_accommodation
            ? 'Yes - '
            : 'No'
        } ${
          values?.assessment_new_variation_works !== false &&
          values?.assessment_new_variation_works?.how_many_new_prefab_units_required_office_accommodation
            ? values?.assessment_new_variation_works?.how_many_new_prefab_units_required_office_accommodation
            : ''
        }`,
      },
      {
        label: 'Are new prefabricated units required for office and/or accommodation units?',
        key: 'shipping_containers_exist',
      },
      {
        label: 'Are new shipping containers required at this facility for storage or similar purposes?',
        key: 'new_shipping_containers_required_facility_storage',
        customValue: `${
          values?.assessment_new_variation_works !== false &&
          values?.assessment_new_variation_works?.new_shipping_containers_required_facility_storage
            ? 'Yes - '
            : 'No'
        } ${
          values?.assessment_new_variation_works !== false &&
          values?.assessment_new_variation_works?.how_many_new_shipping_containers_required_facility_storage
            ? values?.assessment_new_variation_works?.how_many_new_shipping_containers_required_facility_storage
            : ''
        }`,
      },
      {
        label:
          'Are there Mobile Storage Units (temporary warehouses) existing at this facility for storage or similar purposes?',
        key: 'mobile_storage_units_exist_facility',
        customValue: `${
          values?.assessment_new_variation_works !== false &&
          values?.assessment_new_variation_works?.mobile_storage_units_exist_facility
            ? 'Yes - '
            : 'No'
        } ${
          (values?.assessment_new_variation_works !== false &&
            values?.assessment_new_variation_works?.how_many_mobile_storage_units_exist_facility) ||
          ''
        } ${
          values?.assessment_new_variation_works !== false &&
          values?.assessment_new_variation_works?.drainage_system_exists
            ? '- Drainage system'
            : '- No drainage system'
        }`,
      },
    ],
  },
  {
    label: 'Ground improvements works required?',
    key: 'ground_improvements_required',
    children: [
      {
        label: 'Does an effective or functional drainage system exists?',
        key: 'clearing_grubbing_land_leveling_needed',
      },
      {
        label: 'Does the floor level need to be established?',
        key: 'floor_level_establishment_required',
      },
      {
        label: 'Is a nearby supply of gravel, crushed stone, or murram available?',
        key: 'nearby_supply_available_gravel_crushed_stone_murram',
      },
      {
        label: 'Is demolishing, dismantling & disposal of existing structures required?',
        key: 'demolish_dismantle_dispose_existing_structures_required',
      },
      {
        label: 'Does the drainage of the compound area and/or around MSUs needed?',
        key: 'compound_area_drainage_needed',
      },
      {
        label: 'Is rehabilitation of the access or trunk road needed?',
        key: 'access_trunk_road_rehabilitation_needed',
      },
      {
        label: 'Are Culverts (in concrete or steel pipes) needed under roads and/or paths?',
        key: 'culverts_needed_under_roads_paths',
      },
    ],
  },
  {
    label: 'Masonry, concreting & paint works required?',
    key: 'masonry_concreting_paint_required',
    children: [
      {
        label: 'Brick/Block/Stonemasonry in foundations, footings & wall construction?',
        key: 'masonry_foundations_footings_wall_construction',
      },
      {
        label: 'Plastering of walls and other smooth surfaces?',
        key: 'plastering_walls_smooth_surfaces',
      },
      {
        label: 'Plain Cement Concreting in floors, footings, walls, walkways, etc.?',
        key: 'plain_cement_concreting_floors_footings_walls_walkways',
      },
      {
        label: 'Reinforced Cement Concreting in beams, columns, roof, etc.?',
        key: 'reinforced_cement_concreting_beams_columns_roof',
      },
      {
        label: 'Construction and/or rehabilitation works in the kitchen, offices, etc.?',
        key: 'construction_rehabilitation_kitchen_offices',
      },
      {
        label: 'Floor finishes (screed, tiles, skirting, carpets, etc.)?',
        key: 'floor_finishes_screed_tiles_skirting_carpets',
      },
      {
        label: 'Doors, windows, vents, or steel grills?',
        key: 'doors_windows_vents_steel_grills',
      },
      {
        label: 'Construction or rehabilitation of accommodation huts (tukuls) in timber & grass thatch?',
        key: 'accommodation_huts_construction_rehabilitation_timber_grass_thatch',
      },
      {
        label: 'Wood and timber works',
        key: 'wood_timber_works',
      },
      {
        label: 'False ceiling?',
        key: 'false_ceiling',
      },
      {
        label: 'Painting works [in cement based (distemper), oil based weather shield or acrylic paint?',
        key: 'painting_works_cement_distemper_oil_acrylic',
      },
    ],
  },
  {
    label: 'Supply, fabrication & installation steel & ironworks in required?',
    key: 'steel_ironworks_supply_fabrication_installation_required',
    children: [
      {
        label: 'Roofing with galvanized iron sheets?',
        key: 'galvanized_iron_roofing',
      },
      {
        label: 'Staircases with embossed metal plates?',
        key: 'embossed_metal_plates_staircases',
      },
      {
        label: 'Handrails, safety grills & manhole covers, etc.?',
        key: 'handrails_safety_grills_manhole_covers',
      },
      {
        label: 'Miscellaneous metal works?',
        key: 'miscellaneous_metal_works',
      },
    ],
  },
  {
    label: 'Water supply works required?',
    key: 'water_supply_works_required',
    children: [
      {
        label: 'Drilling or rehabilitation of water borehole?',
        key: 'water_borehole_drilling_rehabilitation',
      },
      {
        label: 'Fabrication and erection of steel water tower and water tank?',
        key: 'steel_water_tower_tank_fabrication_erection',
      },
      {
        label: 'Supply & installation of water purification system?',
        key: 'water_purification_system_supply_installation',
      },
      {
        label: 'Water supply pipe laying & installation?',
        key: 'water_supply_pipe_laying_installation',
      },
      {
        label: 'Water taps, valves & related accessories?',
        key: 'water_taps_valves_accessories',
      },
      {
        label: 'Rainwater harvesting system?',
        key: 'rainwater_harvesting_system',
      },
    ],
  },
  {
    label: 'Wastewater & sanitation works required?',
    key: 'wastewater_sanitation_works_required',
    children: [
      {
        label: 'Construction of flush toilet or pit latrine?',
        key: 'flush_toilet_pit_latrine_construction',
      },
      {
        label: 'Construction of showers?',
        key: 'construction_of_showers',
      },
      {
        label: 'Construction or rehabilitation of manholes & gully traps?',
        key: 'construction_rehabilitation_of_manholes_and_gully_traps',
      },
      {
        label: 'Construction or rehabilitation of septic tank & soakage pit?',
        key: 'septic_tank_soakage_pit_construction_rehabilitation',
      },
      {
        label: 'Flush tank, urinals, piping & related accessories?',
        key: 'flush_tank_urinals_piping_accessories',
      },
    ],
  },
  {
    label: 'Security works required?',
    key: 'security_works_required',
    children: [
      {
        label: 'Chain-linked fence, razor wires, etc.?',
        key: 'chain_linked_fence_razor_wires_etc',
      },
      {
        label: 'Main & pedestrian gates?',
        key: 'main_pedestrian_gates',
      },
      {
        label: 'Guardhouse & guard posts?',
        key: 'guardhouse_guard_posts',
      },
      {
        label: 'Boom barrier?',
        key: 'boom_barrier',
      },
      {
        label: 'HESCO security barrier walls?',
        key: 'hesco_security_barrier_walls',
      },
      {
        label: 'Security Bunker?',
        key: 'security_bunker',
      },
    ],
  },
  {
    label: 'Electrical works required?',
    key: 'electrical_works_required',
    children: [
      {
        label: 'Construction of a generator shed?',
        key: 'construction_of_generator_shed',
      },
      {
        label: 'Armoured cables, distribution board (DB) & accessories?',
        key: 'armoured_cables_distribution_board_accessories',
      },
      {
        label: 'Cabling, lighting & solar panels, etc.?',
        key: 'cabling_lighting_solar_panels_etc',
      },
      {
        label: 'Earthing?',
        key: 'earthing',
      },
      {
        label: 'Air conditioners, water heaters & exhaust fans etc.?',
        key: 'air_conditioners_water_heaters_exhaust_fans_etc',
      },
    ],
  },
  {
    label: 'Miscellaneous works required?',
    key: 'miscellaneous_works_required',
    children: [
      {
        label: 'Construction of ICT VSAT slab??',
        key: 'construction_of_ICT_VSAT_slab',
      },
      {
        label: 'MAST?',
        key: 'mast',
      },
      {
        label: 'Construction of ICT Manholes for cabling?',
        key: 'construction_of_ICT_manholes_for_cabling',
      },
      {
        label: 'Construction of fleet workshop & wash-bay?',
        key: 'construction_of_fleet_workshop_wash_bay',
      },
      {
        label: 'Others?',
        key: 'others',
        customValue: `${
          values.miscellaneous_works_required !== false && values.miscellaneous_works_required?.others ? 'Yes -' : 'No'
        } ${
          (values.miscellaneous_works_required !== false && values?.miscellaneous_works_required?.describe_others) || ''
        } `,
      },
    ],
  },
];
const AssessmentChecklistSummary: FC<IAssessmentOverviewSummaryProps> = ({ values }) => {
  const data = getConfig(values);

  const getFieldValue = (
    key: keyof IAssessmentChecklist,
    children: (
      | { label: string; key: string; customValue: string }
      | { label: string; key: string; customValue?: undefined }
    )[]
  ) => {
    const sectionData = values[key];
    if (!isObject(sectionData)) {
      return values[key] ? 'Yes' : 'No';
    } else {
      return children.map((childrenData) => (
        <div key={childrenData.key} className='summary-field'>
          <div className='section-label'>{childrenData.label}</div>
          <div className='section-value'>
            {/*@ts-ignore}*/}
            {childrenData.customValue ? childrenData.customValue : sectionData[childrenData.key] ? 'Yes' : 'No'}
          </div>
        </div>
      ));
    }
  };

  return (
    <div className='assessment-checklist-summary-container'>
      {data.map((checklistData) => (
        <div key={checklistData.key} className='summary-field section'>
          <div className='section-label'>{checklistData.label}</div>
          <div className='section-value'>
            {getFieldValue(checklistData.key as keyof IAssessmentChecklist, checklistData.children)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssessmentChecklistSummary;
