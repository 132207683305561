import React, { FC } from 'react';
import './styles.scss';
import { format, parseISO } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import { IAssessmentProjectOverview } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface IAssessmentOverviewSummaryProps {
  values: IAssessmentProjectOverview;
}

const AssessmentOverviewSummary: FC<IAssessmentOverviewSummaryProps> = ({ values }) => {
  return (
    <div className='assessment-overview-summary-container'>
      <div className='summary-field'>
        <div className='section-label'>Project title</div>
        <div className='section-value'>{values.project_title}</div>
      </div>
      <div className='summary-field'>
        <div className='section-label'>Assessment date</div>
        <div className='section-value'>{format(parseISO(values.assessment_date), DateFormatEnum.DD_MM_YYYY)}</div>
      </div>
      <div className='summary-field'>
        <div className='section-label'>Mission purpose</div>
        <div className='section-value'>{values.mission_purpose}</div>
      </div>
      <div className='summary-field'>
        <div className='section-label'>Requesting unit</div>
        <div className='section-value'>{values.request_unit}</div>
      </div>
      <div className='summary-field'>
        <div className='section-label'>Area office</div>
        <div className='section-value'>{values.area_office}</div>
      </div>
      <div className='summary-field'>
        <div className='section-label'>Facility size (optional)</div>
        <div className='section-value'>{values.facility_size}</div>
      </div>
      <div className='summary-field work-item'>
        <div className='section-label'>Any work done here in the past three years?</div>
        <div className='work-items-done-container'>
          {values?.work_done_items?.map((workItem, key: number) => (
            <div key={key} className='work-item-element'>
              <div className='summary-field'>
                <div className='section-label'>Contract number</div>
                <div className='section-value'>{workItem.contract_number}</div>
              </div>
              <div className='summary-field'>
                <div className='section-label'>PO number</div>
                <div className='section-value'>{workItem.po_number}</div>
              </div>
              <div className='summary-field'>
                <div className='section-label'>Project duration (days)</div>
                <div className='section-value'>{workItem.project_durations}</div>
              </div>
              <div className='summary-field'>
                <div className='section-label'>As-built contract amount (USD)</div>
                <div className='section-value'>
                  {Number(workItem.as_built_contract_amount).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
            </div>
          )) || 'No'}
        </div>
      </div>
    </div>
  );
};

export default AssessmentOverviewSummary;
