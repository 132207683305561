import React, { FC, useMemo, useRef, useState } from 'react';
import { Form, FormApi, FormState, FormStateAccessor } from 'informed';
import { mapDataToComponents, updateForm, validationSchema } from '@digital-office/pages/ProjectsManagementPages/utils';
import { cloneDeep, isEmpty } from 'lodash';
import formConfig from '@digital-office/pages/ProjectsManagementPages/formConfig';
import { Tooltip } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import LocationSection from '@digital-office/pages/ProjectsManagementPages/components/LocationSection';
import { IProjectDetailsResponse } from '@digital-office/common/interfaces';
import { ILocationObject } from '@digital-office/pages/ProjectsManagementPages/components/LocationModal';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

interface IProjectOverviewProps {
  projectDetails: IProjectDetailsResponse;
  formData: FormState | null;
  setFormData: React.Dispatch<React.SetStateAction<FormState | null>>;
  isEditMode: boolean;
  formKey: number;
  locations: ILocationObject[];
  setLocations: React.Dispatch<React.SetStateAction<ILocationObject[]>>;
}

const ProjectOverview: FC<IProjectOverviewProps> = ({
  projectDetails,
  formData,
  setFormData,
  isEditMode,
  formKey,
  locations,
  setLocations,
}) => {
  const formRef = useRef<FormApi>(null);
  const options = projectDetails?.options;
  const navigate = useNavigate();

  const onBlurHandler = useMemo(() => {
    return () => {
      updateForm(formRef, setFormData);
    };
  }, [options]);

  return (
    <div className='project-overview-wrapper'>
      <h3>
        Project general information{' '}
        {projectDetails.service_request && (
          <span onClick={() => navigate(`/digital-office/requests/${projectDetails.service_request?.value}`)}>
            - Converted from Request{' '}
            <span className='service-request-link'>{projectDetails.service_request.label}</span>
          </span>
        )}
      </h3>
      <div className='project-form-wrapper'>
        {projectDetails && (
          <Form
            yupSchema={validationSchema}
            key={`${formKey} - ${isEditMode}`}
            onBlur={onBlurHandler}
            validateOn='change-blur'
            onSubmit={(formState) => setFormData(cloneDeep(formState))}
            formApiRef={formRef}
            initialValues={
              isEditMode && formData?.values
                ? formData.values
                : ({ ...projectDetails.overview, overall_completion: projectDetails.overall_completion } as any)
            }
          >
            <FormStateAccessor>
              {({ initialValues, values }) => {
                return (
                  <div className='hbh-details-container conversion-section'>
                    {formConfig(projectDetails?.overview.code || '', 'edit', isEditMode).map((formData) => {
                      return mapDataToComponents(
                        isEditMode,
                        formData,
                        projectDetails.options,
                        !isEmpty(values) ? values : initialValues
                      );
                    })}
                  </div>
                );
              }}
            </FormStateAccessor>
          </Form>
        )}
      </div>
      <h3>Project infrastructure and deliverables</h3>
      <div className='hbh-details-item location-section'>
        {!locations.length && (
          <Tooltip placement='left' title='At least 1 location must be added'>
            <WarningFilled className='details-tooltip no-location-warning' />
          </Tooltip>
        )}
        <div className='hbh-details-item-label'>
          PROJECT LOCATION(S) - INSERT LOCATION(S) ON MAP (CLICK OR INSERT COORDINATES)
        </div>
        <LocationSection
          locations={locations || []}
          setLocations={(locations) => setLocations(locations)}
          infrastructures={projectDetails?.options?.infrastructures || []}
          disabled={!isEditMode}
        />
      </div>
    </div>
  );
};

export default ProjectOverview;
