import React, { FC, useState } from 'react';
import { Button, Dropdown, PageTitle } from '@ui-modules';
import { getUploadProps } from '@digital-office/pages/ShowcaseEntryPage/components/MainInformationSection/utils';
import { ArrowDownOutlined, DeleteFilled, InboxOutlined } from '@ant-design/icons';
import { ReactComponent as PinIcon } from '@assets/svg/PinIcon.svg';
import { message, Spin, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import TextArea from 'antd/es/input/TextArea';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import {
  CountryPageOptions,
  ICountriesResponse,
  ICountryPageDetailResponse,
  IProjectData,
  ISwitchesResponse,
} from '@digital-office/common/interfaces';
import { IDropdownOption } from '@ui-modules/types';
import { useNavigate, useParams } from 'react-router-dom';
import { find } from 'lodash';

import './styles.scss';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

const { Dragger } = Upload;

interface IHighlightComponentProps {
  story: IProjectData | undefined;
  isLoading: boolean;
}

const CountryConfigurationPage = () => {
  const navigate = useNavigate();
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';
  const { id: uuid } = useParams<{ id: string }>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [coverFile, setCoverFile] = useState<RcFile | null>(null);
  const [imageUrl, setImageUrl] = useState<undefined | string | ArrayBuffer>('');
  const [firstHighlightId, setFirstHighlightId] = useState<string | undefined>();
  const [secondHighlightId, setSecondHighlightId] = useState<string | undefined>();
  const [audience, setAudience] = useState<IDropdownOption | null>({ label: 'WFP Only', value: 'WFP_ONLY' });
  const [overview, setOverview] = useState<string>('');

  const { engineeringRepository } = useRepository();

  const { data: countries } = useQuery<ICountriesResponse>('countries', () => engineeringRepository.getCountries(), {
    refetchOnWindowFocus: false,
  });

  const isoCountry = find(
    countries?.results,
    (countryData) => countryData.name === country || countryData.full_name === country
  );

  const { data: switchData, isLoading: isSwitchLoading } = useQuery<ISwitchesResponse>(
    'waffle-switch',
    () => engineeringRepository.getSwitches(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const countryPageSwitch = data?.results?.find((siteSwitch) => siteSwitch.name === 'country_page');
        if (countryPageSwitch && !countryPageSwitch.active) {
          message.warning('Page is not available');
          navigate('/digital-office');
        }
      },
    }
  );

  const { data: countryPageDetails, refetch: countryDetailsRefetch } = useQuery<ICountryPageDetailResponse>(
    `country-details-${isoCountry?.iso_num}-${uuid}`,
    () =>
      engineeringRepository.getCountryPageDetails(
        facilityId,
        uuid && uuid !== 'new' ? uuid || '' : isoCountry?.iso_num || ''
      ),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(countries && switchData),
      retry: 0,
      onSuccess: (data) => {
        setFirstHighlightId(data.story_highlight1);
        data.story_highlight2 && setSecondHighlightId(data.story_highlight2);
        // setAudience(data.options.audience.find((audience) => audience.value === data.audience) || null);
        setOverview(data.overview);
        setImageUrl(data.cover);
        setIsEditMode(false);

        if (uuid === 'new') {
          navigate(`/digital-office/showcase/country-configuration/${isoCountry?.iso_num}`);
        }
      },
      onError: (error: any) => {
        if (error.response.status == 404) {
          setIsEditMode(true);
        }
      },
    }
  );

  const { data: options } = useQuery<CountryPageOptions>(
    `options-for-create-${uuid}-${isoCountry?.iso_num}`,
    () =>
      engineeringRepository.getCountryPageOptions(
        facilityId,
        uuid && uuid !== 'new' ? uuid || '' : isoCountry?.iso_num || ''
      ),
    {
      enabled: Boolean(countries && switchData),
      refetchOnWindowFocus: false,
    }
  );

  const { data: firstHighLightData, isLoading: isFirstStoryLoading } = useQuery<IProjectData>(
    `first-highlight-story-${firstHighlightId}`,
    () => engineeringRepository.getProjectData(firstHighlightId || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(firstHighlightId && switchData),
    }
  );

  const { data: secondHighlightData, isLoading: isSecondStoryLoading } = useQuery<IProjectData>(
    `second-highlight-story-${secondHighlightId}`,
    () => engineeringRepository.getProjectData(secondHighlightId || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(secondHighlightId && switchData),
    }
  );

  const sendPageMutation = useMutation((data: any) => engineeringRepository.postCountryPage(facilityId, data), {
    onSuccess: () => {
      isoCountry && navigate(`/digital-office/showcase/country-configuration/${isoCountry.iso_num}`);
      setIsEditMode(false);
    },
    onError: () => {
      message.error('Error has occurred, try later');
    },
  });

  const updateCountryPageMutation = useMutation(
    (data: any) =>
      engineeringRepository.updateCountryPage(
        facilityId,
        uuid && uuid !== 'new' ? uuid || '' : isoCountry?.iso_num || '',
        data
      ),
    {
      onSuccess: () => {
        countryDetailsRefetch().then(() => {
          message.success('Country page bas been updated');
        });
        setIsEditMode(false);
      },
      onError: () => {
        message.error('Error has occurred, try later');
      },
    }
  );

  const approveCountryPage = useMutation(
    () =>
      engineeringRepository.approveCountryPage(
        facilityId,
        uuid && uuid !== 'new' ? uuid || '' : isoCountry?.iso_num || ''
      ),
    {
      onSuccess: () => {
        countryDetailsRefetch().then(() => {
          message.success('Country page has been approved');
        });
      },
      onError: () => {
        message.error('Error has occurred, try later');
      },
    }
  );

  const onClickHandler = (type: string) => {
    const country = uuid && uuid !== 'new' ? countryPageDetails?.country || '' : isoCountry?.name || '';

    const payload = {
      ...(coverFile && { cover: imageUrl }),
      overview: overview,
      audience: 'WFP',
      country: country,
      visibility: type,
    };

    uuid === 'new' ? sendPageMutation.mutate(payload) : updateCountryPageMutation.mutate(payload);
  };

  const Placeholder = () => {
    return (
      <div className='preview placeholder'>
        <ArrowDownOutlined />
        <p>Select a story to see the preview</p>
      </div>
    );
  };

  const HighlightComponent: FC<IHighlightComponentProps> = ({ story, isLoading }) => {
    const content = story ? (
      <>
        <div
          className='cover'
          style={{
            backgroundImage: `url(${story.main_picture})`,
          }}
        />
        <div className='summary'>
          <p className='summary-title'>{story.title}</p>
          <div className='summary-description'>
            <PinIcon className='pin-icon' />
            <span>{story.city_name}</span>
          </div>
          <div className='summary-description'>
            <img className='category-icon' src={story.infrastructure_category_icon} />
            <span>{story.infrastructure_category_name}</span>
          </div>
          <p className='summary-text'>{story.snapshot}</p>
        </div>
      </>
    ) : null;

    return (
      <div className={`preview ${isLoading ? 'loading' : ''}`}>
        {isLoading ? <Spin size='large' spinning={true} /> : content}
      </div>
    );
  };

  const getStoryOptions = () => {
    return options?.stories?.filter(
      (option) => option.value !== firstHighlightId && option.value !== secondHighlightId
    );
  };

  const isButtonDisabled = Boolean(!audience || !overview || !imageUrl);
  const showApproveButton = Boolean(options?.has_publishing_permissions && !countryPageDetails?.approved);

  return (
    <section>
      <div className='country-page-title-wrapper'>
        <PageTitle
          title={`Country page configuration - ${uuid && uuid !== 'new' ? countryPageDetails?.country || '' : country}`}
        />
        <VideoLink link={videoLinks.COUNTRY_PAGE} />
      </div>
      <div className='booking-details-container hbh-container country-configuration-wrapper'>
        <p>Country Overview Information</p>
        {isEditMode && <p className='subheader'>ALL THE FIELDS MUST BE FILLED</p>}
        <div className='hbh-details-container overview-detail-container'>
          <div className='hbh-details-item'>
            {isEditMode ? (
              <div className='hbh-details-item-label'>Picture*</div>
            ) : (
              <div className='hbh-details-item-label'>COUNTRY OVERVIEW*</div>
            )}
            <div className='hbh-details-item-value'>
              <div className='story-cover-upload'>
                <div
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                  }}
                  className={`file-picker ${coverFile || imageUrl ? 'image-cover' : ''}`}
                >
                  {isEditMode ? (
                    <Dragger {...getUploadProps(false, coverFile, imageUrl, setCoverFile, setImageUrl)}>
                      {!coverFile && !imageUrl ? (
                        <>
                          <div className='description-info'>
                            <p className='ant-upload-drag-icon'>
                              <InboxOutlined />
                            </p>
                            <p className='ant-upload-text title'>Drag and Drop or browse</p>
                            <p className='ant-upload-text subtitle'>Supports PNG, JPEG, JPG</p>
                          </div>
                        </>
                      ) : (
                        <div className='selected-file-header'>
                          <p>{coverFile?.name || ''}</p>
                          <DeleteFilled
                            onClick={() => {
                              setCoverFile(null);
                              setImageUrl('');
                            }}
                          />
                        </div>
                      )}
                    </Dragger>
                  ) : (
                    <img className='preview-image' src={countryPageDetails?.cover} />
                  )}
                </div>
              </div>
              {isEditMode && <div className='hbh-details-item-label'>COUNTRY OVERVIEW*</div>}
              {isEditMode ? (
                <TextArea
                  value={overview}
                  onChange={(e) => setOverview(e.target.value)}
                  name='text-area'
                  className='text-area'
                />
              ) : (
                <p className='overview-text'>{countryPageDetails?.overview}</p>
              )}
            </div>
          </div>
          {/*<div className='hbh-details-item highlights'>*/}
          {/*  <div className='hbh-details-item-label'>*/}
          {/*    HIGHLIGHTS* - SELECT TWO PROJECTS TO BE HIGHLIGHTED ON THE COUNTRY PAGE*/}
          {/*  </div>*/}
          {/*  <div className='hbh-details-item-value'>*/}
          {/*    <div className='highlight-list'>*/}
          {/*      <div className={`highlight-element ${isEditMode ? 'bordered' : ''}`}>*/}
          {/*        {isEditMode && (*/}
          {/*          <>*/}
          {/*            <label className='dropdown-label'>PROJECT HIGHLIGHT 1*</label>*/}
          {/*            <Dropdown*/}
          {/*              defaultValue={*/}
          {/*                firstHighlightId && options?.stories.find((story) => firstHighlightId === story.value)*/}
          {/*              }*/}
          {/*              onChange={(option) => setFirstHighlightId(option.value)}*/}
          {/*              placeholder='Select...'*/}
          {/*              options={getStoryOptions()}*/}
          {/*            />*/}
          {/*          </>*/}
          {/*        )}*/}
          {/*        {firstHighLightData || isFirstStoryLoading ? (*/}
          {/*          <HighlightComponent isLoading={isFirstStoryLoading} story={firstHighLightData} />*/}
          {/*        ) : (*/}
          {/*          <Placeholder />*/}
          {/*        )}*/}
          {/*      </div>*/}
          {/*      {(countryPageDetails?.story_highlight2 || isEditMode) && (*/}
          {/*        <div className={`highlight-element ${isEditMode ? 'bordered' : ''}`}>*/}
          {/*          {isEditMode && (*/}
          {/*            <>*/}
          {/*              <label className='dropdown-label'>PROJECT HIGHLIGHT 2</label>*/}
          {/*              <Dropdown*/}
          {/*                defaultValue={*/}
          {/*                  secondHighlightId && options?.stories.find((story) => secondHighlightId === story.value)*/}
          {/*                }*/}
          {/*                onChange={(option) => setSecondHighlightId(option.value)}*/}
          {/*                placeholder='Select...'*/}
          {/*                isDisabled={Boolean(!firstHighlightId)}*/}
          {/*                options={getStoryOptions()}*/}
          {/*              />*/}
          {/*            </>*/}
          {/*          )}*/}
          {/*          {secondHighlightData || isSecondStoryLoading ? (*/}
          {/*            <HighlightComponent isLoading={isSecondStoryLoading} story={secondHighlightData} />*/}
          {/*          ) : (*/}
          {/*            <Placeholder />*/}
          {/*          )}*/}
          {/*        </div>*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className='hbh-details-item audience-dropdown'>
            <div className='hbh-details-item-label'>PAGE AUDIENCE*</div>
            <div className='hbh-details-item-value'>
              {isEditMode ? (
                <Dropdown
                  isDisabled={true}
                  value={{ label: 'WFP Only', value: 'WFP_ONLY' }}
                  onChange={(option) => setAudience(option)}
                  options={options?.audience}
                />
              ) : (
                audience?.label
              )}
            </div>
          </div>
        </div>
        <div className='buttons-container'>
          {showApproveButton && countryPageDetails?.visibility === 'DRAFT' && !isEditMode && (
            <Button
              disabled={approveCountryPage.status === 'loading'}
              onClick={() => approveCountryPage.mutate()}
              variant='submit'
              text='SUBMIT FOR APPROVAL'
            />
          )}
          {isEditMode ? (
            <>
              <Button
                onClick={() => onClickHandler('DRAFT')}
                disabled={
                  isButtonDisabled ||
                  sendPageMutation.status === 'loading' ||
                  updateCountryPageMutation.status === 'loading'
                }
                text='SAVE AS DRAFT'
              />
              {options?.has_publishing_permissions && (
                <Button
                  onClick={() => onClickHandler('PUBLISHED')}
                  variant='submit'
                  disabled={
                    isButtonDisabled ||
                    sendPageMutation.status === 'loading' ||
                    updateCountryPageMutation.status === 'loading'
                  }
                  text='SAVE AND PUBLISH'
                />
              )}
            </>
          ) : (
            <Button data-testid='edit-button' text='Edit' onClick={() => setIsEditMode(true)} />
          )}
        </div>
      </div>
    </section>
  );
};

export default CountryConfigurationPage;
