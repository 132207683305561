import React, { useState } from 'react';
import { PageTitle } from '@ui-modules';
import { Chart, ArcElement } from 'chart.js';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { IProjectOverviewResponse } from '@digital-office/common/interfaces';
import { DoughnutChart, InfrastructureChart, PhasesTable } from '@digital-office/common/components';
import { getChartProps } from '@digital-office/common/components/Dashboard/DoughnutChart';
import { IDropdownOption } from '@ui-modules/types';
import TimeRangeFilter from '@digital-office/common/components/Dashboard/TimeRangeFilter';
import { TimeFilterValue } from '@digital-office/pages/DemandOverviewPage';
import { getTimeFilterObject } from '@digital-office/common/helpers/timeFilterParser';
import { Spin } from 'antd';

import './styles.scss';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

Chart.register(ArcElement);

const infrastructureChartColors = ['#006B81', '#008D8D', '#00C6B4'];
const topicChartColors = [
  '#305F72',
  '#396B8F',
  '#497EAC',
  '#5695C9',
  '#63ACD7',
  '#71C3E4',
  '#7FD9F2',
  '#8EE0F2',
  '#9ED6E4',
  '#AEDCE5',
  '#C1E2E6',
  '#D5E8E7',
];

const timeFilterOptions = [
  { label: 'This Year', value: 'current_year' },
  { label: 'Custom', value: 'custom' },
];

const ProjectDashboardPage = () => {
  const { engineeringRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;
  const [coFilter, setCoFilter] = useState<IDropdownOption | null>(null);
  const [timeFilter, setTimeFilter] = useState<IDropdownOption | TimeFilterValue | null>(timeFilterOptions[0]);
  const country = localStorage.getItem('country');

  const { data: overviewData, isLoading } = useQuery<IProjectOverviewResponse>(
    ['projects-demand-overview', timeFilter?.value, coFilter?.value],
    () =>
      engineeringRepository.getProjectOverview(facilityId || facilityId, {
        ...getTimeFilterObject(timeFilter),
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const infrastructureDoughnutChart = overviewData?.infrastructure_categories?.map((category, key) => {
    return {
      name: category.name,
      value: category.percentage,
      color: infrastructureChartColors[key],
    };
  });

  const topicsDoughnutChart = overviewData?.projects_by_topic?.map((topic, key) => {
    return {
      name: topic.name,
      value: topic.percentage,
      color: topicChartColors[key],
    };
  });

  const infrastructureTreeChartData = overviewData?.infrastructures.map((infrastructure, key) => {
    return {
      name: infrastructure.category_name,
      color: infrastructureChartColors[key],
      children: infrastructure.infrastructures.map((infrastructureChild) => ({
        name: infrastructureChild.name,
        size: parseInt(infrastructureChild.percentage),
      })),
    };
  });

  const tableData = overviewData?.projects_by_phase
    ?.sort((a, b) => a.number - b.number)
    ?.map((phase) => ({
      title: `Phase: ${phase.number}: ${phase.description}`,
      value: phase.value,
    }));

  const clearFilters = () => {
    setCoFilter(null);
    setTimeFilter(timeFilterOptions[0]);
  };

  return (
    <div className='projects-overview-wrapper'>
      <Spin spinning={isLoading}>
        <div className='demand-dashboard-page-title-wrapper'>
          <PageTitle className='title' title={`Projects overview - ${country}`} />
          <VideoLink link={videoLinks.DASHBOARD} />
        </div>
        <div className='booking-details-container hbh-container'>
          <div className='filter-dropdown-section'>
            <TimeRangeFilter
              timeFilterValue={timeFilter}
              setTimeFilter={setTimeFilter}
              timeFilterOptions={timeFilterOptions}
            />
            <span className='reset' onClick={clearFilters}>
              Reset filters
            </span>
          </div>
          <div className='projects-stats-section'>
            <div className='chart'>
              {infrastructureDoughnutChart?.length ? (
                <DoughnutChart
                  chartData={infrastructureDoughnutChart || []}
                  chartProps={getChartProps(infrastructureDoughnutChart || [])}
                  label='Infrastructure category'
                />
              ) : null}
            </div>
            <div className='projects-summary'>
              <div className='summary-wrapper'>
                <p className='label'>Active Projects</p>
                <p className='value'>{overviewData?.active_projects}</p>
              </div>
              <div className='summary-wrapper'>
                <p className='label bold'>Completed Projects</p>
                <p className='value'>{overviewData?.completed_projects}</p>
              </div>
            </div>
          </div>
          <div className='infrastructure-breakdown-section'>
            <InfrastructureChart data={infrastructureTreeChartData || []} />
          </div>
          <div className='widget-section'>
            {tableData?.length ? (
              <PhasesTable tableData={tableData || []} headers={['BREAKDOWN BY ACTUAL PHASE', '# Projects']} />
            ) : null}
          </div>
          <div className='widget-section topic-chart'>
            <p className='label'>Projects by topic</p>
            {topicsDoughnutChart?.length ? (
              <div className='chart'>
                <DoughnutChart
                  showPercentage={false}
                  chartData={topicsDoughnutChart || []}
                  chartProps={getChartProps(topicsDoughnutChart || [])}
                  label=''
                />
              </div>
            ) : (
              <p className='no-data-label'>No data</p>
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ProjectDashboardPage;
