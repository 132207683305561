import React from 'react';
import { ReactComponent as NumberProjectIcon } from '@assets/svg/number_project.svg';
import { ReactComponent as PlannedIcon } from '@assets/svg/planned.svg';
import { ReactComponent as InProgressIcon } from '@assets/svg/in_progress.svg';
import { ReactComponent as CompleteIcon } from '@assets/svg/completed.svg';

import './styles.scss';
import DiamondIcon from '@components/GlobalImpact/components/DiamondIcon';
import { ICountryOverview } from '@digital-office/common/interfaces';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface IProps {
  data: ICountryOverview;
  onProjectClickHandler: any;
}

const statsElements = (data: ICountryOverview) => [
  {
    icon: NumberProjectIcon,
    value: data?.no_of_stories,
    label: 'Projects',
  },
  {
    icon: PlannedIcon,
    value: data?.no_of_stories_planned,
    label: 'Planned',
  },
  {
    icon: InProgressIcon,
    value: data?.no_of_stories_in_progress,
    label: 'In progress',
  },
  {
    icon: CompleteIcon,
    value: data?.no_of_stories_completed,
    label: 'Completed',
  },
];

const CountryContent = ({ data, onProjectClickHandler }: IProps) => {
  return (
    <div className='country-summary'>
      <div>
        <p className='stats-label'>Country Overview</p>
        <div className='stats-wrapper'>
          {statsElements(data).map((countryStats) => {
            if (countryStats.value && countryStats.value > 0) {
              const Icon = countryStats.icon;
              return (
                <div key={countryStats.label} className='stats-element'>
                  <Icon />
                  <span className='value'>{countryStats.value}</span>
                  <span>{countryStats.label}</span>
                </div>
              );
            } else return null;
          })}
        </div>
        <div className='country-projects-wrapper'>
          <p className='stats-label'>Country Projects</p>
          <p className='stats-description desktop'>
            Click on the project&apos;s title or on the pin over the map to see the details
          </p>
          <p className='stats-description mobile'>Click on the project&apos;s title to see the details</p>
          <div className='projects-list-wrapper'>
            {data.stories.map((story) => {
              return (
                <div onClick={() => onProjectClickHandler(story.uuid)} key={story.uuid} className='project-element'>
                  <DiamondIcon
                    color={story.infrastructure_category_color}
                    label={`${story.title} - ${story.city_name}`}
                    iconSrc={story.infrastructure_category_icon}
                    showArrow={true}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const ConfiguredCountryContent = ({ data }: IProps) => {
  const navigate = useNavigate();

  return (
    <div className='country-summary-configured'>
      <div
        className='cover'
        style={{
          backgroundImage: `url(${data.country_page_data?.cover})`,
        }}
      />
      <div
        onClick={() => navigate(`/country-overview/${data.country_page_data?.country_iso_num}`)}
        className='navigation-bar'
      >
        <p>Explore our impact in the country</p>
        <ArrowRightOutlined />
      </div>
      <div className='work-section'>
        <p className='section-label'>Our work</p>
        <div className='stats-section'>
          {data.country_page_data?.infrastructure_categories.map((infrastructure) => (
            <div key={infrastructure.uuid} className='stats-element'>
              <span className='percentage-value'>{infrastructure.percentage.toFixed(1)}%</span>
              <img className='icon' src={infrastructure.icon} />
              <span className='name'>{infrastructure.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CountrySummary = ({ data, onProjectClickHandler }: IProps) => {
  return data?.country_page_data ? (
    <ConfiguredCountryContent data={data} onProjectClickHandler={onProjectClickHandler} />
  ) : (
    <CountryContent data={data} onProjectClickHandler={onProjectClickHandler} />
  );
};

export default CountrySummary;
