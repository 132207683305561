import React from 'react';
import './styles.scss';
import loadingSpinner from '../../assets/img/loading-color.gif';

const LoadingPage = () => {
  return (
    <div className='loader-container'>
      <div className='loading-spinner'>
        <img className='spinner' src={loadingSpinner} />
      </div>
    </div>
  );
};

export default LoadingPage;
