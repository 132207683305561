import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './styles.scss';
import ErrorPage from '@components/ErrorPage';

interface IProps {
  hideNavHandler: React.Dispatch<React.SetStateAction<boolean>>;
}

const buttons = [
  {
    label: 'Click here',
    onClickHandler: () => (window.location.href = String(process.env.REACT_APP_HBH_DO_URL)),
  },
];

const RedirectPage: FC<IProps> = ({ hideNavHandler }) => {
  const location = useLocation();
  const isRedirected = location?.state?.isRedirected;
  const originPath = location?.state?.originPathName;

  useEffect(() => {
    hideNavHandler(true);
    originPath && localStorage.setItem('REDIRECT_ORIGIN_PATHNAME', originPath);
  }, []);

  if (!isRedirected) {
    window.location.href = '/';
  }

  return isRedirected ? (
    <ErrorPage
      title='Ops, seems like you opened this page from the source other than UN Booking Hub'
      subtitle='Please, follow the link below and select the Engineering Digital Office to go to this page.'
      buttons={buttons}
    />
  ) : null;
};

export default RedirectPage;
