import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from '@ui-modules';
import { Form, FormApi, FormState, TextArea } from 'informed';
import { TextField, DateField, Dropdown } from '@ui-modules/informed';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import { Checkbox, Drawer, Modal, Tooltip } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UseMutationResult } from 'react-query';
import { EyeOutlined, CheckOutlined, CloseCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import * as Yup from 'yup';
import { cloneDeep, isEmpty } from 'lodash';

import LocationSection from '@digital-office/pages/ProjectsManagementPages/components/LocationSection';
import ProjectSummary from '@components/GlobalImpact/components/ProjectSummary';
import {
  getAmountOfWords,
  getDrawerProps,
  getMultipleOtherPictures,
  getProjectData,
  getStoryCover,
  handleGuidanceDownload,
} from '@digital-office/pages/ShowcaseEntryPage/components/MainInformationSection/utils';
import { IDropdownOption } from '@ui-modules/types';
import { IProjectDetailsResponse, IStoryDetailsResponse, IStoryListResponse } from '@digital-office/common/interfaces';
import { parseLocationData } from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/utils';

import './styles.scss';
import { parseLocationsToPayload } from '@digital-office/pages/ProjectsManagementPages/utils';
import { useNavigate } from 'react-router-dom';
import { format, parse, subDays } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import { ILocationObject } from '@digital-office/pages/ProjectsManagementPages/components/LocationModal';

const getMultipleValueLabel = (selectedItems: IDropdownOption[]) => {
  if (!selectedItems || selectedItems?.length === 0) {
    return 'Select';
  } else if (selectedItems.length === 1) {
    return selectedItems[0].label;
  } else {
    return `${selectedItems.length} items selected`;
  }
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  background: Yup.string()
    .required('Required')
    .test('word-count', 'Maximum 180 words', (value) => {
      if (!value) return true;

      const wordCount = value.split(/\s+/).length;
      return wordCount <= 180;
    }),
  intervention: Yup.string()
    .required('Required')
    .test('word-count', 'Maximum 300 words', (value) => {
      if (!value) return true;

      const wordCount = value.split(/\s+/).length;
      return wordCount <= 300;
    }),
  outcome: Yup.string()
    .required('Required')
    .test('word-count', 'Maximum 250 words', (value) => {
      if (!value) return true;

      const wordCount = value.split(/\s+/).length;
      return wordCount <= 250;
    }),
  client: Yup.string().required('Required'),
  completion_date: Yup.date().required('Required'),
  topics: Yup.array().nullable().min(1, 'Required').required('Required'),
  infrastructure_category: Yup.object().required('Required'),
});

interface IProps {
  formKey: string;
  storyDetails?: IStoryDetailsResponse;
  storyOptions: IStoryListResponse['options_for_create'];
  selectedProject: IDropdownOption | null;
  setSelectedProject: React.Dispatch<React.SetStateAction<IDropdownOption | null>>;
  projectResponseData?: IProjectDetailsResponse;
  sendStoryMutation: UseMutationResult<any, any, any, unknown>;
  approveStoryMutation: UseMutationResult<any, any, any, unknown>;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainInformationSection: FC<IProps> = ({
  storyOptions,
  selectedProject,
  setSelectedProject,
  projectResponseData,
  sendStoryMutation,
  isEditMode = false,
  setIsEditMode,
  storyDetails,
  approveStoryMutation,
}) => {
  const [coverFile, setCoverFile] = useState<RcFile | null>(null);
  const [otherFiles, setOtherFiles] = useState<RcFile[]>([]);
  const [imageUrl, setImageUrl] = useState<undefined | string | ArrayBuffer>(storyDetails?.main_picture);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [formState, setFormState] = useState<FormState | null>(null);
  const formRef = useRef<FormApi>(null);
  const [isSectionCompleted, setIsSectionCompleted] = useState<boolean>(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
  const [isPublishCheckboxMarked, setIsPublishCheckboxMarked] = useState<boolean>(false);
  const navigate = useNavigate();
  const [locations, setLocation] = useState<ILocationObject[]>(
    isEditMode
      ? parseLocationData(projectResponseData?.locations || [], storyOptions.infrastructures)
      : parseLocationData(storyDetails?.project.locations || [], storyOptions.infrastructures)
  );

  const topics =
    storyDetails?.topics.map((topic, key) => `${topic.label}${key !== storyDetails?.topics.length - 1 ? ',' : ''} `) ||
    '-';

  useEffect(() => {
    if (selectedProject?.value === '' && formRef?.current) {
      const fields = {
        ...formState?.values,
        client: undefined,
        budget: undefined,
        topics: undefined,
        title: undefined,
        snapshot: undefined,
        partners_involved: undefined,
      };

      setLocation([]);

      formRef.current.setValues(fields);

      formRef.current.submitForm();
    }
  }, [selectedProject]);

  useEffect(() => {
    if (projectResponseData && formRef?.current) {
      const formValues = {
        ...formState?.values,
        client: projectResponseData.overview.client,
        topics: projectResponseData.overview.topics,
        title: projectResponseData.overview?.title,
        snapshot: projectResponseData.overview.description,
        partners_involved: projectResponseData.overview.partners_involved,
      } as Record<string, any>;

      formRef.current.setValues(formValues);
      formRef.current.submitForm();

      if (isEditMode) {
        projectResponseData?.locations.length <= 1
          ? setLocation(parseLocationData(projectResponseData?.locations || [], storyOptions.infrastructures))
          : setLocation([]);
      }
    }
  }, [projectResponseData]);

  useEffect(() => {
    const storyDetailsData = {
      completion_date: storyDetails && parse(storyDetails.completion_date, DateFormatEnum.YYYY_MM_DD, new Date()),
      infrastructure_category: storyOptions.infrastructure_categories.find(
        (category) => category.label === storyDetails?.infrastructure_category
      ),
      visible_for_organisations: storyOptions.story_audience.find(
        (audience) => audience.value === storyDetails?.visible_for_organisations
      ),
    };
    if (storyDetails && formRef?.current) {
      const formValues = {
        ...formState?.values,
        ...storyDetailsData,
        completion_date: storyDetails?.completion_date
          ? parse(storyDetails.completion_date, DateFormatEnum.YYYY_MM_DD, new Date())
          : undefined,
        client: storyDetails.client,
        budget: storyDetails.budget,
        topics: storyDetails?.topics,
        partners_involved: storyDetails.partners_involved,
        title: storyDetails.title,
        background: storyDetails?.snapshot,
        intervention: storyDetails?.full_description,
        outcome: storyDetails?.outcome,
      } as Record<string, any>;

      setImageUrl(storyDetails?.main_picture);

      const rcFiles = storyDetails.pictures.map((file) => new File(['eee'], file.name));

      setOtherFiles(rcFiles as RcFile[]);

      formRef.current.setValues(formValues);
      formRef.current.submitForm();
    }

    storyDetails && setLocation(parseLocationData([storyDetails?.location] || [], storyOptions.infrastructures));
  }, [isEditMode, storyDetails]);

  const checkIsSectionIsCompleted = () => {
    if (!isEditMode && storyDetails) {
      return setIsSectionCompleted(true);
    } else {
      return setIsSectionCompleted(!(!formState || !isEmpty(formState?.errors) || !locations.length || !imageUrl));
    }
  };

  useEffect(() => {
    checkIsSectionIsCompleted();
  }, [formState, locations, coverFile, imageUrl]);

  const projectData = getProjectData(
    isEditMode,
    formState,
    storyDetails,
    locations,
    imageUrl as string,
    storyOptions.infrastructures
  );

  const getError = (name: string) => {
    if (formRef?.current) {
      return formRef.current.getError(name);
    }
  };

  const getLabel = (name: string, label: string) => {
    const error = getError(name);

    if (!isEditMode) {
      return <p className='field-label'>{label}</p>;
    }

    return (
      <p className={error ? 'field-label error' : 'field-label'}>
        {label} {error ? `- ${error}` : ''}
      </p>
    ) as any;
  };

  const sendStory = async (visibilityType: string) => {
    const values: any = formState?.values;
    const location = parseLocationsToPayload(locations)[0];
    const files: { picture?: string; name: string; uuid?: string }[] = [];

    for (const file of otherFiles) {
      const fileFromStory = storyDetails?.pictures.find((storyFile) => storyFile.name === file.name);
      if (fileFromStory) {
        files.push({ uuid: fileFromStory.uuid, name: fileFromStory.name });
      } else {
        const urlPromise = new Promise<string>((resolve) => {
          const reader = new FileReader();

          reader.onload = () => {
            const url = reader.result as string;
            resolve(url);
          };

          reader.readAsDataURL(file);
        });

        const url = await urlPromise;
        files.push({ picture: url, name: file.name });
      }
    }

    const payload = {
      title: values?.title,
      client: values?.client,
      budget: values?.budget,
      snapshot: values?.background,
      outcome: values?.outcome,
      full_description: values?.intervention,
      project: selectedProject?.value ? selectedProject?.value : null,
      ...(coverFile && { main_picture: projectData?.main_picture }),
      pictures: files.length ? files : [],
      infrastructure_category: values?.infrastructure_category.value,
      visible_for_organisations: 'WFP',
      completion_date: format(new Date(values?.completion_date), DateFormatEnum.YYYY_MM_DD),
      partners_involved: values?.partners_involved,
      topics: values?.topics.map((topic: IDropdownOption) => topic?.value),
      visibility: visibilityType,
      location: {
        ...location,
        project_infrastructures: [
          {
            description: location.project_infrastructures[0].description,
            // @ts-ignore
            infrastructure: locations[0].infrastructure?.value || locations[0].infrastructure?.infrastructure,
          },
        ],
      },
    };

    if (!coverFile && storyDetails?.main_picture) {
      delete payload.main_picture;
    }

    sendStoryMutation.mutate(payload, {
      onSuccess: () => {
        setCoverFile(null);
        setOtherFiles([]);
        setIsPublishCheckboxMarked(false);
      },
    });
  };

  const onLocationChange = (data: ILocationObject[]) => {
    const parsedData = data.map((location) => ({
      ...location,
      locationData: {
        ...location.locationData,
        place_name: location.description,
      },
    }));
    setLocation(parsedData);
  };

  const getVisibilityLabel = () => {
    switch (storyDetails?.visibility) {
      case 'DRAFT':
        return 'Draft';
      case 'PENDING_CLEARANCE':
        return 'Pending clearance';
      case 'PUBLISHED':
        return 'Published';
    }
  };

  return (
    <div className='showcase-information-section-wrapper'>
      <Modal
        footer={null}
        title={
          <div className={'modal-title'}>
            <p>Story approving</p>
          </div>
        }
        width={'60%'}
        open={isApproveModalOpen}
        onCancel={() => setIsApproveModalOpen(false)}
      >
        <div className='approve-confirmation'>
          <p>
            Do you confirm the approval of the Story {storyDetails?.title}? A notification email will be sent to{' '}
            {storyDetails?.created_by}
          </p>
          <div className='buttons-container'>
            <Button onClick={() => setIsApproveModalOpen(false)} variant='warning' text='CANCEL' />
            <Button
              onClick={() => {
                approveStoryMutation.mutate(true);
                setIsApproveModalOpen(false);
              }}
              variant='submit'
              text='CONFIRM'
            />
          </div>
        </div>
      </Modal>
      <Drawer
        getContainer={() => document.getElementsByClassName('hbh-wrapper')[0]}
        visible={isDrawerOpen}
        closeIcon={<CloseCircleOutlined className='close-icon' />}
        placement='right'
        {...getDrawerProps(locations, setIsDrawerOpen)}
      >
        {projectData && (
          <ProjectSummary
            projectId={selectedProject?.value || ''}
            country={locations[0]?.locationData.country}
            data={projectData}
          />
        )}
      </Drawer>
      <div className='showcase-information-section'>
        <div className='header-container'>
          <p className='content-label'>
            Project Information{' '}
            {storyDetails?.project.uuid ? (
              <span>
                - based on{' '}
                <span
                  onClick={() => navigate(`/digital-office/projects/${storyDetails?.project.uuid}`)}
                  className='project-code-label'
                >
                  {storyDetails.project.code}
                </span>{' '}
                project
              </span>
            ) : (
              ''
            )}
          </p>
          <div className='buttons-sections'>
            {!isEditMode && storyOptions.has_publishing_permissions && storyDetails?.is_approvable && (
              <Button
                variant='submit'
                icon={<CheckOutlined />}
                className='button'
                onClick={() => setIsApproveModalOpen(true)}
                text='APPROVE AND PUBLISH'
              />
            )}
            {storyDetails && (
              <Button
                onClick={() => setIsEditMode(!isEditMode)}
                className='edit-button'
                text={isEditMode ? 'CANCEL' : 'EDIT'}
              />
            )}
          </div>
        </div>
        <Form
          formApiRef={formRef}
          onBlur={() => {
            formRef?.current && formRef.current.submitForm();
          }}
          validateOn='change-blur'
          onChange={(formState) => setFormState(cloneDeep(formState))}
          yupSchema={validationSchema}
        >
          <div className='hbh-details-container'>
            <div className='hbh-details-item'>
              <div className='hbh-details-item-label'>{isEditMode ? 'SELECT PROJECT' : 'PROJECT'}</div>
              <div className='hbh-details-item-value'>
                {isEditMode ? (
                  <Dropdown
                    value={selectedProject}
                    onChange={(value) => setSelectedProject(value)}
                    name='project'
                    options={[{ label: '-----', value: '' }, ...storyOptions.projects]}
                  />
                ) : (
                  storyDetails?.project.code || '-'
                )}
              </div>
            </div>
            <div className='hbh-details-item grid-half-column'>
              <div className='hbh-details-item-label'>{getLabel('client', 'CLIENT*')}</div>
              <div className='hbh-details-item-value'>
                {isEditMode ? (
                  <TextField name='client' placeholder='Select Project to fill automatically...' />
                ) : (
                  storyDetails?.client || '-'
                )}
              </div>
            </div>
            <div className='hbh-details-item grid-full-column'>
              {getStoryCover(coverFile, setCoverFile, storyDetails, imageUrl, setImageUrl, isEditMode)}
            </div>
            <div className='hbh-details-item grid-full-column'>
              <div className='hbh-details-item-label story-label'>
                <p>
                  STORY INPUTS -{' '}
                  <span className='download-link' onClick={handleGuidanceDownload}>
                    Click here to download the guidance for publishing project stories
                  </span>
                </p>
                <Button
                  onClick={() => {
                    setIsDrawerOpen(true);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  className={`preview-button ${isSectionCompleted ? 'active' : ''}`}
                  disabled={!isSectionCompleted}
                  icon={<EyeOutlined />}
                  text='Preview'
                />
              </div>
              <div className='hbh-details-item-value'>
                <div className='story-inputs-wrapper'>
                  <div className='story-description-inputs'>
                    <div>
                      <label className='input-label story-label'>{getLabel('title', 'TITLE*')}</label>
                      {isEditMode ? <TextField name='title' /> : storyDetails?.title}
                    </div>
                    <div>
                      <label className='input-label story-label'>{getLabel('background', 'BACKGROUND*')}</label>
                      {isEditMode ? (
                        <div>
                          <TextArea
                            placeholder='Use this space to provide context information about the project: location, general information about the country or region, people affected, how did the idea emerge, reasoning for the project etc. '
                            className='text-area'
                            name='background'
                          />
                          {formState && getAmountOfWords('background', 180, formState)}
                        </div>
                      ) : (
                        storyDetails?.snapshot
                      )}
                    </div>
                    <div>
                      <label className='input-label story-label'>{getLabel('intervention', 'INTERVENTION*')}</label>
                      {isEditMode ? (
                        <div>
                          <TextArea
                            placeholder='Provide information about the implementation of the project: planning, materials used, people involved, start and completion date, etc. '
                            className='text-area'
                            name='intervention'
                          />
                          {formState && getAmountOfWords('intervention', 300, formState)}
                        </div>
                      ) : (
                        storyDetails?.full_description
                      )}
                    </div>
                    <div>
                      <label className='input-label story-label'>{getLabel('outcome', 'OUTCOME*')}</label>
                      {isEditMode ? (
                        <div>
                          <TextArea
                            placeholder='Results impact of your project: e.g. number of km rehabilitated or built and what it facilitated, number of facilities built and how they are used, generally the number of people benefited from the project and how they benefitted, etc. '
                            className='text-area'
                            name='outcome'
                          />
                          {formState && getAmountOfWords('outcome', 250, formState)}
                        </div>
                      ) : (
                        storyDetails?.outcome
                      )}
                    </div>
                  </div>
                  <div className='story-cover-upload'>
                    {getMultipleOtherPictures(
                      otherFiles,
                      setOtherFiles,
                      storyDetails,
                      isEditMode,
                      otherFiles.length >= 5
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='hbh-details-item'>
              <div className='hbh-details-item-label'>PARTNERS INVOLVED</div>
              <div className={`hbh-details-item-value ${!isEditMode && 'overflow-hidden'}`}>
                {isEditMode ? (
                  <TextField name='partners_involved' placeholder='Insert...' />
                ) : (
                  <Tooltip placement='left' title={storyDetails?.partners_involved || '-'}>
                    {storyDetails?.partners_involved || '-'}
                  </Tooltip>
                )}
              </div>
            </div>
            <div className='hbh-details-item'>
              <div className='hbh-details-item-label'>{getLabel('completion_date', 'COMPLETION DATE*')}</div>
              <div className='hbh-details-item-value'>
                {isEditMode ? (
                  <DateField
                    dateFormat={DateFormatEnum.DD_MM_YYYY}
                    maxDate={subDays(new Date(), 1)}
                    name='completion_date'
                    defaultValue={undefined}
                    placeholder='DD/MM/YYYY'
                  />
                ) : storyDetails?.completion_date ? (
                  format(new Date(storyDetails?.completion_date), DateFormatEnum.DD_MM_YYYY)
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className='hbh-details-item'>
              <div className='hbh-details-item-label'>{getLabel('topics', 'PROJECT TOPICS*')}</div>
              <div className={`hbh-details-item-value ${!isEditMode && 'overflow-hidden'}`}>
                {isEditMode ? (
                  <Dropdown
                    options={storyOptions.topics}
                    placeholder={getMultipleValueLabel(formState?.values['topics'] as IDropdownOption[])}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    isMulti={true}
                    name='topics'
                  />
                ) : (
                  <Tooltip placement='left' title={topics}>
                    {topics}
                  </Tooltip>
                )}
              </div>
            </div>
            <div className='hbh-details-item'>
              <div className='hbh-details-item-label'>
                {getLabel('infrastructure_category', 'INFRASTRUCTURE CATEGORY*')}
              </div>
              <div className='hbh-details-item-value'>
                {isEditMode ? (
                  <Dropdown
                    name='infrastructure_category'
                    placeholder='Select...'
                    options={storyOptions?.infrastructure_categories}
                  />
                ) : (
                  storyDetails?.infrastructure_category
                )}
              </div>
            </div>
            <div className='hbh-details-item grid-full-column'>
              <div className={`hbh-details-item-label ${!locations.length ? 'field-label error' : ''}`}>
                <div>
                  PROJECT LOCATION* {!locations.length && <span>- REQUIRED</span>}
                  {isEditMode && (
                    <Tooltip
                      placement='left'
                      align={{ offset: [0, 0] }}
                      rootClassName='showcase-info-tooltip'
                      title='If the story refers to multiple locations, just select one or set location that is in the related area'
                    >
                      <InfoCircleFilled className='details-tooltip location-info' />
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className='hbh-details-item-value'>
                <LocationSection
                  multi={false}
                  disabled={!isEditMode}
                  locations={locations}
                  modalTitle='ADD LOCATION'
                  infrastructureSectionDescription='If the story refers to multiple locations, just select one or set location that is in the related area'
                  infrastructureLabel={'Location description'}
                  setLocations={onLocationChange}
                  infrastructures={storyOptions.infrastructures}
                  showDescriptionOnList={false}
                  prefillDescription={true}
                />
              </div>
            </div>
            <div className='hbh-details-item'>
              <div className='hbh-details-item-label'>
                {' '}
                {getLabel('visible_for_organisations', 'PROJECT OVERVIEW AUDIENCE*')}
              </div>
              <div className='hbh-details-item-value'>
                {isEditMode ? (
                  <Dropdown
                    name='visible_for_organisations'
                    placeholder='Select...'
                    isDisabled={true}
                    value={{ label: 'WFP only', value: 'WFP' }}
                    options={storyOptions.story_audience}
                  />
                ) : (
                  storyOptions.story_audience.find((option) => option.value === storyDetails?.visible_for_organisations)
                    ?.label || '-'
                )}
              </div>
            </div>
            {!isEditMode && (
              <div className='hbh-details-item'>
                <div className='hbh-details-item-label'> {getLabel('visible_for_organisations', 'VISIBILITY')}</div>
                <div className='hbh-details-item-value'>{getVisibilityLabel()}</div>
              </div>
            )}
          </div>
        </Form>
        {isEditMode && (
          <div>
            <div onClick={() => setIsPublishCheckboxMarked((prevState) => !prevState)} className='permission-checkbox'>
              <Checkbox checked={isPublishCheckboxMarked} />
              <span>
                I confirm the project information has been approved by CO Management and CO Comms before submission
              </span>
            </div>
            <div className='buttons-container'>
              <Button
                onClick={() => sendStory('PUBLISHED')}
                disabled={!isSectionCompleted || !isPublishCheckboxMarked}
                className='button'
                text={`${storyOptions.has_publishing_permissions ? 'PUBLISH' : 'SUBMIT FOR APPROVAL'}`}
                variant='submit'
              />
              <Button
                onClick={() => sendStory('DRAFT')}
                disabled={!isSectionCompleted}
                className='button'
                text='SAVE AS DRAFT'
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainInformationSection;
