import React, { FC, useEffect } from 'react';
import { useField } from 'informed';
import { ReactComponent as CloseIcon } from '@assets/svg/CloseIcon.svg';
import { ReactComponent as UploadIcon } from '@assets/svg/upload.svg';

import './styles.scss';

interface IUploadField {
  name: string;
  maxSize?: number;
  maxCount?: number;
}

const UploadField: FC<IUploadField> = (props) => {
  const { name, maxSize, maxCount } = props;

  const { fieldState, fieldApi, render, ref } = useField(props);

  const fieldValue = (fieldState.value || []) as Array<File>;

  const { setValue } = fieldApi;

  useEffect(() => {
    if (!fieldValue) {
      setValue([]);
    }
  }, [fieldValue]);

  return render(
    <div ref={ref} className='upload-field'>
      <label
        htmlFor={name}
        className={`upload-field-label ${maxCount && fieldValue.length >= maxCount ? 'disabled' : ''}`}
      >
        <UploadIcon />
        Drag and drop files here, or click to select
      </label>
      <input
        className='upload-field-input'
        id={name}
        type='file'
        multiple
        onChange={(e) => setValue([...fieldValue, ...Array.from(e.target.files || [])])}
      />
      {fieldValue.map(({ name, size }, idx) => {
        const oversized = maxSize && size >= maxSize;
        return (
          <div key={idx} className={`upload-field-file ${oversized ? 'error' : ''}`}>
            <div className='upload-field-file-text'>{name}</div>
            {oversized && <span className='upload-field-file-oversized'>oversized</span>}
            <CloseIcon
              className='upload-field-file-delete'
              title='Delete file'
              onClick={() => {
                const newValue = [...fieldValue];
                newValue.splice(idx, 1);
                setValue(newValue);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default UploadField;
