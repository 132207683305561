import React, { FC, useEffect, useState } from 'react';

import './styles.scss';
import CurrencyPicker from '@digital-office/pages/ProjectsManagementPages/components/CurrencyPicker';
import { EditOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, Pagination, Table } from '@ui-modules';
import GuidanceModal from '@components/CommunityGuidancePage/components/GuidanceModal';
import { Form, FormStateAccessor } from 'informed';
import { DateField } from '@ui-modules/informed';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import {
  ICostDetailItem,
  IProjectCostList,
  IProjectCostListItem,
  IProjectCostPayload,
} from '@digital-office/common/interfaces';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { message, Tooltip } from 'antd';
import { format, parse } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import FileSaver from 'file-saver';

const items = [
  {
    key: 'USD',
    label: 'USD',
  },
  {
    key: 'PLN',
    label: 'PLN',
  },
];

const getColumns = (editModeHandler: (item: IProjectCostListItem) => void) => [
  {
    dataIndex: 'date_of_certification',
    key: 'date_of_certification',
    title: 'DATE OF CERTIFICATION',
    render: (data: string) => {
      return data ? format(new Date(data), DateFormatEnum.DD_MM_YYYY) : '';
    },
  },
  {
    dataIndex: 'period',
    key: 'period',
    title: 'PERIOD',
    render: (data: string) => {
      return data ? format(new Date(data), 'MMMM-yyyy') : '';
    },
  },
  {
    dataIndex: 'value_of_original_works_to_date',
    key: 'value_of_original_works_to_date',
    title: 'VALUE OF ORIGINAL WORKS TO DAY',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'advance_payment',
    key: 'advance_payment',
    title: 'ADVANCE PAYMENT',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'percentage_retention',
    key: 'percentage_retention',
    title: 'RETENTION %',
  },
  {
    dataIndex: 'other_deductions',
    key: 'other_deductions',
    title: 'OTHER DEDUCTIONS',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'value_of_certificate',
    key: 'value_of_certificate',
    title: 'VALUE OF CERTIFICATE',
    render: (record: number) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: '',
    key: '',
    title: 'ACTION',
    render: (record: undefined, set: IProjectCostListItem) => {
      if (set.uuid) {
        return (
          <div className='actions-section'>
            <Tooltip align={{ offset: [0, 0] }} title='Click to edit source'>
              <EditOutlined onClick={() => editModeHandler(set)} />
            </Tooltip>
          </div>
        );
      } else {
        return undefined;
      }
    },
  },
];

interface IPaymentsSectionProps {
  selectedItem: ICostDetailItem;
}

const schema = Yup.object().shape({
  date_of_certification: Yup.date().required('Date of certification is required'),
  period: Yup.date().required('Period is required'),
  value_of_original_works_to_date: Yup.number().required('Value of original works to date is required'),
  value_of_approved_variations_to_date: Yup.number().required('Value of approved variations to date is required'),
  advance_payment: Yup.number().required('Advance payment is required'),
  percentage_retention: Yup.number().required('Retention percentage is required'),
  repayment_of_advance: Yup.number().required('Repayment of advance is required'),
  other_deductions: Yup.number().required('Other deductions is required'),
});

const PaymentsSection: FC<IPaymentsSectionProps> = ({ selectedItem }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>(items[0].key);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, updateState] = useState<any>(null);
  const facilityId = +localStorage.getItem('facility')!;
  const { id: uuid } = useParams<{ id: string }>();
  const [itemToEdit, setItemToEdit] = useState<IProjectCostListItem | undefined>();

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);

  const { engineeringRepository } = useRepository();

  useEffect(() => {
    if (itemToEdit) {
      setIsModalOpen(true);
    }
  }, [itemToEdit]);

  const {
    data: paymentList,
    isLoading,
    refetch,
  } = useQuery<IProjectCostList>(
    `cost-details-payments-list-${pageSize}-${selectedPage}`,
    () =>
      engineeringRepository.getProjectCostPayments(facilityId, uuid || '', selectedItem.uuid, {
        limit: 10,
        offset: (selectedPage - 1) * 10,
      }),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const sendCostPaymentMutation = useMutation(
    (data: IProjectCostPayload) =>
      engineeringRepository.sendProjectCostPayments(facilityId, uuid || '', selectedItem.uuid, data),
    {
      onSuccess: () => {
        message.success('Payment has been added');
        setIsModalOpen(false);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const getPaymentExportMutation = useMutation(
    () => engineeringRepository.getPaymentExport(facilityId, uuid || '', selectedItem.uuid || ''),
    {
      onSuccess: (data: string) => {
        try {
          FileSaver.saveAs(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
            }),
            `payment_export_${format(new Date(), 'dd-MM-yyyy')}.xlsx`
          );
        } catch (error) {
          message.error('Parsing file error');
        }
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const updateCostPaymentMutation = useMutation(
    (data: IProjectCostPayload) =>
      engineeringRepository.updateProjectCostPayments(
        facilityId,
        uuid || '',
        selectedItem.uuid,
        itemToEdit?.uuid || '',
        data
      ),
    {
      onSuccess: () => {
        message.success('Payment has been updated');
        setIsModalOpen(false);
        setItemToEdit(undefined);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  const getRetentionValue = (values: Record<any, any>) => {
    return (
      (Number(values.value_of_original_works_to_date) + Number(values.value_of_approved_variations_to_date)) *
      Number((values.percentage_retention as number) / 100)
    );
  };

  const getValueOfCertificate = (values: Record<any, any>) => {
    return (
      Number(values.value_of_original_works_to_date || 0) +
      Number(values.value_of_approved_variations_to_date || 0) +
      Number(values.advance_payment || 0) -
      getRetentionValue(values) -
      Number(values.repayment_of_advance || 0) -
      Number(values.other_deductions || 0)
    );
  };

  const getCostDetailsList = () => {
    if (!paymentList?.results) {
      return [];
    }

    const costDetailList = paymentList?.results.reduce(
      (accumulator, item) => {
        return {
          values: [...accumulator.values, { ...item }],
          totalAmount: {
            value_of_original_works_to_date:
              accumulator.totalAmount.value_of_original_works_to_date + (item.value_of_original_works_to_date ?? 0),
            advance_payment: accumulator.totalAmount.advance_payment + item.advance_payment,
            other_deductions: accumulator.totalAmount.other_deductions + item.other_deductions,
            value_of_certificate: accumulator.totalAmount.value_of_certificate + item.value_of_certificate,
          },
        };
      },
      {
        values: [] as IProjectCostListItem[],
        totalAmount: {
          value_of_original_works_to_date: 0,
          advance_payment: 0,
          other_deductions: 0,
          value_of_certificate: 0,
        },
      }
    );

    return [...(costDetailList?.values || []), { ...costDetailList?.totalAmount }];
  };

  const sendCostDetails = (values: Record<any, any>) => {
    const payload = {
      period: format(values.period as Date, DateFormatEnum.YYYY_MM_DD),
      date_of_certification: format(values.date_of_certification as Date, DateFormatEnum.YYYY_MM_DD),
      value_of_original_works_to_date: Number(values.value_of_original_works_to_date),
      value_of_approved_variations_to_date: Number(values.value_of_approved_variations_to_date),
      repayment_of_advance: Number(values.repayment_of_advance),
      advance_payment: Number(values.advance_payment),
      other_deductions: Number(values.other_deductions),
      percentage_retention: Number(values.percentage_retention),
      retention: getRetentionValue(values).toFixed(2),
      value_of_certificate: getValueOfCertificate(values).toFixed(2),
    };

    itemToEdit ? updateCostPaymentMutation.mutate(payload) : sendCostPaymentMutation.mutate(payload);
  };

  return (
    <div className='payment-section-wrapper'>
      <GuidanceModal
        className='financial-modal'
        isClosable={true}
        width='40%'
        destroyOnClose={true}
        centered={false}
        open={isModalOpen}
        closeHandler={() => {
          setIsModalOpen(false);
          setItemToEdit(undefined);
        }}
      >
        <p className='title'>{itemToEdit ? 'Edit' : 'Add new'} item</p>
        <Form
          onSubmit={({ values }) => sendCostDetails(values)}
          yupSchema={schema}
          key={`${itemToEdit}`}
          initialValues={
            itemToEdit
              ? {
                  ...itemToEdit,
                  period: parse(itemToEdit?.period || '', DateFormatEnum.YYYY_MM_DD, new Date()),
                  date_of_certification: parse(
                    itemToEdit?.date_of_certification || '',
                    DateFormatEnum.YYYY_MM_DD,
                    new Date()
                  ),
                }
              : {}
          }
          className='source-form'
          onClick={() => updateState({})}
        >
          <FormStateAccessor>
            {({ values }) => {
              return (
                <>
                  <div className='field'>
                    <label>Date of certification</label>
                    <DateField name='date_of_certification' />
                  </div>
                  <div className='field'>
                    <label>Period</label>
                    <DateField dateFormat='MMMM-yyyy' name='period' />
                  </div>
                  <div className='field'>
                    <label>Value of original works to date (USD)</label>
                    <NumericInput name='value_of_original_works_to_date' />
                  </div>
                  <div className='field'>
                    <label>Value of approved variations to date (USD)</label>
                    <NumericInput name='value_of_approved_variations_to_date' />
                  </div>
                  <div className='field'>
                    <label>Advance payment (USD)</label>
                    <NumericInput name='advance_payment' />
                  </div>
                  <div className='field'>
                    <label>Retention (%)</label>
                    <NumericInput decimalScale={0} percentage={true} suffix=' %' name='percentage_retention' />
                  </div>
                  <div className='field'>
                    <label>Retention (USD)</label>
                    <NumericInput customValue={getRetentionValue(values)} name='retention' disabled={true} />
                  </div>
                  <div className='field'>
                    <label>Repayment of advance (USD)</label>
                    <NumericInput name='repayment_of_advance' />
                  </div>{' '}
                  <div className='field'>
                    <label>Other deductions (USD)</label>
                    <NumericInput name='other_deductions' />
                  </div>
                  <div className='field'>
                    <label>Value of certificate (USD)</label>
                    <NumericInput
                      customValue={getValueOfCertificate(values)}
                      disabled={true}
                      name='certificate_value'
                    />
                  </div>
                  <hr />
                  <div className='buttons-container'>
                    <Button onClick={() => setIsModalOpen(false)} variant='transparent' text='Cancel' />
                    <Button type='submit' variant='primary' text='Save item' />
                  </div>
                </>
              );
            }}
          </FormStateAccessor>
        </Form>
      </GuidanceModal>
      <p className='title'>Payments</p>
      <div className='section-header'>
        <div style={{ visibility: 'hidden' }}>
          <CurrencyPicker
            value={selectedCurrency}
            options={items}
            onChange={(currency) => setSelectedCurrency(currency)}
          />
        </div>
        <div className='control-buttons'>
          <div onClick={() => getPaymentExportMutation.mutate()} className='button'>
            Export Excel
            <FileExcelOutlined />
          </div>
          <div onClick={() => setIsModalOpen(true)} className='button blue'>
            Add new item <span>+</span>
          </div>
        </div>
      </div>
      <Table
        className='funding-table'
        columns={getColumns(setItemToEdit)}
        data={getCostDetailsList() || []}
        isLoading={isLoading}
        variant='dark'
        scroll={{ x: true }}
      />
      <Pagination
        className='pagination'
        selectedPage={selectedPage}
        totalPages={paymentList?.count || 0}
        showJumper={false}
        showPageSize={false}
        variant='dark'
        onPageChange={setSelectedPage}
        onPageSizeChange={setPageSize}
      />
    </div>
  );
};

export default PaymentsSection;
