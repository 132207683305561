import React, { FC } from 'react';
import './styles.scss';
import { MissionOutcomesAndRecommendations } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface IAssessmentOverviewSummaryProps {
  values: MissionOutcomesAndRecommendations;
}

const AssessmentMissionOutcomeSummary: FC<IAssessmentOverviewSummaryProps> = ({ values }) => {
  return (
    <div className='assessment-mission-outcome-summary-container'>
      <div className='summary-field'>
        <div className='section-label'>Outcomes</div>
        <div className='section-value'>{values.outcomes}</div>
      </div>
      <div className='summary-field'>
        <div className='section-label'>Recommendations</div>
        <div className='section-value'>{values.recommendations}</div>
      </div>
    </div>
  );
};

export default AssessmentMissionOutcomeSummary;
