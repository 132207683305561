import { ChartProps, Doughnut } from 'react-chartjs-2';
import React, { FC } from 'react';
import './styles.scss';
import { ArcElement, Chart, Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip);

export interface IChartData {
  name: string;
  value: number;
  color: string;
}

interface IProps {
  label: string;
  chartData: IChartData[];
  chartProps: ChartProps<'doughnut'>;
  showPercentage?: boolean;
}

export const getChartProps = (data: IChartData[]): ChartProps<'doughnut'> => {
  return {
    className: 'infrastructures-chart',
    type: 'doughnut',
    options: {
      cutout: '80%',
      plugins: {
        tooltip: {
          callbacks: {
            title: (tooltipItem) => {
              const label = tooltipItem[0].label;
              const maxLength = 25;
              return label.length > maxLength ? `${label.substring(0, maxLength - 3)}...` : label;
            },
            label: (tooltipItem) => {
              const value = tooltipItem.formattedValue;
              return ` ${value}%`;
            },
          },
        },
      },
      elements: {
        arc: {
          borderWidth: 20,
          borderRadius: 50,
        },
      },
    },
    data: {
      labels: data.map((item) => item.name),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: data.map((item) => item.color),
          borderWidth: 1,
          hoverBorderColor: 'white',
          spacing: 5,
          weight: 1,
        },
      ],
    },
  };
};

const DoughnutChart: FC<IProps> = ({ chartData, chartProps, label, showPercentage = true }) => {
  return (
    <div className='chart'>
      <Doughnut {...chartProps} />
      <div className='chart-legend'>
        <p className='chart-label'>{label}</p>
        <div className='legend-wrapper'>
          {chartData.map((data) => (
            <div key={data.name} className='legend-element'>
              <span className='legend-dot' style={{ backgroundColor: data.color }} />
              {showPercentage && <span className='value'>{data.value} %</span>}
              <span className='name'>{data.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;
