import { ResponsiveContainer, Tooltip, TooltipProps, Treemap } from 'recharts';
import React, { FC } from 'react';
import './styles.scss';

interface ITreemapData {
  name: string;
  color: string;
  children: {
    name: string;
    size: number;
  }[];
}

interface IProps {
  data: ITreemapData[];
}

export const CustomizedContent = (props: any) => {
  const { root, x, y, width, height, name, value } = props;

  const string = `${name} - ${value}%`;
  let croppedText;
  let showLabel = true;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = '14px Open-Sans';
    const textMetrics = context.measureText(string);
    //  Width - (stroke + padding) / single letter width
    if (height <= 25) {
      showLabel = false;
    } else {
      croppedText = string.substring(0, (width - 50) / (textMetrics.width / string.length));
    }
  } else croppedText = '';

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: root.color,
          stroke: '#fff',
          strokeOpacity: 1,
        }}
      />
      <text
        x={x + width / 2}
        y={y + height / 2 + 7}
        wordSpacing='pre-wrap'
        textAnchor='middle'
        data-testid='content-text'
        fill='#fff'
        fontWeight={300}
        fontSize={14}
      >
        {showLabel && `${croppedText}${croppedText !== string ? '...' : ''}`}
      </text>
    </g>
  );
};

export const CustomTooltip = ({ payload }: TooltipProps<number, string>) => {
  const value = payload?.[0]?.payload?.value;
  const name = payload?.[0]?.payload?.name;
  const color = payload?.[0]?.payload?.root.color;

  return (
    <div className='breakdown-chart-tooltip'>
      <div className='square' data-testid='square' style={{ backgroundColor: color }} />
      <div>
        {name} - {value}%
      </div>
    </div>
  );
};

const InfrastructureChart: FC<IProps> = ({ data }) => {
  return (
    <div className='chart-wrapper'>
      <p>Infrastructure breakdown</p>
      <div className='breakdown-legend'>
        {data.map((element) => (
          <div key={element.name}>
            <span className='square' style={{ backgroundColor: element.color }} />
            <span>{element.name}</span>
          </div>
        ))}
      </div>
      <div className='chart'>
        <ResponsiveContainer width='100%' height='100%'>
          {data.length ? (
            <Treemap data={data} dataKey='size' stroke='#fff' fill='#fff' content={<CustomizedContent />}>
              <Tooltip content={<CustomTooltip />} />
            </Treemap>
          ) : (
            <p className='no-data-label'>No data</p>
          )}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default InfrastructureChart;
