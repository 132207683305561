import React, { FC } from 'react';
import { FormApi, Radio, RadioGroup } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import * as Yup from 'yup';
import { IAssessmentFormData } from '@digital-office/pages/FieldAssessmentReports/components/AssessmentChecklistStep';

interface IAssessmentConductedList {
  name: string;
  values: IAssessmentFormData;
  formRef: React.RefObject<FormApi>;
}

const getAssessmentWaterSupplyListYupValidation = () => {
  return Yup.object().shape({
    water_borehole_drilling_rehabilitation: Yup.string().required('Required'),
    steel_water_tower_tank_fabrication_erection: Yup.string().required('Required'),
    water_purification_system_supply_installation: Yup.string().required('Required'),
    water_supply_pipe_laying_installation: Yup.string().required('Required'),
    water_taps_valves_accessories: Yup.string().required('Required'),
    rainwater_harvesting_system: Yup.string().required('Required'),
  });
};

const AssessmentWaterSupplyList: FC<IAssessmentConductedList> = ({ formRef }) => {
  return (
    <div className='assessment-details-list-container'>
      <div className='table-header'>
        <div className='headers'>
          <span>Yes</span>
          <span>No</span>
        </div>
      </div>
      <InformedField
        name='data[water_supply_works_required][water_borehole_drilling_rehabilitation]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Drilling or rehabilitation of water borehole?</span>
            <RadioGroup
              className='radio'
              name='data[water_supply_works_required][water_borehole_drilling_rehabilitation]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[water_supply_works_required][steel_water_tower_tank_fabrication_erection]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Fabrication and erection of steel water tower and water tank?</span>
            <RadioGroup
              className='radio'
              name='data[water_supply_works_required][steel_water_tower_tank_fabrication_erection]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[water_supply_works_required][water_purification_system_supply_installation]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Supply & installation of water purification system?</span>
            <RadioGroup
              className='radio'
              name='data[water_supply_works_required][water_purification_system_supply_installation]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[water_supply_works_required][water_supply_pipe_laying_installation]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Water supply pipe laying & installation?</span>
            <RadioGroup
              className='radio'
              name='data[water_supply_works_required][water_supply_pipe_laying_installation]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[water_supply_works_required][water_taps_valves_accessories]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Water taps, valves & related accessories?</span>
            <RadioGroup className='radio' name='data[water_supply_works_required][water_taps_valves_accessories]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[water_supply_works_required][rainwater_harvesting_system]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Rainwater harvesting system?</span>
            <RadioGroup className='radio' name='data[water_supply_works_required][rainwater_harvesting_system]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
    </div>
  );
};

export { getAssessmentWaterSupplyListYupValidation, AssessmentWaterSupplyList };
