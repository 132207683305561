import { Button, Dropdown } from '@ui-modules';
import React, { FC, useState } from 'react';
import { Modal } from 'antd';
import './styles.scss';
import { IDropdownOption } from '@ui-modules/types';
import { IPhase } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection';

interface IProps {
  isAddPhaseModalActive: boolean;
  setIsAddPhaseModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  phases: IPhase[];
  onPhaseAdd: (index: string) => void;
}

const getOptions = (phases: IPhase[]) => {
  const result: IDropdownOption[] = [];
  phases.forEach((phase, key) => {
    if (phase.removed)
      result.push({
        label: `Phase: ${key} - ${phase.title}`,
        value: String(key),
      });
  });
  return result;
};

const AddPhase: FC<IProps> = ({ isAddPhaseModalActive, setIsAddPhaseModalActive, phases, onPhaseAdd }) => {
  const [phaseSelectedToAdd, setPhaseSelectedToAdd] = useState<string>();

  return (
    <Modal
      footer={null}
      title={
        <div className={'modal-title'}>
          <p>Add Phase</p>
        </div>
      }
      width={'40%'}
      destroyOnClose={true}
      data-testid='add-phase-modal'
      open={isAddPhaseModalActive}
      onCancel={() => {
        setIsAddPhaseModalActive(false);
        setPhaseSelectedToAdd(undefined);
      }}
    >
      <div className='add-phase-wrapper'>
        <h4>Select phase to add</h4>
        {phases && (
          <Dropdown
            onChange={(option) => setPhaseSelectedToAdd(option.value)}
            placeholder='Select...'
            className='dropdown'
            options={getOptions(phases)}
          />
        )}
        <Button
          onClick={() => {
            phaseSelectedToAdd && onPhaseAdd(phaseSelectedToAdd);
            setIsAddPhaseModalActive(false);
            setPhaseSelectedToAdd(undefined);
          }}
          className='confirm-button'
          data-testid='phase-confirm-button'
          variant='submit'
          disabled={!phaseSelectedToAdd}
          text='CONFIRM'
        />
      </div>
    </Modal>
  );
};

export default AddPhase;
