import React, { FC } from 'react';
import { Drawer } from 'antd';
import { ICMSNavigation } from '../../interfaces/Common';
import { ReactComponent as DigitalOfficeIcon } from '@assets/svg/do-icon.svg';
import { ReactComponent as MyProjectsIcon } from '@assets/svg/my_projects_icon.svg';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

interface IProps {
  data: ICMSNavigation[];
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hasAccessDO?: boolean;
  hasDoParams: boolean;
  logoutHandler: () => void;
}

const MobileNavigation: FC<IProps> = ({ data, isMobileMenuOpen, setIsMobileMenuOpen, hasAccessDO, logoutHandler }) => {
  const pathname = location.pathname.split('/')[1];
  const navigate = useNavigate();

  return (
    <div className='mobile-nav-wrapper'>
      <Drawer
        style={{ position: 'absolute' }}
        getContainer={false}
        closeIcon={null}
        onClose={() => setIsMobileMenuOpen(false)}
        headerStyle={{ display: 'none' }}
        className='mobile-nav-drawer'
        mask={true}
        open={isMobileMenuOpen}
        placement='left'
      >
        <div className='menu-wrapper'>
          {data.map((menuItem) => (
            <div
              onClick={() => {
                !menuItem.is_external ? navigate(menuItem.link_url) : window.open(menuItem.link_url, '_blank');
                setIsMobileMenuOpen(false);
              }}
              key={menuItem.link_title}
              className={`menu-element ${pathname === menuItem.link_url && 'active'}`}
            >
              <img src={menuItem.icon} />
              <div>{menuItem.link_title}</div>
            </div>
          ))}
          <div
            onClick={() =>
              (window.location.href = hasAccessDO ? 'digital-office' : String(process.env.REACT_APP_HBH_DO_URL))
            }
            className='navigation-button digital-office nav'
          >
            <DigitalOfficeIcon className='icon' />
            <div className='button-label'>
              <p>Digital</p>
              <p>Office</p>
            </div>
          </div>
          <div onClick={() => (window.location.href = '/customer-area')} className='navigation-button my-projects nav'>
            <MyProjectsIcon className='icon' />
            <div className='button-label'>
              <p>My</p>
              <p>Requests</p>
            </div>
          </div>
          <p onClick={logoutHandler} className='logout-button'>
            Logout
          </p>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileNavigation;
