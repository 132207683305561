import React, { FC, useMemo, useState } from 'react';
import { Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { cloneDeep, find, remove } from 'lodash';
import { message, Modal, Tooltip } from 'antd';
import { IDropdownOption } from '@ui-modules/types';
import { Button } from '@ui-modules';
import { ReactComponent as DragIndicator } from '@assets/svg/DragIndicator.svg';
import { ReactComponent as DeleteIcon } from '@assets/svg/CloseIcon.svg';
import { WarningFilled } from '@ant-design/icons';
import phase_data from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection/data';
import { IPhase } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection/index';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

export interface DeliverableItem {
  name: string;
  is_template?: boolean;
  finished?: boolean;
}

interface IRemoveModalProps {
  title: string;
  isVisible: boolean;
  description: string | JSX.Element;
  closeHandler: () => void;
  removeHandler: () => void;
}

interface IDraggableListContainerProps {
  phaseKey: number;
  templates: IDropdownOption[];
  phases: IPhase[];
  setPhases: React.Dispatch<React.SetStateAction<IPhase[]>>;
  phaseData: IPhase;
  setTemplates: React.Dispatch<React.SetStateAction<IDropdownOption[]>>;
  isEditable?: boolean;
}

interface IWarningIconProps {
  deliverablesCount: number;
  datesAreProvided: boolean;
}

// components

const RemoveModal: FC<IRemoveModalProps> = ({ title, removeHandler, description, closeHandler, isVisible }) => {
  return (
    <Modal
      footer={null}
      title={
        <div className={'modal-title'}>
          <p>{title}</p>
        </div>
      }
      width={'40%'}
      destroyOnClose={true}
      onCancel={closeHandler}
      open={isVisible}
    >
      <div className='remove-modal-confirmation'>
        <p>{description}</p>
        <div className='button-group'>
          <Button variant='warning' onClick={closeHandler} text='CANCEL' />
          <Button variant='submit' onClick={removeHandler} text='CONFIRM' />
        </div>
      </div>
    </Modal>
  );
};

const DraggableListContainer: FC<IDraggableListContainerProps> = ({
  phaseKey,
  phases,
  setPhases,
  phaseData,
  templates,
  setTemplates,
  isEditable,
}) => {
  const [deliverableToDelete, setDeliverableToDelete] = useState<{
    phaseKey: number;
    deliverable: DeliverableItem;
  } | null>(null);

  const removeDeliverableHandler = useMemo(() => {
    return () => {
      if (deliverableToDelete) {
        removeDeliverable(deliverableToDelete?.phaseKey, deliverableToDelete.deliverable.name, phases, setPhases);

        if (!templates.find((template) => template.label === deliverableToDelete.deliverable.name)) {
          setTemplates([
            ...templates,
            { label: deliverableToDelete.deliverable.name, value: deliverableToDelete.deliverable.name },
          ]);
        }
        setDeliverableToDelete(null);
      }
    };
  }, [deliverableToDelete]);

  return (
    <div>
      <RemoveModal
        title='REMOVE A DELIVERABLE'
        removeHandler={removeDeliverableHandler}
        isVisible={Boolean(deliverableToDelete)}
        closeHandler={() => setDeliverableToDelete(null)}
        description={
          <p>
            Do you want to remove deliverable: <b>{deliverableToDelete?.deliverable.name}</b> from Phase: {phaseKey}?
          </p>
        }
      />
      <Droppable droppableId={phaseData.title}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className='deliverable-list'>
            {phaseData.deliverables.map((deliverableItem, key) => {
              return (
                <Draggable
                  key={`${phaseData.title}-${deliverableItem.name}`}
                  draggableId={`${phaseData.title}-${deliverableItem.name}`}
                  index={key}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <div className='deliverable-item'>
                        {isEditable && <DragIndicator className='drag-icon' />}
                        <span className='title'>{deliverableItem.name}</span>
                        {isEditable && (
                          <DeleteIcon
                            onClick={() => setDeliverableToDelete({ phaseKey: phaseKey, deliverable: deliverableItem })}
                            className='close-icon'
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const WarningIcon: FC<IWarningIconProps> = ({ deliverablesCount, datesAreProvided, ...rest }) => {
  const warningTitle = `${!deliverablesCount ? '• Provide at least 1 deliverable \n' : ''} ${
    !datesAreProvided
      ? '• Providing dates will allow you to see the overall project plan and easily track project status'
      : ''
  }`;

  return !deliverablesCount || !datesAreProvided ? (
    <span className='no-deliverables-warning' {...rest}>
      <Tooltip
        overlayClassName='conversion-page-tooltip'
        overlayStyle={{ whiteSpace: 'pre-line' }}
        placement='left'
        title={warningTitle}
      >
        <WarningFilled />
      </Tooltip>
    </span>
  ) : null;
};

// handlers

const reorder = (list: DeliverableItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const onDragEnd = (result: DropResult, phases: IPhase[], setPhases: React.Dispatch<React.SetStateAction<IPhase[]>>) => {
  if (!result.destination) {
    return;
  }

  const sourceKey = phases.findIndex((phase) => phase.title === result.source.droppableId);
  const destinationKey = phases.findIndex((phase) => phase.title === result.destination?.droppableId);

  if (sourceKey !== destinationKey) {
    const sourceDeliverable = phases[sourceKey].deliverables[result.source.index].name;
    const destinationItems = phases[destinationKey].deliverables;

    if (find(destinationItems, (item) => item.name === sourceDeliverable)) {
      message.warning('Phase cannot contain deliverable with same title');
      return false;
    }

    const items = phases[sourceKey].deliverables;
    const sourceFilteredList = phases[sourceKey].deliverables.filter((item) => item.name !== sourceDeliverable);

    const sourceList = {
      ...phases[sourceKey],
      deliverables: sourceFilteredList,
    };

    destinationItems.splice(result.destination.index, 0, {
      name: items[result.source.index].name,
    });

    const destinationList = {
      ...phases[destinationKey],
      deliverables: destinationItems,
    };

    const newPhases = [...phases];
    newPhases[sourceKey] = sourceList;
    newPhases[destinationKey] = destinationList;

    setPhases(newPhases);
  } else {
    const items = reorder(phases[destinationKey].deliverables, result.source.index, result.destination.index);

    const newObject = cloneDeep(phases);
    newObject[destinationKey].deliverables = items;
    setPhases(newObject);
  }
};

const onNewDeliverableHandler = (
  item: DeliverableItem,
  phases: IPhase[],
  templates: IDropdownOption[],
  setPhases: React.Dispatch<React.SetStateAction<IPhase[]>>,
  newDeliverableIndex: number,
  setNewDeliverableIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
  setTemplates: React.Dispatch<React.SetStateAction<IDropdownOption[]>>,
  sendDeliverableTemplateMutation: any
) => {
  const newObject = cloneDeep(phases);
  const deliverables = newObject[newDeliverableIndex as number].deliverables;

  if (find(deliverables, { name: item.name })) {
    //To prevent duplicate dnd keys
    return message.warning('Deliverable name in phase must be unique');
  }

  if (item.is_template) {
    sendDeliverableTemplateMutation.mutate({ name: item.name });
    // setTemplates([...templates, { label: item.name, value: item.name }]);
  }

  newObject[newDeliverableIndex as number].deliverables.push({
    name: item.name,
    finished: false,
    is_template: item.is_template,
  });
  setPhases(newObject);
  setNewDeliverableIndex(undefined);
};

const removeDeliverable = (
  index: number,
  name: string,
  phases: IPhase[],
  setPhases: React.Dispatch<React.SetStateAction<IPhase[]>>
) => {
  const newObject = cloneDeep(phases);

  remove(newObject[index].deliverables, { name: name });
  setPhases(newObject);
};

const onDateChange = (
  date: Date | null,
  phaseIndex: number,
  key: 'start_date' | 'end_date',
  phases: IPhase[],
  setPhases: React.Dispatch<React.SetStateAction<IPhase[]>>
) => {
  const newObject = cloneDeep(phases);
  newObject[phaseIndex][key] = date ? format(date, DateFormatEnum.YYYY_MM_DD) : undefined;
  if (key === 'start_date') {
    newObject[phaseIndex]['end_date'] = undefined;
  }
  setPhases(newObject);
};

const onPhaseRemove = (
  index: number,
  isSharepointDeletion: boolean,
  phases: IPhase[],
  setPhases: React.Dispatch<React.SetStateAction<IPhase[]>>
) => {
  const newObject = cloneDeep(phases);
  newObject[index].delete_phase = true;
  newObject[index].delete_sp_folder = isSharepointDeletion;
  newObject[index].removed = true;
  setPhases(newObject);
};

const onPhaseAdd = (index: string, phases: IPhase[], setPhases: React.Dispatch<React.SetStateAction<IPhase[]>>) => {
  const newObject = cloneDeep(phases);
  newObject[Number(index)] = {
    ...phase_data[Number(index)],
    uuid: 'new',
  };
  setPhases(newObject);
};

export {
  RemoveModal,
  DraggableListContainer,
  WarningIcon,
  onDragEnd,
  onNewDeliverableHandler,
  removeDeliverable,
  onDateChange,
  onPhaseRemove,
  onPhaseAdd,
};
