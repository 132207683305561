import React, { FC, useEffect, useState } from 'react';
import { Button } from '@ui-modules';
import { DragDropContext } from 'react-beautiful-dnd';
import { Checkbox, DatePicker, Modal } from 'antd';
import { IDropdownOption } from '@ui-modules/types';
import AddPhase from '@digital-office/pages/ProjectsManagementPages/components/AddPhase';
import AddDeliverable from '@digital-office/pages/ProjectsManagementPages/components/AddDeliverable';
import { ReactComponent as DeleteIcon } from '@assets/svg/CloseIcon.svg';
import {
  DraggableListContainer,
  WarningIcon,
  onDateChange,
  onDragEnd,
  onNewDeliverableHandler,
  onPhaseAdd,
  onPhaseRemove,
} from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection/utils';
import PhaseDetail from '@digital-office/pages/ProjectsManagementPages/components/PhaseDetail';
import { FileFilled } from '@ant-design/icons';
import { ReactComponent as ManualIcon } from '@assets/svg/construction_manual.svg';
import './styles.scss';
import phase_data from './data';
import { MANUAL_URL } from '@digital-office/pages/ProjectsManagementPages/constants';
import { UseMutationResult } from 'react-query';
import { format, isAfter, isBefore, parse } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { DateFormatEnum } from '@common/utils/constants';

const FnsDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

export interface DeliverableItem {
  name: string;
  is_template?: boolean;
  finished?: boolean;
}

export interface IPhase {
  title: string;
  description: string;
  deliverables: DeliverableItem[];
  removed?: boolean;
  uuid?: string;
  delete_phase?: boolean;
  delete_sp_folder?: boolean;
  number?: number;
  start_date?: string;
  end_date?: string;
}

interface IPhaseSectionProps {
  onPhaseChange: React.Dispatch<React.SetStateAction<IPhase[]>>;
  deliverableTemplates: IDropdownOption[];
  persistingPhases?: IPhase[] | undefined;
  isEditableView?: boolean;
  updateDeliverable?: (phases: IPhase[]) => void;
  sendDeliverableTemplateMutation: UseMutationResult<any, any, { name: string }, unknown>;
  showSharepointRemovingSection?: boolean;
}

interface IRemovePhaseModalProps {
  phaseToDelete: number | null;
  onCancel: () => void;
  onConfirm: (value: boolean) => void;
  showSharepointRemovingSection: boolean;
}

const RemovePhaseModal: FC<IRemovePhaseModalProps> = ({
  phaseToDelete,
  onCancel,
  onConfirm,
  showSharepointRemovingSection,
}) => {
  const [isSharepointDeletion, setIsSharepointDeletion] = useState<boolean>(false);

  return (
    <div className='remove-phase-modal'>
      <p>
        Do you want to remove <b>Phase: {phaseToDelete}</b>?
      </p>
      {showSharepointRemovingSection && (
        <div className='sharepoint-checkbox'>
          <Checkbox value={isSharepointDeletion} onChange={() => setIsSharepointDeletion(!isSharepointDeletion)} />
          <span className='sharepoint-label'>Delete SharePoint folders related to this Phase</span>
        </div>
      )}
      <div className='button-group'>
        <Button onClick={onCancel} variant='warning' text='CANCEL' />
        <Button onClick={() => onConfirm(isSharepointDeletion)} variant='submit' text='CONFIRM' />
      </div>
    </div>
  );
};

const PhaseSection: FC<IPhaseSectionProps> = ({
  onPhaseChange,
  deliverableTemplates,
  persistingPhases,
  isEditableView,
  updateDeliverable,
  sendDeliverableTemplateMutation,
  showSharepointRemovingSection = true,
}) => {
  const [phases, setPhases] = useState<IPhase[]>(persistingPhases ? persistingPhases : phase_data);
  const [newDeliverableIndex, setNewDeliverableIndex] = useState<number | undefined>(undefined);
  const [isAddPhaseModalActive, setIsAddPhaseModalActive] = useState<boolean>(false);
  const [phaseToDelete, setPhaseToDelete] = useState<number | null>(null);
  const [templates, setTemplates] = useState<IDropdownOption[]>(deliverableTemplates);

  useEffect(() => {
    onPhaseChange(phases);
  }, [phases]);

  useEffect(() => {
    setTemplates(deliverableTemplates);
  }, [deliverableTemplates]);

  useEffect(() => {
    persistingPhases && setPhases(persistingPhases);
  }, [persistingPhases]);

  return (
    <div className='hbh-details-item-value'>
      <Modal
        footer={null}
        title={
          <div className={'modal-title'}>
            <p>REMOVE A PHASE</p>
          </div>
        }
        width={'40%'}
        destroyOnClose={true}
        onCancel={() => setPhaseToDelete(null)}
        open={phaseToDelete !== null}
      >
        <RemovePhaseModal
          onConfirm={(isSharepointDeletion: boolean) => {
            phaseToDelete !== null && onPhaseRemove(phaseToDelete, isSharepointDeletion, phases, setPhases);
            setPhaseToDelete(null);
          }}
          onCancel={() => setPhaseToDelete(null)}
          phaseToDelete={phaseToDelete}
          showSharepointRemovingSection={showSharepointRemovingSection}
        />
      </Modal>
      <AddDeliverable
        setNewDeliverableIndex={setNewDeliverableIndex}
        newDeliverableIndex={newDeliverableIndex}
        deliverableTemplates={templates}
        onNewDeliverable={(data) =>
          newDeliverableIndex !== undefined &&
          onNewDeliverableHandler(
            data,
            phases,
            templates,
            setPhases,
            newDeliverableIndex,
            setNewDeliverableIndex,
            setTemplates,
            sendDeliverableTemplateMutation
          )
        }
      />
      <AddPhase
        phases={phases}
        isAddPhaseModalActive={isAddPhaseModalActive}
        setIsAddPhaseModalActive={setIsAddPhaseModalActive}
        onPhaseAdd={(index) => onPhaseAdd(index, phases, setPhases)}
      />
      <div className='phase-wrapper'>
        <div className='manual-section'>
          <ManualIcon className='icon' />
          <div className='manual-content'>
            <p className='manual-description'>
              To support you throughout the project&apos;s whole lifecycle, the following section allows you to set up
              the project based on the WFP Engineering Construction manual, a detailed and comprehensive tool for
              successfully managing deliverables, risk and cost.
            </p>
            <Button
              icon={<FileFilled className='project-icon' />}
              text='ACCESS THE MANUAL HERE'
              variant='transparent'
              className='manual-button'
              onClick={() => window.open(MANUAL_URL, '_blank')}
            />
          </div>
        </div>
        <DragDropContext onDragEnd={(result) => onDragEnd(result, phases, setPhases)}>
          {phases.map((phaseData, phaseKey) => {
            if (phaseData.removed) return null;
            if (!isEditableView)
              return (
                updateDeliverable && (
                  <PhaseDetail
                    phase={phaseData}
                    phasesList={phases}
                    setPhases={setPhases}
                    updateProject={updateDeliverable}
                  />
                )
              );
            return (
              <div data-testid={`phase-${phaseKey}-wrapper`} key={phaseKey} className='phase-item'>
                {isEditableView && (
                  <span
                    onClick={() => setPhaseToDelete(phaseKey)}
                    data-testid={`remove-${phaseKey}`}
                    className='delete-icon'
                  >
                    <DeleteIcon />
                  </span>
                )}
                <div className='deliverable-content'>
                  <span>
                    <b>Phase {phaseKey}: </b>
                    {phaseData.title}
                  </span>
                  <p className='phase-description'>{phaseData.description}</p>
                  {isEditableView && (
                    <WarningIcon
                      data-testid={`phase-${phaseKey}-warning`}
                      deliverablesCount={phaseData.deliverables.length}
                      datesAreProvided={Boolean(phaseData.start_date && phaseData.end_date)}
                    />
                  )}
                  <div className='deliverable-section'>
                    <div className='date-picker-section'>
                      <div data-testid={`phase-${phaseKey}-estimated-start-date-picker`}>
                        <span>Estimated start date </span>
                        <FnsDatePicker
                          value={phaseData.start_date ? new Date(phaseData.start_date) : null}
                          onChange={(e) => onDateChange(e, phaseKey, 'start_date', phases, setPhases)}
                          placeholder='Select'
                          disabled={!isEditableView}
                          data-testid={`test-${phaseKey}`}
                          format='DD/MM/yyyy'
                        />
                      </div>
                      <div data-testid={`phase-${phaseKey}-estimated-end-date-picker`}>
                        <span>Estimated completion date </span>
                        <FnsDatePicker
                          value={
                            phaseData.end_date ? parse(phaseData.end_date, DateFormatEnum.YYYY_MM_DD, new Date()) : null
                          }
                          onChange={(e) => onDateChange(e, phaseKey, 'end_date', phases, setPhases)}
                          disabled={!isEditableView}
                          disabledDate={(current) => {
                            const currentDate = new Date();
                            const customDate = format(currentDate, DateFormatEnum.YYYY_MM_DD);
                            const startDate = parse(phaseData.start_date || '', DateFormatEnum.YYYY_MM_DD, new Date());

                            // Allow selecting the date only if it's after the start_date or if no start_date is selected
                            return phaseData.start_date
                              ? isBefore(current, startDate)
                              : isAfter(current, parse(customDate, DateFormatEnum.YYYY_MM_DD, new Date()));
                          }}
                          format={DateFormatEnum.DD_MM_YYYY}
                          placeholder='Select'
                        />
                      </div>
                    </div>
                    <p>Expected deliverables:</p>
                    <DraggableListContainer
                      phases={phases}
                      isEditable={isEditableView}
                      templates={templates}
                      phaseKey={phaseKey}
                      phaseData={phaseData}
                      setPhases={setPhases}
                      setTemplates={setTemplates}
                    />
                    {isEditableView && (
                      <Button
                        onClick={() => setNewDeliverableIndex(phaseKey)}
                        variant='transparent'
                        className='add-deliverable-button'
                        text='+ ADD DELIVERABLE'
                      />
                    )}
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        </DragDropContext>
        {isEditableView && (
          <Button
            disabled={!phases.find((phase) => phase.removed)}
            onClick={() => setIsAddPhaseModalActive(true)}
            className='add-phase-button'
            variant='submit'
            text='ADD PHASE'
          />
        )}
      </div>
    </div>
  );
};

export default PhaseSection;
