import React, { FC, useState } from 'react';
import { Pagination, Table } from '@ui-modules';

import './styles.scss';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { ICostDetailItem, IProjectCostDetailList } from '@digital-office/common/interfaces';
import { useParams } from 'react-router-dom';

interface IProps {
  onEditClickHandler: (item: ICostDetailItem) => void;
}

const columns = (onEditClickHandler: IProps['onEditClickHandler']) => [
  {
    dataIndex: 'section_category',
    key: 'section_category',
    title: 'COST ITEM',
  },
  {
    dataIndex: 'contract_lump_sum',
    key: 'contract_lump_sum',
    title: 'CONTRACT LUMP SUM',
    render: (record: string) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'approved_contingency',
    key: 'approved_contingency',
    title: 'APPROVED CONTINGENCY',
    render: (record: string) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'approved_variations',
    key: 'approved_variations',
    title: 'APPROVED VARIATIONS',
    render: (record: string) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'certified_to_date',
    key: 'certified_to_date',
    title: 'CERTIFIED TO DATE',
    render: (record: string) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'cost_remaining',
    key: 'cost_remaining',
    title: 'COST REMAINING',
    render: (record: string) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'retention_amount',
    key: 'retention_amount',
    title: 'RETENTION AMOUNT',
    render: (record: string) => {
      return Number(record).toLocaleString('en-US', {
        minimumFractionDigits: 2,
      });
    },
  },
  {
    dataIndex: 'contract_completion_date',
    key: 'contract_completion_date',
    title: 'CONTRACT COMPLETION',
    render: (data: string, set: ICostDetailItem) => {
      return data ? format(new Date(data), DateFormatEnum.DD_MM_YYYY) : set.uuid ? '-' : '';
    },
  },
  {
    dataIndex: '',
    key: '',
    title: 'ACTION',
    render: (record: string, set: ICostDetailItem) => {
      if (set.uuid) {
        return (
          <p data-testid={`${set.uuid}-click-button`} onClick={() => onEditClickHandler(set)}>
            View
          </p>
        );
      } else {
        return undefined;
      }
    },
  },
];

const CostDetailsSections: FC<IProps> = ({ onEditClickHandler }) => {
  // const [selectedCurrency, setSelectedCurrency] = useState<string>(items[0].key);

  const { engineeringRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const { id: uuid } = useParams<{ id: string }>();

  const { data: costDetails } = useQuery<IProjectCostDetailList>(
    `cost-details-list-${pageSize}-${selectedPage}`,
    () =>
      engineeringRepository.getProjectCostDetails(facilityId, uuid || '', {
        limit: 10,
        offset: (selectedPage - 1) * 10,
      }),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const getCostDetailsData = () => {
    if (!costDetails?.results) {
      return [];
    }

    const fundingSourceData = costDetails?.results.reduce(
      (accumulator, item) => {
        return {
          values: [...accumulator.values, { ...item }],
          totalAmount: {
            approved_contingency: accumulator.totalAmount.approved_contingency + item.approved_contingency,
            approved_variations: accumulator.totalAmount.approved_variations + item.approved_variations,
            certified_to_date: accumulator.totalAmount.certified_to_date + item.certified_to_date,
            contract_lump_sum: accumulator.totalAmount.contract_lump_sum + item.contract_lump_sum,
            cost_remaining: accumulator.totalAmount.cost_remaining + item.cost_remaining,
            retention_amount: accumulator.totalAmount.retention_amount + item.retention_amount,
          },
        };
      },
      {
        values: [] as ICostDetailItem[],
        totalAmount: {
          approved_contingency: 0,
          approved_variations: 0,
          certified_to_date: 0,
          contract_lump_sum: 0,
          cost_remaining: 0,
          retention_amount: 0,
        },
      }
    );

    return [...(fundingSourceData?.values || []), { ...fundingSourceData.totalAmount }];
  };

  return (
    <div className='cost-details-wrapper'>
      {/*<CurrencyPicker value={selectedCurrency} options={items} onChange={(currency) => setSelectedCurrency(currency)} />*/}
      <Table
        className='cost-details-table'
        columns={columns(onEditClickHandler)}
        data={costDetails ? getCostDetailsData() : []}
        variant='dark'
        scroll={{ x: true }}
      />
      <Pagination
        className='pagination'
        selectedPage={selectedPage}
        totalPages={costDetails?.count || 0}
        showJumper={false}
        showPageSize={false}
        variant='dark'
        onPageChange={setSelectedPage}
        onPageSizeChange={setPageSize}
      />
    </div>
  );
};

export default CostDetailsSections;
