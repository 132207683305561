import React, { FC, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { Form, FormApi, FormStateAccessor, TextArea } from 'informed';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import { TextField } from '@ui-modules/informed';
import { isEmpty } from 'lodash';
import { UseMutationResult } from 'react-query';
import { Button } from '@ui-modules';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import { ProgressEstimatedCostAndConclusion } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface IEstimatedCost {
  values: ProgressEstimatedCostAndConclusion;
  formRef?: React.RefObject<FormApi>;
  isEditMode?: boolean;
}

export const EstimatedCost: FC<IEstimatedCost> = ({ values, isEditMode = true }) => {
  const totalValue = Number(
    (Number(values?.estimated_cost?.general_civil_works_obligations) || 0) +
      (Number(values?.estimated_cost?.opened_second_question_value) || 0)
  );

  const getContingencyAmount = (): number => {
    return (totalValue * Number(values?.estimated_cost?.percentage_contingency || 0)) / 100;
  };

  const generalCivicWorkValue = totalValue + getContingencyAmount();

  const otherWorkValue = Number(Number(values?.estimated_cost?.other_works || 0) + generalCivicWorkValue);

  return (
    <div className='estimated-cost-list-wrapper'>
      <div className='headers'>
        <span className='order'>NO.</span>
        <span className='description'>DESCRIPTION OF WORKS</span>
        <span className='amount'>AMOUNT (USD)</span>
      </div>
      <div className='list-element'>
        <p className='order'>1.</p>
        <p className='description'>General Civil Works Obligations</p>
        <div className='amount'>
          {isEditMode ? (
            <NumericInput suffix='' name='estimated_cost[general_civil_works_obligations]' />
          ) : (
            Number(values?.estimated_cost?.general_civil_works_obligations || 0).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            }) || '-'
          )}
        </div>
      </div>
      <div className='list-element'>
        <p className='order'>2.</p>
        <div className='description'>
          {isEditMode ? (
            <TextField placeholder='Insert...' name='estimated_cost[opened_second_question_text]' />
          ) : (
            values?.estimated_cost?.opened_second_question_text || '-'
          )}
        </div>
        <div className='amount'>
          {isEditMode ? (
            <NumericInput suffix='' name='estimated_cost[opened_second_question_value]' />
          ) : (
            Number(values?.estimated_cost?.opened_second_question_value || 0).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      </div>
      <div className='list-element summary'>
        <p className='order'>3.</p>
        <div className='description summary'>
          <p>Sub total</p>
        </div>
        <div className='amount summary'>
          {totalValue?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }) || 0}
        </div>
      </div>
      <div className='list-element'>
        <p className='order'>4.</p>
        <div className='description percentage'>
          <div className='small-input'>
            {isEditMode ? (
              <NumericInput suffix='' percentage={true} name='estimated_cost[percentage_contingency]' />
            ) : (
              values?.estimated_cost?.percentage_contingency || '0'
            )}
          </div>
          <span>% Contingency</span>
        </div>
        <div className='amount'>
          {isEditMode ? (
            <NumericInput
              customValue={getContingencyAmount() || '0'}
              disabled={true}
              suffix=''
              name='estimated_cost[contingency_amount]'
            />
          ) : (
            Number(getContingencyAmount() || 0).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            }) || '-'
          )}
        </div>
      </div>
      <div className='list-element summary'>
        <p className='order'>5.</p>
        <div className='description summary'>
          <p>General Civil Works - Total</p>
        </div>
        <div className='amount summary'>
          {generalCivicWorkValue?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }) || 0}
        </div>
      </div>
      <div className='list-element'>
        <p className='order'>6.</p>
        <p className='description'>
          Other Works (Subcontracted Electrical and Mechanical, HVAC Plant installations, etc)
        </p>
        <div className='amount'>
          {isEditMode ? (
            <NumericInput suffix='' name='estimated_cost[other_works]' />
          ) : (
            Number(values?.estimated_cost?.other_works || 0).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            }) || '-'
          )}
        </div>
      </div>
      <div className='list-element summary'>
        <p className='order'>7.</p>
        <div className='description summary'>
          <p>Civil + Electrical + Other works - Total</p>
        </div>
        <div className='amount summary'>
          {otherWorkValue?.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          }) || 0}
        </div>
      </div>
    </div>
  );
};

interface IAssessmentProgressStep {
  initialValues: ProgressEstimatedCostAndConclusion | null;
  updateMutation: UseMutationResult<any, unknown, any, unknown>;
  finalizeStepMutation: UseMutationResult<any, unknown, any, unknown>;
  formKey: number;
  onStepBackButton: () => void;
}

const ProjectProgressStep: FC<IAssessmentProgressStep> = ({
  initialValues,
  formKey,
  finalizeStepMutation,
  updateMutation,
  onStepBackButton,
}) => {
  const formRef = useRef<FormApi>(null);

  const [updateType, setUpdateType] = useState<string | undefined>();
  const [, updateState] = useState<any>(null);

  useEffect(() => {
    if (updateType) {
      formRef?.current?.submitForm();
    }
  }, [updateType]);

  const onSubmitButtonClick = (type: string) => {
    if (type === updateType) {
      formRef?.current?.submitForm();
    } else {
      setUpdateType(type);
    }
  };

  const parseFormDataToPayload = (values: ProgressEstimatedCostAndConclusion) => {
    const payload = {
      ...values,
      potential_recommended_sow: values.potential_recommended_sow || '',
      conclusion: values.conclusion || '',
    };

    if (updateType === 'draft') {
      updateMutation.mutate({ current_step: 4, progress_estimated_cost_and_conclusion: payload });
    } else {
      finalizeStepMutation.mutate({ current_step: 4, progress_estimated_cost_and_conclusion: payload });
    }
  };

  const onStepBackClick = () => {
    const modifiedField = formRef?.current?.getFormState().modified;
    if (isEmpty(modifiedField)) {
      onStepBackButton();
    } else {
      const confirmAlert = confirm(
        'You have unsaved changes. Saves will not be saved. Are you sure you want to leave?'
      );

      if (confirmAlert) {
        onStepBackButton();
      }
    }
  };

  return (
    <p className='assessment-progress-step-container'>
      <Form
        formApiRef={formRef}
        key={`$${formKey}`}
        onSubmit={({ values }) => parseFormDataToPayload(values)}
        initialValues={initialValues as Record<string, any>}
        validateOn='change'
        onSubmitFailure={() => updateState({})}
        onValueChange={() => updateState({})}
      >
        <FormStateAccessor>
          {({ values }) => {
            return (
              <div>
                <InformedField
                  description='SoW is provisional. The SoW as detailed below is tentative and subject to change. In the case of any revision in the work requirements during the final review and approval.'
                  label='Potential recommended Scope of Works (SoW) (optional)'
                  maxLength={1000}
                  name='potential_recommended_sow'
                  formRefApi={formRef}
                  informedComponent={
                    <TextArea maxLength={1000} className='text-area' name='potential_recommended_sow' />
                  }
                />
                <InformedField
                  description='Based on your potential SoW and best judgment, provide a rough estimated cost for decision making. Detailed estimate to work out once the client approves project'
                  label='Estimated cost (optional)'
                  informedComponent={<EstimatedCost formRef={formRef} values={values} />}
                />
                <InformedField
                  description='Provide concluding details for your mission, if required'
                  label='Conclusion (optional)'
                  maxLength={1000}
                  name='conclusion'
                  formRefApi={formRef}
                  informedComponent={<TextArea maxLength={1000} className='text-area' name='conclusion' />}
                />
              </div>
            );
          }}
        </FormStateAccessor>
        <div className='buttons-container'>
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            onClick={() => onStepBackClick()}
            variant='transparent'
            className='button back'
            text='Back'
            icon={<ArrowLeftOutlined />}
          />
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            className='button'
            variant='transparent'
            onClick={() => onSubmitButtonClick('draft')}
            text='Save as draft'
          />
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            icon={<ArrowRightOutlined />}
            className='button go-next'
            onClick={() => onSubmitButtonClick('submit')}
            text='Save and go next'
          />
        </div>
      </Form>
    </p>
  );
};

export default ProjectProgressStep;
