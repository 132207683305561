import { Dropdown } from '@ui-modules';
import { CalendarOutlined } from '@ant-design/icons';
import React, { FC, useRef } from 'react';
import { components, ControlProps } from 'react-select';
import { DatePicker } from 'antd';
import { IDropdownOption } from '@ui-modules/types';
import { TimeFilterValue } from '@digital-office/pages/DemandOverviewPage';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import './styles.scss';
import { format, isAfter } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

const FNSPicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

const { RangePicker } = FNSPicker;

interface IProps {
  timeFilterValue: IDropdownOption | TimeFilterValue | null;
  setTimeFilter: React.Dispatch<React.SetStateAction<IDropdownOption | TimeFilterValue | null>>;
  timeFilterOptions: IDropdownOption[];
}

const getControl = (label: string, Icon: any) => {
  const Control = ({ children, ...props }: ControlProps) => (
    <components.Control className='control-wrapper' {...props}>
      <Icon className='control-icon' /> <span>{label}: </span>
      {children}
    </components.Control>
  );

  return Control;
};

const TimeRangeFilter: FC<IProps> = ({ setTimeFilter, timeFilterOptions, timeFilterValue }) => {
  const ref = useRef<HTMLDivElement>(null);

  const onTimeFilterChange = (value: IDropdownOption) => {
    if (value.value === 'custom') {
      // Trigger click on first row of calendar
      const node = document.getElementsByClassName('demand-overview-datepicker')[0] as HTMLDivElement;
      node.click();
    } else {
      setTimeFilter(value);
    }
  };

  return (
    <div className='time-range-filter'>
      <div ref={ref} className='time-filter-section'>
        <Dropdown
          className='dropdown'
          components={{ Control: getControl('Time Filter', CalendarOutlined) }}
          placeholder='Select'
          onChange={onTimeFilterChange}
          options={timeFilterOptions}
          value={timeFilterValue}
        />
        <RangePicker
          format='MM-yyyy'
          disabledDate={(current) => isAfter(current, new Date())}
          picker='month'
          className='demand-overview-datepicker'
          onChange={(value) => {
            setTimeFilter({
              label: `${format(value?.[0] as Date, 'MM/yyyy')} - ${format(value?.[1] as Date, 'MM/yyyy')}`,
              value: {
                date_from: format(value?.[0] as Date, DateFormatEnum.YYYY_MM_DD) || '',
                date_to: format(value?.[1] as Date, DateFormatEnum.YYYY_MM_DD) || '',
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default TimeRangeFilter;
