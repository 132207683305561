import React, { FC, useEffect } from 'react';
import MapBoxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import { useControl } from 'react-map-gl';
import ReactMapGl, { Marker } from 'react-map-gl';
import axios from 'axios';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { getMapboxGeocodingPath } from '@digital-office/pages/ProjectsManagementPages/utils';
import { Feature } from '@digital-office/common/interfaces';
import { MAPBOX_API_KEY } from '@components/GlobalImpact/utils';

interface IGeocoderProps {
  onDecoderSelectHandler: (result: Result) => void;
}

const coordinatesGeocoder = (query: string): Result[] => {
  const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);
  if (!matches) {
    return [];
  }

  const coordinateFeature = (lng: number, lat: number) => {
    return {
      center: [lng, lat],
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      place_name: 'Lat: ' + lat + ' Lng: ' + lng,
      place_type: ['coordinate'],
      properties: {},
      type: 'Feature',
    };
  };

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [];

  if (coord1 < -90 || coord1 > 90) {
    // must be lng, lat
    geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
    // must be lat, lng
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
    // else could be either lng, lat or lat, lng
    geocodes.push(coordinateFeature(coord1, coord2));
    geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes as Result[];
};

const Geocoder: FC<IGeocoderProps> = ({ onDecoderSelectHandler }) => {
  const ctrl = new MapBoxGeocoder({
    accessToken: MAPBOX_API_KEY,
    marker: false,
    placeholder: 'Try: -40, 170',
    localGeocoder: coordinatesGeocoder,
    language: 'en-us',
    types: 'address, place',
  });
  useControl(() => ctrl);
  ctrl.on('result', (e) => {
    onDecoderSelectHandler(e.result as Result);
  });
  return null;
};

export interface ILocationPickerData {
  long: number;
  lat: number;
  place_name: string;
  country: string;
  region?: string;
  place?: string;
}

interface IProps {
  onLocationChange: (data: ILocationPickerData) => void;
  initCoords?: number[];
  zoomToLocation: boolean;
  persistingLocation?: ILocationPickerData | null;
  height?: number;
}

const LocationPicker: FC<IProps> = ({ onLocationChange, initCoords, zoomToLocation, persistingLocation, height }) => {
  const [locationCoordinates, setLocationCoordinates] = React.useState<ILocationPickerData | null>(
    persistingLocation || null
  );
  const country = localStorage?.getItem('country') || '';

  useEffect(() => {
    if (persistingLocation === null) {
      setLocationCoordinates(null);
    } else {
      persistingLocation && setLocationCoordinates(persistingLocation);
    }
  }, [persistingLocation]);

  const onDecoderSelectHandler = (result: Result) => {
    const country = result.context.find((data) => data.id.includes('country')).text;

    setLocationCoordinates({
      place_name: result.place_name,
      long: result.geometry.coordinates[0],
      lat: result.geometry.coordinates[1],
      country: country,
    });

    onLocationChange({
      place_name: result.place_name,
      long: result.geometry.coordinates[0],
      lat: result.geometry.coordinates[1],
      country: country,
    });
  };

  return (
    <div data-testid='map' className='location-picker-wrapper'>
      <ReactMapGl
        attributionControl={false}
        style={{ width: '100%', height: height || 300 }}
        initialViewState={
          initCoords && {
            longitude: initCoords[0],
            latitude: initCoords[1],
            zoom: country === 'Global' && !zoomToLocation ? 1 : 4,
          }
        }
        mapStyle='mapbox://styles/mapbox/streets-v11'
        mapboxAccessToken={MAPBOX_API_KEY}
        data-testid='map'
        onClick={async (event) => {
          const reverseGeocoding = await axios.get(getMapboxGeocodingPath(`${event.lngLat.lng}, ${event.lngLat.lat}`));

          const country = reverseGeocoding.data.features.find((feature: any) =>
            feature.place_type.includes('country')
          )?.place_name;

          const region = reverseGeocoding.data.features.find((feature: any) =>
            feature.place_type.includes('region')
          )?.place_name;

          const place = reverseGeocoding.data.features.find((feature: any) =>
            feature.place_type.includes('place')
          )?.place_name;

          setLocationCoordinates({
            long: event.lngLat.lng,
            lat: event.lngLat.lat,
            place_name: reverseGeocoding.data.features[0].place_name,
            place: place,
            region: region,
            country: country,
          });

          onLocationChange({
            long: event.lngLat.lng,
            lat: event.lngLat.lat,
            region: region,
            place: place,
            place_name: reverseGeocoding.data.features[0].place_name,
            country: country,
          });
        }}
      >
        <Geocoder onDecoderSelectHandler={onDecoderSelectHandler} />
        {locationCoordinates && <Marker longitude={locationCoordinates.long} latitude={locationCoordinates.lat} />}
      </ReactMapGl>
    </div>
  );
};

export default LocationPicker;
