import React, { FC } from 'react';

interface CounterProps {
  length: number;
  max: number;
}

const Counter: FC<CounterProps> = ({ max, length }) => {
  return <div className={`bf-field-counter ${length > max ? 'overfilled' : ''}`}>{`${length}/${max}`}</div>;
};

export default Counter;
