import React, { useEffect, useState } from 'react';
import { Button, PageTitle, Tabs } from '@ui-modules';
import { message, Spin } from 'antd';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { IProjectDetailsResponse } from '@digital-office/common/interfaces';
import { useParams } from 'react-router-dom';
import { parsePhasesToPayload } from '@digital-office/pages/ProjectsManagementPages//utils';
import { FormState } from 'informed';
import { IPhase } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection';
import { ILocationObject } from '@digital-office/pages/ProjectsManagementPages/components/LocationModal';
import {
  checkApplyButtonShouldBeDisabled,
  parseLocationData,
  parsePhases,
  updateValues,
} from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/utils';
import {
  FINANCIAL_TAB,
  OVERVIEW_TAB,
  PHASES_TAB,
  REPOSITORY_TAB,
  RISK_TAB,
  UPDATES_TAB,
} from '@digital-office/pages/ProjectsManagementPages/constants';
import ProjectOverview from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectOverview';
import ProjectPhases from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectPhases';
import ProjectRepository from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectRepository';

import './styles.scss';
import { cloneDeep } from 'lodash';
import ProjectUpdates from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectUpdates';
import ProjectRisk from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectRisk';
import ProjectFinancials from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectFinancials';
import { ReactComponent as OverviewIcon } from '@assets/svg/project_info.svg';
import { ReactComponent as RepositoryIcon } from '@assets/svg/project_repository.svg';
import { ReactComponent as UpdatesIcon } from '@assets/svg/project_updates.svg';
import { ReactComponent as RiskIcon } from '@assets/svg/project_risk.svg';
import { ReactComponent as FinancialsIcon } from '@assets/svg/project_financials.svg';
import { ReactComponent as PhasesIcon } from '@assets/svg/project_phases.svg';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

const tabs = [OVERVIEW_TAB, PHASES_TAB, REPOSITORY_TAB, UPDATES_TAB, RISK_TAB, FINANCIAL_TAB];

const ProjectDetailsPage = () => {
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [phases, setPhases] = useState<IPhase[]>([]);
  const [formData, setFormData] = useState<FormState | null>(null);
  const [locations, setLocations] = useState<ILocationObject[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { engineeringRepository } = useRepository();
  const { id: projectId } = useParams<{ id: string }>();
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState<boolean>(true);
  const navigate = useNavigate();

  const {
    data: projectDetails,
    refetch,
    dataUpdatedAt,
    isLoading,
  } = useQuery<IProjectDetailsResponse>(
    `projects-overview-${projectId}`,
    () => engineeringRepository.getProjectsDetails(facilityId, projectId || ''),
    {
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        if (err.response.status === 404) {
          message.error('Project with such id was not found');

          setTimeout(() => {
            navigate('/digital-office/projects');
          }, 2000);
        }
      },
    }
  );

  useEffect(() => {
    if (selectedTab === OVERVIEW_TAB) {
      refetch();
    }
  }, [selectedTab]);

  const updateDeliverable = useMutation(
    (data: any) => engineeringRepository.updateProject(facilityId, projectId || '', data),
    {
      onSuccess: () => {
        message.success('Project updated successfully');
        setIsEditMode(false);
        refetch();
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  useEffect(() => {
    if (projectDetails?.phases) {
      setPhases(parsePhases(projectDetails?.phases));
    }

    if (projectDetails?.locations) {
      setLocations(parseLocationData(projectDetails.locations, projectDetails.options.infrastructures));
    }
  }, [projectDetails]);

  useEffect(() => {
    projectDetails &&
      checkApplyButtonShouldBeDisabled(
        formData,
        locations,
        phases,
        setIsApplyButtonDisabled,
        projectDetails,
        projectDetails.options.infrastructures
      );
  }, [formData, locations, phases, isEditMode]);

  const restoreInitialValues = () => {
    setPhases(parsePhases(phases));
    projectDetails &&
      setLocations(parseLocationData(projectDetails?.locations, projectDetails.options.infrastructures));

    if (formData) {
      formData.modified = {};
      formData.values = projectDetails ? projectDetails?.overview : {};
    }
  };

  const updateDeliverableHandler = (phases: IPhase[]) => {
    updateDeliverable.mutate({
      phases: parsePhasesToPayload(phases),
    });
  };

  const tabsIcon = {
    [OVERVIEW_TAB]: <OverviewIcon />,
    [PHASES_TAB]: <PhasesIcon />,
    [REPOSITORY_TAB]: <RepositoryIcon />,
    [UPDATES_TAB]: <UpdatesIcon />,
    [RISK_TAB]: <RiskIcon />,
    [FINANCIAL_TAB]: <FinancialsIcon />,
  };

  return (
    <Spin className='project-spinner' spinning={isLoading}>
      <div
        className='project-details-page-wrapper'
        style={{ display: isLoading || !projectDetails ? 'none' : 'block' }}
      >
        <div className='page-title-wrapper'>
          <PageTitle title={`PROJECT - ${projectDetails?.overview.code} - ${country}`} />
          {selectedTab === FINANCIAL_TAB ? <VideoLink link={videoLinks.FUNDING_AND_COST} /> : null}
          {selectedTab === REPOSITORY_TAB || selectedTab === UPDATES_TAB ? (
            <VideoLink link={videoLinks.PROJECT_REPOSITORY} />
          ) : null}
          {selectedTab === RISK_TAB ? <VideoLink link={videoLinks.RISK_REGISTER} /> : null}
        </div>
        <div className='booking-list'>
          <Tabs
            className='engineering-tabs project-details-tabs'
            selectedTab={tabs.indexOf(selectedTab)}
            onTabSelect={setSelectedTab}
            tabIcons={tabsIcon}
            tabs={tabs}
          />
        </div>
        <div className='booking-details-container project-details-wrapper'>
          <div className='hbh-details-container details-phases-section'>
            {projectDetails && selectedTab === OVERVIEW_TAB && (
              <ProjectOverview
                projectDetails={projectDetails}
                formData={formData}
                setFormData={setFormData}
                isEditMode={isEditMode}
                formKey={dataUpdatedAt}
                locations={locations}
                setLocations={setLocations}
              />
            )}
            {projectDetails && selectedTab === PHASES_TAB && (
              <ProjectPhases
                projectDetails={projectDetails}
                isEditMode={isEditMode}
                phases={phases}
                setPhases={setPhases}
                updateDeliverableHandler={updateDeliverableHandler}
              />
            )}
            {projectDetails && selectedTab === REPOSITORY_TAB && (
              <ProjectRepository data={projectDetails.sharepoint_tree} />
            )}
            {projectDetails && selectedTab === UPDATES_TAB && <ProjectUpdates />}
            {projectDetails && selectedTab === RISK_TAB && <ProjectRisk />}
            {projectDetails && selectedTab === FINANCIAL_TAB && <ProjectFinancials />}
          </div>
          <div
            className={`buttons-wrapper ${
              selectedTab === REPOSITORY_TAB ||
              selectedTab === UPDATES_TAB ||
              selectedTab === RISK_TAB ||
              selectedTab === FINANCIAL_TAB
                ? 'hidden'
                : ''
            }`}
          >
            {isEditMode && (
              <Button
                disabled={isApplyButtonDisabled}
                className='button-edit'
                onClick={() =>
                  projectDetails &&
                  updateValues(
                    projectDetails,
                    cloneDeep(formData),
                    locations,
                    phases,
                    updateDeliverable,
                    projectDetails.options.infrastructures
                  )
                }
                variant='submit'
                text='Apply'
              />
            )}
            <Button
              className='button-edit'
              onClick={() => {
                isEditMode ? restoreInitialValues() : window.scrollTo({ top: 0, behavior: 'smooth' });
                setIsEditMode(!isEditMode);
              }}
              text={!isEditMode ? 'Edit mode' : 'Cancel'}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ProjectDetailsPage;
