import React, { useEffect, useState } from 'react';
import './styles.scss';
import FundingSection from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectFinancials/components/FundingSection';
import CostDetailsSections from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectFinancials/components/CostDetailsSections';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DetailOverview from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectFinancials/components/DetailOverview';
import PaymentsSection from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectFinancials/components/PaymentsSection';
import { ICostDetailItem } from '@digital-office/common/interfaces';
import StagePaymentsSection from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectFinancials/components/StagePaymentsSection';
import PaymentsVariationsSection from '@digital-office/pages/ProjectsManagementPages/ProjectDetailsPage/components/ProjectFinancials/components/PaymentsVariationsSection';

const ProjectFinancials = () => {
  const [selectedDetail, setSelectedDetail] = useState<ICostDetailItem | undefined>();

  useEffect(() => {
    if (selectedDetail) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selectedDetail]);

  return (
    <div className='project-financials-wrapper'>
      {selectedDetail ? (
        <div>
          <div onClick={() => setSelectedDetail(undefined)} className='back-to-dashboard'>
            <ArrowLeftOutlined />
            <span>Back to financial dashboard</span>
          </div>
          <p className='title'>{selectedDetail.section_category}</p>
          <DetailOverview setSelectedDetail={setSelectedDetail} selectedDetail={selectedDetail} />
          {selectedDetail.cost_details_payments_type === 'payments' ? (
            <PaymentsSection selectedItem={selectedDetail} />
          ) : (
            <StagePaymentsSection selectedItem={selectedDetail} />
          )}
          <PaymentsVariationsSection selectedItem={selectedDetail} />
        </div>
      ) : (
        <>
          <p className='title'>Project funding details</p>
          <p className='description'>
            This section allows to monitor financial sources and allocations, ensuring transparency and effective budget
            tracking.
          </p>
          <FundingSection />
          <p className='title space-up'>Cost details</p>
          <p className='description'>
            Please find here a detailed breakdown of the project&apos;s expenses, enabling to control costs, manage
            contingencies, and oversee the financial health of the project in real-time, supporting cost-efficiency and
            accountability.
          </p>
          <CostDetailsSections onEditClickHandler={(value) => setSelectedDetail(value)} />
        </>
      )}
    </div>
  );
};

export default ProjectFinancials;
