import React, { useState } from 'react';
import { Button } from '@ui-modules';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { message, Spin } from 'antd';
import { useMutation, useQuery } from 'react-query';
import UpdateForm from '@digital-office/pages/ProjectsManagementPages/components/UpdateForm';
import UpdatesList from '@digital-office/pages/ProjectsManagementPages/components/UpdatesList';

import { useRepository } from '@context/repository.context';
import { IProjectUpdatePayload, ProjectUpdatesListResponse } from '@digital-office/common/interfaces';

import './styles.scss';

const ProjectUpdates = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { id: projectId } = useParams<{ id: string }>();
  const facilityId = +localStorage.getItem('facility')!;

  const { engineeringRepository } = useRepository();

  const {
    data: projectUpdates,
    refetch,
    isLoading,
  } = useQuery<ProjectUpdatesListResponse>(
    `project-updates-${projectId}`,
    () => engineeringRepository.getProjectUpdates(facilityId, projectId || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(projectId),
    }
  );

  const postUpdates = useMutation(
    (data: IProjectUpdatePayload) => engineeringRepository.postProjectUpdate(facilityId, projectId || '', data),
    {
      onSuccess: () => {
        message.success('Update has been added');
        refetch();
        setIsEditMode(false);
      },
      onError: (error: any) => {
        const errorKeys = Object.keys(error.response.data);
        const result = errorKeys
          .map((errorKey) => {
            return `${errorKey}: ${error.response.data[errorKey]}`;
          })
          .join(', ');
        message.error(result);
      },
    }
  );

  return (
    <div className='project-updates-wrapper'>
      <div className='section-header'>
        <h3>Project Status updates</h3>
        {!isEditMode && (
          <Button
            onClick={() => setIsEditMode(true)}
            icon={<CloudDownloadOutlined />}
            variant='submit'
            disabled={Boolean(!projectUpdates?.options)}
            text='Post new update'
          />
        )}
      </div>
      <div className='hbh-details-item status-list-wrapper'>
        <Spin className='spinner' spinning={isLoading}>
          {!isEditMode && !isLoading
            ? projectUpdates && (
                <UpdatesList
                  emptyListMessage='No updates have been published, click on "Post new update" to create the first one'
                  wrapperBox={false}
                  updatesList={projectUpdates?.results}
                />
              )
            : projectUpdates && (
                <UpdateForm options={projectUpdates?.options} mutation={postUpdates} setIsEditMode={setIsEditMode} />
              )}
        </Spin>
      </div>
    </div>
  );
};

export default ProjectUpdates;
