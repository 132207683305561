import React, { FC } from 'react';
import './styles.scss';

interface IProgressBar {
  value: number;
}

const ProgressBar: FC<IProgressBar> = ({ value }) => {
  return (
    <div className='progress-bar'>
      <div style={{ width: `${value}%` }} className='progress-indicator' />
      <span className='progress-value'>{value}%</span>
    </div>
  );
};

export default ProgressBar;
