enum VideoLinks {
  PROJECT_CREATION = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/EdW-9sWxXf9FmRJUAk8WoN0B1yUsKi1_TEPn1DckjD1suA?e=It3Rlh',
  PROJECT_LIST = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/EdW-9sWxXf9FmRJUAk8WoN0B1yUsKi1_TEPn1DckjD1suA?e=It3Rlh',
  FUNDING_AND_COST = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/ETzIKtRmhqpNlBuoDg6GBIcBq-vODhRFmYZOz5Gwy83xpA?e=BCnaNK',
  PROJECT_REPOSITORY = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/EQw7_92E2tNAkacSfdW4mhgBbE3rlYnqB56FpyEEL1R_lA?e=8BvDCx',
  RISK_REGISTER = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/Ed0ZqaV7TDdPtQCz3oOTPvwB48BdRBUuDe72jN-GR6WiWQ?e=0pTCzE',
  SHOWCASE = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/ETAiNxJB_D5HtqtCUnzxdOIBmbJNaSGCPoyKS1Fzui9exA?e=OY42oC',
  CUSTOMER_AREA = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/EbtN5C-vfNRHrt-tVR9J-p0BBBl68ql_vnALjg3tk0GzqQ?e=2v7MeA',
  SERVICE_REQUEST = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/EdNm091CeBxAu269TCQ64oQBtxg-e1q0qaATRHnMyWXV7w?e=opDapK',
  COUNTRY_PAGE = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/EW5s4BkwCgpMnO9cjDBJTGIBi-79JZsb4oAlhgpJOSBJuw?e=VoZLKr',
  GUIDANCE = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/EUh5yyOogE9OtK8_AkHyB8oBoVnANzBMm5NT3G47qIUMOA?e=wwQimS',
  DASHBOARD = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/Ea6WrOD5UP1Lu0VtPwq5KrABuVupY4VAOVmXHi1VFp2eKw?e=IYUCuH',
  FIELD_ASSESSMENT_REPORT = 'https://wfp.sharepoint.com/:v:/s/WFPEngineering/EdmkFuGO_9FNoasAAzqGBpIBsjGjtJgjaqwA-cm_X7vO4w?e=7xBxNk',
}

export default VideoLinks;
