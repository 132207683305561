import * as Yup from 'yup';
import { IDropdownOption } from '@ui-modules/types';
import { message } from 'antd';
import { UseMutationResult } from 'react-query';

import detailsConfig from '@digital-office/pages/FFADetailsPage/detailsConfig';
import { IAssessmentActivityDetails, IFFAUpdatePayload } from '@digital-office/common/interfaces';
import { endOfMonth, format } from 'date-fns';

const validationSchema = Yup.object().shape({
  activity: Yup.mixed().required('Required'),
  activity_type: Yup.object().required('Required'),
  partners: Yup.array().min(1, 'Required'),
  location: Yup.string().required('Required'),
  desired_start_date: Yup.mixed().required('Required'),
  estimated_budget: Yup.string().required('Required'),
});

const getEditableKeys = () => {
  const config = detailsConfig();
  const editableItems = config.flatMap((section) => section.items.filter((item) => item.editable));
  return editableItems.map((item) => item.edit_key);
};

const getInitialValues = (assessmentRequestDetails: IAssessmentActivityDetails) => {
  const editableKeys = getEditableKeys();
  if (!assessmentRequestDetails || !editableKeys) return {};

  const values = {
    ...assessmentRequestDetails['assessment_results'],
    ...assessmentRequestDetails['project_information'],
    ...assessmentRequestDetails['requestor_details'],
  };

  let result = {};
  editableKeys.forEach((editableKey) => {
    if (!editableKey) return false;
    result = {
      ...result,
      [editableKey]: values[editableKey],
    };
  });

  return result;
};

const parseModifiedFields = (modified: Record<any, any>) => {
  const result: IFFAUpdatePayload = {};
  const keys = Object.keys(modified);

  keys.map((key) => {
    if (key === 'activity_type' || key === 'activity') {
      result[key] = modified[key].value;
    } else if (key === 'desired_start_date') {
      result[key] = modified[key] ? format(endOfMonth(new Date(modified[key])), 'yyyy-MM-dd') : null;
    } else if (key === 'partners') {
      result[key] = modified[key].map((partner: IDropdownOption) => partner.value);
    } else {
      result[key] = modified[key];
    }
  });

  return result;
};

const focalPointHandler = (
  assessmentRequestDetails: IAssessmentActivityDetails,
  option: IDropdownOption,
  updateAssessment: UseMutationResult<any, unknown, any, unknown>
) => {
  if (
    option.value !== assessmentRequestDetails?.project_information.focal_point &&
    updateAssessment.status !== 'loading'
  ) {
    message.info('Request is processing...');
    updateAssessment.mutate({ focal_point: option.value });
  }
};

export { validationSchema, getEditableKeys, getInitialValues, parseModifiedFields, focalPointHandler };
