import React, { useState } from 'react';
import { Button, Dropdown, PageTitle } from '@ui-modules';
import TimeRangeFilter from '@digital-office/common/components/Dashboard/TimeRangeFilter';
import { IDropdownOption } from '@ui-modules/types';
import { TimeFilterValue } from '@digital-office/pages/DemandOverviewPage';
import { useQuery } from 'react-query';
import { ProjectFeedResponse } from '@digital-office/common/interfaces';
import { Spin } from 'antd';
import { useRepository } from '@context/repository.context';
import UpdatesList from '@digital-office/pages/ProjectsManagementPages/components/UpdatesList';
import { getTimeFilterObject } from '@digital-office/common/helpers/timeFilterParser';

const timeFilterOptions = [
  { label: 'This Year', value: 'current_year' },
  { label: 'Custom', value: 'custom' },
];

import './styles.scss';

const ProjectStatusFeedPage = () => {
  const [timeFilter, setTimeFilter] = useState<IDropdownOption | TimeFilterValue | null>(timeFilterOptions[0]);
  const [projectFilter, setProjectFilter] = useState<IDropdownOption | null>(null);
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';

  const { engineeringRepository } = useRepository();

  const { data: updates, isLoading } = useQuery<ProjectFeedResponse>(
    `project-feed-${timeFilter?.value} - ${projectFilter?.value}`,
    () =>
      engineeringRepository.getAllProjectUpdates(facilityId, {
        ...getTimeFilterObject(timeFilter),
        project: projectFilter?.value,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className='status-feed-wrapper'>
      <PageTitle title={`Project status feed - ${country}`} />
      <div className='booking-details-container hbh-container'>
        <div className='filter-dropdown-section'>
          <div>
            <label>Filter by posting date</label>
            <TimeRangeFilter
              timeFilterValue={timeFilter}
              setTimeFilter={setTimeFilter}
              timeFilterOptions={timeFilterOptions}
            />
          </div>
          <Dropdown
            placeholder='All'
            className='dropdown select'
            value={projectFilter}
            onChange={(value) => setProjectFilter(value)}
            label='Filter by project'
            isDisabled={!updates}
            options={updates?.options.projects}
          />
          <Button
            onClick={() => {
              setProjectFilter(null);
              setTimeFilter(null);
            }}
            text='Clear'
            className='clear-button'
          />
        </div>
        <Spin spinning={isLoading}>
          {updates && (
            <UpdatesList
              emptyListMessage='No updates have been published'
              showProjectTitle={true}
              showProjectId={true}
              wrapperBox={true}
              updatesList={updates?.results}
            />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default ProjectStatusFeedPage;
