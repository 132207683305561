import { cloneDeep, find, isEqual } from 'lodash';
import {
  EditableFields,
  IDropdownResponse,
  IHistory,
  ILocationOptions,
  IServiceRequestDetails,
  IUserDetails,
} from '@digital-office/common/interfaces';
import { IDropdownOption } from '@ui-modules/types';
import * as Yup from 'yup';
import { DateField, Dropdown, TextField } from '@ui-modules/informed';
import React, { MouseEventHandler } from 'react';
import { components, ControlProps, OptionProps } from 'react-select';
import { Modal, Spin, Table, Tooltip } from 'antd';
import { Button } from '@ui-modules';
import { addDays, format, parse, startOfMonth } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

type HistoryProps = {
  isActive: boolean;
  onCancel: MouseEventHandler;
  dataSource: IHistory[];
  isLoading: boolean;
  referenceCode: string;
};

type IFocalPointModalProps = {
  isActive: boolean;
  data: IDropdownOption;
  referenceCode?: string;
  onSubmitHandler: () => void;
  onCancel: () => void;
  isLoading: boolean;
};

const getDropdownStatusComponentProps = (showIndicator: boolean, status: string) => {
  const Control = ({ children, ...props }: ControlProps) => {
    return (
      <components.Control className={status} {...props}>
        {children}
      </components.Control>
    );
  };

  const Option = ({ children, isDisabled, ...props }: OptionProps) => {
    return (
      <components.Option data-testid='option' isDisabled={isDisabled} {...props}>
        {isDisabled ? (
          <div>
            <Tooltip
              placement='left'
              title='To change status assign a Focal point to the request or set it to "Cancelled"'
            >
              {children}
            </Tooltip>
          </div>
        ) : (
          children
        )}
      </components.Option>
    );
  };

  if (showIndicator) {
    return {
      Control,
      Option,
      IndicatorSeparator: () => null,
      IndicatorsContainer: () => null,
    };
  }
  return { Control, Option };
};

const focalPointDropdownCustomStyles = {
  control: () => ({
    color: '#fff',
    display: 'inline-flex',
    borderRadius: '3px !important',
    width: '100%',
    marginTop: 15,
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: '#fff',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: '#fff',
    fontSize: 14,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    svg: {
      fill: '#fff !important',
    },
  }),
};

const statusDropdownCustomStyles = {
  control: () => ({
    color: 'white',
    display: 'inline-flex',
    border: 'none !important',
    borderRadius: '3px !important',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: 'white',
    margin: '0 20px',
    fontSize: 14,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    svg: {
      fill: '#fff !important',
    },
  }),
};

const retrieveDropdownInitialValue = (record: string, key: string, source: IDropdownOption[]): any => {
  return find(source, { [key]: record });
};

const getInputComponent = function (record: string | undefined, isEditMode: boolean, key: string) {
  return isEditMode ? <TextField name={key} initialValue={record} disabled={!isEditMode} /> : record;
};

// Components

const History = ({ isActive, onCancel, dataSource, isLoading, referenceCode }: HistoryProps) => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'history_date',
      key: 'history_date',
      render: (item: string) => format(new Date(item), DateFormatEnum.DD_MM_YYYY),
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (item: IUserDetails) => `${item?.first_name} ${item?.last_name}`,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (item: string) => item?.split(';').map((action: string) => <p key={action}>{action}</p>),
    },
  ];

  return (
    <Modal
      footer={null}
      title={
        <div className={'modal-title'}>
          <p>History changes for #{referenceCode}</p>
        </div>
      }
      width={'60%'}
      centered
      visible={isActive}
      onCancel={onCancel}
    >
      <Spin spinning={isLoading || !dataSource}>
        <Table
          rowKey={(record) => record.history_date}
          className='history-table'
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </Spin>
    </Modal>
  );
};

const FocalPointModal = ({
  isActive,
  isLoading,
  onCancel,
  referenceCode,
  data,
  onSubmitHandler,
}: IFocalPointModalProps) => {
  return (
    <Modal
      footer={null}
      title={
        <div className={'modal-title'}>
          <p>Change Engineering Focal point {referenceCode ? `for ${referenceCode}` : ''}</p>
        </div>
      }
      width={'60%'}
      centered
      visible={isActive}
      closable={!isLoading}
      onCancel={!isLoading ? onCancel : () => {}}
    >
      <div className='focal-point-wrapper'>
        <div className='description'>
          <p className='assign-info'>
            Do you want to assign <span className='bold'>{data?.label}</span> as a focal point for request
            {referenceCode ? ` ${referenceCode}` : ''}?
          </p>
          <p className='notification-info'>
            <span className='bold'>{data?.label}</span> will be notified via email
          </p>
        </div>
        <div className='buttons-container'>
          <Button disabled={isLoading} onClick={onSubmitHandler} variant='submit' text='CONFIRM AND NOTIFY' />
          <Button disabled={isLoading} onClick={onCancel} variant='danger' text='CANCEL' />
        </div>
      </div>
    </Modal>
  );
};

const getDateComponent = (record: string, isEditMode: boolean, key: string, dateFormat?: string) => {
  return (
    <DateField
      disabled={!isEditMode}
      defaultValue={record ? parse(record, DateFormatEnum.YYYY_MM_DD, new Date()) : undefined}
      name={key}
      dateFormat={dateFormat ?? DateFormatEnum.YYYY_MM_DD}
      minDate={new Date()}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    />
  );
};

function instanceOfEditableFields(key: any): key is keyof EditableFields {
  return true;
}

const getDropdownComponent = (
  record: string,
  isEditMode: boolean,
  key: keyof EditableFields | keyof IServiceRequestDetails,
  options: IDropdownOption[],
  isMulti?: boolean,
  defaultValue?: IDropdownOption | string[],
  editedFields?: EditableFields
) => {
  let formValue: (IDropdownOption | undefined)[] | IDropdownOption | undefined;

  if (editedFields && instanceOfEditableFields(editedFields)) {
    if (Array.isArray(editedFields[key as keyof EditableFields])) {
      formValue = editedFields.services?.map((valueElement: string): IDropdownOption | undefined => {
        return options?.find((option: IDropdownOption) => option['value'] === valueElement);
      });
    } else {
      formValue = options?.find((option) => option['value'] === editedFields[key as keyof EditableFields]);
    }
  }

  return isEditMode ? (
    // @ts-ignore
    <Dropdown
      storageValue='value'
      defaultValue={defaultValue ? defaultValue : retrieveDropdownInitialValue(record, 'label', options)?.value}
      name={key}
      options={options}
      isMulti={isMulti}
      value={formValue}
    />
  ) : (
    record
  );
};

// Initial value parsers

const getParsedServices = (services: IDropdownResponse[]): IDropdownOption[] => {
  return services?.map((service) => {
    return {
      label: service.name,
      value: service.uuid,
    };
  });
};

const getParsedLocations = (
  locations: ILocationOptions[]
): { distance_required: boolean | undefined; label: string; value: string }[] => {
  return locations?.map((location) => {
    return {
      label: location.name,
      value: location.uuid,
      distance_required: location.distance_required,
    };
  });
};

const ownedOptions: IDropdownOption[] = [
  { value: 'owned', label: 'Owned' },
  { value: 'rented', label: 'Rented' },
];

// Form parser

const _parseDateToDayUnits = (date: string) => {
  // for backend purposes
  const currentMonth = format(new Date(), 'MM/yyyy');

  if (date === currentMonth) {
    return format(addDays(new Date(), 1), DateFormatEnum.YYYY_MM_DD);
  } else {
    const parsedDate = parse(`10/${date}`, DateFormatEnum.DD_MM_YYYY, new Date());
    return format(startOfMonth(parsedDate), DateFormatEnum.YYYY_MM_DD);
  }
};

const convertFromValuesToRequestBody = (
  values: any,
  modified: EditableFields,
  serviceRequestDetails: IServiceRequestDetails
) => {
  const fields = cloneDeep(values);
  const result: EditableFields = {};

  //@todo: refactor

  if (fields['owned'] && modified['owned']) {
    const ownedValue = fields['owned'] === 'owned';
    if (ownedValue !== serviceRequestDetails['owned']) {
      result['owned'] = ownedValue;
    }
    delete fields['owned'];
    delete modified['owned'];
  }

  if (fields['desired_start_date']) {
    const date = format(new Date(fields.desired_start_date), DateFormatEnum.YYYY_MM_DD);
    if (date !== serviceRequestDetails['desired_start_date']) {
      result['desired_start_date'] = _parseDateToDayUnits(format(new Date(fields.desired_start_date), 'MM/yyyy'));
    }
    delete fields['desired_start_date'];
    delete modified['desired_start_date'];
  }

  if (fields['rental_expiration_date']) {
    const date = format(new Date(fields.rental_expiration_date), DateFormatEnum.YYYY_MM_DD);
    if (date !== serviceRequestDetails['rental_expiration_date']) {
      result['rental_expiration_date'] = date;
    }
    delete fields['rental_expiration_date'];
    delete modified['rental_expiration_date'];
  }

  if (fields['fund_expiration_date']) {
    const date = format(new Date(fields.fund_expiration_date), DateFormatEnum.YYYY_MM_DD);
    if (date !== serviceRequestDetails['fund_expiration_date']) {
      result['fund_expiration_date'] = date;
    }
    delete fields['fund_expiration_date'];
    delete modified['fund_expiration_date'];
  }

  if (fields['rental_expiration_date']) {
    const date = format(new Date(fields.rental_expiration_date), DateFormatEnum.YYYY_MM_DD);
    if (date !== serviceRequestDetails['rental_expiration_date']) {
      result['rental_expiration_date'] = date;
    }
    delete fields['rental_expiration_date'];
    delete modified['rental_expiration_date'];
  }

  if (fields['services']) {
    if (!isEqual(fields['services'], serviceRequestDetails.services)) {
      result['services'] = fields['services'];
    }
    delete fields['fund_expiration_date'];
  }

  const keys = Object.keys(modified);

  const modifiedValues = keys.reduce((accumulator, key: string): EditableFields => {
    if (values[key] && values[key] !== serviceRequestDetails[key as keyof EditableFields]) {
      return {
        ...accumulator,
        [key]: values[key],
      };
    } else return accumulator;
  }, {} as EditableFields);

  return { ...result, ...modifiedValues };
};

// Validation

const getValidationSchema = (locationTypes: ILocationOptions[]) =>
  Yup.object().shape({
    description: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    // location: Yup.string().required('Required'),
    desired_start_date: Yup.string().required('Required'),
    services: Yup.array().min(1, 'At least 1 service required'),
    planned_usage_years: Yup.number()
      .min(5, '5 is minimal value. If below leave that field empty.')
      .typeError('Must be a number'),
    distance: Yup.number().when(['location_type'], {
      is: (location_type: string): boolean =>
        find(getParsedLocations(locationTypes), { value: location_type })?.distance_required || false,
      then: Yup.number().required('Required').typeError('Must be a number'),
    }),
  });

export {
  convertFromValuesToRequestBody,
  getParsedServices,
  getParsedLocations,
  getValidationSchema,
  retrieveDropdownInitialValue,
  getDropdownComponent,
  getInputComponent,
  getDateComponent,
  getDropdownStatusComponentProps,
  ownedOptions,
  statusDropdownCustomStyles,
  focalPointDropdownCustomStyles,
  History,
  FocalPointModal,
};
