import React, { FC, useState } from 'react';
import { ReactComponent as MapMarker } from '@assets/svg/marker.svg';
import './styles.scss';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { Breadcrumb, message, Spin } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ICustomerAreaRequestList,
  ICustomerAreaServiceRequestListItem,
  IServiceRequestDetails,
  IServicesListResponse,
  ISwitchesResponse,
} from '@digital-office/common/interfaces';
import { format, parseISO } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

interface IDetailsComponent {
  label: string;
  content: string | JSX.Element;
}

const CustomerRequestDetails = () => {
  const { engineeringRepository } = useRepository();
  const { state } = useLocation();
  const { id: serviceRequestId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [requestsList, setRequestsList] = useState<ICustomerAreaServiceRequestListItem[] | undefined>();

  const getDetailsFacilityId = () => {
    if (state?.facility) {
      return state?.facility;
    } else {
      return requestsList?.find((element) => element?.uuid === serviceRequestId)?.facility_id_on_hbh;
    }
  };

  useQuery<ISwitchesResponse>('waffle-switch', () => engineeringRepository.getSwitches(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const storyPublishingSwitch = data?.results?.find((siteSwitch) => siteSwitch.name === 'customer_area');
      if (!storyPublishingSwitch?.active) {
        message.warning('Page is not available');
        navigate('/home');
      }
    },
  });

  useQuery<ICustomerAreaRequestList>(
    `custom-area-requests-list-details-page`,
    () => engineeringRepository.getCustomerAreaRequestsList(null),
    {
      onSuccess: (data) => {
        setRequestsList(data.results);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: services } = useQuery<IServicesListResponse>(
    `services`,
    () => engineeringRepository.getServiceRequestServices(getDetailsFacilityId()),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: serviceRequestDetails, isLoading } = useQuery<IServiceRequestDetails>(
    `customer-area-request-detail-${serviceRequestId}`,
    () => engineeringRepository.getServiceRequestDetails(getDetailsFacilityId(), serviceRequestId || ''),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(state?.facility) || Boolean(requestsList?.length),
      onError: (err: any) => {
        if (err.response.status === 404) {
          message.error('Request with such id was not found');
          navigate('/customer-requests/');
        }
      },
    }
  );

  const LocationComponent = () => {
    return (
      <div className='location-details'>
        {serviceRequestDetails?.location ? (
          <div className='location-element'>
            <MapMarker />
            <span>{serviceRequestDetails?.location}</span>
          </div>
        ) : serviceRequestDetails?.locations ? (
          serviceRequestDetails?.locations.map(({ name }, idx) => (
            <div className='location-element' key={idx}>
              <MapMarker />
              <span>{name}</span>
            </div>
          ))
        ) : null}
      </div>
    );
  };

  const DetailsComponent: FC<IDetailsComponent> = ({ label, content }) => {
    if (!serviceRequestDetails) return <span>-</span>;

    return (
      <div className='details-element'>
        <p className='details-label'>{label}</p>
        <p className='details-value'>{content}</p>
      </div>
    );
  };

  const ServicesList = () => {
    return (
      <ul className='dotted-list'>
        {serviceRequestDetails?.services.map((service) => (
          <li key={service}>{services?.results.find((listService) => listService.uuid === service)?.name || ''}</li>
        ))}
      </ul>
    );
  };

  const AttachmentsList = () => {
    if (!serviceRequestDetails?.attachments?.length) return <span>-</span>;

    return (
      <ul className='dotted-list'>
        {serviceRequestDetails?.attachments.map((item: string) => (
          <li className='attachments-list-element' key={item} onClick={() => window.open(item, '_blank')!.focus()}>
            {item.split('/').pop()}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className='customer-request-details-wrapper'>
      <div className='content'>
        <Breadcrumb
          items={[
            {
              title: (
                <p className='crumb-active' onClick={() => navigate('/customer-area')}>
                  Home
                </p>
              ),
            },
            {
              title: (
                <p className='crumb-active' onClick={() => navigate('/customer-requests/')}>
                  My requests
                </p>
              ),
            },
            { title: <p>Request details</p> },
          ]}
        />
        <Spin spinning={isLoading || !serviceRequestDetails}>
          <p className='request-title'>Request n.{serviceRequestDetails?.reference_code}</p>
          <div className='request-details-content'>
            <p className='details-title'>Requestor details</p>
            <div className='details-list-wrapper'>
              <DetailsComponent label='Name' content={serviceRequestDetails?.user?.first_name || ''} />
              <DetailsComponent label='Surname' content={serviceRequestDetails?.user?.last_name || ''} />
              <DetailsComponent label='Organisation' content={serviceRequestDetails?.user?.organisation || ''} />
              <DetailsComponent label='Email' content={serviceRequestDetails?.user?.email || ''} />
              <DetailsComponent label='Phone Number' content={serviceRequestDetails?.phone_number || ''} />
            </div>
          </div>
          <div className='request-details-content'>
            <p className='details-title'>Project information</p>
            <div className='details-list-wrapper'>
              <DetailsComponent
                label='Infrastructure category'
                content={serviceRequestDetails?.infrastructure_category_name || ''}
              />
              <DetailsComponent label='Infrastructure' content={serviceRequestDetails?.infrastructure_name || ''} />
              <DetailsComponent label='Project location(s)' content={<LocationComponent />} />
              <DetailsComponent
                label='Desired start date'
                content={
                  serviceRequestDetails?.desired_start_date
                    ? format(parseISO(serviceRequestDetails?.desired_start_date), DateFormatEnum['MM/YYYY'])
                    : ''
                }
              />
              <DetailsComponent
                label='Project estimate budget (USD)'
                content={
                  serviceRequestDetails
                    ? Number(serviceRequestDetails?.estimated_budget).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })
                    : ''
                }
              />
              <DetailsComponent
                label='Fund expiration date'
                content={
                  serviceRequestDetails?.fund_expiration_date
                    ? format(parseISO(serviceRequestDetails?.fund_expiration_date), DateFormatEnum.DD_MM_YYYY)
                    : ''
                }
              />
              <DetailsComponent label='Engineering Services needed for the project' content={<ServicesList />} />
              <DetailsComponent label='Project title' content={serviceRequestDetails?.title || ''} />
              <DetailsComponent label='Project descriptions' content={serviceRequestDetails?.description || ''} />
            </div>
          </div>
          <div className='request-details-content'>
            <p className='details-title'>Additional information</p>
            <div className='details-list-wrapper'>
              <DetailsComponent
                label='New construction'
                content={serviceRequestDetails ? (serviceRequestDetails?.new_construction ? 'Yes' : 'No') : ''}
              />
              <DetailsComponent
                label='Rehabilitation existing buildings'
                content={
                  serviceRequestDetails ? (serviceRequestDetails?.rehabilitation_existing_building ? 'Yes' : 'No') : ''
                }
              />
              <DetailsComponent
                label='Is the land/building owned or rented?'
                content={serviceRequestDetails ? (serviceRequestDetails?.owned ? 'Owned' : 'Rented') : ''}
              />
              <DetailsComponent
                label='Rental expiration date'
                content={
                  serviceRequestDetails?.rental_expiration_date
                    ? format(parseISO(serviceRequestDetails?.rental_expiration_date), DateFormatEnum.DD_MM_YYYY)
                    : '-'
                }
              />
              <DetailsComponent
                label='Planned usage years (optional)'
                content={
                  serviceRequestDetails?.planned_usage_years ? String(serviceRequestDetails?.planned_usage_years) : '-'
                }
              />
              <DetailsComponent label='Upload attachments' content={<AttachmentsList />} />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default CustomerRequestDetails;
