import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useRepository } from '@context/repository.context';
import LoadingPage from '@components/LoadingPage';
import { ReactComponent as ArrowIcon } from '@assets/svg/icon_arrow_round.svg';
import { ReactComponent as InProgressIcon } from '@assets/svg/in_progress.svg';
import { ReactComponent as PlannedIcon } from '@assets/svg/planned.svg';
import { ReactComponent as CompletedIcon } from '@assets/svg/completed.svg';
import { ReactComponent as MapIcon } from '@assets/svg/location_map.svg';
import { ReactComponent as PartnersIcon } from '@assets/svg/partners.svg';
import { IProjectStoryResponse } from '../../interfaces/Common';
import './styles.scss';
import { Image } from 'antd';

const statusData = {
  Planned: {
    icon: <PlannedIcon className='icon' />,
  },
  Completed: {
    icon: <CompletedIcon className='icon' />,
  },
  ['In Progress']: {
    icon: <InProgressIcon className='icon' />,
  },
};

const getProjectDetailsStats = (projectStory: IProjectStoryResponse) => {
  const stats = [
    {
      icon: (
        <img
          alt='infrastructure_icon'
          src={projectStory?.infrastructure_category_icon}
          className='infrastructure-icon icon'
        />
      ),
      title: projectStory?.infrastructure_category_name,
      label: 'Infrastructure category',
    },
    {
      icon: <MapIcon className='icon' />,
      title: projectStory?.city_name,
      label: 'Location',
    },
    {
      icon: projectStory ? statusData[projectStory.status_name]?.icon : null,
      title: projectStory?.status_name,
      label: 'Project status',
    },
    ...(projectStory?.partners_involved
      ? [
          {
            icon: <PartnersIcon className='icon' />,
            title: projectStory?.partners_involved,
            label: 'Main partners',
          },
        ]
      : ([] as any)),
  ];

  return stats.map((projectStats) => {
    return (
      <div key={projectStats.label} className='stats-element'>
        <div>{projectStats.icon}</div>
        <div>
          <p>{projectStats.title}</p>
          <p className='stats-label'>{projectStats.label}</p>
        </div>
      </div>
    );
  });
};

const GlobalImpactDetails = () => {
  const { id: projectId } = useParams<{ id: string }>();
  const { engineeringRepository } = useRepository();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: projectStory, isLoading } = useQuery<IProjectStoryResponse>(
    `impact-details-${projectId}`,
    () => engineeringRepository.getProjectStory(projectId || ''),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: Boolean(projectId),
      onError: () => {
        navigate('/forbidden');
      },
    }
  );

  if (isLoading)
    return (
      <div className='story-container'>
        <LoadingPage />
      </div>
    );

  const redirectButtonHandler = () => {
    if (state?.source === 'country-overview') {
      return navigate(`/country-overview/${state?.country_overview_id}`);
    } else {
      return navigate('/global-impact', { state: { persistedProjectId: projectId } });
    }
  };

  return (
    <div className='site-container'>
      <div className='story-container'>
        <p className='story-label'>
          Global Impact / {projectStory?.country_name ? <span>{projectStory?.country_name} / </span> : null}{' '}
          <span>Project Detail</span>
        </p>
        <div style={{ backgroundImage: `url(${projectStory?.main_picture})` }} className='story-cover'>
          <div onClick={redirectButtonHandler} className='map-button'>
            <ArrowIcon />
            <p className='button-label desktop'>
              BACK TO {state?.source === 'country-overview' ? 'COUNTRY PAGE' : 'MAP'}
            </p>
            <p className='button-label mobile'>BACK</p>
          </div>
        </div>
        <div className='details-container'>
          <div className='content'>
            <div className='title'>
              <span>{`${projectStory?.title} - ${projectStory?.city_name}`}</span>
            </div>
            <div className='overview'>
              <p className='overview-label'>Background</p>
            </div>
            <div>
              <p className='description' dangerouslySetInnerHTML={{ __html: projectStory?.snapshot || '' }} />
            </div>
            <div className='overview'>
              <p className='overview-label'>INTERVENTION</p>
            </div>
            <div>
              <p className='description' dangerouslySetInnerHTML={{ __html: projectStory?.full_description || '' }} />
            </div>
            <div className='overview'>
              <p className='overview-label'>OUTCOME</p>
            </div>
            <div>
              <p className='description' dangerouslySetInnerHTML={{ __html: projectStory?.outcome || '' }} />
            </div>
          </div>
          <div className='project-overview'>
            <p>Project overview</p>
            <div className='project-stats-wrapper'>{projectStory && getProjectDetailsStats(projectStory)}</div>
            {projectStory?.pictures.length ? (
              <div className='pictures'>
                <p className='pictures-label'>Additional project pictures:</p>
                <Image.PreviewGroup
                  previewPrefixCls='global-impact-preview'
                  items={projectStory?.pictures.map((pic) => pic.picture)}
                >
                  <Image src={projectStory?.pictures[0]?.picture} />
                </Image.PreviewGroup>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalImpactDetails;
