import React, { FC } from 'react';
import { Image } from 'antd';
import { IProjectUpdateEntry } from '@digital-office/common/interfaces';

import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

interface IUpdateFormProps {
  updatesList: IProjectUpdateEntry[];
  wrapperBox?: boolean;
  showProjectId?: boolean;
  showProjectTitle?: boolean;
  emptyListMessage: string;
}

const UpdatesList: FC<IUpdateFormProps> = ({
  updatesList,
  emptyListMessage,
  wrapperBox,
  showProjectId = false,
  showProjectTitle = false,
}) => {
  if (updatesList.length === 0) {
    return <p className='empty-list-message'>{emptyListMessage}</p>;
  }

  const navigate = useNavigate();

  return (
    <div className={`${wrapperBox ? 'hbh-details-container update-box-container' : ''}`}>
      {updatesList.map((status, index: number) => {
        return (
          <div className={`update-item-container ${wrapperBox ? 'no-border hbh-details-item' : ''}`} key={index}>
            {!wrapperBox && <div className='dot' />}
            <div className='text-info-wrapper'>
              <div className='status-header'>
                {format(new Date(status.created), DateFormatEnum.DD_MM_YYYY)} - {status.created_by}
                <p className='subtitle'>
                  {showProjectId && (
                    <>
                      <span
                        onClick={() => navigate(`/digital-office/projects/${status.project_uuid}`)}
                        className='project-id'
                      >
                        {status.project_code}
                      </span>{' '}
                    </>
                  )}
                  {showProjectTitle ? `- ${status.project_title}` : ''}{' '}
                </p>
              </div>
              <div className='related-info'>
                <div>
                  Related to infrastructure(s): <span className='related-value'>{status.infrastructure}</span>
                </div>
                <div>
                  Phase: <span className='related-value'>{status.phase || 'None'}</span>
                </div>
              </div>
              <p className='title'>{status.title}</p>
              <p className='description'>{status.details}</p>
            </div>
            <div className='image-container'>
              <Image.PreviewGroup>
                {status.pictures.map((image) => (
                  <Image key={image.picture} className='image' src={image.picture} />
                ))}
              </Image.PreviewGroup>
            </div>
            {index !== updatesList.length - 1 && !wrapperBox && <hr className='hr' />}
          </div>
        );
      })}
    </div>
  );
};

export default UpdatesList;
