import React, { FC } from 'react';
import { FormApi, Radio, RadioGroup } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import * as Yup from 'yup';
import { IAssessmentFormData } from '@digital-office/pages/FieldAssessmentReports/components/AssessmentChecklistStep';

interface IAssessmentConductedList {
  name: string;
  values: IAssessmentFormData;
  formRef: React.RefObject<FormApi>;
}

const getAssessmentWastewaterListYupValidation = () => {
  return Yup.object().shape({
    flush_toilet_pit_latrine_construction: Yup.string().required('This field is required'),
    construction_of_showers: Yup.string().required('This field is required'),
    construction_rehabilitation_of_manholes_and_gully_traps: Yup.string().required('This field is required'),
    septic_tank_soakage_pit_construction_rehabilitation: Yup.string().required('This field is required'),
    flush_tank_urinals_piping_accessories: Yup.string().required('This field is required'),
  });
};

const AssessmentWastewaterList: FC<IAssessmentConductedList> = ({ formRef }) => {
  return (
    <div className='assessment-details-list-container'>
      <div className='table-header'>
        <div className='headers'>
          <span>Yes</span>
          <span>No</span>
        </div>
      </div>
      <InformedField
        name='data[wastewater_sanitation_works_required][flush_toilet_pit_latrine_construction]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Construction of flush toilet or pit latrine?</span>
            <RadioGroup
              className='radio'
              name='data[wastewater_sanitation_works_required][flush_toilet_pit_latrine_construction]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[wastewater_sanitation_works_required][construction_of_showers]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Construction of showers?</span>
            <RadioGroup className='radio' name='data[wastewater_sanitation_works_required][construction_of_showers]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[wastewater_sanitation_works_required][construction_rehabilitation_of_manholes_and_gully_traps]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Construction or rehabilitation of manholes & gully traps?</span>
            <RadioGroup
              className='radio'
              name='data[wastewater_sanitation_works_required][construction_rehabilitation_of_manholes_and_gully_traps]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[wastewater_sanitation_works_required][septic_tank_soakage_pit_construction_rehabilitation]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Construction or rehabilitation of septic tank & soakage pit?</span>
            <RadioGroup
              className='radio'
              name='data[wastewater_sanitation_works_required][septic_tank_soakage_pit_construction_rehabilitation]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[wastewater_sanitation_works_required][flush_tank_urinals_piping_accessories]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Flush tank, urinals, piping & related accessories?</span>
            <RadioGroup
              className='radio'
              name='data[wastewater_sanitation_works_required][flush_tank_urinals_piping_accessories]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
    </div>
  );
};

export { getAssessmentWastewaterListYupValidation, AssessmentWastewaterList };
