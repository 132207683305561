import React, { useState } from 'react';
import { PageTitle, Dropdown } from '@ui-modules';
import { IDropdownOption } from '@ui-modules/types';
import { TimeFilterValue } from '@digital-office/pages/DemandOverviewPage';
import { ReactComponent as CoFilterIcon } from '@assets/svg/co_filter.svg';
import TimeRangeFilter from '@digital-office/common/components/Dashboard/TimeRangeFilter';
import { components, ControlProps } from 'react-select';
import { CountryMap, DoughnutChart, PhasesTable, SummaryTiles } from '@digital-office/common/components';
import { getChartProps } from '@digital-office/common/components/Dashboard/DoughnutChart';
import { useRepository } from '@context/repository.context';
import { useQuery } from 'react-query';
import { IFFADashboardResponse } from '@digital-office/common/interfaces';
import { Spin } from 'antd';
import {
  customTooltip,
  mapActivitiesToTable,
  mapComplexityToChartData,
  mapCountriesData,
} from '@digital-office/pages/FFADashboardPage/utils';

import './styles.scss';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';
import VideoLink from '@digital-office/common/components/VideoLink';
import videoLinks from '@digital-office/common/enums/videoLinks';

const timeFilterOptions = [
  { label: 'This Year', value: 'current_year' },
  { label: 'Custom', value: 'custom' },
];

const FFADashboardPage = () => {
  const [timeFilter, setTimeFilter] = useState<IDropdownOption | TimeFilterValue | null>(timeFilterOptions[0]);
  const [countryFilter, setCountryFilter] = useState<IDropdownOption | null>(null);
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage?.getItem('country') || '';

  const getTimeFilter = () => {
    if (timeFilter?.value === 'current_year') {
      const currentYear = new Date().getFullYear();
      return {
        created_after: `${currentYear}-01-01`,
        created_before: `${currentYear}-12-31`,
      };
    } else {
      if (typeof timeFilter?.value === 'object') {
        const dateFrom = timeFilter?.value?.date_from ? startOfMonth(new Date(timeFilter.value.date_from)) : null;
        const dateTo = timeFilter?.value?.date_to ? endOfMonth(new Date(timeFilter.value.date_to)) : null;

        const formattedDateFrom = dateFrom ? format(dateFrom, DateFormatEnum.YYYY_MM_DD) : null;
        const formattedDateTo = dateTo ? format(dateTo, DateFormatEnum.YYYY_MM_DD) : null;

        return {
          created_after: formattedDateFrom,
          created_before: formattedDateTo,
        };
      }
    }
  };

  const { engineeringRepository } = useRepository();

  const { data: dashboardData, isLoading } = useQuery<IFFADashboardResponse>(
    `activity-dashboard-${countryFilter?.value} - ${timeFilter?.value}`,
    () => engineeringRepository.getActivityDashboard(facilityId, { country: countryFilter?.value, ...getTimeFilter() }),
    {
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const clearFilters = () => {
    setCountryFilter(null);
    setTimeFilter(timeFilterOptions[0]);
  };

  const getControl = (label: string, Icon: any) => {
    const Control = ({ children, ...props }: ControlProps) => (
      <components.Control className='control-wrapper' {...props}>
        <Icon className='control-icon' /> <span>{label}: </span>
        {children}
      </components.Control>
    );

    return Control;
  };

  const summaryTilesData = [
    {
      label: 'Total Usage',
      value: Number(dashboardData?.total_usage || '').toLocaleString('en-US', {
        minimumFractionDigits: 0,
      }),
    },
    {
      label: 'Guidance sent via email',
      value: Number(dashboardData?.guidance_sent_via_email || '').toLocaleString('en-US', {
        minimumFractionDigits: 0,
      }),
    },
    {
      label: 'Requests for Engineering support',
      value: (
        <div>
          <span>{dashboardData?.requests_for_engineering_support.percentage}% </span>
          <span>
            (
            {Number(dashboardData?.requests_for_engineering_support.value).toLocaleString('en-US', {
              minimumFractionDigits: 0,
            })}
            )
          </span>
        </div>
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <div className='dashboard-overview-wrapper'>
        <div className='demand-dashboard-page-title-wrapper'>
          <PageTitle
            className='title'
            title={`Community asset creation - Engineering Guidance tool usage - ${country}`}
          />
          <VideoLink link={videoLinks.DASHBOARD} />
        </div>
        <div className='booking-details-container hbh-container'>
          <div className='filter-dropdown-section'>
            <TimeRangeFilter
              timeFilterValue={timeFilter}
              setTimeFilter={setTimeFilter}
              timeFilterOptions={timeFilterOptions}
            />
            {dashboardData && dashboardData.is_global && (
              <Dropdown
                className='dropdown'
                components={{ Control: getControl('Country filter', CoFilterIcon) }}
                placeholder='Select'
                onChange={setCountryFilter}
                options={dashboardData.options.countries}
              />
            )}
          </div>
          <span className='reset' onClick={clearFilters}>
            Reset filters
          </span>
          {dashboardData ? (
            <div>
              <div className='projects-stats-section'>
                <DoughnutChart
                  chartData={mapComplexityToChartData(dashboardData.activities_by_engineering_complexity)}
                  chartProps={
                    getChartProps(mapComplexityToChartData(dashboardData.activities_by_engineering_complexity)) || []
                  }
                  label='By Engineering Requirements'
                />
                <SummaryTiles data={summaryTilesData} />
              </div>
              <div className='widget-section'>
                {dashboardData.is_global && (
                  <div className='widget-wrapper'>
                    <CountryMap
                      customTooltip={(tooltip) => customTooltip(tooltip, dashboardData)}
                      label='Requests by country'
                      selectedCountries={mapCountriesData(dashboardData.countries)}
                    />
                  </div>
                )}
                <div className='table-widget'>
                  <PhasesTable
                    tableData={mapActivitiesToTable(dashboardData.activities_by_category)}
                    headers={['BREAKDOWN BY CATEGORY', '%']}
                  />
                </div>
              </div>
            </div>
          ) : (
            <p className='empty-data-label'>No data...</p>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default FFADashboardPage;
