import React from 'react';
import {
  IAssessmentProjectInformation,
  IAssessmentRequestorDetails,
  IAssessmentRiskCategory,
  IDetailsActivity,
  IFFARequestDetailsConfig,
} from '@digital-office/common/interfaces';
import { DateField, Dropdown, TextField } from '@ui-modules/informed';
import { FormApi } from 'informed';
import { IDropdownOption } from '@ui-modules/types';
import NumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import { format, parse, startOfMonth } from 'date-fns';
import { DateFormatEnum } from '@common/utils/constants';

const detailsConfig = (): IFFARequestDetailsConfig[] => [
  {
    section_title: '',
    items: [
      {
        key: 'project_information',
        label: 'CREATED AT',
        content: (record: IAssessmentProjectInformation) => format(new Date(record.created), DateFormatEnum.DD_MM_YYYY),
      },
      {
        key: 'project_information',
        label: 'NATURE OF REQUEST',
        content: (record: IAssessmentProjectInformation) => record.nature_of_request,
      },
      {
        key: 'project_information',
        label: 'ACTIVITY',
        editable: true,
        edit_key: 'activity',
        tooltip_full_width: true,
        content: (record: IAssessmentProjectInformation, isEditMode: boolean, source, formRef: FormApi) => {
          const activityTypeValue = formRef && (formRef.getValue('activity_type') as IDropdownOption);
          const options = activityTypeValue
            ? source.options.available_activity_types_with_activities[activityTypeValue?.value]
            : source.options.available_activity_types_with_activities[source.project_information.activity_type.value];
          if (isEditMode) {
            return <Dropdown name='activity' options={options} />;
          } else {
            return record.activity.label;
          }
        },
      },
      {
        key: 'project_information',
        edit_key: 'activity_type',
        editable: true,
        label: 'ACTIVITY TYPE',
        content: (record: IAssessmentProjectInformation, isEditMode: boolean, source, formRef: FormApi) => {
          if (isEditMode) {
            return (
              <Dropdown
                name='activity_type'
                options={source.options.activity_types}
                onChange={() => formRef.setValue('activity', null)}
              />
            );
          } else {
            return record.activity_type.label;
          }
        },
      },
      {
        key: 'project_information',
        label: 'PROJECT COUNTRY',
        content: (record: IAssessmentProjectInformation) => record.country,
      },
      {
        key: 'project_information',
        label: 'PROJECT LOCATION',
        edit_key: 'location',
        editable: true,
        content: (record: IAssessmentProjectInformation, isEditMode: boolean) => {
          if (isEditMode) {
            return <TextField name='location' />;
          } else {
            return record.location;
          }
        },
      },
      {
        key: 'project_information',
        label: 'PROJECT ESTIMATED BUDGET',
        editable: true,
        edit_key: 'estimated_budget',
        content: function (record: IAssessmentProjectInformation, isEditMode: boolean) {
          if (isEditMode) {
            return <NumericInput name='estimated_budget' />;
          } else {
            const fixedValue = Number(record.estimated_budget.toFixed(2)).toLocaleString('en-US', {
              minimumFractionDigits: 2,
            });
            return `${fixedValue} USD`;
          }
        },
      },
      {
        key: 'project_information',
        label: 'ESTIMATED START DATE',
        editable: true,
        edit_key: 'desired_start_date',
        content: function (record: IAssessmentProjectInformation, isEditMode: boolean, source, formRef: FormApi) {
          if (isEditMode) {
            const value = formRef && (formRef.getValue('desired_start_date') as string);
            const fallback = format(new Date(record.desired_start_date), 'MM/yyyy') as string;

            return (
              <DateField
                minDate={startOfMonth(new Date())}
                value={value ? (format(new Date(value), 'MM/yyyy') as string) : fallback}
                dateFormat='MM/yyyy'
                defaultValue={parse(fallback, 'MM/yyyy', new Date())}
                showMonthYearPicker
                name='desired_start_date'
              />
            );
          } else {
            return format(new Date(record.desired_start_date), 'MM/yyyy');
          }
        },
      },
    ],
  },
  {
    section_title: 'Requestor Details',
    items: [
      {
        key: 'requestor_details',
        label: 'NAME AND MIDDLE NAME',
        content: (record: IAssessmentRequestorDetails) => record?.first_name,
      },
      {
        key: 'requestor_details',
        label: 'SURNAME',
        content: (record: IAssessmentRequestorDetails) => record?.last_name,
      },
      {
        key: 'requestor_details',
        label: 'EMAIL',
        content: (record: IAssessmentRequestorDetails) => record?.email,
      },
      {
        key: 'requestor_details',
        label: 'ORGANISATION',
        content: (record: IAssessmentRequestorDetails) => record?.organisation,
      },
      {
        key: 'requestor_details',
        label: 'REQUESTING UNIT',
        content: (record: IAssessmentRequestorDetails) => record?.requesting_unit,
      },
    ],
  },
  {
    section_title: 'FFA information and results',
    items: [
      {
        key: 'assessment_results',
        label: 'RISK ASSESSMENT RESULT',
        content: (record: IAssessmentRiskCategory) =>
          record?.risk_category === 'RELEVANT' ? 'MEDIUM PLUS' : record?.risk_category,
      },
      {
        key: 'assessment_results',
        label: 'PRE-ASSESSMENT COMPLETED',
        content: (record: IAssessmentProjectInformation) => (record?.preliminary_assessment_completed ? 'YES' : 'NO'),
      },
      {
        key: 'assessment_results',
        edit_key: 'partners',
        label: 'POTENTIAL PARTNERS',
        className: 'grid-full-column',
        editable: true,
        content: (record: IAssessmentProjectInformation, isEditMode: boolean, source) => {
          const options = source.options.partners;
          if (isEditMode) {
            return <Dropdown isMulti={true} name='partners' options={options} />;
          } else {
            return (
              record?.partners?.map(
                (partner, index) => `${partner.label}${index !== record?.partners.length - 1 ? ', ' : ''}`
              ) || '-'
            );
          }
        },
      },
      {
        key: 'assessment_results',
        label: 'INFO TECH PROVIDED',
        tooltip_disabled: true,
        className: 'grid-full-column text-ellipsis',
        content: function (record: IDetailsActivity, isEditMode: boolean, source) {
          return record?.activity_link ? (
            <a target='_blank' className='link' href={record?.activity_link} rel='noreferrer'>
              {source?.project_information.activity.label}
            </a>
          ) : (
            '-'
          );
        },
      },
      {
        key: 'assessment_results',
        label: 'TYPE OF AGREEMENT / CONTRACTUAL CONDITIONS',
        tooltip_disabled: true,
        className: 'grid-full-column grid-full-height',
        content: (record: IAssessmentRiskCategory) => record?.conditions || '-',
      },
      {
        key: 'assessment_results',
        label: 'TECHNICAL DOCUMENTS',
        tooltip_disabled: true,
        className: 'grid-full-column grid-full-height',
        content: (record: IAssessmentRiskCategory) => record?.technical_documents || '-',
      },
      {
        key: 'assessment_results',
        label: 'REMARKS',
        tooltip_disabled: true,
        className: 'grid-full-column grid-full-height',
        content: (record: IAssessmentRiskCategory) => record?.additional_information || '-',
      },
    ],
  },
];

export default detailsConfig;
