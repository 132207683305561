import React, { Dispatch, FC, SetStateAction } from 'react';
import { ReactComponent as ReviewIcon } from '@assets/svg/review.svg';
import { ReactComponent as HotelIcon } from '@assets/svg/hotel.svg';
import { ReactComponent as UserIcon } from '@assets/svg/user.svg';
import { ReactComponent as ProjectIcon } from '@assets/svg/project.svg';
import NavigationStepper from '@digital-office/common/components/NavigationStepper/NavigationStepper';
import { useFormState, useMultistepApi } from 'informed';
import { FormTempValues, IYupSchema, yupSchemas } from '@pages/BookingFormPage/consts';

interface SideMenuProps {
  setYupSchema: Dispatch<SetStateAction<IYupSchema>>;
}

const SideMenu: FC<SideMenuProps> = ({ setYupSchema }) => {
  const { setCurrent, getCurrentStep } = useMultistepApi();

  const formState = useFormState();

  const currentStep = getCurrentStep();

  const { details, project, additional } = formState.values as unknown as FormTempValues;

  const onClick = (step: string) => {
    setCurrent(step);
    setYupSchema(yupSchemas[step]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const stepsConfig = [
    {
      label: 'Your details',
      icon: <UserIcon />,
      isCompleted: Boolean(details && details.phone_number && details.requesting_unit),
      isDisabled: false,
      onClick: () => onClick('details'),
      key: 'details',
    },
    {
      label: 'Project information',
      icon: <ProjectIcon />,
      isCompleted: Boolean(
        project &&
          project.description &&
          project.desired_start_date &&
          project.estimated_budget &&
          project.infrastructure &&
          project.infrastructure_category &&
          project.location &&
          project.services &&
          project.title
      ),
      isDisabled: !project && currentStep !== 'project',
      onClick: () => onClick('project'),
      key: 'project',
    },
    {
      label: 'Additional information',
      icon: <HotelIcon />,
      isCompleted: Boolean(
        additional &&
          additional.new_construction &&
          additional.own_status &&
          additional.rehabilitation_existing_building
      ),
      isDisabled: !additional && currentStep !== 'additional',
      onClick: () => onClick('additional'),
      key: 'additional',
    },
    {
      label: 'Review and submit',
      icon: <ReviewIcon />,
      isCompleted: false,
      isDisabled: !details || !project || !additional,
      onClick: () => onClick('review'),
      step: 'review',
    },
  ];

  const currentStepIdx = stepsConfig.findIndex((step) => step.key === currentStep);

  return <NavigationStepper disableIfBeforeCurrentStep={false} config={stepsConfig} currentStep={currentStepIdx} />;
};

export default SideMenu;
