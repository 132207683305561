import React, { FC } from 'react';
import { FormApi, Radio, RadioGroup } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import * as Yup from 'yup';
import { IAssessmentFormData } from '@digital-office/pages/FieldAssessmentReports/components/AssessmentChecklistStep';

interface IAssessmentConductedList {
  name: string;
  values: IAssessmentFormData;
  formRef: React.RefObject<FormApi>;
}

const getAssessmentElectricalListYupValidation = () => {
  return Yup.object().shape({
    construction_of_generator_shed: Yup.string().required('This field is required'),
    armoured_cables_distribution_board_accessories: Yup.string().required('This field is required'),
    cabling_lighting_solar_panels_etc: Yup.string().required('This field is required'),
    earthing: Yup.string().required('This field is required'),
    air_conditioners_water_heaters_exhaust_fans_etc: Yup.string().required('This field is required'),
  });
};

const AssessmentElectricalList: FC<IAssessmentConductedList> = ({ formRef }) => {
  return (
    <div className='assessment-details-list-container'>
      <div className='table-header'>
        <div className='headers'>
          <span>Yes</span>
          <span>No</span>
        </div>
      </div>
      <InformedField
        name='data[electrical_works_required][construction_of_generator_shed]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Construction of a generator shed?</span>
            <RadioGroup className='radio' name='data[electrical_works_required][construction_of_generator_shed]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[electrical_works_required][armoured_cables_distribution_board_accessories]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Armoured cables, distribution board (DB) & accessories?</span>
            <RadioGroup
              className='radio'
              name='data[electrical_works_required][armoured_cables_distribution_board_accessories]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />

      <InformedField
        name='data[electrical_works_required][cabling_lighting_solar_panels_etc]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Cabling, lighting & solar panels, etc.?</span>
            <RadioGroup className='radio' name='data[electrical_works_required][cabling_lighting_solar_panels_etc]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />

      <InformedField
        name='data[electrical_works_required][earthing]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Earthing?</span>
            <RadioGroup className='radio' name='data[electrical_works_required][earthing]'>
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
      <hr className='hr' />
      <InformedField
        name='data[electrical_works_required][air_conditioners_water_heaters_exhaust_fans_etc]'
        formRefApi={formRef}
        label=''
        informedComponent={
          <div className='list-element'>
            <span className='list-label'>Air conditioners, water heaters & exhaust fans etc.?</span>
            <RadioGroup
              className='radio'
              name='data[electrical_works_required][air_conditioners_water_heaters_exhaust_fans_etc]'
            >
              <div className='radio-element'>
                <Radio value='yes' label='' />
              </div>
              <div className='radio-element'>
                <Radio value='no' label='' />
              </div>
            </RadioGroup>
          </div>
        }
      />
    </div>
  );
};

export { getAssessmentElectricalListYupValidation, AssessmentElectricalList };
