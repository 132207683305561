import { ILocationObject } from '@digital-office/pages/ProjectsManagementPages/components/LocationModal';
import { IPhase } from '@digital-office/pages/ProjectsManagementPages/components/PhaseSection';
import { IOptions } from '@digital-office/pages/ProjectsManagementPages/ProjectConversionPage';
import { ReactComponent as InfoIcon } from '@assets/svg/InfoIcon.svg';
import { Dropdown, TextField } from '@ui-modules/informed';
import { FormApi, FormState, TextArea } from 'informed';
import InformedNumericInput from '@digital-office/pages/ProjectsManagementPages/components/NumericInput';
import { Tooltip } from 'antd';
import * as Yup from 'yup';
import { MAPBOX_API_KEY } from '@components/GlobalImpact/utils';
import React from 'react';
import { IDropdownOption } from '@ui-modules/types';

interface IPhasesPayload {
  start_date: string;
  end_date: string;
  uuid?: string;
  description: string;
  delete_phase?: boolean;
  delete_sp_folder?: boolean;
  number: number;
  deliverables: {
    name: string;
    is_template: boolean;
  }[];
}

interface IFormConfig {
  key: string;
  label: string;
  type: string;
  tooltip?: string;
  className?: string;
  required?: boolean;
  prefix?: string;
  icon?: JSX.Element;
  max?: number;
  children?: IFormConfig[];
  hideValueTooltip?: boolean;
  showTooltipOnDetails?: boolean;
  multiple?: boolean;
  componentClassName?: string;
  disabled?: boolean;
  displayFormatter?: (value: any) => JSX.Element | string;
}

const parseLocationsToPayload = (locations: ILocationObject[]) =>
  locations.map((location) => ({
    name: location.locationData.place_name,
    latitude: location.locationData.lat,
    longitude: location.locationData.long,
    country: location.locationData.country,
    project_infrastructures: [
      {
        infrastructure: location.infrastructure.value,
        description: location.description,
      },
    ],
  }));

const getMultipleValueLabel = (selectedItems: IDropdownOption[]) => {
  if (!selectedItems || selectedItems?.length === 0) {
    return 'Select';
  } else if (selectedItems.length === 1) {
    return selectedItems[0].label;
  } else {
    return `${selectedItems.length} items selected`;
  }
};

const parsePhasesToPayload = (source: IPhase[]) => {
  let phases: IPhasesPayload[] = [];
  source.forEach((phase, key) => {
    if (phase.uuid && phase.start_date && phase.end_date && phase.deliverables.length) {
      phases = [
        ...phases,
        {
          uuid: phase.uuid === 'new' ? undefined : phase.uuid,
          start_date: phase.start_date,
          end_date: phase.end_date,
          description: phase.title,
          ...(phase.uuid !== 'new' && {
            delete_phase: phase.delete_phase || false,
            delete_sp_folder: phase.delete_sp_folder || false,
          }),
          number: key,
          deliverables: phase.deliverables.map((deliverable) => ({
            name: deliverable.name,
            is_template: deliverable.is_template || false,
            finished: deliverable.finished || false,
          })),
        },
      ];
    }
  });

  return phases;
};

const mapDataToComponents = (
  isEditMode: boolean,
  formData: IFormConfig,
  options: IOptions,
  formValues: Record<string, any>,
  wrapper = true
) => {
  const {
    type,
    key,
    className,
    tooltip,
    required,
    prefix,
    icon,
    max,
    children,
    componentClassName,
    displayFormatter,
    hideValueTooltip,
    showTooltipOnDetails,
    disabled,
    multiple,
  } = formData;

  let component;

  switch (type) {
    case 'input':
      component = (
        <div data-testid={`field-${formData.key}`} className='input-wrapper'>
          {prefix && <div className='prefix'>{prefix}</div>}
          <TextField
            className={`${!prefix ? 'no-prefix' : ''} ${icon ? 'suffix' : ''} `}
            placeholder='Insert...'
            disabled={disabled}
            name={formData.key}
          />
          {icon && <div className='icon'>{icon}</div>}
        </div>
      );
      break;
    case 'textarea':
      component = (
        <div className='textarea-wrapper'>
          <TextArea
            style={{ width: '100%', resize: 'none', color: 'black' }}
            data-testid={`field-${formData.key}`}
            placeholder='Insert...'
            className={componentClassName}
            maxLength={max}
            disabled={disabled}
            aria-rowcount={20}
            name={formData.key}
          />
          {max && (
            <p className='counter'>
              {formValues[key]?.length || 0}/{max}
            </p>
          )}
        </div>
      );
      break;
    case 'numberInput':
      component = (
        <div data-testid={`field-${formData.key}`}>
          <InformedNumericInput disabled={disabled} name={key} />
        </div>
      );
      break;
    case 'dropdown':
      component = (
        <div data-testid={`field-${formData.key}`}>
          <Dropdown
            isMulti={multiple}
            isDisabled={disabled}
            name={key}
            hideSelectedOptions={!multiple}
            closeMenuOnSelect={!multiple}
            controlShouldRenderValue={!multiple}
            placeholder={getMultipleValueLabel(formValues[key])}
            options={options[key]}
          />
        </div>
      );
  }

  const getValue = () => {
    return (displayFormatter ? displayFormatter(formValues[key]) : formValues[key]) || '-';
  };

  const content = (
    <div>
      {tooltip && (isEditMode || showTooltipOnDetails) && (
        <Tooltip overlayClassName='conversion-page-tooltip' title={tooltip}>
          <InfoIcon className='details-tooltip' />
        </Tooltip>
      )}
      <div className='hbh-details-item-label'>
        <div className='item-label'>
          {`${formData.label} ${max && isEditMode ? `MAX - ${max} CHARACTERS` : ''}`}{' '}
          {required && isEditMode && <span className='required-label'>✱</span>}
        </div>
      </div>
      <div className='hbh-details-item-value'>
        {isEditMode ? (
          component
        ) : (
          <Tooltip overlayClassName='project-value-tooltip' title={!hideValueTooltip ? getValue : ''}>
            <p className='non-edit-value'>{getValue()}</p>
          </Tooltip>
        )}
      </div>
    </div>
  );

  return (
    <div key={key} className={`hbh-details-item ${className} ${!wrapper && 'no-border'}`}>
      {type === 'section' ? (
        <div>
          <div className='hbh-details-item-label'>
            <div className='item-label'>
              {formData.label} {required && <span className='required-label'>✱</span>}
            </div>
          </div>
          {children && children.map((child) => mapDataToComponents(isEditMode, child, options, formValues, false))}
        </div>
      ) : (
        content
      )}
    </div>
  );
};

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required('Required')
    .matches(/^[a-zA-Z0-9-]+$/, 'Letters and numbers only'),
  client: Yup.string().required('Required'),
  topics: Yup.array().min(1, 'Required'),
  engineering_project_manager: Yup.object().required('Required'),
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  contact_person: Yup.string().required('Required'),
  contact_phone_number: Yup.string()
    .required('Required')
    .matches(/^\+[1-9][0-9]{4,}$/, 'Phone number is not valid'),
  contact_email: Yup.string().required('Required').email('Invalid email'),
});

const updateForm = (
  formRef: React.RefObject<FormApi>,
  setFormData: React.Dispatch<React.SetStateAction<FormState | null>>
) => {
  const ref = formRef.current;
  if (ref) {
    setFormData(null);
    ref && ref.submitForm();
  }
};

const mapboxRootPath = 'https://api.mapbox.com/geocoding/v5/mapbox.places';

const getMapboxGeocodingPath = (params: string) => {
  return `${mapboxRootPath}/${params}.json?access_token=${MAPBOX_API_KEY}`;
};

export {
  parseLocationsToPayload,
  parsePhasesToPayload,
  validationSchema,
  getMapboxGeocodingPath,
  updateForm,
  mapDataToComponents,
};
