import React, { FC } from 'react';
import { Modal, ModalProps } from 'antd';
import './styles.scss';

interface IGuidanceModalProps extends ModalProps {
  children: React.ReactNode;
  open: boolean;
  closeHandler: () => void;
  isClosable?: boolean;
}

const GuidanceModal: FC<IGuidanceModalProps> = ({ children, open, closeHandler, isClosable = false, ...rest }) => {
  return (
    <Modal
      width='40%'
      onCancel={closeHandler}
      closable={isClosable}
      centered={true}
      className='guidance-modal'
      open={open}
      footer={null}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default GuidanceModal;
