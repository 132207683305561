import React, { FC, useEffect, useRef, useState } from 'react';
import { Form, FormApi, TextArea } from 'informed';
import { InformedField } from '@digital-office/pages/FieldAssessmentReports/components/ProjectOverviewStep';
import { UseMutationResult } from 'react-query';
import { Button } from '@ui-modules';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { MissionOutcomesAndRecommendations } from '@digital-office/pages/FieldAssessmentReports/utils/types';

interface IAssessmentMissionOutcomeStep {
  initialValues: MissionOutcomesAndRecommendations | null;
  updateMutation: UseMutationResult<any, unknown, any, unknown>;
  finalizeStepMutation: UseMutationResult<any, unknown, any, unknown>;
  formKey: number;
  onStepBackButton: () => void;
}

const validationSchemaSubmit = Yup.object().shape({
  outcomes: Yup.string().required('This field is required').max(1000, 'Max 1000 characters'),
  recommendations: Yup.string().required('This field is required').max(1000, 'Max 1000 characters'),
});

const validationSchemaDraft = Yup.object().shape({
  outcomes: Yup.string().max(1000, 'Max 1000 characters'),
  recommendations: Yup.string().max(1000, 'Max 1000 characters'),
});

const MissionOutcomeStep: FC<IAssessmentMissionOutcomeStep> = ({
  initialValues,
  formKey,
  finalizeStepMutation,
  updateMutation,
  onStepBackButton,
}) => {
  const formRef = useRef<FormApi>(null);
  const [updateType, setUpdateType] = useState<string | undefined>();
  const [, updateState] = useState<any>(null);

  useEffect(() => {
    if (updateType) {
      formRef?.current?.submitForm();
    }
  }, [updateType]);

  const onSubmitButtonClick = (type: string) => {
    if (type === updateType) {
      formRef?.current?.submitForm();
    } else {
      setUpdateType(type);
    }
  };

  const parseFormDataToPayload = (values: MissionOutcomesAndRecommendations) => {
    const payload = {
      outcomes: values?.outcomes || '',
      recommendations: values?.recommendations || '',
    };

    if (updateType === 'draft') {
      updateMutation.mutate({ current_step: 3, mission_outcomes_and_recommendations: payload });
    } else {
      finalizeStepMutation.mutate({ current_step: 3, mission_outcomes_and_recommendations: payload });
    }
  };

  const onStepBackClick = () => {
    const modifiedField = formRef?.current?.getFormState().modified;
    if (isEmpty(modifiedField)) {
      onStepBackButton();
    } else {
      const confirmAlert = confirm(
        'You have unsaved changes. Saves will not be saved. Are you sure you want to leave?'
      );

      if (confirmAlert) {
        onStepBackButton();
      }
    }
  };

  return (
    <div className='assessment-mission-outcome-step-container'>
      <Form
        key={`$${formKey}`}
        onSubmit={({ values }) => parseFormDataToPayload(values as unknown as MissionOutcomesAndRecommendations)}
        initialValues={initialValues as Record<string, any>}
        validateOn='change'
        yupSchema={updateType === 'draft' ? validationSchemaDraft : validationSchemaSubmit}
        onSubmitFailure={() => updateState({})}
        onValueChange={() => updateState({})}
        formApiRef={formRef}
      >
        <InformedField
          label='Outcomes'
          name='outcomes'
          maxLength={1000}
          formRefApi={formRef}
          description='Provide details of information and data collected at the site'
          informedComponent={<TextArea maxLength={1000} className='text-area' name='outcomes' />}
        />
        <InformedField
          name='recommendations'
          formRefApi={formRef}
          maxLength={1000}
          label='Recommendations'
          description='Enter your recommendations based on meetings, visits, and observations'
          informedComponent={<TextArea maxLength={1000} className='text-area' name='recommendations' />}
        />
        <div className='buttons-container'>
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            onClick={() => onStepBackClick()}
            variant='transparent'
            className='button back'
            text='Back'
            icon={<ArrowLeftOutlined />}
          />
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            className='button'
            variant='transparent'
            onClick={() => onSubmitButtonClick('draft')}
            text='Save as draft'
          />
          <Button
            disabled={finalizeStepMutation.status === 'loading' || updateMutation.status === 'loading'}
            icon={<ArrowRightOutlined />}
            className='button go-next'
            onClick={() => onSubmitButtonClick('submit')}
            text='Save and go next'
          />
        </div>
      </Form>
    </div>
  );
};

export default MissionOutcomeStep;
